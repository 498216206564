import { Box, Paper, Typography } from "@mui/material";
import React from "react";

const ChatSection = () => {
  const messages = [
    {
      id: 1,
      text: "Hey! How are the Saturday morning chores going?",
      sender: "friend",
      timestamp: "10:30 AM",
    },
    {
      id: 2,
      text: "Done actually! My personal assistant took care of it all. I'm headed to Storvik park with the kids now. You should come join! ☀️ ",
      sender: "me",
      timestamp: "10:32 AM",
    },
    {
      id: 3,
      text: "Wow, that sounds amazing! Do you still have that referral link? I could use a hand getting the kids organized on the weekdays. I'll try to be there soon!",
      sender: "friend",
      timestamp: "10:33 AM",
    },
  ];

  return (
    <Box
      sx={{
        p: 2,
        maxWidth: "500px",
        margin: "auto",
        // backgroundColor: "white",
        // borderRadius: "15px",
      }}
    >
      {/* <Typography align="center" variant="caption">Today 10:30 AM</Typography> */}
      {messages.map((message) => (
        <Box
          key={message.id}
          sx={{
            position: "relative",
            maxWidth: "75%",
            my: 1,
            ml: message.sender === "me" ? "auto" : "0",
            mr: message.sender === "me" ? "0" : "auto",
            "&::before": {
              content: '""',
              position: "absolute",
              bottom: "0",
              width: "0",
              height: "0",
              border: "10px solid transparent",
              borderBottom: "0",
              borderTopColor:
                message.sender === "me" ? "hsl(194deg 100% 45%)" : "grey.200",
              // Adjust the transform and position for a more iMessage-like tail
              transform:
                message.sender === "me"
                  ? "translate(-50%, 0%) rotate(-135deg)"
                  : "translate(50%, 0%) rotate(135deg)",
              right: message.sender === "me" ? "-16px" : "auto",
              left: message.sender === "me" ? "auto" : "-16px",
              // Ensure the tail is properly positioned at the bottom of the bubble
              bottom: "-8px",
            },
          }}
        >
          <Paper
            elevation={0}
            sx={{
              py: 1,
              px: 2,
              bgcolor:
                message.sender === "me" ? "hsl(194deg 100% 45%)" : "grey.200",
              borderRadius: "20px",
              // Fine-tune the border radius for a smoother bubble appearance
              borderRadius:
                message.sender === "me"
                  ? "20px 20px 0 20px"
                  : "20px 20px 20px 0",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                color:
                  message.sender === "me" ? "common.white" : "text.primary",
              }}
            >
              {message.text}
            </Typography>
          </Paper>
        </Box>
      ))}
    </Box>
  );
};

export default ChatSection;
