import { Loop } from "@mui/icons-material";
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthProvider";
import { useUser } from "../../contexts/UserProvider";
import { db } from "../../firebase";
import { formatName } from "../../services/stringServices";
import CopyUrlButton from "../buttons/CopyUrlButton";
import FindOfferHelp from "../carousels/FindOfferHelp";

const InviteDash = () => {
  const theme = useTheme();
  const { currentUser } = useAuth();

  const { userPrivateData } = useUser();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [referredUsers, setReferredUsers] = useState([]); // State to store the fetched users
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchReferredUsers = async () => {
      const referredUids = userPrivateData?.referralStats?.referredUids;

      if (!referredUids || referredUids.length === 0) {
        setLoading(false);
        return;
      } // Exit if there are no referred UIDs to fetch

      const usersPromises = referredUids.map((uid) =>
        getDoc(doc(db, "usersPublic", uid)).then((docSnap) =>
          docSnap.exists() ? docSnap.data() : null
        )
      );

      try {
        const users = await Promise.all(usersPromises);
        // Filter out any null values in case some docs don't exist
        setReferredUsers(users);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching referred users:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchReferredUsers();
  }, [currentUser, userPrivateData?.referralStats?.referredUids]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          position: "relative",
          gap: { xs: 2, md: 10 },
          mt: { xs: 0, md: "32px" },
        }}
      >
        <Box
          sx={{
            maxWidth: "500px",
            width: "100%",
            gap: { xs: 2, md: 5 },
            mx: { xs: "auto", md: "none" },
            display: "flex",
            flexDirection: "column",
            flex: 0.55,
          }}
        >
          <Box
            sx={{
              gap: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="h3" fontWeight={"550"}>
              Invite others to join
            </Typography>
            <Typography>
              If you know someone that is looking for help or has skills to
              offer, invite them to join the community!
            </Typography>
            <Box>
              <CopyUrlButton
                url={`${window.location.origin}${
                  currentUser?.uid ? `?ref=${currentUser.uid}` : ""
                }`}
              />
            </Box>
          </Box>
          {!isMobile && <FindOfferHelp />}
        </Box>
        <Box sx={{ flex: 0.45, mt: { xs: 0, md: 9 } }}>
          <Typography sx={{ fontWeight: "600", fontSize: "1.15rem", mb: 1 }}>
            Your invites
          </Typography>
          <List sx={{ width: "100%", bgcolor: "grey.100", borderRadius: 2 }}>
            {loading ? (
              <ListItem
                key={"loading"}
                sx={{ borderRadius: "10px", width: "auto" }}
              >
                <ListItemAvatar>
                  <Skeleton variant="circular" width={40} height={40} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Skeleton variant="rounded" width={90} sx={{ mb: 1 }} />
                  }
                  secondary={<Skeleton variant="rounded" width={120} />}
                />
              </ListItem>
            ) : referredUsers.length > 0 ? (
              referredUsers.map((user, index) => (
                <ListItem
                  key={index}
                  sx={{ borderRadius: "10px", width: "auto" }}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={`${user.firstName} ${user.lastName}`}
                      src={user.avatarUrl}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      user?.firstName && user?.lastName
                        ? `${formatName(user.firstName)} ${formatName(
                            user.lastName
                          )}`
                        : "Guest user"
                    }
                    secondary={`Joined ${
                      user?.createdAt
                        ? DateTime.fromJSDate(
                            user.createdAt.toDate()
                          ).toRelative()
                        : "Not available"
                    }`}
                  />
                </ListItem>
              ))
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  m: 3,
                }}
              >
                <Loop color={"disabled"} />
                <Typography sx={{ color: "text.secondary" }}>
                  No one joined yet. Check back soon!
                </Typography>
              </Box>
            )}
          </List>
        </Box>
        {isMobile && (
          <Box sx={{ mt: 3 }}>
            <FindOfferHelp />
          </Box>
        )}
      </Box>
    </>
  );
};

export default InviteDash;
