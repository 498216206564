import { Check, KeyboardArrowLeft } from "@mui/icons-material";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const WalkthroughView = ({ slidesData, handleClose }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const isLastStep = currentStep === slidesData.length - 1;
  const isFirstStep = currentStep === 0;

  const handleNext = () => {
    if (isLastStep) {
      handleClose(); // Assuming handleClose is a function prop for closing the modal
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = () => {
    if (!isFirstStep) {
      setCurrentStep(currentStep - 1);
    }
  };

  if (slidesData.length === 0) {
    // Fallback content when there are no slides
    return (
      <DialogContent>
        <Typography variant="h6" gutterBottom>
          No Information Available
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Sorry, there are no steps to display at this time.
        </Typography>
        <DialogActions>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </DialogContent>
    );
  }

  const currentSlide = slidesData[currentStep] || {};

  return (
    <>
      <DialogContent>
        <Button
          disabled={isFirstStep}
          onClick={handleBack}
          sx={{ color: "text.secondary", mb: 2, px: 0, minWidth: "50px" }}
        >
          <KeyboardArrowLeft /> Back
        </Button>
        <Box>
          {currentSlide.icon ? <currentSlide.icon sx={{fontSize: 40, color: "primary.main", mb: 2,}} /> : null}
          <Typography
            align="left"
            fontWeight={"600"}
            fontSize={"1.15rem"}
            gutterBottom
          >
            {currentSlide.title || "No Title"}
          </Typography>
          <Typography align="left" color={"text.secondary"} sx={{ mb: 1 }}>
            {currentSlide.subtitle || "No Subtitle Available"}
          </Typography>
          <List>
            {currentSlide.bullets ? (
              currentSlide.bullets.map((bullet, idx) => (
                <ListItem key={idx} disablePadding>
                  <ListItemIcon sx={{ minWidth: "42px" }}>
                    <Check color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={bullet} />
                </ListItem>
              ))
            ) : (
              <Typography> No details available. </Typography>
            )}
          </List>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          disableElevation
          color="primary"
          variant="contained"
          onClick={handleNext}
          sx={{
            width: "100%",
            height: "56px",
            textTransform: "none",
          }}
        >
          {isLastStep ? "Finish" : "Next"}
        </Button>
      </DialogActions>
    </>
  );
};

export default WalkthroughView;
