import { Box, Divider, useMediaQuery, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { useHelpers } from "../../contexts/HelpersProvider";
import DatePicker from "./DatePicker";

const AvailabilityView = () => {
  const { helpers, setSelectedDate } = useHelpers();
  const [weekIndex, setWeekIndex] = useState(0);
  const [selectedVisit, setSelectedVisit] = useState();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDateChange = (date) => {
    setSelectedVisit({}); // Reset selectedVisit and its key
    setSelectedDate(date);
    console.log("selectedDate: ", date);
  };

  // Assuming 'helpers' is an array of helper objects with an 'availability' array containing start and end times.
  const createHighlightedDates = (helpers) => {
    // Use a Set to ensure uniqueness of dates
    const uniqueDates = new Set();
    const today = DateTime.now().startOf("day"); // Define the start of today

    // Loop through all helpers
    helpers.forEach((helper) => {
      if (Array.isArray(helper.availability)) {
        // Loop through each availability window for the helper
        helper.availability.forEach((window) => {
          if (window?.start) {
            // Convert start time to a DateTime object and extract the date part
            const date = DateTime.fromJSDate(window.start.toDate()).startOf(
              "day"
            );

            // Only add the date if it's today or later
            if (date >= today) {
              uniqueDates.add(date); // Add to the Set to ensure uniqueness
            }
          }
        });
      }
    });

    // Convert the Set to an array and sort it
    const highlightedDates = Array.from(uniqueDates).sort((a, b) => a - b);

    return highlightedDates; // This array contains all unique dates when any helper is available
  };

  const highlightedDates = createHighlightedDates(helpers);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        gap: 2,
        boxShadow: {
          xs: "none",
          sm: "none",
        },
        borderRadius: "30px",
        px: { xs: 0, sm: 2 },
        mb: { xs: 2, sm: 0 },
      }}
    >
      <DatePicker
        onChange={handleDateChange}
        highlightedDates={highlightedDates}
        weekIndex={weekIndex}
        setWeekIndex={setWeekIndex}
      />
      {isMobile && <Divider sx={{ mx: -2 }} />}
    </Box>
  );
};

export default AvailabilityView;
