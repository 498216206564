import {
  Box,
  DialogContent,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDialog } from "../../contexts/DialogProvider";
import DesktopBackButton from "../buttons/DesktopBackButton";
import UserShareButton from "../buttons/UserShareButton";
import DrawerHeader from "../dialogs/DrawerHeader";
import ProfileCard from "../profile/ProfileCard";

// Update the function signature to accept props
const ProfileDash = ({ inputId, showMobileHeader = true }) => {
  const { id: urlId } = useParams(); // Rename destructured id to avoid naming conflict
  const [userData, setUserData] = useState(null);
  const { handleCloseProfile } = useDialog();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // Determine the id to use, preferring URL parameter over inputId
  const id = urlId || inputId;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let isMounted = true; // Flag to check component's mounted state

    const fetchUserData = async () => {
      if (!id || Object.keys(id).length === 0) return; // Guard clause to ensure id is defined

      const db = getFirestore();
      try {
        const userDoc = doc(db, "usersPublic", id);
        const docSnap = await getDoc(userDoc);

        if (docSnap.exists() && isMounted) {
          // Check if document exists and component is still mounted
          setUserData({ id: docSnap.id, ...docSnap.data() });
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();

    return () => {
      isMounted = false; // Cleanup function to set isMounted to false when the component unmounts
    };
  }, [id]); // Depend on id so that useEffect reacts to changes in either urlId or inputId

  return (
    <>
      {(!isMobile || showMobileHeader) && (
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-start",
              height: "52px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
              <DesktopBackButton />

              <Typography
                variant="h4"
                component="h1"
                sx={{ ml: { xs: 0, sm: 0 } }}
              >
                Profile
              </Typography>
            </Box>
            {userData && (
              <Box sx={{ top: -6, position: "relative" }}>
                <UserShareButton user={userData} />
              </Box>
            )}
          </Box>
          <Divider sx={{ mx: { xs: -2, sm: 0 }, mb: 2 }} />
          <ProfileCard userData={userData} key="profile-dash" />
        </Box>
      )}
      {!showMobileHeader && (
        <>
          <DrawerHeader
            title={"Profile"}
            onClose={handleCloseProfile}
            anchor="right"
            actions={<UserShareButton user={userData} />}
          />
          <DialogContent>
            {/* <Box sx={{ position: "fixed", top: 6, right: 8 }}>
              <UserShareButton user={userData} />
            </Box> */}
            <ProfileCard userData={userData} key="profile-dash" />
          </DialogContent>
        </>
      )}
    </>
  );
};

export default ProfileDash;
