import {
  AccessTime,
  Autorenew,
  Block,
  BuildOutlined,
  PaymentOutlined,
  ReportProblemOutlined,
  Security,
  ThumbDown,
  ThumbDownOutlined,
} from "@mui/icons-material";
import { DialogContent } from "@mui/material";
import React, { useState } from "react";
import { useUser } from "../../contexts/UserProvider";
import GridButtons from "../buttons/GridButtons";
import CreateReportForm from "../forms/CreateReportForm";

const ReportCard = ({ role = "user", defendant, visit, handleClose }) => {
  const [selected, setSelected] = useState("");
  const { userPrivateData } = useUser();

  const selectReport = (label) => {
    setSelected(label);
  };

  const requesterActions = [
    {
      icon: <Block color="primary" />,
      primary: "Avoiding the Platform",
      secondary: "Suggesting off-app transactions to bypass rules or fees.",
      action: selectReport,
      label: "avoiding_platform",
    },
    {
      icon: <Autorenew color="primary" />,
      primary: "No Show",
      secondary: "The helper did not arrive at all without communicating.",
      action: selectReport,
      label: "no_show",
    },
    {
      icon: <AccessTime color="primary" />,
      primary: "Unreliability",
      secondary:
        "Being late, canceling without notice, or lacked clear communication.",
      action: selectReport,
      label: "unreliability",
    },
    {
      icon: <ThumbDownOutlined color="primary" />,
      primary: "Unprofessional Behavior",
      secondary: "Rudeness, inappropriate language, or lack of respect.",
      action: selectReport,
      label: "unprofessional_behavior",
    },
    {
      icon: <BuildOutlined color="primary" />,
      primary: "Poor Quality Work",
      secondary: "Tasks performed inadequately or incompletely.",
      action: selectReport,
      label: "poor_quality",
    },
    {
      icon: <Security color="primary" />,
      primary: "Safety Issues",
      secondary: "Anything making you feel unsafe or breaching your privacy.",
      action: selectReport,
      label: "safety_issues",
    },
  ];

  const helperActions = [
    {
      icon: <Block color="primary" />,
      primary: "Avoiding the Platform",
      secondary: "Suggesting off-app transactions to bypass rules or fees.",
      action: selectReport,
      label: "avoiding_platform",
    },
    {
      icon: <Block color="primary" />,
      primary: "Payment Issues",
      secondary: "Delays or failures in receiving payment as agreed.",
      action: selectReport,
      label: "payment_issues",
    },
    {
      icon: <Autorenew color="primary" />,
      primary: "No Show",
      secondary: "The other person was not not present for the scheduled service.",
      action: selectReport,
      label: "requester_no_show",
    },
    {
      icon: <ReportProblemOutlined color="primary" />,
      primary: "Miscommunication",
      secondary: "Significant differences between job description and reality.",
      action: selectReport,
      label: "miscommunication",
    },
    {
      icon: <ThumbDown color="primary" />,
      primary: "Disrespectful Behavior",
      secondary: "Rudeness or disrespect during communication or service.",
      action: selectReport,
      label: "disrespectful_behavior",
    },
    {
      icon: <Security color="primary" />,
      primary: "Safety Issues",
      secondary: "Anything making you feel unsafe or breaching your privacy.",
      action: selectReport,
      label: "safety_issues",
    },
  ];

  const userActions = [
    {
      icon: <Block color="primary" />,
      primary: "Avoiding the Platform",
      secondary: "Suggesting off-app transactions to bypass rules or fees.",
      action: selectReport,
      label: "avoiding_platform",
    },
    {
      icon: <Autorenew color="primary" />,
      primary: "No Show",
      secondary:
        "The person did not arrive or was not present without communicating.",
      action: selectReport,
      label: "no_show",
    },
    {
      icon: <AccessTime color="primary" />,
      primary: "Unreliability",
      secondary:
        "Being late, canceling without notice, or lacked clear communication.",
      action: selectReport,
      label: "unreliability",
    },
    {
      icon: <ThumbDownOutlined color="primary" />,
      primary: "Unprofessional Behavior",
      secondary: "Rudeness, inappropriate language, or lack of respect.",
      action: selectReport,
      label: "unprofessional_behavior",
    },
    {
      icon: <PaymentOutlined color="primary" />,
      primary: "Payment Issues",
      secondary: "Delays or failures in receiving payment as agreed.",
      action: selectReport,
      label: "payment_issues",
    },
    {
      icon: <ReportProblemOutlined color="primary" />,
      primary: "Miscommunication",
      secondary: "Significant differences between job description and reality.",
      action: selectReport,
      label: "miscommunication",
    },
    {
      icon: <BuildOutlined color="primary" />,
      primary: "Poor Quality Work",
      secondary: "Tasks performed inadequately or incompletely.",
      action: selectReport,
      label: "poor_quality",
    },
    {
      icon: <Security color="primary" />,
      primary: "Safety Issues",
      secondary: "Anything making you feel unsafe or breaching your privacy.",
      action: selectReport,
      label: "safety_issues",
    },
  ];

  let actions = [];
  if (role === "requester") actions = requesterActions;
  if (role === "helper") actions = helperActions;
  if (role === "user") actions = userActions;

  return (
    <DialogContent>
      {!selected ? (
        <GridButtons actions={actions} />
      ) : (
        <CreateReportForm
          claimant={userPrivateData}
          defendant={defendant}
          visit={visit}
          handleClose={handleClose}
          selectedReport={actions.find((action) => action.label === selected)}
          handleBack={() => setSelected("")}
        />
      )}
    </DialogContent>
  );
};

export default ReportCard;
