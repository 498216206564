import { useTheme } from "@emotion/react";
import { Star } from "@mui/icons-material";
import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useDialog } from "../../contexts/DialogProvider";
import { convertStateNameToAbbreviation } from "../../services/locationServices";
import { formatName } from "../../services/stringServices";

function NameRatingLocationDetails({
  user,
  nameClickable = false,
  size = "normal",
}) {
  const {
    avatarUrl,
    firstName,
    lastName,
    bio,
    skills,
    hourlyRate,
    city,
    state,
    zipCode,
    availability = [],
    id,
    totalReviews,
    averageRating,
  } = user || {};

  const { handleOpenProfile } = useDialog();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const firstNonZeroAvailability =
    ["threeDay", "thisWeek", "nextWeek"].find(
      (key) => availability[key] !== 0
    ) || "none";

  const availabilityMessages = {
    threeDay: { label: "within 3 days", color: "success" },
    thisWeek: { label: "this week", color: "info" },
    nextWeek: { label: "next week", color: "warning" },
    none: { label: "limited", color: "disabled" },
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant={size === "normal" ? "h6" : "caption"}
          fontSize={size === "normal" ? "1.15rem" : "0.8rem"}
          fontWeight={"600"}
          component="div"
          sx={
            nameClickable
              ? {
                  cursor: "pointer",
                  textDecoration: "underline",
                  ":hover": {
                    textDecoration: "underline",
                  },
                }
              : {}
          }
          onClick={nameClickable ? () => handleOpenProfile(id) : undefined}
        >
          {formatName(firstName)} {formatName(lastName)}
        </Typography>
        {averageRating && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Star
              fontSize={size === "normal" ? "1rem" : "0.8rem"}
              sx={{ mr: "6px" }}
            />
            <Typography fontSize={size === "normal" ? "1rem" : "0.8rem"}>
              {averageRating.toFixed(1)}{" "}
              {/* <span style={{ color: "text.secondary" }}> */}({totalReviews})
              {/* </span> */}
            </Typography>
          </Box>
        )}
      </Box>
      <Typography
        variant="body2"
        color="text.secondary"
        fontSize={size === "normal" ? "0.9rem" : "0.8rem"}
      >
        {city}, {convertStateNameToAbbreviation(state)}
      </Typography>
    </Box>
  );
}

export default NameRatingLocationDetails;
