import { Avatar, Box, Chip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SKILL_LIST } from "../../services/skillServices";

const SkillBubbles = ({ skills = [], keySkill = {} }) => {
  const [randomizedSkills, setRandomizedSkills] = useState([]);

  useEffect(() => {
    // Randomize skills excluding the keySkill
    const skillsToRandomize = skills.filter((skill) => skill !== keySkill.name);
    skillsToRandomize.sort(() => 0.5 - Math.random()); // Simple shuffle

    // Prepend keySkill.name if it exists in the original skills array
    const randomized =
      keySkill.name && skills.includes(keySkill.name)
        ? [keySkill.name, ...skillsToRandomize]
        : skillsToRandomize;

    setRandomizedSkills(randomized);
  }, [skills, keySkill.name]); // Only re-randomize if skills or keySkill change

  const maxSkillsToShow = 3;

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        gap: 0.5,
      }}
    >
      {randomizedSkills.slice(0, maxSkillsToShow).map((skillName, index) => {
        const { Icon } =
          SKILL_LIST.find((skill) => skill.name === skillName) || {};

        return (
          <Chip
            key={index}
            icon={
              Icon ? <Icon sx={{ fontSize: "0.7rem" }} color="action" /> : null
            }
            label={skillName}
            variant="outlined"
            size="small"
            sx={{
              fontSize: "0.7rem",
              color: "gray",
              border: "1px solid rgba(0,0,0,0)",
            }}
          />
        );
      })}

      {randomizedSkills.length > maxSkillsToShow && (
        <Avatar
          sx={{
            bgcolor: "#f5f5f5",
            color: "gray",
            width: 24,
            height: 24,
            fontSize: "0.875rem",
            border: "1px solid rgba(0,0,0,0.12)",
            boxSizing: "border-box",
          }}
        >
          <Typography fontSize={"0.7rem"} fontWeight={"600"} sx={{ mr: "1px" }}>
            +{randomizedSkills.length - maxSkillsToShow}
          </Typography>
        </Avatar>
      )}
    </Box>
  );
};

export default SkillBubbles;
