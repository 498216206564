import {
  Avatar,
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useDashboard } from "../../contexts/DashboardContext";
import { useUser } from "../../contexts/UserProvider";

const StyledBottomNavigation = styled(BottomNavigation)(
  ({ theme, inStandaloneMode }) => ({
    backgroundColor: theme.palette.background.paper,
    paddingBottom: inStandaloneMode ? theme.spacing(2) : 0, // Conditional padding

    "& .MuiBottomNavigationAction-root": {
      transition: "color 0.3s",
      "&, &.Mui-selected": {
        color: theme.palette.action.active,
        padding: theme.spacing(1),
        minWidth: 0, // Override min-width to maintain size consistency
      },
      "&.Mui-selected": {
        color: theme.palette.primary.main,
        position: "relative", // To position the pseudo-element
        "&::before": {
          // Pseudo-element for the highlight at the top
          content: '""',
          position: "absolute",
          top: 0, // Position at the top
          left: "50%",
          transform: "translateX(-50%)",
          width: "70%",
          height: "3px",
          backgroundColor: theme.palette.primary.main,
          // borderRadius: "2px",
        },
      },
    },
    "& .MuiBottomNavigationAction-label": {
      fontSize: "0.75rem", // Smaller label size for a more minimalist look
      transition: "font-size 0.2s",
      "&.Mui-selected": {
        fontSize: "0.75rem", // Smaller label size for a more minimalist look

        fontWeight: "bold", // Make font weight heavier when selected
      },
    },
  })
);

function MobileNav() {
  const { selectedTab, changeTab, tabs, inStandaloneMode } = useDashboard();
  const { userPrivateData } = useUser(); // Use your custom hook to access user data
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

  // Calculate the total unread messages count
  useEffect(() => {
    if (userPrivateData && userPrivateData.unreadConversations) {
      const totalUnreadCount = Object.values(
        userPrivateData.unreadConversations
      ).reduce((acc, current) => acc + current, 0);
      setUnreadMessagesCount(totalUnreadCount);
    }
  }, [userPrivateData]);

  const theme = useTheme();

  const mobileLinks = tabs.filter((tab) => tab.mobile);

  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: "10" }}
      elevation={3}
    >
      <StyledBottomNavigation
        value={selectedTab}
        onChange={(_, value) => {
          changeTab(value);
        }}
        showLabels
        inStandaloneMode={inStandaloneMode}
      >
        {mobileLinks.map((tab) => (
          <BottomNavigationAction
            key={tab.label}
            value={tab.label}
            label={tab.label}
            icon={
              tab.label === "Account" ? (
                <Avatar
                  src={userPrivateData?.avatarUrl || ""}
                  alt="Account"
                  sx={{
                    width: 24,
                    height: 24,
                    border:
                      selectedTab === "Account"
                        ? `2px solid ${theme.palette.primary.main}`
                        : "none",
                  }}
                />
              ) : (
                <Badge
                  variant="dot"
                  badgeContent={
                    tab.label === "Chat"
                      ? unreadMessagesCount
                      : tab.label === "Home"
                      ? userPrivateData?.notificationCount || 0
                      : 0
                  }
                  color="error"
                >
                  {selectedTab === tab.label ? tab.icon : tab.outlineIcon}
                </Badge>
              )
            }
            disabled={tab.disabled}
          />
        ))}
      </StyledBottomNavigation>
    </Paper>
  );
}

export default MobileNav;
