import { KeyboardArrowRight } from "@mui/icons-material";
import { Avatar, AvatarGroup, Box, Typography } from "@mui/material";
import React from "react";
import { formatMessageTime } from "../../services/dateServices";
import { formatName } from "../../services/stringServices"; // Ensure you've the function available

const ConversationsListItem = ({ conversation, currentUser }) => {
  const hasUnreadMessages = conversation?.unreadByUsers?.includes(
    currentUser?.uid || ""
  );

  // Filter out the current user from participantDetails to display others
  const otherParticipants = Object.entries(conversation.participantDetails)
    .filter(([key, _]) => key !== currentUser.uid)
    .map(
      ([, participant]) =>
        `${formatName(participant.firstName)} ${formatName(
          participant.lastName
        )}`
    )
    .join(", ");

  return (
    <Box display="flex" alignItems="flex-start" sx={{ width: "100%" }}>
      {/* Unread Indicator */}
      {hasUnreadMessages && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: 0,
            width: 10,
            height: 10,
            mt: "-5px",
            borderRadius: "50%",
            bgcolor: (theme) => theme.palette.primary.main,
          }}
        />
      )}
      {/* Avatar */}
      <AvatarGroup max={4}>
        {Object.entries(conversation.participantDetails)
          .filter(([key, _]) => key !== currentUser.uid)
          .map(([key, participant], index) => (
            <Avatar
              key={key}
              alt={`${participant.firstName} ${participant.lastName}`}
              src={participant.avatarUrl || ""}
              sx={{ borderRadius: "15px", width: "60px", height: "60px" }}
            />
          ))}
      </AvatarGroup>

      {/* Content Box */}
      <Box display="flex" flexDirection="column" sx={{ ml: 2, width: "100%" }}>
        {/* Name and Timestamp */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2" component="span">
            {otherParticipants}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              component="span"
              variant="caption"
              fontSize={"0.6rem"}
              color="text.secondary"
              sx={{ textAlign: "right" }}
            >
              {formatMessageTime(
                conversation?.lastMessageTimestamp || conversation?.createdAt
              )}
            </Typography>
            <KeyboardArrowRight color="disabled" />
          </Box>
        </Box>

        {/* Last Message */}
        <Box sx={{ maxWidth: { xs: "60vw", sm: "20vw" } }}>
          <Typography
            variant="body2"
            sx={{
              mt: 0.5,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {conversation.lastMessage}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ConversationsListItem;
