import { Close, Search } from "@mui/icons-material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useState } from "react";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  // "&:not(:last-child)": {
  //   borderBottom: 0,
  // },
  "&::before": {
    display: "none",
  },
  borderRadius: "15px",
  overflow: "hidden",
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const FAQAccordion = ({ data, stickySearch = true }) => {
  const [expanded, setExpanded] = React.useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // Filter FAQ data based on search term, ignoring case and spaces
  const filteredData = data.filter(
    (item) =>
      item.question.title
        .toLowerCase()
        .includes(searchTerm.replace(/\s+/g, "").toLowerCase()) ||
      item.question.subtitle
        ?.toLowerCase()
        .includes(searchTerm.replace(/\s+/g, "").toLowerCase()) ||
      item.answer
        .toLowerCase()
        .includes(searchTerm.replace(/\s+/g, "").toLowerCase())
  );

  const clearSearch = () => {
    setSearchTerm("");
  };

  return (
    <div>
      <Box
        sx={{
          my: 2,
          position: "relative",
          backgroundColor: "white",
          zIndex: 10,
          boxSizing: "border-box",
        }}
      >
        <TextField
          fullWidth
          placeholder="Search FAQs..."
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            endAdornment: searchTerm && (
              <InputAdornment position="end">
                <IconButton onClick={clearSearch}>
                  <Close />
                </IconButton>
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <Search sx={{ mr: 1 }} />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {filteredData.length > 0 ? (
        filteredData.map((item, index) => (
          <React.Fragment key={index}>
            <Accordion
              key={index}
              onChange={handleChange(index)}
              expanded={expanded === index}
            >
              <AccordionSummary
                aria-controls={`panel${index + 1}-content`}
                id={`panel${index + 1}-header`}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    alignItems: "center",
                  }}
                >
                  {item.icon && <div>{item.icon}</div>}
                  <Box>
                    <Typography variant="body1" fontWeight={"550"}>
                      {item.question.title}
                    </Typography>
                    {item.question.subtitle && (
                      <Typography variant="body2" color="text.secondary">
                        {item.question.subtitle}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2">{item.answer}</Typography>
              </AccordionDetails>
            </Accordion>
            <Box sx={{ height: "16px" }} />
          </React.Fragment>
        ))
      ) : (
        <Typography variant="body1" sx={{ textAlign: "center", mt: 2 }}>
          No FAQs match your search.
        </Typography>
      )}
    </div>
  );
};

export default FAQAccordion;
