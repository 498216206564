import { KeyboardArrowRight } from "@mui/icons-material";
import { ListItemButton, Typography } from "@mui/material";
import React from "react";
import { useDialog } from "../../contexts/DialogProvider";
import { formatName } from "../../services/stringServices"; // Ensure you've the function available

const ConversationTitle = ({ conversation, currentUser }) => {
  const { handleOpenProfile } = useDialog();

  if (!conversation || Object.keys(conversation).length === 0 || !currentUser)
    return;

  // Filter out the current user from participantDetails to display others
  const otherParticipants = Object.entries(conversation?.participantDetails)
    .filter(([key, _]) => key !== currentUser.uid)
    .map(([key, participant]) => ({
      id: key, // Store the key as id
      name: formatName(`${participant.firstName} ${participant.lastName}`),
    }));

  return (
    <>
      {otherParticipants.map((participant) => (
        <ListItemButton
          key={participant.id} // Use participant's ID as the key
          onClick={() => {
            handleOpenProfile(participant.id);
          }} // Pass ID to the handler
          sx={{ display: "flex", alignItems: "center", borderRadius: "15px" }}
        >
          {/* Avatar and other components remain unchanged */}
          <Typography variant="subtitle2">{participant.name}</Typography>
          <KeyboardArrowRight color="disabled" />
        </ListItemButton>
      ))}
    </>
  );
};

export default ConversationTitle;
