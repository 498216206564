import { KeyboardArrowLeft } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthProvider";
import { useUser } from "../../contexts/UserProvider";
import { db } from "../../firebase";
import { validateAbout } from "../../services/formServices";

const AboutView = ({
  handleNext = () => {},
  handleBack = () => {},
  canSkip = true,
  showBack = false,
}) => {
  const [loading, setLoading] = useState(false);
  const { userPrivateData } = useUser();
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    bio: userPrivateData?.bio || "",
  });

  const { currentUser } = useAuth();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setValues({
      bio: userPrivateData?.bio || "",
    });
  }, [userPrivateData?.bio]);

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;

    const actualValue = type === "checkbox" ? checked : value;

    setValues((prevValues) => ({ ...prevValues, [name]: actualValue }));

    // Clear error for the field
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    let newError = validateAbout(values);
    if (Object.keys(newError).length > 0 && !canSkip) {
      setErrors(newError);
      setLoading(false); // Ensure to stop the loading process here if there are validation errors
      return; // Exit the function if there are errors
    }
    try {
      await setDoc(
        doc(db, "usersPublic", currentUser.uid),
        {
          bio: values.bio,
        },
        { merge: true }
      );

      await setDoc(
        doc(db, "usersPrivate", currentUser.uid),
        {
          bio: values.bio,
        },
        { merge: true }
      );

      handleNext();
    } catch (err) {
      console.log("error: ", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DialogContent>
        {showBack && (
          <ButtonBase
            onClick={handleBack}
            disabled={loading}
            sx={{ color: "text.secondary", my: 1 }}
          >
            <KeyboardArrowLeft /> Back
          </ButtonBase>
        )}
        <Typography
          align="left"
          fontWeight={"600"}
          fontSize={"1.15rem"}
          gutterBottom
        >
          About me
        </Typography>
        <Typography align="left" color={"text.secondary"} sx={{ mb: 2 }}>
          Here's your chance to make a great first impression
        </Typography>
        <TextField
          fullWidth
          placeholder="Tell others about yourself"
          variant="outlined"
          name="bio"
          value={values.bio}
          onChange={handleChange}
          multiline
          rows={4}
          sx={{ my: 1 }}
          error={!!errors.bio}
          helperText={errors.bio || `${(values.bio || "").length}/300`}
          inputProps={{
            maxLength: 300,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "100%",
          }}
        >
          <Button
            disableElevation
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={loading}
            sx={{
              width: "100%",
              height: "56px",
              textTransform: "none",
            }} // Take up half the available width
          >
            {loading ? ( // Conditional rendering based on loading state
              <CircularProgress size={24} color="inherit" />
            ) : (
              <Typography fontWeight={"500"}>Continue</Typography>
            )}
          </Button>

          {canSkip && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: "56px",
                alignItems: "center",
              }}
            >
              <Typography
                align="center"
                onClick={handleNext}
                fontWeight={"500"}
                sx={{ textDecoration: "underline", my: 1, cursor: "pointer" }}
              >
                Skip for now
              </Typography>
            </Box>
          )}
        </Box>
      </DialogActions>
    </>
  );
};

export default AboutView;
