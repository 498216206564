import { KeyboardArrowRight } from "@mui/icons-material";
import {
  Box,
  Chip,
  Grid,
  Icon,
  ListItemButton,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";

const GridButtons = ({ actions, columns = { xs: 1, sm: 2 } }) => {
  const theme = useTheme();

  const handleItemClick = (actionFunction, actionLabel, event) => {
    event.preventDefault();
    event.stopPropagation();
    actionFunction(actionLabel); // Perform the specific action
  };

  // A utility function to calculate the number of columns
  const getColumnSize = (breakpoint) => {
    const sizes = ["xs", "sm", "md", "lg", "xl"];
    let sizeFound = columns[breakpoint];
    if (sizeFound) return 12 / sizeFound; // Convert column count to grid size

    // If specific breakpoint is not defined, find the closest smaller breakpoint that is defined
    let index = sizes.indexOf(breakpoint);
    while (index >= 0) {
      sizeFound = columns[sizes[index]];
      if (sizeFound) return 12 / sizeFound;
      index--;
    }
    return 12; // Default to full width if no configuration is provided
  };

  return (
    <>
      <Grid container spacing={2}>
        {actions.map((item, index) => (
          <Grid
            item
            xs={getColumnSize("xs")}
            sm={getColumnSize("sm")}
            md={getColumnSize("md")}
            lg={getColumnSize("lg")}
            xl={getColumnSize("xl")}
            key={index}
            sx={{ display: "flex" }}
          >
            <Box
              elevation={10}
              sx={{
                borderRadius: "15px",
                width: "100%",
                backgroundColor: "white",
                boxShadow:
                  "0px 10px 20px rgba(0,0,0,0.1), 0px 3px 6px rgba(0,0,0,0.1)",
              }}
            >
              <ListItemButton
                onClick={(event) =>
                  handleItemClick(item.action, item.label, event)
                }
                sx={{
                  display: "flex",
                  position: "relative",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  padding: 2,
                  borderRadius: 1,
                  flexGrow: 1,
                  height: "100%",
                  transition: "border-color 0.3s ease",
                  border: `1px solid rgba(0,0,0,0)`,
                  "&:hover": {
                    borderColor: theme.palette.grey[500],
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: { xs: 1, sm: 2 },
                  }}
                >
                  <Icon
                    sx={{ minWidth: "auto", marginRight: theme.spacing(1) }}
                  >
                    {item.icon}
                  </Icon>
                  <Typography
                    variant="subtitle2"
                    fontSize={"1rem"}
                    fontWeight={"600"}
                  >
                    {item.primary}
                  </Typography>
                </Box>
                {item?.chip?.label && (
                  <Chip
                    label={item?.chip?.label}
                    color={item?.chip?.color || "default"}
                    sx={{ mb: 1 }}
                  />
                )}
                <Typography variant="body2" color="text.secondary">
                  {item.secondary}
                </Typography>
                {item.arrow && (
                  <KeyboardArrowRight
                    sx={{ position: "absolute", right: 12, top: 12 }}
                  />
                )}
              </ListItemButton>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default GridButtons;
