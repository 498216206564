// Adjusted NotificationsList.js with Popover
import {
  Box,
  Divider,
  List,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { doc, runTransaction, updateDoc } from "firebase/firestore";
import React, { useCallback, useRef, useState } from "react";
import { useAuth } from "../../contexts/AuthProvider";
import { useNotifications } from "../../contexts/NotificationsProvider";
import { db } from "../../firebase";
import NotificationItem from "./NotificationItem";

const NotificationsList = ({ notifications, onClose }) => {
  const { hasMore, fetchMoreNotifications } = useNotifications();
  const { currentUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false); // Loading state

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClick = async (notificationId) => {
    const notificationRef = doc(db, "notifications", notificationId);
    updateDoc(notificationRef, {
      readAt: new Date(), // Firestore uses JavaScript Date objects
    });

    const userPrivateDocRef = doc(db, "usersPrivate", currentUser?.uid);

    try {
      await runTransaction(db, async (transaction) => {
        const userPrivateDoc = await transaction.get(userPrivateDocRef);
        if (!userPrivateDoc.exists()) {
          throw "Document does not exist!";
        }

        const currentNotificationCount =
          userPrivateDoc.data().notificationCount;
        // Ensure notificationCount doesn't go negative.
        const newNotificationCount = Math.max(0, currentNotificationCount - 1);

        transaction.update(userPrivateDocRef, {
          notificationCount: newNotificationCount,
        });
      });
    } catch (e) {
      console.error("Transaction failed: ", e);
    }

    {
      !isMobile && onClose();
    }
  };

  // Inside NotificationsList component
  const listRef = useRef(null);

  const handleScroll = useCallback(() => {
    const { scrollTop, scrollHeight, clientHeight } = listRef.current;

    if (scrollTop + clientHeight >= scrollHeight - 5 && hasMore && !isLoading) {
      setIsLoading(true); // Set loading to true before fetching

      // Fetch more notifications if not already loading and if more items are available
      fetchMoreNotifications().then(() => {
        setIsLoading(false); // Reset loading state once fetching is complete
      });
    }
  }, [hasMore, fetchMoreNotifications, isLoading]);

  return (
    <List
      sx={{ maxHeight: { xs: "auto", sm: "500px" }, overflow: "auto" }} // Set a max-height and make it scrollable
      onScroll={handleScroll}
      ref={listRef}
      disablePadding
    >
      {notifications.length === 0 && (
        <Typography align="center" sx={{ my: 2, color: "text.secondary" }}>
          You have no notifications
        </Typography>
      )}
      {notifications.map((notification, index) => (
        <React.Fragment key={notification.id}>
          <NotificationItem notification={notification} onClick={handleClick} />
          {index < notifications.length - 1 && (
            <Divider variant="fullWidth" component="li" />
          )}
        </React.Fragment>
      ))}
      {/* Skeleton loaders displayed when isLoading is true */}
      {isLoading &&
        Array.from(new Array(3)).map((_, index) => (
          <React.Fragment key={index}>
            <Divider variant="fullWidth" component="li" />
            <Box
              key={index}
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "row",
                boxSizing: "border-box",
                gap: 2,
              }}
            >
              <Skeleton variant="circular" width={56} height={56} />
              <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                <Skeleton variant="text" width="90%" />
                <Skeleton variant="text" width="80%" />
                <Skeleton variant="text" width="30%" />
              </Box>
            </Box>
          </React.Fragment>
        ))}
    </List>
  );
};

export default NotificationsList;
