import { LocationOn, PostAdd } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Fab,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthProvider";
import { useDialog } from "../../contexts/DialogProvider";
import { useUser } from "../../contexts/UserProvider";
import AddressView from "../auth/AddressView";
import ResponsiveDialog from "../dialogs/ResponsiveDialog";
import HelperResults from "../discover/HelperResults";
import FilterBar from "../search/FilterBar";

const DiscoverDash = () => {
  const { userPrivateData, userPrivateLoading } = useUser();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    handleOpenCreateBooking,
    handleOpenLoginOrSignUp,
    handleOpenCreateProfile,
  } = useDialog();

  const [openAddAddress, setOpenAddAddress] = useState();
  const { currentUser } = useAuth();

  const handleOpenAddAddress = () => {
    setOpenAddAddress(true);

    // Uncomment out to open up the sign in instead and not allow anonymous.
    // if (!currentUser || currentUser?.isAnonymous) {
    //   handleOpenLoginOrSignUp({ setupType: "profile" });
    //   return;
    // } else {
    //   handleOpenCreateProfile({ setupType: "profile" });
    // }
  };

  return (
    <div>
      {isMobile && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-start",
              height: "52px",
            }}
          >
            <Typography variant="h4" component="h1" sx={{ height: "52px" }}>
              Explore
            </Typography>

            <Button
              variant="text"
              onClick={handleOpenCreateBooking}
              sx={{
                textTransform: "none",
                height: "44px",
                width: "100px",
              }}
              startIcon={<PostAdd />}
            >
              Job Post
            </Button>
          </Box>

          <Divider sx={{ mx: { xs: -2, sm: 0 }, mb: 2 }} />
        </>
      )}

      <Box sx={{ gap: 1, display: "flex", flexDirection: "column" }}>
        <FilterBar />
        <HelperResults />
      </Box>
      {!userPrivateData?.line1 && !userPrivateLoading && (
        <Fab
          // size="small"
          onClick={handleOpenAddAddress}
          sx={{
            position: "fixed",
            bottom: { xs: "calc(72px + 16px)", sm: 60 },
            mx: "auto",
            width: "200px",
            boxSizing: "border-box",
            boxSizing: "border-box",
            textTransform: "none",
            right: { xs: 0, sm: 24 },
            left: { xs: 0, sm: "unset" },

            backgroundColor: "rgba(0, 0, 0, 0.75)", // Black with subtle opacity
            color: "white", // White text
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.9)", // Slightly less opacity on hover
            },
          }}
          variant="extended"
        >
          <LocationOn sx={{ mr: "4px" }} />
          <Typography fontSize={"0.85rem"} fontWeight={"600"}>
            Add address
          </Typography>
        </Fab>
      )}
      <ResponsiveDialog
        zIndex={2000}
        open={openAddAddress}
        onClose={() => setOpenAddAddress(false)}
        title={"Add Address"}
        width="600px"
        anchor="bottom"
        fullHeight={true}
      >
        <AddressView
          handleNext={() => setOpenAddAddress(false)}
          handleBack={() => setOpenAddAddress(false)}
          showDisclaimer={!currentUser || currentUser?.isAnonymous}
          showLogin={!currentUser || currentUser?.isAnonymous}
          buttonText="Agree and continue"
        />
      </ResponsiveDialog>
    </div>
  );
};

export default DiscoverDash;
