import { Flag } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthProvider";
import { useDialog } from "../../contexts/DialogProvider";
import { useUser } from "../../contexts/UserProvider";
import { useVisits } from "../../contexts/VisitProvider";
import ReportCard from "../cards/ReportCard";
import ResponsiveDialog from "../dialogs/ResponsiveDialog";

const ReportButton = ({ visitId = "", role = "user", defendant = {} }) => {
  const { visitsData } = useVisits(); // Now expecting an object
  const { currentUser } = useAuth();
  const [openReport, setOpenReport] = useState();
  const { handleOpenCreateProfile, handleOpenLoginOrSignUp } = useDialog();
  const { userPrivateData } = useUser();

  const accountSetup =
    userPrivateData?.accountSetup === "complete" ||
    userPrivateData?.accountSetup === "show_success";

  const visit = visitId ? visitsData[visitId] : null;

  const handleOpen = () => {
    if (!currentUser || currentUser?.isAnonymous) {
      handleOpenLoginOrSignUp();
      return;
    }

    if (!accountSetup) {
      handleOpenCreateProfile();
      return;
    }

    setOpenReport(true);
  };

  return (
    <>
      <Box
        sx={{ display: "flex", justifyContent: "center", width: "100%", my: 2 }}
      >
        <Button
          variant="text"
          color="inherit"
          sx={{
            textTransform: "none",
            width: "100%",
            maxWidth: "300px",
            mx: "auto",
            height: "56px",
          }}
          startIcon={<Flag />}
          onClick={handleOpen}
        >
          Report
        </Button>
      </Box>

      <ResponsiveDialog
        open={openReport}
        onClose={() => setOpenReport(false)}
        title={"Report"}
        width="600px"
        anchor="right"
        fullHeight={true}
        zIndex={2000}
      >
        <ReportCard
          role={role}
          defendant={defendant}
          visit={visit}
          handleClose={() => setOpenReport(false)}
        />
      </ResponsiveDialog>
    </>
  );
};

export default ReportButton;
