import { Typography } from "@mui/material";
import { DateTime } from "luxon";

export const formatDate = (dateInput) => {
  // Ensure input is a Luxon DateTime, handling both Date and DateTime inputs
  const date = normalizeDateToDateTime(dateInput);

  // Return the formatted date
  return date.toFormat("cccc, LLL d");
};

export const formatVisitDate = (timestamp, zone = "America/Los_Angeles") => {
  return DateTime.fromJSDate(timestamp.toDate())
    .setZone(zone)
    .toFormat("cccc, LLLL d");
};

export const formatTimeRange = (startInput, endInput) => {
  // Normalize inputs to Luxon DateTime
  const startTime = normalizeDateToDateTime(startInput);
  const endTime = normalizeDateToDateTime(endInput);

  // Check if either time is invalid
  if (!startTime.isValid || !endTime.isValid) {
    console.error("Invalid date input for formatTimeRange:", {
      startInput,
      endInput,
    });
    return "Time not available"; // A graceful fallback message
  }

  // Format times, dropping ":00" for whole hours
  const startTimeFormatted =
    startTime.minute === 0
      ? startTime.toFormat("h a")
      : startTime.toFormat("h:mm a");
  const endTimeFormatted =
    endTime.minute === 0 ? endTime.toFormat("h a") : endTime.toFormat("h:mm a");

  return `${startTimeFormatted} to ${endTimeFormatted}`;
};

// Utility function to normalize date inputs to Luxon DateTime
const normalizeDateToDateTime = (dateInput) => {
  let dateTime;

  if (DateTime.isDateTime(dateInput)) {
    // Input is already a Luxon DateTime
    dateTime = dateInput;
  } else if (dateInput instanceof Date) {
    // Input is a JavaScript Date
    dateTime = DateTime.fromJSDate(dateInput);
  } else if (dateInput?.toDate && typeof dateInput.toDate === "function") {
    // Input is a Firebase Timestamp
    dateTime = DateTime.fromJSDate(dateInput.toDate());
  } else {
    // Unhandled or invalid type
    console.error("Unsupported date input type:", dateInput);
    return DateTime.invalid("Unsupported date input type"); // Return an invalid DateTime instance
  }

  return dateTime.isValid ? dateTime : DateTime.invalid("Invalid date");
};

// drop the :00 for a time string
export const formatTime = (time, timeZoneId) => {
  const formattedTime = DateTime.fromJSDate(time.toDate())
    .setZone(timeZoneId)
    .toFormat("h:mma");
  return formattedTime.replace(":00", "");
};

export const formatDateRange = (eventDays) => {
  const dates = Object.values(eventDays).map((day) => ({
    start: DateTime.fromJSDate(day.startTime.toDate()),
    end: DateTime.fromJSDate(day.endTime.toDate()),
  }));

  if (dates.length === 0) return "Dates not set";

  // Find the earliest start date and the latest end date
  const earliestStart = DateTime.min(...dates.map((date) => date.start));
  const latestEnd = DateTime.max(...dates.map((date) => date.end));

  if (earliestStart.hasSame(latestEnd, "day")) {
    return earliestStart.toFormat("MMMM d"); // Single day event
  } else if (earliestStart.hasSame(latestEnd, "month")) {
    // If start and end dates are in the same month
    return `${earliestStart.toFormat("MMMM d")} - ${latestEnd.toFormat("d")}`;
  } else {
    // If start and end dates are in different months
    return `${earliestStart.toFormat("MMMM d")} - ${latestEnd.toFormat(
      "MMMM d"
    )}`;
  }
};

export const formatTimeRangeCompact = ({ start, end, timeZoneId }) => {
  const startTime = start.setZone(timeZoneId);
  const endTime = end.setZone(timeZoneId);

  const isSameAmPm = startTime.toFormat("a") === endTime.toFormat("a");

  // Format start time: Include 'a' if AM/PM differs or if minutes are not zero
  const startTimeFormatted = startTime.toFormat(
    `h${startTime.minute !== 0 ? ":mm" : ""}${!isSameAmPm ? " a" : ""}`
  );

  // Format end time: Always include 'a', remove ':00' if on the hour
  const endTimeFormatted = endTime.toFormat(
    `h${endTime.minute !== 0 ? ":mm" : ""} a`
  );

  return `${startTimeFormatted} - ${endTimeFormatted}`;
};

export const formatTimeRangeCompactListItem = ({ start, end, timeZoneId }) => {
  const startTime = DateTime.fromJSDate(start.toDate()).setZone(timeZoneId);
  const endTime = DateTime.fromJSDate(end.toDate()).setZone(timeZoneId);

  const isSameAmPm = startTime.toFormat("a") === endTime.toFormat("a");

  // Format start time: Include 'a' if AM/PM differs or if minutes are not zero
  const startTimeFormatted = startTime.toFormat(
    `h${startTime.minute !== 0 ? ":mm" : ""}${!isSameAmPm ? "a" : ""}`
  );

  // Format end time: Always include 'a', remove ':00' if on the hour
  const endTimeFormatted = endTime.toFormat(
    `h${endTime.minute !== 0 ? ":mm" : ""}a`
  );

  return `${startTimeFormatted} to ${endTimeFormatted}`;
};

export const formatMessageTime = (firebaseTimestamp) => {
  // Check if firebaseTimestamp is valid and can be converted to a Date
  if (!firebaseTimestamp || typeof firebaseTimestamp.toDate !== "function") {
    return "Invalid date"; // or any other fallback value you prefer
  }

  const now = DateTime.now();
  const messageDate = DateTime.fromJSDate(firebaseTimestamp.toDate());

  if (messageDate.toFormat("yyyy-MM-dd") === now.toFormat("yyyy-MM-dd")) {
    // Message is from today
    return messageDate.toFormat("h:mm a");
  } else if (
    messageDate.toFormat("yyyy-MM-dd") ===
    now.minus({ days: 1 }).toFormat("yyyy-MM-dd")
  ) {
    // Message is from yesterday
    return `Yesterday ${messageDate.toFormat("h:mm a")}`;
  } else if (
    now.diff(messageDate, "days").days < 7 &&
    messageDate.weekNumber === now.weekNumber
  ) {
    // Message is from the past week
    return `${messageDate.toFormat("cccc h:mm a")}`;
  } else if (messageDate.year === now.year) {
    // Message is from earlier in the current year
    return messageDate.toFormat("LLLL d, h:mm a");
  } else {
    // Message is from a previous year
    return messageDate.toFormat("LLLL d, yyyy h:mm a");
  }
};

export const renderRelativeTime = (timestamp) => {
  // Assuming 'timestamp' is a Firestore Timestamp object
  const jsDate = timestamp; // Convert Firestore Timestamp to JavaScript Date
  const luxonDateTime = DateTime.fromJSDate(jsDate); // Convert JavaScript Date to Luxon DateTime
  return luxonDateTime.toRelative(); // Format as relative time
};

export const formatDateWithBoldDay = (firebaseTimestamp) => {
  if (!firebaseTimestamp || !firebaseTimestamp.toDate) {
    // Input validation: check if firebaseTimestamp exists and has toDate method
    return <Typography variant="h6">Invalid date</Typography>;
  }
  // Convert Firebase Timestamp to Luxon DateTime
  const date = DateTime.fromJSDate(firebaseTimestamp.toDate());

  // Format the date, e.g., "Friday, May 18th"
  const formattedDate = date.toFormat("cccc, LLLL d");

  // Split the formatted date to separate the day
  const [day, restOfDate] = formattedDate.split(", ");

  return (
    <Typography variant="h6" fontWeight="400" lineHeight={"1.5rem"}>
      <strong>{day}</strong>, {restOfDate}
    </Typography>
  );
};

export function calculateAge(birthMonth) {
  const birthDate = DateTime.fromISO(birthMonth + "-01");
  const diff = DateTime.now().diff(birthDate, ["years", "months"]).toObject();
  const age = Math.floor(diff.years - (diff.months < 0 ? 1 : 0));

  if (age > 40) {
    const decade = Math.floor(age / 10) * 10; // Find the starting year of the decade
    const yearWithinDecade = age % 10; // Find how many years into the decade

    if (yearWithinDecade < 4) return `early ${decade}'s`;
    else if (yearWithinDecade < 7) return `mid ${decade}'s`;
    else return `late ${decade}'s`;
  }

  return age;
}
