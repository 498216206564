import {
  CalendarMonth,
  CalendarToday,
  DriveEta,
  Event,
  EventAvailable,
  Palette,
  PersonSearch,
  Swipe,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthProvider";
import { useUser } from "../../contexts/UserProvider";
import { useVisits } from "../../contexts/VisitProvider";
import MessageBox from "../boxes/MessageBox";
import HelpButton from "../buttons/HelpButton";

import { useDashboard } from "../../contexts/DashboardContext";
import { useDialog } from "../../contexts/DialogProvider";
import {
  deleteAvailabilityAllFuture,
  deleteAvailabilitySeriesFuture,
  deleteSingleAvailability,
} from "../../services/windowServicesV2";
import WeekView from "../calendar/WeekView";
import VisitViewer from "../visits/VisitViewer";

const CalendarDash = () => {
  const { userPrivateData, userPublicData, updateUserSetup } = useUser(); // Fetch user data
  const { currentUser } = useAuth();
  const { visitsData, visits } = useVisits();
  const { handleOpenLoginOrSignUp } = useDialog();
  const { changeTab } = useDashboard();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  console.log(timeZone);

  // for booking view
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedAvailability, setSelectedAvailability] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const stage = userPrivateData?.calendarSetup || "not_started";
  const isHelper = userPublicData?.isHelper;

  const helpData = [
    {
      header: "Add availability",
      body: "Use the availability button to add. You can also set repeating availability. Tap to delete.",
      icon: EventAvailable,
      condition: isHelper,
    },
    {
      header: "Drive time",
      body: "Visits will always have a 30 minute buffer around them where you cannot set availability. This is to give you time to travel between visits.",
      icon: DriveEta,
      condition: isHelper,
    },
    {
      header: "Color coding",
      body: "Your booked visits will show up in purple. If you're a helper, availability will show green and visits booked with you are blue.",
      icon: Palette,
      condition: isHelper,
    },
    {
      header: "Switch between weeks",
      body: "Use the left and right keyboard arrows or the buttons to navigate through different weeks on the calendar.",
      icon: Event,
    },
    {
      header: "View visit details",
      body: "Click on any scheduled visit on the calendar to see detailed information.",
      icon: CalendarToday,
    },
    {
      header: "Swipe to view more",
      body: "On mobile devices, you can swipe left or right to navigate through the calendar or lists.",
      icon: Swipe,
    },

    // Add more items as needed
  ];

  const handleClickAvailability = ({ event, window }) => {
    setMenuAnchorEl({
      left: event.clientX,
      top: event.clientY,
    });
    setSelectedAvailability(window);
  };

  const handleDelete = async ({ availability, type }) => {
    handleCloseMenu();
    setIsLoading(true); // Start loading
    const startTimestamp = availability.start.toJSDate(); // Convert to JavaScript Date

    try {
      switch (type) {
        case "deleteAll":
          await deleteAvailabilityAllFuture(currentUser.uid, startTimestamp);
          break;
        case "deleteThisAndFuture":
          await deleteAvailabilitySeriesFuture(
            currentUser.uid,
            availability.recurringId,
            startTimestamp
          );
          break;
        case "deleteSingle":
        default:
          await deleteSingleAvailability(currentUser.uid, availability.id);
          break;
      }
      console.log("Deletion successful");
    } catch (error) {
      console.error("Error during deletion:", error);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
          mx: { xs: 0, sm: 0 },
          height: "52px",
          flexShrink: 0,
          minHeight: "52px",
          flexGrow: 0,       // Do not allow the box to grow
          flexBasis: "52px"  // Base size before remaining space is distributed
        }}
      >
        <Typography variant="h4" component="h1">
          {isHelper ? "Calendar" : "Visits"}
        </Typography>
        {isHelper && <HelpButton helpData={helpData} />}
      </Box>
      <Divider sx={{ mx: { xs: -2, sm: 0 }, mb: 2 }} />

      {isHelper ? (
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <WeekView
            initialDay={calendarDate}
            visits={visitsData}
            userId={currentUser.uid}
            availability={userPublicData?.availability}
            minBookingMinutes={userPublicData?.minBookingMinutes}
            bookingDaysInAdvance={userPublicData?.bookingDaysInAdvance}
            geohash6={userPublicData?.geohash6}
            skills={userPublicData?.skills}
            bounds={userPublicData?.bounds}
            showAddAvailability={isHelper}
            onClickAvailability={handleClickAvailability}
            // isMobile={false}
            // height={boxHeight - 48}
          />
        </Box>
      ) : visits.length > 0 ? (
        <VisitViewer />
      ) : (
        <MessageBox
          Icon={<CalendarMonth color="primary" />}
          headerText="Schedule a visit"
          messageText="After scheduling your first visit, you will be able to view and manage all your visits here."
          hideButton={true}
          customButton={
            <Button
              variant="outlined"
              disableElevation
              startIcon={<PersonSearch />}
              sx={{ height: "56px", width: "100%", textTransform: "none" }}
              onClick={() => {
                changeTab("Explore");
              }}
            >
              Explore helpers
            </Button>
          }
        />
      )}
      <Menu
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={handleCloseMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          menuAnchorEl
            ? { top: menuAnchorEl.top, left: menuAnchorEl.left }
            : undefined
        }
        sx={{
          borderRadius: "5px",
        }}
      >
        <MenuItem
          onClick={() =>
            handleDelete({
              availability: selectedAvailability,
              type: "deleteSingle",
            })
          }
        >
          Delete this occurrance
        </MenuItem>
        {selectedAvailability?.recurringId && (
          <MenuItem
            key="delete-this-future"
            onClick={() =>
              handleDelete({
                availability: selectedAvailability,
                type: "deleteThisAndFuture",
              })
            }
          >
            Delete this and future series
          </MenuItem>
        )}
        <MenuItem
          key="delete-series"
          onClick={() =>
            handleDelete({
              availability: selectedAvailability,
              type: "deleteAll",
            })
          }
        >
          Delete this and all future
        </MenuItem>
      </Menu>
    </>
  );
};

export default CalendarDash;
