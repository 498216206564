import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from "@mui/material";
import React from "react";
import { useDialog } from "../../contexts/DialogProvider";
import TaskMomLogo from "../../svgs/TaskMomLogo";
import { PersonOutline, WavingHandOutlined } from "@mui/icons-material";

const WelcomeView = ({ handleClose = () => {}, setupType = null }) => {
  const { handleOpenCreateProfile } = useDialog();

  const handleContinue = (type) => {
    handleOpenCreateProfile({ canSkip: type === "profile", setupType: type }); // only allow skip if it's "profile" setup, not helper. Allowing them to complete helper setup before profile would be bad?
    handleClose();
  };

  return (
    <>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            my: 5,
          }}
        >
          <Box width="200px">
            <TaskMomLogo color="#000" />
          </Box>
        </Box>
        <Typography
          align="left"
          fontWeight={"600"}
          fontSize={"1.15rem"}
          gutterBottom
        >
          Welcome to the community
        </Typography>
        <Typography align="left" color={"text.secondary"}>
          Discover, book, and offer help with a wide range of tasks through our
          commuity-driven platform.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        {(setupType === "helper" || setupType === null) && (
          <Button
            color="primary"
            variant="outlined"
            startIcon={<WavingHandOutlined />}
            onClick={() => handleContinue("helper")}
            sx={{
              width: "100%",
              height: "56px",
              textTransform: "none",
            }} // Take up half the available width
          >
            <Typography fontWeight={"500"}>Become a helper</Typography>
          </Button>
        )}
        {setupType === null && (
          <Divider sx={{ color: "text.secondary", width: "100%" }}>or</Divider>
        )}
        {(setupType === "profile" || setupType === null) && (
          <Button
            color="primary"
            variant="contained"
            startIcon={<PersonOutline />}
            onClick={() => handleContinue("profile")}
            sx={{
              width: "100%",
              height: "56px",
              textTransform: "none",
            }} // Take up half the available width
          >
            <Typography fontWeight={"500"}>Create your profile</Typography>
          </Button>
        )}
      </DialogActions>
    </>
  );
};

export default WelcomeView;
