import { Box, Typography, useTheme } from "@mui/material";
import { formatTimeRangeCompact } from "../../services/dateServices";
import { formatName } from "../../services/stringServices";

const VisitItem = ({
  visit,
  window,
  userId,
  timeZone,
  start,
  end,
  onSelect,
}) => {
  const theme = useTheme();

  const status = visit?.status;
  const visitStart = window.start;
  const visitEnd = window.end;

  // console.log("visitStart: ", visitStart);

  // Determine the user's role relative to the visit
  const role =
    userId === visit.requester.id
      ? "requester"
      : visit.helpers[userId]
      ? "helper"
      : "unknown";

  // Extract only the time portion of the visit start and end times
  const visitStartTime = visitStart.hour * 60 + visitStart.minute;
  const visitEndTime = visitEnd.hour * 60 + visitEnd.minute;

  // Similarly for the start and end of the timeline
  const timelineStart = start.hour * 60 + start.minute;
  const timelineEnd = end.hour * 60 + end.minute;

  const totalTimelineMinutes = timelineEnd - timelineStart;
  const startMinutes = visitStartTime - timelineStart;
  const durationMinutes = visitEndTime - visitStartTime;

  // Helper function to format names according to the specified logic, including only confirmed helpers
  const formatHelpersNames = (helpersMap) => {
    // Filter for helpers with responseStatus "confirmed" before mapping their names
    const acceptedHelpers = Object.values(helpersMap)
      .filter((helper) => helper.responseStatus === "accepted")
      .map((helper) => formatName(helper.firstName));

    if (acceptedHelpers.length === 1) {
      // For a single accepted helper, include the first letter of the last name
      const singleHelperKey = Object.keys(helpersMap).find(
        (key) => helpersMap[key].responseStatus === "accepted"
      );
      return `${acceptedHelpers[0]} ${formatName(
        helpersMap[singleHelperKey].lastName.charAt(0)
      )}.`;
    } else if (acceptedHelpers.length > 1) {
      // For multiple accepted helpers, format the string accordingly
      const lastHelper = acceptedHelpers.pop();
      return `${acceptedHelpers.join(", ")} and ${formatName(lastHelper)}`;
    }
    return "Open visit"; // Default text if no accepted helpers
  };

  // Construct the visit title based on the role and the number of helpers
  let visitTitle;
  if (role === "helper") {
    // If it's a helper viewing the visit, show the requester's name
    visitTitle = `${formatName(visit.requester.firstName)} ${formatName(
      visit.requester.lastName
    )?.charAt(0)}.`;
  } else if (role === "requester" && Object.keys(visit.helpers).length > 0) {
    // If it's a requester viewing the visit, format helper names according to the rules
    visitTitle = formatHelpersNames(visit.helpers);
  } else {
    // Default title if no specific conditions are met
    visitTitle = "Open visit";
  }

  const top = (startMinutes / totalTimelineMinutes) * 100;
  const height = (durationMinutes / totalTimelineMinutes) * 100;

  let style;
  switch (status) {
    case "confirmed":
      style = {
        border: `1px solid ${
          role === "helper" ? theme.palette.primary.dark : "hsl(300, 100%, 21%)"
        }`,
        backgroundColor:
          role === "helper" ? theme.palette.primary.main : "hsl(300,100%,30%)",
        color: "white",
      };
      break;
    case "open":
      style = {
        border: `1px solid ${theme.palette.warning.main}`,
        backgroundColor: "hsla(36, 100%, 95%, 1)",
        color: theme.palette.warning.main,
      };
      break;
    default:
      style = { backgroundColor: "grey", color: "white" };
  }

  const handleClick = () => {
    onSelect(visit); // Pass the visit ID to the parent's handler
  };

  return (
    <Box
      sx={{
        ...style,
        position: "absolute",
        top: `${top}%`,
        height: `${height}%`,

        left: { xs: 0, sm: 2, md: 5 },
        right: { xs: 2, md: 5 },
        padding: "5px",
        borderRadius: "5px",
        boxSizing: "border-box",

        overflow: "hidden",
        whiteSpace: "normal", // Allows text to wrap
        wordWrap: "break-word", // Breaks words to prevent overflow
        display: "flex",
        flexDirection: "column",
        zIndex: 1,
        cursor: "pointer", // Make it visually clear that it's clickable
        userSelect: "none",
      }}
      onClick={handleClick}
    >
      <Typography
        variant="subtitle2"
        fontWeight={"600"}
        sx={{
          fontSize: { xs: "0.75rem", sm: "0.875rem" },
          lineHeight: { xs: "0.8rem", sm: "0.9rem" },
          mb: 0.5,
        }}
      >
        {visitTitle}
      </Typography>
      <Typography variant="caption" lineHeight={"0.8rem"}>
        {formatTimeRangeCompact({
          start: window.start,
          end: window.end,
          timeZone: visit.timeZone,
        })}
      </Typography>
    </Box>
  );
};

export default VisitItem;
