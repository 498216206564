import TextField from "@mui/material/TextField";
import React from "react";

const NameField = ({ name, value, error, handleChange, label }) => {
  const handleNameChange = (e) => {
    const sanitizedValue = e.target.value.replace(/[^a-zA-Z-\s']/g, "");
    handleChange({ target: { name: e.target.name, value: sanitizedValue } });
  };

  return (
    <TextField
      fullWidth
      name={name}
      type="text"
      label={label}
      value={value}
      onChange={handleNameChange}
      error={!!error}
      helperText={error}
    />
  );
};

export default NameField;
