import { useMediaQuery, useTheme } from "@mui/material";
import {
  Timestamp,
  collection,
  doc,
  endBefore,
  getDocs,
  limit,
  limitToLast,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { DateTime } from "luxon";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase";
import { useAuth } from "./AuthProvider";

const UserContext = createContext();

export const useVisits = () => useContext(UserContext);

export const VisitsProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [loadingVisits, setLoadingVisits] = useState(true);

  const [visitsData, setVisitsData] = useState({}); // Initialize visitsData as an object
  const unsubscribeRef = useRef([]);
  const visitRangeRef = useRef({});
  const visitIdRef = useRef({});
  const [visits, setVisits] = useState([]);

  const [lastDoc, setLastDoc] = useState(null);
  const [firstDoc, setFirstDoc] = useState(null);
  const [hasMorePastVisits, setHasMorePastVisits] = useState(true);
  const [hasMoreFutureVisits, setHasMoreFutureVisits] = useState(true);

  const [upcomingVisitId, setUpcomingVisitId] = useState(null);

  useEffect(() => {
    if (!currentUser?.uid) return;

    // Fetch only the next visit chronologically
    const now = Timestamp.now();
    const visitsQuery = query(
      collection(db, "visits"),
      where("status", "==", "confirmed"),
      where("participants", "array-contains", currentUser.uid),
      where("end", ">", now), // Consider visits that haven't ended yet
      orderBy("end", "asc"), // Order by end time to get the closest future visit
      limit(1) // Limit to only the closest visit
    );

    const unsubscribe = onSnapshot(visitsQuery, (querySnapshot) => {
      (async () => {
        // Immediately-invoked async function
        for (const change of querySnapshot.docChanges()) {
          if (change.type === "added" || change.type === "modified") {
            const docRef = change.doc.ref;

            // Handle case where "details" document does not exist
            const newDocData = {
              id: change.doc.id,
              startTime: DateTime.fromJSDate(change.doc.data().start.toDate()),
              endTime: DateTime.fromJSDate(change.doc.data().end.toDate()),
              ...change.doc.data(),
            };
            setVisitsData((prevState) => ({
              ...prevState,
              [newDocData.id]: newDocData,
            }));

            setUpcomingVisitId(docRef.id);
          } else if (change.type === "removed") {
            setVisitsData((prevState) => {
              const newState = { ...prevState };
              delete newState[change.doc.id];
              return newState;
            });
          }
        }
      })();
    });

    return () => unsubscribe(); // Cleanup subscription
  }, [currentUser?.uid]);

  // Function to load visits within a specified date range
  const loadVisitsInRange = async (start, end) => {
    if (!currentUser?.uid) return;

    const rangeKey = `${start.toISODate()}_${end.toISODate()}`;

    if (visitRangeRef.current[rangeKey]) {
      return;
    }

    const startTimestamp = Timestamp.fromDate(start.toJSDate());
    const endTimestamp = Timestamp.fromDate(end.toJSDate());

    const visitsQuery = query(
      collection(db, "visits"),
      where("start", ">=", startTimestamp),
      where("end", "<=", endTimestamp),
      where("participants", "array-contains", currentUser.uid)
    );

    const unsubscribe = onSnapshot(visitsQuery, (querySnapshot) => {
      (async () => {
        // Immediately-invoked async function
        for (const change of querySnapshot.docChanges()) {
          if (change.type === "added" || change.type === "modified") {
            const docRef = change.doc.ref;

            // Correctly access DocumentReference from the change object
            const newDocData = {
              id: change.doc.id,
              ...change.doc.data(),
              startTime: DateTime.fromJSDate(change.doc.data().start.toDate()),
              endTime: DateTime.fromJSDate(change.doc.data().end.toDate()),
            };
            setVisitsData((prevState) => ({
              ...prevState,
              [newDocData.id]: newDocData,
            }));
          } else if (change.type === "removed") {
            console.log("Removing visit: ", change.doc.id);
            setVisitsData((prevState) => {
              const newState = { ...prevState };
              delete newState[change.doc.id];
              return newState;
            });
          }
        }
        setLoadingVisits(false);
      })();
    });

    // Store the unsubscribe function using the rangeKey
    visitRangeRef.current[rangeKey] = unsubscribe;
  };

  // Assuming visitIdRef is a useRef hook initialized to an empty object to track subscriptions
  const loadVisitById = async (visitId) => {
    if (!currentUser?.uid || !visitId) return;

    if (visitIdRef.current[visitId]) {
      return;
    }

    const visitRef = doc(db, "visits", visitId);

    const unsubscribe = onSnapshot(visitRef, async (docSnapshot) => {
      if (docSnapshot.exists()) {
        // Handle case where "details" document does not exist
        const newDocData = {
          id: docSnapshot.id,
          startTime: DateTime.fromJSDate(docSnapshot.data().start.toDate()),
          endTime: DateTime.fromJSDate(docSnapshot.data().end.toDate()),
          ...docSnapshot.data(),
        };

        setVisitsData((prevState) => ({
          ...prevState,
          [newDocData.id]: newDocData,
        }));
      } else {
        // Document does not exist anymore or access was revoked, remove from state
        setVisitsData((prevState) => {
          const newState = { ...prevState };
          delete newState[visitId]; // Remove the document from state using its ID
          return newState;
        });
        console.log("No such document or no permission to view it!");

        // If required, unsubscribe here to stop listening to changes
        if (visitIdRef.current[visitId]) {
          visitIdRef.current[visitId]();
          delete visitIdRef.current[visitId];
        }
      }
      setLoadingVisits(false);
    });

    // Store the unsubscribe function using the visit ID
    visitIdRef.current[visitId] = unsubscribe;
  };

  useEffect(() => {
    if (currentUser) {
      fetchInitialVisits();
    }
  }, [currentUser]);

  const fetchInitialVisits = async () => {
    const today = DateTime.now();
    const initialQuery = query(
      collection(db, "visits"),
      where("participants", "array-contains", currentUser.uid),
      where("start", ">=", Timestamp.fromDate(today.toJSDate())),
      orderBy("start", "asc"),
      limit(5)
    );

    const querySnapshot = await getDocs(initialQuery);
    let visitsData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    let first = querySnapshot.docs[0]
    let morePast = true;

    if (visitsData.length < 5) {
      console.log("triggering less than 5");
      // Not enough future visits, fetch past visits to fill up
      const remainingVisitsNeeded = 5 - visitsData.length;
      let pastQuery;
      if (querySnapshot.docs[0]) {
        pastQuery = query(
          collection(db, "visits"),
          where("participants", "array-contains", currentUser.uid),
          orderBy("start", "asc"), // Note the order is now descending to fetch the most recent past visits
          endBefore(querySnapshot.docs[0]),
          limitToLast(remainingVisitsNeeded) // Using limitToLast for backward pagination
        );
      } else {
        pastQuery = query(
          collection(db, "visits"),
          where("participants", "array-contains", currentUser.uid),
          where("start", "<=", Timestamp.fromDate(today.toJSDate())),
          orderBy("start", "asc"), // Note the order is now descending to fetch the most recent past visits
          limitToLast(remainingVisitsNeeded) // Using limitToLast for backward pagination
        );
      }

      const pastQuerySnapshot = await getDocs(pastQuery);
      first = pastQuerySnapshot.docs[0];
      morePast = pastQuerySnapshot.docs.length === remainingVisitsNeeded;
      console.log("pastQuerySnapshot.length: ", pastQuerySnapshot.docs.length);
      console.log("remainingVisitsNeeded: ", remainingVisitsNeeded);

      const pastVisitsData = pastQuerySnapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        // .reverse(); // Reverse to maintain chronological order after merging

      visitsData = [...pastVisitsData, ...visitsData]; // Combine past and future visits
    }

    setVisits(visitsData);
    setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
    setFirstDoc(first);
    setHasMoreFutureVisits(querySnapshot.docs.length === 5);
    setHasMorePastVisits(morePast);
  };

  const loadFutureVisits = async () => {
    if (!lastDoc) return;
    const visitsQuery = query(
      collection(db, "visits"),
      where("participants", "array-contains", currentUser.uid),
      orderBy("start", "asc"),
      startAfter(lastDoc),
      limit(1)
    );
    const querySnapshot = await getDocs(visitsQuery);
    if (!querySnapshot.empty) {
      const newVisits = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setVisits((prevVisits) => [...prevVisits, ...newVisits]);
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setHasMoreFutureVisits(querySnapshot.docs.length === 1);
    } else {
      setHasMoreFutureVisits(false);
    }
  };

  const loadPastVisits = async () => {
    if (!firstDoc) return;
    console.log("firstDoc: ");
    const visitsQuery = query(
      collection(db, "visits"),
      where("participants", "array-contains", currentUser.uid),
      orderBy("start", "asc"),
      endBefore(firstDoc),
      limitToLast(1) // Using limitToLast for backward pagination
    );
    const querySnapshot = await getDocs(visitsQuery);
    if (!querySnapshot.empty) {
      const newVisits = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setVisits((prevVisits) => [...newVisits, ...prevVisits]);
      setFirstDoc(querySnapshot.docs[0]);
      setHasMorePastVisits(querySnapshot.docs.length === 1);
    } else {
      setHasMorePastVisits(false);
    }
  };

  const value = {
    visitsData,
    setVisitsData,
    loadVisitsInRange,
    upcomingVisitId,
    loadVisitById,
    loadingVisits,
    setLoadingVisits,
    fetchInitialVisits,
    loadFutureVisits,
    loadPastVisits,
    visits,
    hasMorePastVisits,
    hasMoreFutureVisits,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
