import { keyframes } from "@emotion/react";
import React, { useEffect, useState } from "react";
import EmailView from "./EmailView";
import ForgotView from "./ForgotView";
import LoginView from "./LoginView";
import SignUpView from "./SignUpView";
import WelcomeView from "./WelcomeView";
import SmsView from "./SmsView";

const fadeInMoveDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const LoginOrSignUp = ({
  handleClose = () => {},
  setHeaderTitle,
  setupType = null,
}) => {
  const [view, setView] = useState("EMAIL");
  const viewTitles = {
    EMAIL: "Login or Sign Up",
    LOGIN: "Welcome back",
    SIGNUP: "Finish signing up",
    WELCOME: "Create your profile",
    FORGOT: "Forgot Password",

  };

  const [email, setEmail] = useState("");

  useEffect(() => {
    const title = viewTitles[view]; // Fetch the title based on current view
    setHeaderTitle(title); // This assumes you have a setHeaderTitle function to update the title
  }, [view]); // Dependency array with 'view' to re-run the effect when 'view' changes

  switch (view) {
    case "EMAIL":
      return <EmailView setView={setView} email={email} setEmail={setEmail} />;
    case "LOGIN":
      return (
        <LoginView
          handleClose={handleClose}
          setView={setView}
          email={email}
          showBack={true}
        />
      );
    case "FORGOT":
      return (
        <SmsView
          handleClose={handleClose}
          setView={setView}
          email={email}
          showBack={true}
        />
      );
    case "SIGNUP":
      return <SignUpView setView={setView} email={email} showBack={true} />;
    case "WELCOME":
      return <WelcomeView handleClose={handleClose} setupType={setupType} />;
    default:
      return;
  }
};

export default LoginOrSignUp;
