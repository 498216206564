import { Event } from "@mui/icons-material";
import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import { useDialog } from "../../contexts/DialogProvider";
import { formatMessageTime } from "../../services/dateServices";
import { formatName } from "../../services/stringServices";

const MessageItem = ({ message }) => {
  const { handleOpenVisit } = useDialog();

  const renderMessage = () => (
    <>
      <Avatar
        sx={{ mt: "4px" }}
        alt={`${message.firstName} ${message.lastName}`}
        src={message.avatarUrl}
      />
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <Typography
            sx={{ display: "inline" }}
            component="span"
            variant="subtitle2"
            color="text.primary"
          >
            {formatName(message.firstName)}
          </Typography>
          <Typography
            sx={{ display: "inline" }}
            component="span"
            variant="caption"
            fontSize={"0.6rem"}
            color="text.secondary"
          >
            {formatMessageTime(message.createdAt)}
          </Typography>
        </Box>
        <Typography
          sx={{ display: "inline" }}
          component="span"
          variant="body2"
          color="text.primary"
        >
          {message.content}
        </Typography>
      </Box>
    </>
  );

  const renderVisitMessage = () => (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Typography
        sx={{ ml: "auto" }}
        component="span"
        variant="caption"
        fontSize={"0.6rem"}
        color="text.secondary"
      >
        {formatMessageTime(message.createdAt)}
      </Typography>
      <Box
        sx={{
          backgroundColor: "#f5f5f5",
          borderRadius: "50px",
          p: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Event sx={{ color: "#6c6c6c" }} />
          <Typography variant="body2" color="text.primary">
            {message.content} -{" "}
            <Typography
              component="span"
              variant="body2"
              color="primary"
              sx={{
                textDecoration: "underline",
                cursor: "pointer",
                color: "text.primary",
              }}
              onClick={() => handleOpenVisit(message?.routingId)}
            >
              show visit
            </Typography>
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  const renderContent = () => {
    if (message.routingCollection === "visits") {
      return renderVisitMessage();
    } else {
      return renderMessage(); // Return null if none of the conditions match
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        py: 1.5,
        px: 2,
        gap: 1,
        boxSizing: "border-box",
      }}
    >
      {renderContent()}
    </Box>
  );
};

export default MessageItem;
