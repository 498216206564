import { KeyboardArrowLeft } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormHelperText,
  Typography,
} from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthProvider";
import { useUser } from "../../contexts/UserProvider";
import { db } from "../../firebase";
import { validateAvatar } from "../../services/formServices";
import AvatarField from "../fields/AvatarField";

const AvatarView = ({
  handleNext = async () => {},
  handleBack = async () => {},
  canSkip = true,
  showBack = false,
}) => {
  const [loading, setLoading] = useState(false);
  const { userPrivateData } = useUser();
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    avatarUrl: userPrivateData?.avatarUrl || "",
  });

  useEffect(() => {
    // Update state with new phone data when userPrivateData changes
    setValues({
      avatarUrl: userPrivateData?.avatarUrl || "",
    });
  }, [userPrivateData?.avatarUrl]);

  const { currentUser } = useAuth();

  const handleSubmit = async () => {
    setLoading(true);
    let newError = validateAvatar(values);
    if (Object.keys(newError).length > 0 && !canSkip) {
      setErrors(newError);
      setLoading(false); // Ensure to stop the loading process here if there are validation errors
      return; // Exit the function if there are errors
    }
    try {
      await setDoc(
        doc(db, "usersPublic", currentUser.uid),
        {
          avatarUrl: values.avatarUrl,
        },
        { merge: true }
      );

      await setDoc(
        doc(db, "usersPrivate", currentUser.uid),
        {
          avatarUrl: values.avatarUrl,
        },
        { merge: true }
      );

      await handleNext();
    } catch (err) {
      console.log("error: ", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DialogContent>
        {showBack && (
          <ButtonBase
            onClick={handleBack}
            disabled={loading}
            sx={{ color: "text.secondary", my: 1 }}
          >
            <KeyboardArrowLeft /> Back
          </ButtonBase>
        )}
        <Typography
          align="left"
          fontWeight={"600"}
          fontSize={"1.15rem"}
          gutterBottom
        >
          Profile picture
        </Typography>
        <Typography align="left" color={"text.secondary"} sx={{ mb: 2 }}>
          Choose an easily recognizeable photo for your profile.
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <div style={{ marginBottom: "8px" }}>
            <AvatarField
              avatarUrl={values?.avatarUrl}
              onAvatarUpload={(url) => {
                setValues((prev) => ({ ...prev, avatarUrl: url }));
                setErrors((prev) => ({ ...prev, avatarUrl: "" }));
              }}
            />
            <FormHelperText
              id="my-helper-text"
              error={!!errors.avatarUrl}
              sx={{ ml: "14px" }}
            >
              {errors.avatarUrl
                ? errors.avatarUrl
                : "Your profile picture is viewable by anyone on the platform."}
            </FormHelperText>
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "100%",
          }}
        >
          <Button
            disableElevation
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={loading}
            sx={{
              width: "100%",
              height: "56px",
              textTransform: "none",
            }} // Take up half the available width
          >
            {loading ? ( // Conditional rendering based on loading state
              <CircularProgress size={24} color="inherit" />
            ) : (
              <Typography fontWeight={"500"}>Continue</Typography>
            )}
          </Button>

          {canSkip && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: "56px",
                alignItems: "center",
              }}
            >
              <Typography
                align="center"
                onClick={handleNext}
                fontWeight={"500"}
                sx={{ textDecoration: "underline", my: 1, cursor: "pointer" }}
              >
                Skip for now
              </Typography>
            </Box>
          )}
        </Box>
      </DialogActions>
    </>
  );
};

export default AvatarView;
