import { Box, Typography } from "@mui/material";
import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import SingleLineFooter from "../components/footer/SingleLineFooter";
import AuthHeader from "../components/header/AuthHeader";
import { db } from "../firebase";
import { convertStateNameToAbbreviation } from "../services/locationServices";
import { formatName } from "../services/stringServices";

const SitemapPage = () => {
  const [userLinks, setUserLinks] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const helperQuery = query(
        collection(db, "usersPublic"), // Target the correct collection
        where("isHelper", "==", true) // Check if user is a helper
      );

      const querySnapshot = await getDocs(helperQuery);
      const users = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const links = users.flatMap((user) => {
        const { firstName, city, state, hourlyRate, skills = [] } = user;

        return skills.map((skill) => ({
          url: `/profile/${user.id}?firstName=${encodeURIComponent(
            formatName(firstName)
          )}&skill=${encodeURIComponent(skill)}&city=${encodeURIComponent(
            city
          )}&rate=${encodeURIComponent(hourlyRate)}&state=${encodeURIComponent(
            state
          )}`,
          label: `Hire ${formatName(
            firstName
          )} for help with ${skill} near me in ${city}, ${convertStateNameToAbbreviation(
            state
          )}`,
        }));
      });

      // Add links for /faq and /landing
      const staticLinks = [
        { url: "/faq", label: "Frequently Asked Questions" },
        { url: "/landing", label: "Welcome to TaskMom" },
      ];

      setUserLinks([...staticLinks, ...links]);
    };

    fetchUsers();
  }, []);

  return (
    <Box sx={{ position: "relative" }}>
      <Helmet>
        <title>Find Affordable Local Help Near You | TaskMom</title>
        <meta
          name="description"
          content="Find skilled helpers near you for various tasks around the house. With TaskMom, you can find, book, and offer help with ease within your local community"
        />
      </Helmet>
      <AuthHeader />
      <Box
        sx={{
          maxWidth: "800px",
          mx: "auto",
          pb: 4,
        }}
      >
        <h1>TaskMom: Affordable Local Help for Busy Parents</h1>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))", // Dynamic column layout with a minimum width
            gap: 2, // Space between items
          }}
        >
          {userLinks.map((link) => (
            <Box key={link.url} sx={{ padding: 1 }}>
              <Link
                to={link.url}
                style={{ textDecoration: "underline", color: "black" }} // Style links
              >
                <Typography variant="body1">{link.label}</Typography>
              </Link>
            </Box>
          ))}
        </Box>
      </Box>
      <SingleLineFooter />
    </Box>
  );
};

export default SitemapPage;
