import {
  Dialog,
  DialogActions,
  Divider,
  SwipeableDrawer,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React from "react";
import DrawerHeader from "./DrawerHeader";

const ResponsiveDialog = ({
  title,
  open,
  onClose,
  children,
  dialogActions = false,
  width = "800px",
  fullHeight = "auto",
  maxHeight = "auto",
  anchor = "bottom",
  desktopAnchor = null,
  showClose = true,
  showBackdrop = true,
  showLogo = false,
  zIndex = false,
  actions = null,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const borderRadius = anchor === "bottom" ? "15px" : "0px";
  const mobileHeight = anchor === "bottom" ? "98svh" : "100svh";

  return isMobile ? (
    <>
      <SwipeableDrawer
        anchor={anchor}
        // variant={showClose ? "persistent" : "permanent"}
        open={open}
        onClose={onClose}
        onOpen={() => {}}
        disableSwipeToOpen={true}
        ModalProps={{
          sx: {
            zIndex: zIndex, // Apply the zIndex directly here
          },
        }}
        PaperProps={{
          sx: {
            // className: "hideScrollbar",
            borderTopLeftRadius: { xs: borderRadius, sm: "0px" }, // Rounded top-left corner
            borderTopRightRadius: { xs: borderRadius, sm: "0px" }, // Rounded top-right corner
            maxHeight: mobileHeight,
            height: fullHeight,
            width: "100%",
            zIndex: `${zIndex} !important`, // Using !important to override any other styles
            boxShadow:
              "0px 8px 10px -5px rgba(0,0,0,0.2), 0px 16px 24px 2px rgba(0,0,0,0.14), 0px 6px 30px 5px rgba(0,0,0,0.12)",
            // boxSizing: "border-box",
          },
        }}
      >
        {/* SwipeableDrawer content */}
        {title && <DrawerHeader title={title} onClose={onClose} anchor={anchor} showClose={showClose} showLogo={showLogo} actions={actions} />}
        {/* {open && children} */}
        {children}
        {dialogActions && (
          <DialogActions
            sx={{
              boxShadow:
                "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
              justifyContent: "center",
            }}
          >
            {dialogActions}
          </DialogActions>
        )}
      </SwipeableDrawer>
    </>
  ) : (
    <>
      {desktopAnchor && (
        <SwipeableDrawer
          disableSwipeToOpen={true}
          open={open}
          anchor={desktopAnchor}
          onClose={onClose}
          slotProps={{
            backdrop: {
              style: {
                backgroundColor: showBackdrop ? undefined : "transparent",
              },
            },
          }}
          PaperProps={{
            sx: {
              width: width,
              maxWidth: width,
              zIndex: zIndex && zIndex,
              borderTopLeftRadius: { xs: "15px", sm: "0px" }, // Rounded top-left corner
              borderTopRightRadius: { xs: "15px", sm: "0px" }, // Rounded top-right corner
              maxHeight: `min(calc(100% - 64px), ${maxHeight})`,
            },
          }}
        >
          {title && <DrawerHeader title={title} onClose={onClose} anchor={anchor} showClose={showClose} showLogo={showLogo} actions={actions} />}
          {children}
          {dialogActions && (
            <>
              <Divider />
              <DialogActions
                sx={{
                  justifyContent:
                    desktopAnchor === "right" ? "flex-start" : "flex-end",
                }}
              >
                {dialogActions}
              </DialogActions>
            </>
          )}
        </SwipeableDrawer>
      )}
      {desktopAnchor === null && (
        <Dialog
          open={open}
          onClose={onClose}
          fullWidth={true}
          hideBackdrop={!showBackdrop}
          PaperProps={{
            sx: {
              zIndex: zIndex && zIndex,
              position: "relative", // Set position to relative
              borderRadius: "15px",
              width: width,
              maxWidth: width,
              maxHeight: `min(calc(100% - 64px), ${maxHeight})`,
              overflow: "hidden",
            },
          }}
        >
          {/* Dialog content */}
          {title && <DrawerHeader title={title} onClose={onClose} anchor={anchor} showClose={showClose} showLogo={showLogo} actions={actions} />}

          {children}
          {dialogActions && (
            <>
              <Divider />
              <DialogActions>{dialogActions}</DialogActions>
            </>
          )}
        </Dialog>
      )}
    </>
  );
};

export default ResponsiveDialog;
