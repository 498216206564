import {
  CelebrationOutlined,
  CottageOutlined,
  InterestsOutlined,
  PaletteOutlined,
  PetsOutlined,
} from "@mui/icons-material";
import { Box, Card, CardContent, Divider, Typography } from "@mui/material";
import React from "react";

const facts = [
  {
    id: "myFunFact",
    icon: <CelebrationOutlined />,
    label: "My fun fact",
  },
  {
    id: "myAllAbout",
    icon: <InterestsOutlined />,
    label: "I'm all about",
  },
  {
    id: "mySecretTalent",
    icon: <PaletteOutlined />,
    label: "Secret talent",
  },
  {
    id: "myFamily",
    icon: <CottageOutlined />,
    label: "My family",
  },
  {
    id: "myPets",
    icon: <PetsOutlined />,
    label: "My pets",
  },
];

const FactsDetails = ({ factsData = {} }) => {
  console.log("factsData: ", factsData);

  if (Object.values(factsData).every((fact) => fact === "")) return null;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: 1,
        bgcolor: "background.paper",
        boxSizing: "border-box",
        mx: { xs: 1, sm: 0 },
      }}
    >
      <Divider sx={{ mt: { xs: 2, sm: 4 }, mb: 2 }} />
      <Typography
        variant="h6"
        fontSize={"1.15rem"}
        fontWeight={"600"}
        sx={{ mb: 2 }}
      >
        Personal trivia
      </Typography>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
          gap: "16px",
        }}
      >
        {facts.map((fact) => {
          const userFact = factsData[fact.id];
          if (!userFact) return null;

          return (
            <Card
              key={fact.id}
              sx={{
                backgroundColor: "#F5F5F5",
                borderRadius: "15px",
                height: "min-content",
                boxShadow: "none",
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="body2" color="text.secondary">
                      {fact.label}:
                    </Typography>
                    <Typography variant="body1">{userFact}</Typography>
                  </Box>
                  <Box sx={{ minWidth: 38, color: "text.secondary" }}>
                    {fact.icon}
                  </Box>
                </Box>
              </CardContent>
            </Card>
          );
        })}
      </div>
    </Box>
  );
};

export default FactsDetails;
