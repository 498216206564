import {
  collection,
  endAt,
  getDocs,
  orderBy,
  query,
  startAt,
  where,
} from "firebase/firestore";
import { geohashQueryBounds } from "geofire-common"; // make sure you have 'geofire-common' installed
import { db } from "../firebase";

const BASE32 = "0123456789bcdefghjkmnpqrstuvwxyz";
const BITS = [16, 8, 4, 2, 1];

export function decodeGeoHash(geohash) {
  const bounds = decodeGeoHashToBounds(geohash);
  if (bounds.length === 0) return [0, 0];
  const lat = (bounds.sw.lat + bounds.ne.lat) / 2;
  const lng = (bounds.sw.lng + bounds.ne.lng) / 2;

  return [lat, lng];
}

function decodeGeoHashToBounds(geohash) {
  if (!geohash) return [];

  let isEven = true;
  const lat = [-90.0, 90.0];
  const lon = [-180.0, 180.0];

  for (let i = 0; i < geohash.length; i++) {
    const c = geohash[i];
    const cd = BASE32.indexOf(c);
    for (let j = 0; j < 5; j++) {
      const mask = BITS[j];
      if (isEven) {
        refineInterval(lon, cd, mask);
      } else {
        refineInterval(lat, cd, mask);
      }
      isEven = !isEven;
    }
  }
  const latCenter = (lat[0] + lat[1]) / 2;
  const lonCenter = (lon[0] + lon[1]) / 2;

  return {
    sw: { lat: lat[0], lng: lon[0] },
    ne: { lat: lat[1], lng: lon[1] },
    center: { lat: latCenter, lng: lonCenter },
  };
}

function refineInterval(interval, cd, mask) {
  if (cd & mask) {
    interval[0] = (interval[0] + interval[1]) / 2;
  } else {
    interval[1] = (interval[0] + interval[1]) / 2;
  }
}

function haversineDistance(coords1, coords2, isMiles = false) {
  // Earth radius in kilometers or miles
  const radius = isMiles ? 3958.8 : 6371;

  const [lat1, lon1] = coords1;
  const [lat2, lon2] = coords2;

  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return radius * c;
}

function toRadians(degrees) {
  return (degrees * Math.PI) / 180;
}

export function calculateDistanceGeohash(geohash1, geohash2) {
  const coords1 = decodeGeoHash(geohash1);
  const coords2 = decodeGeoHash(geohash2);

  return haversineDistance(coords1, coords2, true); // true for miles
}

export function isPointInPolygon(point, polygon) {
  let x, y;

  // Check if point is an array, assume [0] is lat and [1] is lng
  if (Array.isArray(point)) {
    x = point[0];
    y = point[1];
  } else {
    // If point is an object
    x = point.lat;
    y = point.lng;
  }

  let inside = false;

  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    let xi = polygon[i].lat,
      yi = polygon[i].lng;
    let xj = polygon[j].lat,
      yj = polygon[j].lng;

    let intersect =
      yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
}

const stateAbbreviations = {
  Alabama: "AL",
  Alaska: "AK",
  Arizona: "AZ",
  Arkansas: "AR",
  California: "CA",
  Colorado: "CO",
  Connecticut: "CT",
  Delaware: "DE",
  Florida: "FL",
  Georgia: "GA",
  Hawaii: "HI",
  Idaho: "ID",
  Illinois: "IL",
  Indiana: "IN",
  Iowa: "IA",
  Kansas: "KS",
  Kentucky: "KY",
  Louisiana: "LA",
  Maine: "ME",
  Maryland: "MD",
  Massachusetts: "MA",
  Michigan: "MI",
  Minnesota: "MN",
  Mississippi: "MS",
  Missouri: "MO",
  Montana: "MT",
  Nebraska: "NE",
  Nevada: "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  Ohio: "OH",
  Oklahoma: "OK",
  Oregon: "OR",
  Pennsylvania: "PA",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  Tennessee: "TN",
  Texas: "TX",
  Utah: "UT",
  Vermont: "VT",
  Virginia: "VA",
  Washington: "WA",
  "West Virginia": "WV",
  Wisconsin: "WI",
  Wyoming: "WY",
};

export const convertStateNameToAbbreviation = (input = "") => {
  const inputUpperCase = input?.toUpperCase();

  const stateNames = Object.keys(stateAbbreviations);
  const stateAbbreviationValues = Object.values(stateAbbreviations);

  // Check if input is already an abbreviation
  if (stateAbbreviationValues.includes(inputUpperCase)) {
    return inputUpperCase;
  }

  // Convert full state name to abbreviation
  const foundState = stateNames.find(
    (stateName) => stateName.toUpperCase() === inputUpperCase
  );

  return foundState ? stateAbbreviations[foundState] : input;
};

// Function to get requesters in an area
export const getUsersInArea = async (
  { geohash, center },
  radiusInMiles = 5
) => {
  let searchCenter;

  if (geohash) {
    searchCenter = decodeGeoHash(geohash);
  } else if (center && center.lat && center.lng) {
    searchCenter = [center.lat, center.lng];
  } else {
    console.error("Either geohash or center with lat/lng is required");
    return [];
  }

  const radiusInM = radiusInMiles * 1609.34; // Convert miles to meters
  const bounds = geohashQueryBounds(searchCenter, radiusInM);

  let requestersLocations = [];

  try {
    for (const [start, end] of bounds) {
      const q = query(
        collection(db, "requesters"), // Adjust according to your collection name
        where("status", "in", ["active", "waitlist"]), // Query for 'active' or 'waitlist'
        orderBy("location.geohash"),
        startAt(start),
        endAt(end)
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const requesterData = doc.data();
        let coords = decodeGeoHash(requesterData?.location?.geohash);
        let requesterCoordinates = {
          lat: coords[0],
          lng: coords[1],
          status: requesterData.status,
        };

        requestersLocations.push(requesterCoordinates);
      });
    }

    return requestersLocations;
  } catch (error) {
    console.error("Error getting requesters: ", error);
    return [];
  }
};

export const formatAddress = (address) => {
  const { line1, line2, city, state, zipCode } = address;

  let formattedAddress = line1; // Start with line 1, which presumably always exists

  if (line2) {
    formattedAddress += `, ${line2}`; // Add line 2 if it exists
  }

  // Continue adding components with assured existence, separated by commas
  formattedAddress += `, ${city}`;
  formattedAddress += `, ${convertStateNameToAbbreviation(state)}`;

  // Assuming zipCode always exists as well
  formattedAddress += ` ${zipCode}`;

  return formattedAddress;
};
