import { Box, Chip, Divider, Typography } from "@mui/material";
import React from "react";
import { INTEREST_LIST } from "../../services/variableServices";

const InterestsDetails = ({ interests = [], firstName }) => {
  if (interests.length === 0) return;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: 1,
        bgcolor: "background.paper",
        boxSizing: "border-box",
        mx: { xs: 1, sm: 0 },
      }}
    >
      <Divider sx={{ mt: { xs: 2, sm: 4 }, mb: 2 }} />
      <Typography
        variant="h6"
        fontSize={"1.15rem"}
        fontWeight={"600"}
        sx={{ mb: 2 }}
      >
        My interests
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(auto-fill, minmax(150px, 1fr))",
          }, // Creates a responsive grid layout
          gap: { xs: 1, sm: 2 },
        }}
      >
        {interests.map((interest, index) => {
          // Find the interest object from INTEREST_LIST
          const interestObj = INTEREST_LIST.find((s) => s.name === interest);

          // If the interest object is found, render the Chip with the icon; otherwise, render without an icon
          return (
            <Chip
              key={index}
              label={<Typography variant="subtitle1">{interest}</Typography>}
              variant="outlined"
              sx={{
                width: "100%",
                justifyContent: "flex-start",
                height: "44px",
                border: "none",
              }}
              icon={
                interestObj
                  ? React.cloneElement(<interestObj.Icon />, {
                      style: { marginRight: "0px" },
                    })
                  : undefined
              } // Apply margin right directly to the icon
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default InterestsDetails;
