import { Box, Divider, Typography } from "@mui/material";
import React from "react";

const BioDetails = ({ bio = "", firstName = "" }) => {
  if (!bio) return;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: 1,
        bgcolor: "background.paper",
        boxSizing: "border-box",
        mx: { xs: 1, sm: 0 },
      }}
    >
      <Divider sx={{ mt: { xs: 2, sm: 4 }, mb: 2 }} />
      <Typography
        variant="h6"
        fontSize={"1.15rem"}
        fontWeight={"600"}
        sx={{ mb: 2 }}
      >
        About me
      </Typography>
      <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
        {bio}
      </Typography>
    </Box>
  );
};

export default BioDetails;
