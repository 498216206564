import { Stack } from "@mui/material";
import React from "react";
import HelperCard from "./HelperCard"; // Assume this is your HelperCard component

const HelperStack = () => {
  const helpers = [
    {
      id: 3,
      firstName: "Addie",
      lastInitial: "N.",
      rating: 4.2,
      age: "16 years old",
      reviews: 10,
      location: "Anacortes, WA",
      avatarUrl:
        "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/public%2Faddie-pic.jpg?alt=media&token=cd345126-2073-490c-8404-dbc507f05354",
      hourlyRate: 15,
      bio: "Hi! I'm Addie. I'm in high school and I play varsity basketball. I love playing piano at church, helping my dad with construction projects, and hanging out with my family. As the oldest, I am in charge of watching out for my younger siblings. I am also getting into photography! Schedule a visit with me on TaskMom to see if we'd be a good fit.",
    },
    {
      id: 2,
      firstName: "Sophia",
      lastInitial: "C.",
      rating: 4.8,
      age: "19 years old",
      reviews: 8,
      location: "Anacortes, WA",
      avatarUrl:
        "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/public%2Fsophia-pic.jpg?alt=media&token=324fcb42-084e-498d-a633-34a7410d46fa",
      hourlyRate: 18,
      bio: "Hey there! I'm Sophia, a college student with a love for kids and a heart full of care. I'm studying psychology and I'm fascinated by what makes people think. When I'm not studying, I love doing watercolor paintings, reading a good book, or exploring nature on a hike. Singing at my church fills my heart with joy. I'm excited to share my passions and lend a helping hand wherever I can on TaskMom!",
    },
    {
      id: 1,
      firstName: "Julianna",
      lastInitial: "T.",
      rating: 4.5,
      age: "22 years old",
      reviews: 22,
      location: "Anacortes, WA",
      avatarUrl:
        "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/public%2Fjulianna-pic.jpg?alt=media&token=62864e5c-0983-4940-98f1-46ba9ad9b983",
      hourlyRate: 22,
      bio: "Hi, I'm Julianna! I have a heart for nannying, a passion for crocheting, and an eye for photography. I love spending quality time with my family and relaxing. I'm known for my patience and attention to detail, whether it's capturing a moment on camera, caring for someone else, or crafting something special. Book a visit with me on TaskMom!",
    },
    {
      id: 4,
      firstName: "Renee",
      lastInitial: "R.",
      rating: 4.5,
      age: "early 50's",
      reviews: 22,
      location: "Anacortes, WA",
      avatarUrl:
        "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/public%2Frenee-pic.jpg?alt=media&token=109399cb-f0a0-42ad-a337-d4b5d7133bac",
      hourlyRate: 20,
      bio: "Hi my name is Renee. In my free time, I run a local granola company. I am great at researching and planning trips, including flights and lodging. I also love cooking for my family and jogging in the forest during the week. I can assist with cooking, organization, travel planning, health and well-being, local recommendations, and education, including homeschooling through TaskMom.",
    },
  ];
  return (
    <Stack direction="column" spacing={2}>
      {helpers.map((helper) => (
        <HelperCard key={helper.id} helper={helper} />
      ))}
    </Stack>
  );
};

export default HelperStack;
