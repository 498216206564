import { Box, Typography } from "@mui/material";
import React from "react";

const TimeMarker = ({ start, end, height }) => {
  const totalDuration = end.diff(start, "minutes").minutes;
  const markerCount = totalDuration / 15; // 15-minute intervals

  // Determine the shift needed based on the start time
  // console.log("start: ", start);
  const startMinute = start.minute;

  const isLessThanFourHours = end.diff(start, "hours").hours < 4;

  const shift =
    startMinute === 0
      ? 0
      : startMinute === 15
      ? 1
      : startMinute === 30
      ? 2
      : -1;

  // console.log("shift: ", shift);

  // Convert height from "200px" to 200
  const numericHeight = parseInt(height, 10);

  // Generate markers
  const markers = Array.from({ length: markerCount + 1 }, (_, i) => {
    const time = start.plus({ minutes: i * 15 });
    let label = "";
    if ((i + shift) % 4 === 0) {
      label =
        time.minute === 0
          ? time.toFormat("ha").toLowerCase()
          : time.toFormat("h:mma").toLowerCase();
    }

    const is45MinMarker = (i + shift) % 4 === 1 || (i + shift) % 4 === 3;
    const alpha =
      numericHeight < 300 && is45MinMarker && !isLessThanFourHours
        ? 0
        : (i + shift) % 4 === 0
        ? 0.14
        : 0.04;

    return {
      position: (i / markerCount) * 100,
      label: label,
      alpha: alpha,
    };
  });

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
        userSelect: "none",
      }}
    >
      {markers.map((marker, index) => (
        <Box
          key={index}
          sx={{
            position: "absolute",
            top: `${marker.position}%`,
            height: "1px",
            left: 0, // Adjust top for vertical centering
            width: "100%",
            backgroundColor: `rgba(0, 0, 0, ${marker.alpha})`,
            zIndex: "-2",
          }}
        >
          {marker.label && (
            <Typography
              variant="caption"
              sx={{
                position: "absolute",
                top: "50%", // Changed from '100%' to '0'
                transform: "translate(-110%, -50%)", // Adjust to move labels above the line
                whiteSpace: "nowrap",
                color: `rgba(0, 0, 0, 0.6)`,
              }}
            >
              {marker.label}
            </Typography>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default TimeMarker;
