import styled from "@emotion/styled";
import {
  Adb,
  AddBoxOutlined,
  Apple,
  ExploreOutlined,
  Google,
  IosShare,
  Menu,
  MoreVert,
} from "@mui/icons-material";
import {
  Badge,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

// Styled ToggleButtonGroup
const StyledToggleButtonGroup = styled(ToggleButtonGroup, {
  shouldForwardProp: (prop) => prop !== "selectedIndex", // Prevent selectedIndex from being forwarded
})(({ theme, selectedIndex }) => ({
  position: "relative",
  backgroundColor: theme.palette.grey[200],
  // boxShadow: "inset 0 0 8px rgba(0,0,0,0.2)", // Inner shadow for a subtle 3D effect
  textTransform: "none",
  overflow: "hidden",
  width: "100%",

  "&::after": {
    boxShadow: `
        inset 0 0 8px rgba(0,0,0,0.2),`,
    // 0 0 40px 2px hsla( 190, 100%, 50%, 0.5)`, // Add a vibrant blue glow effect
    content: '""',
    position: "absolute",
    bottom: 0,
    height: "calc(100% - 8px)",
    width: `calc(100% / 2 - 4px)`, // Assuming there are only two toggle buttons
    backgroundColor: theme.palette.primary.main,
    transition: "transform 300ms ease",
    transform: `translateX(${selectedIndex * 100}%)`,
    borderRadius: "12px",
    margin: "4px",
  },
}));

// Styled ToggleButton
const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: theme.palette.common.white,
    backgroundColor: "transparent",
  },
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
  border: "none",
  overflow: "hidden",
  zIndex: 1,
  minWidth: { xs: "auto", sm: "200px" },
  width: "100%",
  textTransform: "none",
}));

const InstallInstructions = () => {
  const [platform, setPlatform] = useState("ios");

  const instructions = {
    ios: [
      {
        title: "Open in Safari",
        description: "Already using Safari? You’re in the right place.",
        icon: ExploreOutlined,
      },
      {
        title: "Use Share Button",
        description:
          "Tap the Share icon at the bottom center of the address bar.",
        icon: IosShare,
      },
      {
        title: "Add to Home Screen",
        description: "Scroll down the list and tap 'Add to Home Screen'.",
        icon: AddBoxOutlined,
      },
    ],
    android: [
      {
        title: "Open in Chrome",
        description: "Already using Chrome? You’re in the right place.",
        icon: Google,
      },
      {
        title: "Access Menu",
        description:
          "Tap the menu button in the upper right corner of the screen.",
        icon: MoreVert,
      },
      {
        title: "Add to Home Screen",
        description: "Select 'Add to Home screen' from the menu options.",
        icon: Menu,
      },
    ],
  };

  // Handle platform switch
  const handlePlatformChange = (event, newPlatform) => {
    if (newPlatform !== null) {
      setPlatform(newPlatform);
    }
  };
  const selectedIndex = platform === "ios" ? 0 : 1; // Map platform to indices

  return (
    <>
      <Typography sx={{ maxWidth: "800px", mx: "auto", mb: 4 }}>
        Install the TaskMom app directly from your browser in 3 simple steps.
        This gives the best mobile experience, as we are waiting to launch in
        the App Store until we reach 10,000 users.
      </Typography>

      <StyledToggleButtonGroup
        value={platform}
        exclusive
        onChange={handlePlatformChange}
        selectedIndex={selectedIndex}
        aria-label="Platform"
      >
        <StyledToggleButton
          value="ios"
          aria-label="ios"
          sx={{
            textTransform: "none",
            minWidth: "100px",
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Apple />
          iOS
        </StyledToggleButton>
        <StyledToggleButton
          value="android"
          aria-label="android"
          sx={{
            textTransform: "none",
            minWidth: "100px",
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Adb />
          Android
        </StyledToggleButton>
      </StyledToggleButtonGroup>

      <Box
        sx={{
          display: "flex",
          position: "relative",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "center",
          alignItems: "flex-start",
          gap: 2,
          maxWidth: "460px",
          mx: "auto",
          // mt: { xs: 1, sm: 10 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            maxWidth: "600px",
          }}
        >
          <List>
            {instructions[platform].map((step, index) => (
              <ListItem key={index} disableGutters>
                <ListItemIcon sx={{ mr: 2 }}>
                  {step.icon ? (
                    <step.icon
                      sx={{
                        width: 38,
                        color: "primary.main",
                        height: 38,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "1rem",
                        transform: "scale(1) translate(30%, 0%)",
                      }}
                    />
                  ) : (
                    <Badge
                      badgeContent={index + 1}
                      color="primary"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      sx={{
                        ".MuiBadge-badge": {
                          width: 32,
                          height: 32,
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "1rem",
                          transform: "scale(1) translate(50%, -50%)",
                        },
                      }}
                    />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={`${step.title}`}
                  secondary={step.description}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </>
  );
};

export default InstallInstructions;
