import { keyframes } from "@emotion/react";
import { Box, Button, Typography } from "@mui/material";
import React from "react";

// Define our keyframes for the animation
const fadeInMoveDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const MessageBox = ({
  Icon, // This will now directly accept a React element
  headerText = "Help Others",
  messageText = "Setup your rate, skills, service area, and references, to be listed as a resource in your local community.",
  buttonText = "Setup",
  endIcon = null,
  startIcon = null,

  hideButton = false,
  messageAlign = "left",
  buttonProps = {}, // Additional props for the button
  buttonColor = "inherit",
  handleClick = () => {},
  customButton = null,
  mt = { xs: "56px", sm: "96px" },
  mb = { xs: "56px", sm: "96px" },
}) => (
  <Box
    textAlign="left"
    sx={{
      mt: mt,
      mb: mb,
      opacity: 1,
      maxWidth: {xs: "100%", sm: "350px"},
      mx: { xs: 0, sm: 4 },
      // animation: `${fadeInMoveDown} 1s forwards`,
    }}
  >
    {/* Directly render the Icon element */}
    {React.cloneElement(Icon, {
      sx: { fontSize: 40, mb: 1, ...Icon.props.sx },
    })}
    <Typography variant="h6" sx={{ mb: 1 }}>
      {headerText}
    </Typography>
    <Typography sx={{ mb: 2, maxWidth: "400px", mx: "auto" }}>
      {messageText}
    </Typography>
    {!hideButton && (
      <Button
        variant="outlined"
        onClick={handleClick}
        endIcon={endIcon}
        startIcon={startIcon}
        color={buttonColor}
        sx={{
          height: "56px",
          width: { xs: "100%", sm: "auto" },
          px: 4,
          ...buttonProps,
        }}
      >
        {buttonText}
      </Button>
    )}
    <Box sx={{ maxWidth: "400px", mx: "auto" }}>
      {customButton && customButton}
    </Box>
  </Box>
);

export default MessageBox;
