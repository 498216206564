import {
  Timestamp,
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  getDocs,
} from "firebase/firestore";
import React, { createContext, useContext, useEffect, useState } from "react";
import { db } from "../firebase"; // Assuming this is your configured Firebase instance

const ActivityContext = createContext();

export function useActivity() {
  return useContext(ActivityContext);
}

export const ActivityProvider = ({ children }) => {
  const [activities, setActivities] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  const [listenerUnsubscribe, setListenerUnsubscribe] = useState(null);

  const INITIAL_LIMIT = 6;

  const [hasMore, setHasMore] = useState(true);
  const FETCH_LIMIT = 1;

  const loadMoreActivities = async () => {
    if (isLoading || !lastVisible || !hasMore || activities.length > 100)
      return;

    setIsLoading(true);

    const nextQuery = query(
      collection(db, "activity"),
      orderBy("createdAt", "desc"),
      startAfter(lastVisible),
      limit(FETCH_LIMIT)
    );

    await new Promise((resolve) => setTimeout(resolve, 100));

    const documentSnapshots = await getDocs(nextQuery);

    const newLastVisible =
      documentSnapshots.docs[documentSnapshots.docs.length - 1];
    setLastVisible(newLastVisible);

    const newActivities = documentSnapshots.docs.map((doc) => doc.data());

    // If fewer documents than the limit are returned, we've reached the end
    if (documentSnapshots.docs.length < FETCH_LIMIT) {
      console.log("No more activities to load.");
      setHasMore(false);
    }

    if (documentSnapshots.docs.length > 0) {
      const newLastVisible =
        documentSnapshots.docs[documentSnapshots.docs.length - 1];
      setLastVisible(newLastVisible);
      // setActivities((prevActivities) => [...prevActivities, ...newActivities]);

      setActivities((prevActivities) => {
        // Create a Set of existing activity IDs for quick lookup
        const existingIds = new Set(
          prevActivities.map((activity) => activity.id)
        );

        // Filter out new activities that already exist in the state by their ID
        const uniqueNewActivities = newActivities.filter(
          (newActivity) => !existingIds.has(newActivity.id)
        );

        // Combine previous activities with the unique new activities
        return [...prevActivities, ...uniqueNewActivities];
      });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    // Start real-time listener
    const q = query(
      collection(db, "activity"),
      orderBy("createdAt", "desc"),
      limit(INITIAL_LIMIT)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const updates = [];
      let newLastVisible = null;
      // does this code run each time the snapshot is re-ran?

      querySnapshot.docChanges().forEach((change, index) => {
        if (change.type === "added") {
          updates.push(change.doc.data());
        }
      });
      if (updates.length) {
        // setActivities((prevActivities) => [...updates, ...prevActivities]);

        setActivities((prevActivities) => {
          // Create a Set of existing activity IDs for quick lookup
          const existingIds = new Set(
            prevActivities.map((activity) => activity.id)
          );

          // Filter out updates that already exist in the state by their ID
          const uniqueUpdates = updates.filter(
            (update) => !existingIds.has(update.id)
          );

          // Combine the unique updates with previous activities
          return [...uniqueUpdates, ...prevActivities];
        });

        if (lastVisible === null) {
          newLastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
          setLastVisible(newLastVisible);
        }
      }
    });

    setListenerUnsubscribe(() => unsubscribe);

    // Cleanup function to stop listening
    return () => {
      if (listenerUnsubscribe) listenerUnsubscribe();
    };
  }, []);

  /**
   * Function to add an activity to the Firestore database.
   * @param {string} type The type of activity (e.g., "new_user", "private_visit_booked", "public_visit_booked", "new_helper").
   * @param {Array<string>} tasks An array of tasks, applicable for "private_visit_booked" and "public_visit_booked".
   * @param {string} city The city where the activity occurred.
   * @param {string} state The state where the activity occurred.
   * @param {Array<Object>} users An array of user objects involved in the activity.
   * @param {string} [description] Optional description for the activity, particularly for "public_visit_booked".
   *
   * [X] new user
   * [X] new helper
   * [ ] private visit booked
   * [ ] public visit booked
   */
  const createActivity = async (activityDetails) => {
    const {
      type,
      city,
      state,
      geohash6,
      users,
      tasks = [],
      bounds = [],
      description = "",
      visitId = "",
      date = "",
    } = activityDetails;

    try {
      // Construct the activity object with conditional inclusion of the description field
      const activity = {
        type,
        tasks,
        city,
        state,
        geohash6,
        users,
        bounds,
        createdAt: Timestamp.now(), // Use Firestore Timestamp for consistency
        description,
        visitId,
        date,
      };

      console.log("activity: ", activity)

      // Add the activity to the "Activities" collection
      const docRef = doc(collection(db, "activity"));
      await setDoc(docRef, { id: docRef.id, ...activity });
    } catch (error) {
      console.error("Error adding activity: ", error);
    }
  };

  return (
    <ActivityContext.Provider
      value={{
        createActivity,
        activities,
        setActivities,
        lastVisible,
        setLastVisible,
        loadMoreActivities,
        hasMore,
        isLoading,
        setIsLoading,
        showScrollToTop,
        FETCH_LIMIT,
        setShowScrollToTop,
      }}
    >
      {children}
    </ActivityContext.Provider>
  );
};
