import React, { useEffect, useRef } from "react";
import mojs from "@mojs/core"; // Assuming you have installed mo-js
import { green } from "@mui/material/colors";

const ZINDEX = 3000;

export const ConfirmationAnimation = ({
  triggerAnimation,
  size = "medium",
}) => {
  const parentRef = useRef(null); // 1. Create a ref for the parent div

  const sizes = {
    small: 0.7,
    medium: 1,
    large: 1.5,
    xlarge: 2,
  };

  const scaleFactor = sizes[size] || sizes.medium;
  const RADIUS = 28 * scaleFactor;

  class CheckCircleShape extends mojs.CustomShape {
    getShape() {
      return '<path d="M50 0C22.4 0 0 22.4 0 50C0 77.6 22.4 100 50 100C77.6 100 100 77.6 100 50C100 22.4 77.6 0 50 0ZM36.45 71.45L18.5 53.5C18.0364 53.0375 17.6686 52.488 17.4177 51.8832C17.1667 51.2783 17.0376 50.6299 17.0376 49.975C17.0376 49.3201 17.1667 48.6717 17.4177 48.0668C17.6686 47.462 18.0364 46.9125 18.5 46.45C20.45 44.5 23.6 44.5 25.55 46.45L40 60.85L74.4 26.45C76.35 24.5 79.5 24.5 81.45 26.45C83.4 28.4 83.4 31.55 81.45 33.5L43.5 71.45C41.6 73.4 38.4 73.4 36.45 71.45Z" />';
    }
  }
  mojs.addShape("checkCircle", CheckCircleShape);

  const checkCircle = new mojs.Shape({
    shape: "checkCircle",
    fill: green[500], // Use MUI's green color
    stroke: "none", // Use MUI's green color
    left: "12px",
    top: "12px",
    // origin: "50% 50%",
    scale: { 0: 1 },
    rotate: { [-90]: 0 }, // initial rotation
    zIndex: ZINDEX + 100, // ensure it's on top of everything else
    easing: "elastic.out",
    duration: 2000,
    delay: 200,
    radius: RADIUS / 2,
  });

  const circle = new mojs.Shape({
    left: "12px",
    top: "12px",
    zIndex: ZINDEX, // ensure it's on top of everything else
    stroke: { "#FF4561": "#FF9C00" },
    strokeWidth: { [2 * RADIUS]: 0 },
    fill: "none",
    scale: { 0: 1, easing: "quad.out" },
    radius: RADIUS,
    duration: 650,
  });

  const burst = new mojs.Burst({
    left: "12px",
    top: "12px",
    zIndex: ZINDEX, // ensure it's on top of everything else
    radius: { 6: RADIUS - 7 },
    angle: 45,
    count: 8,
    children: {
      shape: "line",
      radius: RADIUS / 7,
      scale: { 2: 1 },
      stroke: ["#FF9C00", "#FF4561", "#4CAF50", "#448AFF", "#FFC107"],
      strokeDasharray: "100%",
      strokeDashoffset: { "-100%": "100%" },
      degreeShift: [5, 10, 15],
      duration: [900, 700],
      delay: [200, 300],
      easing: "quad.out",
      strokeLinecap: "round", // this makes the ends of the lines rounded
    },
  });

  const burst2 = new mojs.Burst({
    left: "12px",
    top: "12px",
    zIndex: ZINDEX, // ensure it's on top of everything else
    radius: { 4: RADIUS * 1.5 },
    angle: 45,
    count: 14,
    children: {
      radius: 2.5,
      fill: ["#FF9C00", "#FF4561", "#4CAF50", "#448AFF", "#FFC107"],
      scale: { 1: 0, easing: "quad.in" },
      pathScale: [0.8, null],
      degreeShift: [13, null],
      duration: [500, 700],
      easing: "quint.out",
    },
  });

  const timeline = new mojs.Timeline({ speed: 1.5 });
  timeline.add(burst2, circle, checkCircle);

  useEffect(() => {
    // Replay the animation when triggerAnimation changes
    timeline.replay();
    checkCircle.play();
  }, [triggerAnimation]);

  useEffect(() => {
    if (parentRef.current) {
      // 2. Append the mojs objects to this div after they are created
      parentRef.current.appendChild(checkCircle.el);
      parentRef.current.appendChild(circle.el);
      parentRef.current.appendChild(burst.el);
      parentRef.current.appendChild(burst2.el);
    }
  }, [checkCircle, circle, burst, burst2]);

  return (
    <div
      ref={parentRef} // Attach the ref to your div
      style={{
        width: "24px",
        height: "24px",
        position: "relative",
        overflow: "visible",
      }}
    ></div>
  );
};
