import { Box, Button, useTheme } from "@mui/material";
import React from "react";

const StyledChecklist = ({ items, checkedItems, onChange, columns = 1 }) => {
  const theme = useTheme();

  // Calculate the fraction of the width each button should take depending on the number of columns
  const buttonWidth = `${100 / columns}%`;

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: `repeat(${columns}, 1fr)`, // Use grid layout to support multiple columns
        gap: 1,
        alignItems: "center",
        width: "100%",
        mx: "auto",
      }}
    >
      {items.map(({ name, Icon }) => (
        <Button
          key={name}
          fullWidth
          variant="outlined"
          onClick={() => onChange(name)}
          startIcon={Icon && <Icon />}
          sx={{
            borderRadius: "15px",
            height: "48px",
            textTransform: "none",
            borderColor: checkedItems.includes(name)
              ? theme.palette.primary.main
              : theme.palette.grey[200],
            backgroundColor: "#fff",
            color: checkedItems.includes(name)
              ? theme.palette.primary.main
              : "inherit",
            "&:hover": {
              backgroundColor: "#f5f5f5",
              borderColor: checkedItems.includes(name)
                ? theme.palette.primary.main
                : "#adadad",
            },
          }}
        >
          <Box>{name}</Box>
        </Button>
      ))}
    </Box>
  );
};

export default StyledChecklist;
