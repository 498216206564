import {
  Box,
  Button,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import React, { useState } from "react";
import { useUser } from "../../contexts/UserProvider";
import { db } from "../../firebase";
import { SKILL_LIST } from "../../services/skillServices";
import BoundsView from "../auth/BoundsView";
import ReferencesView from "../auth/ReferencesView";
import ResponsiveDialog from "../dialogs/ResponsiveDialog";
import GoogleMapsDisplay from "../maps/GoogleMapsDisplay";
import ReferencesDetails from "../profile/ReferencesDetails";
import StyledSwitch from "../styled/StyledSwitch";
import UserProperty from "./UserProperty";

const UserHelperProfile = () => {
  const { userPrivateData, userPublicData } = useUser();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  const [openMap, setOpenMap] = useState(false);
  const [openReferences, setOpenReferences] = useState(false);

  // Toggles are controled from realtime data, not stale states
  const active = userPublicData?.helperStatus === "active";
  const showMaxBookingMinutes = userPublicData?.maxBookingMinutes !== null;

  console.log("showMaxBookingMinutes: ", showMaxBookingMinutes);

  if (!userPrivateData) return;

  // Function to generate duration options
  const generateDurationOptions = (startMinutes = 15) => {
    const options = [];
    for (let minutes = startMinutes; minutes <= 480; minutes += 15) {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;
      let label = `${hours > 0 ? `${hours} hour${hours > 1 ? "s" : ""} ` : ""}${
        mins > 0 ? `${mins} minute${mins > 1 ? "s" : ""}` : ""
      }`.trim();
      options.push({ value: minutes, label });
    }
    return options;
  };

  const skillsProp = [
    {
      label: "Skills",
      editDescription: "The skills that you can help with.",
      fields: [
        {
          name: "skills",
          label: "Skills",
          fieldType: "multipleChoice",
          allItems: SKILL_LIST,
          value: userPublicData?.skills || [],
        },
      ],
    },
  ];
  const hourlyRateProps = [
    {
      label: "Hourly rate",
      disableEdit: false,
      editDescription: "Set your hourly rate for services.",
      fields: [
        {
          name: "hourlyRate",
          label: "Hourly rate",
          fieldType: "hourlyRate",
          value: userPublicData?.hourlyRate,
        },
      ],
    },
  ];
  const bookingDaysProp = [
    {
      label: "Booking Days in Advance",
      disableEdit: false,
      editDescription: "Set how much heads up you need for booking.",
      fields: [
        {
          name: "bookingDaysInAdvance",
          label: "Booking Days in Advance",
          fieldType: "bookingDaysInAdvance",
          value: userPublicData?.bookingDaysInAdvance,
        },
      ],
    },
  ];
  const minBookingMinutesProp = [
    {
      label: "Booking minimum",
      editDescription: "Set the shortest time someone can book you for.",
      fields: [
        {
          name: "minBookingMinutes",
          label: "Booking minimum",
          fieldType: "durationDropdown",
          value: userPublicData?.minBookingMinutes,
          options: generateDurationOptions(),
        },
      ],
    },
  ];
  const maxBookingMinutesProp = [
    {
      label: "Booking maximum",
      editDescription: "Set the longest time someone can book you for.",
      fields: [
        {
          name: "maxBookingMinutes",
          label: "Booking maximum",
          fieldType: "durationDropdown",
          value: userPublicData?.maxBookingMinutes,
          options: generateDurationOptions(userPublicData?.minBookingMinutes),
        },
      ],
    },
  ];

  const handleBoundsSave = async (newBounds) => {
    setLoading(true);

    const publicRef = doc(db, "usersPublic", userPrivateData.id);
    const privateRef = doc(db, "usersPrivate", userPrivateData.id);

    try {
      await updateDoc(publicRef, { bounds: newBounds });
      await updateDoc(privateRef, { bounds: newBounds });
    } catch (error) {
      console.error("Error updating bounds", error);
    } finally {
      setLoading(false);
    }
  };

  const toggleHelperStatus = async (event) => {
    // setActive(!active);
    // Determine the new status based on the switch's checked state
    const newStatus = event.target.checked ? "active" : "inactive";

    // Reference to the user's document in Firestore
    const userDocRef = doc(db, "usersPublic", userPrivateData.id);

    try {
      // Update helperStatus in the user's document
      await updateDoc(userDocRef, {
        helperStatus: newStatus,
      });
    } catch (error) {
      console.error("Error updating user helperStatus:", error);
    }
  };

  const toggleShowMaxBookingMinutes = async (event) => {
    // Determine the new status based on the switch's checked state
    const newStatus = event.target.checked ? "active" : "inactive";

    // Reference to the user's document in Firestore
    const userDocRef = doc(db, "usersPublic", userPrivateData.id);

    console.log("event.target.checked: ", event.target.checked);

    try {
      // Update helperStatus in the user's document
      await updateDoc(userDocRef, {
        maxBookingMinutes: event.target.checked ? "" : null,
      });
    } catch (error) {
      console.error("Error updating user helperStatus:", error);
    }
  };

  return (
    <>
      <Box sx={{ mx: 2, mb: 6 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: { xs: 2, sm: 10 },
          }}
        >
          <Box sx={{ flex: 0.3, mb: 1 }}>
            <Typography variant="h6" sx={{ mt: 2 }}>
              Hourly rate and skills
            </Typography>
            <Typography variant="body1" color={"text.secondary"}>
              These are the tasks that you will be available for hire.
            </Typography>
          </Box>
          <Box sx={{ flex: 0.7 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Typography variant="body1" fontWeight={"600"} sx={{ mb: 0 }}>
                  Helper Status
                </Typography>
                <Box
                  sx={{
                    px: 1,
                    py: 0.5,
                    minWidth: "50px",
                    justifyContent: "center",
                    display: "flex",
                    backgroundColor: active
                      ? "hsl(100, 100%, 90%)"
                      : "hsl(0, 100%, 90%)",
                    borderRadius: "10px",
                  }}
                >
                  <Typography variant="caption">
                    {active ? "active" : "paused"}
                  </Typography>
                </Box>
              </Box>
              <StyledSwitch checked={active} onChange={toggleHelperStatus} />
            </Box>
            <Typography variant="body1" sx={{ mb: 2, color: "text.secondary" }}>
              {active
                ? "People can book visits with you"
                : "People cannot book visits with you"}
            </Typography>
            <Divider sx={{ my: 2 }} />
            <UserProperty
              propsData={hourlyRateProps}
              isPrivate={false}
              userId={userPrivateData.id}
            />
            <Divider sx={{ my: 2 }} />
            <UserProperty
              propsData={skillsProp}
              isPrivate={false}
              userId={userPrivateData.id}
            />
          </Box>
        </Box>
        <Divider
          sx={{
            mt: { xs: 2, sm: 3 },
            mb: { xs: 0, sm: 3 },

            mx: { xs: -2, sm: 0 },
            borderBottomWidth: { xs: "5px", sm: "unset" },
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: { xs: 2, sm: 10 },
          }}
        >
          <Box sx={{ flex: 0.3, mb: 1 }}>
            <Typography variant="h6" sx={{ mt: 2 }}>
              Booking preferences
            </Typography>
            <Typography variant="body1" color={"text.secondary"}>
              This defines how others can book with you.
            </Typography>
          </Box>

          <Box sx={{ flex: 0.7 }}>
            <UserProperty
              propsData={bookingDaysProp}
              isPrivate={false}
              userId={userPrivateData.id}
            />
            <Divider sx={{ my: 2 }} />

            <UserProperty
              propsData={minBookingMinutesProp}
              isPrivate={false}
              userId={userPrivateData.id}
            />
            <Divider sx={{ my: 2 }} />

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography>Set a booking maximum</Typography>
              <StyledSwitch
                checked={showMaxBookingMinutes}
                onChange={toggleShowMaxBookingMinutes}
              />
            </Box>
            {showMaxBookingMinutes && (
              <Box sx={{ mt: 1 }}>
                <UserProperty
                  propsData={maxBookingMinutesProp}
                  isPrivate={false}
                  userId={userPrivateData.id}
                />
              </Box>
            )}

            <Divider sx={{ my: 2 }} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                mb: 2,
                alignItems: "flex-end",
              }}
            >
              <Typography variant="body1" fontWeight={"600"} sx={{ mb: 0 }}>
                References
              </Typography>

              <Button
                variant="text"
                size="small"
                sx={{ textTransform: "none" }}
                onClick={() => setOpenReferences(true)}
              >
                Edit
              </Button>
            </Box>
            <ReferencesDetails
              references={userPrivateData?.references}
              contentOnly={true}
            />
            {/* <Typography variant="body1" sx={{ mb: 2, color: "text.secondary" }}>
              {userPrivate
                ? formattedFacts
                : "Set your references"}
            </Typography> */}
          </Box>
        </Box>
        <Divider
          sx={{
            mt: { xs: 2, sm: 3 },
            mb: { xs: 0, sm: 3 },

            mx: { xs: -2, sm: 0 },
            borderBottomWidth: { xs: "5px", sm: "unset" },
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: { xs: 2, sm: 10 },
          }}
        >
          <Box sx={{ flex: 0.3, mb: 1 }}>
            <Typography variant="h6" sx={{ mt: 2 }}>
              Set your service area
            </Typography>
            <Typography variant="body1" color={"text.secondary"}>
              Only people within this area will see your profile.
            </Typography>
          </Box>

          <Box sx={{ flex: 0.7 }}>
            <GoogleMapsDisplay
              handleEdit={() => setOpenMap(true)}
              buttonText={"Save"}
              initialBounds={userPrivateData?.bounds || []}
              initialCenter={{
                lat: userPrivateData?.lat || "",
                lng: userPrivateData?.lng || "",
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: { xs: 2, sm: 10 },
          }}
        ></Box>
      </Box>
      <ResponsiveDialog
        open={openMap}
        onClose={() => setOpenMap(false)}
        title="Edit bounds"
        width="600px"
        anchor="bottom"
        zIndex={2100}
        fullHeight={true}
      >
        <BoundsView
          canSkip={false}
          validate={false}
          buttonText="Save"
          handleNext={() => setOpenMap(false)}
        />
      </ResponsiveDialog>
      <ResponsiveDialog
        open={openReferences}
        onClose={() => setOpenReferences(false)}
        title="Edit references"
        width="600px"
        anchor="bottom"
        zIndex={2100}
        fullHeight={true}
      >
        <ReferencesView
          canSkip={false}
          validate={false}
          buttonText="Save"
          handleNext={() => setOpenReferences(false)}
        />
      </ResponsiveDialog>
    </>
  );
};

export default UserHelperProfile;
