import { Draw, ForumOutlined, KeyboardArrowRight } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  ListItemButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useDialog } from "../../contexts/DialogProvider";
import { formatName } from "../../services/stringServices";

// Adjusted to accept `persons` prop, which is an object
const PersonsSection = ({ persons }) => {
  const { handleOpenChat, handleOpenProfile, handleOpenReviews } = useDialog();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Sort persons based on role and responseStatus
  const sortedPersons = Object.values(persons).sort((a, b) => {
    if (a.role === "requester" && b.role !== "requester") return -1;
    if (b.role === "requester" && a.role !== "requester") return 1;
    if (a.responseStatus === "declined" && b.responseStatus !== "declined")
      return 1;
    if (b.responseStatus === "declined" && a.responseStatus !== "declined")
      return -1;
    return 0; // No change in order for others
  });

  return (
    <Box>
      {Object.values(sortedPersons).map((person) => (
        <Box
          key={person.id} // Assuming each person object has a unique 'id' property
        >
          <ListItemButton
            onClick={() => handleOpenProfile(person.id)}
            sx={{
              mb: 1, // Margin between the button and the actions below
              borderRadius: "15px",
              justifyContent: "space-between",
              "&:hover": {
                // Adjusted selectors for higher specificity
                "& .chatButton, & .reviewButton": {
                  opacity: 1,
                },
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                alignItems: "center",
              }}
            >
              <Avatar
                src={person.avatarUrl}
                sx={{
                  width: { xs: 56, sm: 72 },
                  height: { xs: 56, sm: 72 },
                  borderRadius: "15px",
                }}
              />
              <Box>
                <Typography variant="body1" fontWeight="bold">
                  {formatName(person.firstName)} {formatName(person.lastName)}
                </Typography>
                {person.responseStatus !== "declined" && (
                  <Box
                    sx={{
                      display: "inline-block", // Make the box only as wide as its content
                      px: 1,
                      py: 0.5,
                      borderRadius: "5px",
                      backgroundColor:
                        person.role === "requester"
                          ? "hsla( 190,100%,50% ,.1)"
                          : "hsla( 300,100%,50% ,.1)",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color:
                          person.role === "requester"
                            ? "hsla( 190,100%,20% ,1)"
                            : "hsla( 300,100%,20% ,1)",
                      }}
                    >
                      {person.role.charAt(0).toUpperCase() +
                        person.role.slice(1)}{" "}
                      {/* Capitalize the role */}
                    </Typography>
                  </Box>
                )}
                {person.responseStatus === "declined" && (
                  <Box
                    sx={{
                      display: "inline-block", // Make the box only as wide as its content
                      px: 1,
                      py: 0.5,
                      borderRadius: "5px",
                      backgroundColor: "hsla( 0,100%,50% ,.1)",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: "hsla( 0,100%,20% ,1)",
                      }}
                    >
                      Declined
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                alignItems: "center",
              }}
            >
              {!isMobile && (
                <>
                  <Button
                    variant="outlined"
                    startIcon={<ForumOutlined />}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpenChat([{ ...person }]);
                    }}
                    sx={{
                      flexGrow: 1,
                      textTransform: "none",
                      opacity: 0,
                      transition: "opacity 0.2s ease-in-out",
                    }}
                    className="chatButton" // Add a class for easier targeting
                  >
                    Chat
                  </Button>
                  <Button
                    variant="outlined"
                    startIcon={<Draw />}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpenReviews(person.id);
                    }}
                    sx={{
                      flexGrow: 1,
                      textTransform: "none",
                      opacity: 0,
                      transition: "opacity 0.2s ease-in-out",
                    }}
                    className="reviewButton" // Add a class for easier targeting
                  >
                    Review
                  </Button>
                </>
              )}

              <KeyboardArrowRight />
            </Box>
          </ListItemButton>
        </Box>
      ))}
    </Box>
  );
};

export default PersonsSection;
