import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import {
  Badge,
  Box,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDashboard } from "../../contexts/DashboardContext";
import { useUser } from "../../contexts/UserProvider";

const Sidebar = () => {
  const { selectedTab, changeTab, tabs } = useDashboard();
  const [open, setOpen] = useState(false); // <-- Moved open state into the Sidebar

  const { userPrivateData } = useUser(); // Access userPrivateData using your custom provider
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md")); // This checks for the 'md' screen size or smaller

  // Calculate the total unread messages count
  useEffect(() => {
    if (userPrivateData && userPrivateData.unreadConversations) {
      const totalUnreadCount = Object.values(
        userPrivateData.unreadConversations
      ).reduce((acc, current) => acc + current, 0);
      setUnreadMessagesCount(totalUnreadCount);
    }
  }, [userPrivateData?.unreadConversations]);

  // Collapse the sidebar when the 'Explore' tab is selected
  useEffect(() => {
    console.log("Selected tab: ", selectedTab)
    if (selectedTab === "Explore") {
      setOpen(false);
    } else {
      // Optionally, you can decide to expand the sidebar when other tabs are selected
      // setOpen(true);
    }
  }, [selectedTab]);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  // This effect updates the 'open' state when the screen size changes to 'md' or smaller
  // useEffect(() => {
  //   if (isMdDown) {
  //     setOpen(false); // Collapse the sidebar if the screen size is 'md' or smaller
  //   } else {
  //     setOpen(true); // Expand the sidebar if the screen size is larger than 'md'
  //   }
  // }, [isMdDown]);

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        width: open ? "250px" : "92px", // Adjust widths as per your design requirement
        flexShrink: 0,
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        "& .MuiDrawer-paper": {
          border: "none",
          position: "absolute",
          flexGrow: 1,
          zIndex: "500",
          height: "calc(100vh - 80px)",
          boxSizing: "border-box",
          width: open ? "250px" : "92px", // Adjust widths as per your design requirement
          padding: "16px 16px", // <-- This is the padding added to the inside of the drawer.
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <IconButton onClick={handleDrawerToggle}>
          {open ? (
            theme.direction === "rtl" ? (
              <KeyboardDoubleArrowRight />
            ) : (
              <KeyboardDoubleArrowLeft />
            )
          ) : theme.direction === "rtl" ? (
            <KeyboardDoubleArrowLeft />
          ) : (
            <KeyboardDoubleArrowRight />
          )}
        </IconButton>
      </Box>
      <List sx={{ py: 2 }}>
        {tabs
          .filter((tab) => !tab.hidden)
          .filter((tab) => tab.desktop)
          .map((tab) => (
            <ListItemButton
              key={tab.label}
              selected={selectedTab === tab.label}
              onClick={() => changeTab(tab.label)}
              sx={{ borderRadius: "8px", mb: "10px", height: "48px" }}
              disabled={tab.disabled}
            >
              <ListItemIcon
                sx={{
                  color:
                    selectedTab === tab.label
                      ? theme.palette.primary.main
                      : theme.palette.grey[700], // Change icon color
                }}
              >
                {selectedTab === tab.label ? tab.icon : tab.outlineIcon}
                {/* Conditionally render a badge for the Chat tab */}
                {!open && (
                  <Badge
                    variant="dot"
                    badgeContent={
                      tab.label === "Chat" ? unreadMessagesCount : 0
                    }
                    color="error"
                  >
                    <span />
                  </Badge>
                )}
              </ListItemIcon>
              {open && (
                <>
                  <ListItemText
                    primary={tab.label}
                    primaryTypographyProps={{
                      fontSize: "1rem",
                      color:
                        selectedTab === tab.label
                          ? theme.palette.primary.main
                          : theme.palette.grey[700], // Change text color
                      fontWeight: selectedTab === tab.label ? "600" : "inherit",
                    }}
                  />
                  <Badge
                    badgeContent={
                      tab.label === "Chat" ? unreadMessagesCount : 0
                    }
                    color="error"
                    sx={{
                      "& .MuiBadge-badge": {
                        right: 3,
                        padding: "0 4px",
                      },
                    }}
                  >
                    <span />
                  </Badge>
                </>
              )}
            </ListItemButton>
          ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;
