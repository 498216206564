import { ForumOutlined, PersonSearch } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  ListItemButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../contexts/AuthProvider";
import { useChat } from "../../contexts/ChatProvider";
import { useDashboard } from "../../contexts/DashboardContext";
import { useUser } from "../../contexts/UserProvider";
import MessageBox from "../boxes/MessageBox";
import CopyUrlButton from "../buttons/CopyUrlButton";
import ResponsiveDialog from "../dialogs/ResponsiveDialog";
import Conversation from "./Conversation";
import ConversationsListItem from "./ConversationListItem";
import ConversationTitle from "./ConversationTitle";

const ConversationsList = () => {
  const { currentUser } = useAuth();
  const [conversation, setConversation] = useState(null);
  const { changeTab } = useDashboard();

  const navigate = useNavigate();
  const { conversationParam } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openChat, setOpenChat] = useState(false);
  const { userPublicData } = useUser();

  const { conversationsData, markConversationAsRead } = useChat();

  useEffect(() => {
    if (!conversationsData) return;

    // Assuming conversationsData is an array of conversation objects
    const foundConversation = conversationsData.find(
      (convo) => convo.id === conversationParam
    );

    if (foundConversation) {
      setConversation(foundConversation);
    } else {
      // Handle the case where no conversation matches the parameter
      console.log("No conversation found with id:", conversationParam);
      // console.log("Setting first conversation as selected");
      // setConversation(conversationsData[0]);
    }
  }, [conversationParam, conversationsData]); // Include conversationsData in the dependency array if it's dynamic

  const handleConversationClick = async (conversation) => {
    markConversationAsRead(conversation);

    if (isMobile) {
      setConversation(conversation);
      setOpenChat(true);
    } else {
      navigate(`/chat/${conversation?.id}`);
    }
  };

  const filteredConversations =
    conversationsData?.filter(
      (convo) => convo.lastMessage || convo.id === conversation?.id
    ) || [];

  return (
    <>
      {filteredConversations.length > 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            flexGrow: 1,
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              id: "this-one",
              height: "auto",
              overflow: { xs: "unset", sm: "auto" },
              pr: { xs: 1, sm: 2 },

              flexDirection: "column",
              flex: 0.4,
            }}
          >
            {filteredConversations
              .sort((a, b) => b.lastMessageTimestamp - a.lastMessageTimestamp)
              .map((convo) => (
                <React.Fragment key={convo.id}>
                  {/* Use React.Fragment with key */}
                  <ListItemButton
                    key={convo.id}
                    onClick={() => handleConversationClick(convo)}
                    selected={convo.id === conversation?.id}
                    sx={{
                      borderRadius: "15px",
                      mx: { xs: 0, sm: 0 },
                      bgcolor:
                        convo.id === conversation?.id
                          ? "action.selected"
                          : "inherit",
                      my: 1,
                      pl: 2,
                      pr: 1,
                      maxHeight: "80px",
                    }}
                  >
                    <ConversationsListItem
                      conversation={convo}
                      currentUser={currentUser}
                    />
                  </ListItemButton>
                  <Divider key={`divider-${convo.id}`} />
                </React.Fragment>
              ))}
          </Box>
          {!isMobile && (
            <Divider
              orientation="vertical"
              height={"100%"}
              flexItem={true}
              sx={{ pr: 0.5 }}
            />
          )}
          {!isMobile && <Conversation conversation={conversation} />}
        </Box>
      ) : userPublicData?.isHelper ? (
        <MessageBox
          Icon={<ForumOutlined color="primary" />}
          headerText="Reach out"
          messageText="You have no conversations yet. Change that by sharing your booking link with others!"
          hideButton={true}
          customButton={
            <CopyUrlButton
              url={`${window.location.origin}/profile/${currentUser?.uid}${
                currentUser?.uid ? `?ref=${currentUser.uid}` : ""
              }`}
              extraText={"your personal booking link"}
            />
          }
        />
      ) : (
        <MessageBox
          Icon={<ForumOutlined color="primary" />}
          headerText="Reach out"
          messageText="You have no conversations yet. Start a chat with a helper to see if they're a good fit."
          hideButton={true}
          customButton={
            <Button
              variant="outlined"
              disableElevation
              startIcon={<PersonSearch />}
              sx={{ height: "56px", width: "100%", textTransform: "none" }}
              onClick={() => {
                changeTab("Explore");
              }}
            >
              Find help
            </Button>
          }
        />
      )}
      <ResponsiveDialog
        zIndex={400}
        key={"converstaion"}
        open={openChat}
        onClose={() => {
          setOpenChat(false);
          setConversation(null);
        }}
        title={
          <ConversationTitle
            conversation={conversation}
            currentUser={currentUser}
          />
        }
        width="400px"
        anchor="right"
        fullHeight={true}
      >
        <Conversation conversation={conversation} />
      </ResponsiveDialog>
    </>
  );
};

export default ConversationsList;
