import { useTheme } from "@emotion/react";
import { Box, Menu, MenuItem, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useHelpers } from "../../contexts/HelpersProvider";
import { SKILL_LIST } from "../../services/skillServices"; // Ensure this is the correct path
import SkillsBar from "./SkillsBar";
import AvailabilityView from "../calendar/AvailabilityView";

const TIMELINE_OPTIONS = [
  { label: "Within 3 days", value: "threeDay" },
  { label: "Within a week", value: "thisWeek" },
  { label: "Next week", value: "nextWeek" },
  { label: "Anytime", value: "anytime" },
];

const FilterBar = () => {
  const {
    fetchHelpers,
    selectedSkills,
    selectedTimeline,
    setSelectedSkills,
    setSelectedTimeline,
  } = useHelpers();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const timeline = searchParams.get("timeline");
    const category = searchParams.get("category");

    if (timeline) {
      setSelectedTimeline(timeline);
    }
    if (category) {
      // Assuming you want to set the category based on a name match in SKILL_LIST
      const matchingSkill = SKILL_LIST.find((skill) => skill.name === category);
      if (matchingSkill) {
        setSelectedSkills([matchingSkill]);
      }
    }
  }, [location.search]);

  const handleToggle = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleTimelineClick = (value) => {
    const category = selectedSkills.length > 0 ? selectedSkills[0].name : null;
    updateUrlParams(value, category);
    setSelectedTimeline(value);
    setAnchorEl(null); // Close the menu after selection
  };

  const handleSkillClick = (checkedNames) => {
    let category = null;
    if (checkedNames.length > 0) {
      const lastCheckedName = checkedNames[checkedNames.length - 1];
      const selectedSkill = SKILL_LIST.find(
        (skill) => skill.name === lastCheckedName
      );
      if (selectedSkill) {
        setSelectedSkills([selectedSkill]);
        category = selectedSkill.name;
      }
    } else {
      setSelectedSkills([]);
    }
    // Update URL with the new skill/category, keeping the current timeline
    updateUrlParams(selectedTimeline, category);
  };

  const updateUrlParams = (timeline, category) => {
    const searchParams = new URLSearchParams();
    if (timeline) searchParams.set("timeline", timeline);
    if (category) searchParams.set("category", category);
    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        gap: { xs: 0, md: 2 },
        alignItems: "center",
        position: "relative",
      }}
    >
      <SkillsBar
        items={SKILL_LIST}
        checkedItems={selectedSkills.map((skill) => skill.name)} // Assuming checkedItems expects a list of skill names
        onChange={handleSkillClick}
      />
      <Box
        sx={{
          width: { xs: "100%", md: "250px" },
          minWidth: { xs: "auto", sm: "380px" },
        }}
      >
        <AvailabilityView />
      </Box>
      {/* <Button
        size="large"
        variant="outlined"
        disableRipple
        aria-label="select timeline"
        aria-haspopup="true"
        onClick={handleToggle} // Pass the event to handleToggle
        sx={{
          border: "1px solid rgba(0, 0, 0, 0.12)",
          borderRadius: "12px",
          textTransform: "none",
          px: 1,
          height: "56px",
          width: { xs: "auto", sm: "250px" },
          minWidth: "180px",
          color: "inherit",
          position: { xs: "absolute", sm: "relative" },
          top: {xs: -79, sm: "unset"},
          right: {xs: -10, sm: "unset"},
          left: {xs: "50%", sm: "unset"},
          transition: "box-shadow 0.3s ease-in-out", // Smooth transition for shadow
          boxShadow: anchorEl ? "0 5px 10px 0 rgba(0,0,0,0.15)" : "none", // Drop shadow when open
          backgroundColor: "rgba(0, 0, 0, 0)",
          transition: "box-shadow, border, backgroundColor, 0.3s ease-in-out", // Smooth transition for shadow
          "&:hover": {
            boxShadow: "0 5px 10px 0 rgba(0,0,0,0.15)", // Slightly more pronounced shadow on hover
            border: "1px solid rgba(0, 0, 0, 0.15)",
            backgroundColor: "rgba(0, 0, 0, 0)",
          },
        }}
      >
        <Event sx={{ color: "#616161", position: "absolute", left: 12 }} />
        <Typography sx={{ pl: 2 }}>
          {
            TIMELINE_OPTIONS.find((option) => option.value === selectedTimeline)
              .label
          }
        </Typography>
      </Button> */}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        sx={{ width: "100%" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        // Your existing MenuProps and styling
      >
        {TIMELINE_OPTIONS.map((option) => (
          <MenuItem
            sx={{ width: "100%" }}
            key={option.value}
            selected={option.value === selectedTimeline}
            onClick={() => handleTimelineClick(option.value)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
      {/* <Button
        variant="contained"
        onClick={() => fetchHelpers({ selectedSkills, selectedTimeline })}
        fullWidth
        startIcon={<Tune />}
        sx={{ textTransform: "none", height: "56px", maxWidth: "150px" }}
      >
        Apply Filters
      </Button> */}
    </Box>
  );
};

export default FilterBar;
