/* global google */

import { Draw } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

function GoogleMapsDisplay({
  initialBounds,
  initialCenter = {},
  handleEdit = null
}) {
  const mapRef = useRef(null);
  const map = useRef(null);
  const poly = useRef(null);
  const drawing = useRef(null);

  const [polygonBounds, setPolygonBounds] = useState([]);
  const [locating, setLocating] = useState(false);
  const [userLocationMarker, setUserLocationMarker] = useState(null);

  const disableMapOptions = () => {
    if (map.current) {
      map.current.setOptions({
        draggable: false,
        zoomControl: false,
        scrollwheel: false,
        gestureHandling: "none", // Disable gesture handling when drawing
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        clickableIcons: false, // Disable clicks on POIs
        disableDoubleClickZoom: true,
      });
    }
  };

  const getUserLocation = () => {
    setLocating(true); // Start locating process

    if (navigator.geolocation && !userLocationMarker) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          // Clear existing marker if it exists
          if (userLocationMarker) {
            userLocationMarker.setMap(null);
          }

          // Create a new marker at the current location
          const marker = new google.maps.Marker({
            position: pos,
            map: map.current,
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              fillColor: "#4285F4",
              fillOpacity: 1,
              scale: 8,
              strokeColor: "white",
              strokeWeight: 2,
            },
          });
          setUserLocationMarker(marker);
          setLocating(false); // Successfully located, stop locating
        },
        () => {
          setLocating(false); // Failed to locate, stop locating
        }
      );
    } else {
      setLocating(false); // Geolocation is not supported by the browser
    }
  };

  useEffect(() => {
    if (map.current && initialBounds?.length > 0) {
      const bounds = new google.maps.LatLngBounds();
      initialBounds.forEach((point) => {
        bounds.extend(new google.maps.LatLng(point.lat, point.lng));
      });

      map.current.fitBounds(bounds);
      initializePolygonWithBounds(initialBounds);
      const listener = map.current.addListener("bounds_changed", () => {
        const currentZoom = map.current.getZoom();
        map.current.setZoom(currentZoom); // Optionally adjust the zoom level here
        google.maps.event.removeListener(listener);
      });
    } else if (map.current && initialCenter.lat && initialCenter.lng) {
      map.current.setCenter(
        new google.maps.LatLng(initialCenter.lat, initialCenter.lng)
      );
    }
  }, [initialBounds, initialCenter]);

  const initializePolygonWithBounds = (bounds) => {
    // Clear existing polygon if it exists
    // setPolygonBounds([]);
    if (poly.current) {
      poly.current.setMap(null);
      poly.current = null;
    }

    const path = bounds.map(
      (point) => new google.maps.LatLng(point.lat, point.lng)
    );
    poly.current = new google.maps.Polygon({
      map: map.current,
      paths: path,
      clickable: false,
    });

    // setPolygonBounds(bounds); // Set the initial state for polygon bounds
  };

  const initialize = () => {
    const center = new google.maps.LatLng(
      initialCenter.lat || 48.47,
      initialCenter.lng || -122.7
    );
    const mapOptions = {
      zoom: 12,
      center: center,
      streetViewControl: false,
      fullscreenControl: false,
      mapTypeControl: false,
      draggable: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };
    map.current = new google.maps.Map(mapRef.current, mapOptions);
  };

  useEffect(() => {
    initialize();
    getUserLocation();
  }, []);

  return (
    <div>
      <Box
        onClick={handleEdit && handleEdit}
        sx={{
          borderRadius: "15px",
          overflow: "hidden",
          // border: "1px solid rgba(0, 0, 0, 0.12)",
          position: "relative",
          width: "100%",
          boxSizing: "border-box",
          height: "100%",
          cursor: "pointer",
        }}
      >
        <div ref={mapRef} style={{ height: "350px", width: "100%" }}></div>
        {handleEdit && (
          <Button
            variant="contained"
            color="inherit"
            sx={{
              bgcolor: "white",
              borderRadius: "5px",
              fill: "#f5f5f5",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
              width: "auto",
              position: "absolute",
              top: 0,
              left: 0,
              m: { xs: 1, sm: 2 },
              boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px",
              height: "42px",
              justifyContent: "center",
              pr: "15px",
              pl: "10px",
              textTransform: "none",
            }}
            onClick={handleEdit}
          >
            <Draw color="primary" />
            <Typography color="black">Edit map</Typography>
          </Button>
        )}
      </Box>
    </div>
  );
}

export default GoogleMapsDisplay;
