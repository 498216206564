import {
  Box,
  Button,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthProvider";
import { useDialog } from "../../contexts/DialogProvider";
import { useUser } from "../../contexts/UserProvider";
import { db } from "../../firebase";
import { formatAddress } from "../../services/locationServices";
import { formatName } from "../../services/stringServices";
import { INTEREST_LIST } from "../../services/variableServices";
import AddressView from "../auth/AddressView";
import FactsView from "../auth/FactsView";
import ResponsiveDialog from "../dialogs/ResponsiveDialog";
import AvatarField from "../fields/AvatarField";
import UserProperty from "./UserProperty";

const UserPersonalInfo = () => {
  const { userPrivateData } = useUser();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const [openAddress, setOpenAddress] = useState(false);
  const { handleOpenProfile } = useDialog();
  const { currentUser } = useAuth();

  if (!userPrivateData) return;

  const nameProps = [
    {
      label: "Name",
      editDescription:
        "Your legal name, which could be a license or a passport.",
      fields: [
        {
          name: "firstName",
          label: "First Name",
          fieldType: "name",
          value: formatName(userPrivateData.firstName),
        },
        {
          name: "lastName",
          label: "Last Name",
          fieldType: "name",
          value: formatName(userPrivateData.lastName),
        },
      ],
    },
  ];
  const emailProps = [
    {
      label: "Email",
      disableEdit: true,
      editDescription: "Use an address you’ll always have access to.",
      fields: [
        {
          name: "email",
          label: "Email",
          fieldType: "email",
          value: userPrivateData.email,
        },
      ],
    },
  ];
  const phoneProps = [
    {
      label: "Phone",
      editDescription: "Use your mobile phone number.",
      fields: [
        {
          name: "phone",
          label: "Phone",
          fieldType: "phone",
          value: userPrivateData.phone,
        },
      ],
    },
  ];
  const bioProps = [
    {
      label: "Bio",
      editDescription: "Tell others about yourself.",
      fields: [
        {
          name: "bio",
          label: "Bio",
          fieldType: "multiline",
          value: userPrivateData.bio,
        },
      ],
    },
  ];
  const addressProps = [
    {
      label: "Address",
      disableEdit: true,
      editDescription: "The address where you will be receiving help.",
      fields: [
        {
          name: "address",
          label: "Email",
          fieldType: "address",
          value: formatAddress(userPrivateData),
        },
      ],
    },
  ];
  const interestsProp = [
    {
      label: "Interests",
      editDescription: "Your personal interests.",
      fields: [
        {
          name: "interests",
          label: "Interests",
          fieldType: "multipleChoice",
          allItems: INTEREST_LIST,
          value: userPrivateData?.interests || [],
        },
      ],
    },
  ];

  const onAvatarUpload = async (avatarUrl) => {
    try {
      // Update the usersPrivate collection
      const privateRef = doc(db, "usersPrivate", userPrivateData.id);
      const publicRef = doc(db, "usersPublic", userPrivateData.id);

      await updateDoc(privateRef, { avatarUrl: avatarUrl });
      await updateDoc(publicRef, { avatarUrl: avatarUrl });
    } catch (error) {
      console.error("Error updating avatar:", error);
    }
  };

  // Filter out null or empty string values from the facts map
  const factsList = userPrivateData.facts
    ? Object.values(userPrivateData.facts).filter((fact) => fact && fact.trim())
    : [];

  // Format the list into a natural language string with commas and 'and'
  const formattedFacts =
    factsList.length > 0
      ? factsList.slice(0, -1).join(", ") +
        (factsList.length > 1 ? ", and " : "") +
        factsList.slice(-1)
      : "No interesting facts to display.";

  const profileCard = () => (
    <Button
      onClick={() => handleOpenProfile(currentUser?.uid)}
      sx={{
        position: "fixed",
        top: 14,
        right: 8,
      }}
    >
      View
    </Button>
  );

  return (
    <>
      <Box sx={{ mx: 2, mb: 6 }}>
        {/* <Divider
          sx={{
            mt: { xs: 4, sm: 3 },
            mb: { xs: 0, sm: 3 },

            mx: { xs: -2, sm: 0 },
            borderBottomWidth: { xs: "5px", sm: "unset" },
          }}
        /> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: { xs: 2, sm: 10 },
          }}
        >
          <Box sx={{ flex: 0.3, mb: 1 }}>
            <Typography variant="h6" sx={{ mt: 2 }}>
              Public
            </Typography>
            <Typography variant="body1" color={"text.secondary"}>
              This information is viewable by anyone on the platform.
            </Typography>
          </Box>
          <Box sx={{ flex: 0.7 }}>
            {!isMobile && <Box>{profileCard()}</Box>}
            <AvatarField
              avatarUrl={userPrivateData.avatarUrl}
              onAvatarUpload={onAvatarUpload}
            />
            <Divider sx={{ my: 2 }} />
            <UserProperty
              propsData={nameProps}
              isPrivate={false}
              userId={userPrivateData.id}
            />

            <Divider sx={{ my: 2 }} />
            <UserProperty
              propsData={bioProps}
              isPrivate={false}
              userId={userPrivateData.id}
            />
            <Divider sx={{ my: 2 }} />
            <UserProperty
              propsData={interestsProp}
              isPrivate={false}
              userId={userPrivateData.id}
            />
            <Divider sx={{ my: 2 }} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <Typography variant="body1" fontWeight={"600"} sx={{ mb: 0 }}>
                Personal trivia
              </Typography>

              <Button
                variant="text"
                size="small"
                sx={{ textTransform: "none" }}
                onClick={() => setOpen(true)}
              >
                Edit
              </Button>
            </Box>
            <Typography variant="body1" sx={{ mb: 2, color: "text.secondary" }}>
              {formattedFacts
                ? formattedFacts
                : "Choose a few to highlight your your personality"}
            </Typography>
          </Box>
        </Box>
        <Divider
          sx={{
            mt: { xs: 2, sm: 3 },
            mb: { xs: 0, sm: 3 },

            mx: { xs: -2, sm: 0 },
            borderBottomWidth: { xs: "5px", sm: "unset" },
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: { xs: 2, sm: 10 },
          }}
        >
          <Box sx={{ flex: 0.3, mb: 1 }}>
            <Typography variant="h6" sx={{ mt: 2 }}>
              Private
            </Typography>
            <Typography variant="body1" color={"text.secondary"}>
              This information is only shared when you book with a helper
            </Typography>
          </Box>

          <Box sx={{ flex: 0.7 }}>
            <UserProperty
              propsData={phoneProps}
              isPrivate={true}
              userId={userPrivateData.id}
            />
            <Divider sx={{ my: 2 }} />
            <UserProperty
              propsData={emailProps}
              isPrivate={true}
              userId={userPrivateData.id}
            />
            <Divider sx={{ my: 2 }} />

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <Typography variant="body1" fontWeight={"600"} sx={{ mb: 0 }}>
                Address
              </Typography>

              <Button
                variant="text"
                size="small"
                sx={{ textTransform: "none" }}
                onClick={() => setOpenAddress(true)}
              >
                Edit
              </Button>
            </Box>
            <Typography variant="body1" sx={{ mb: 2, color: "text.secondary" }}>
              {formatAddress(userPrivateData)}{" "}
            </Typography>
          </Box>
        </Box>
      </Box>
      <ResponsiveDialog
        open={open}
        onClose={() => setOpen(false)}
        title="Edit trivia"
        width="600px"
        anchor="bottom"
        zIndex={2100}
        fullHeight={true}
      >
        <FactsView
          canSkip={false}
          validate={false}
          buttonText="Save"
          handleNext={() => setOpen(false)}
        />
      </ResponsiveDialog>
      <ResponsiveDialog
        open={openAddress}
        onClose={() => setOpenAddress(false)}
        title="Edit home address"
        width="600px"
        anchor="bottom"
        zIndex={2100}
        fullHeight={true}
      >
        <AddressView
          canSkip={false}
          buttonText="Save"
          handleNext={() => setOpenAddress(false)}
        />
      </ResponsiveDialog>
    </>
  );
};

export default UserPersonalInfo;
