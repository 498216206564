// NotificationContext.js
import {
  Timestamp,
  collection,
  doc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  runTransaction,
  setDoc,
  startAfter,
  where,
  writeBatch,
} from "firebase/firestore";
import React, { createContext, useContext, useEffect, useState } from "react";
import { db } from "../firebase";
import { useAuth } from "./AuthProvider"; // Assuming you have an AuthProvider

const NotificationContext = createContext();

export function useNotifications() {
  return useContext(NotificationContext);
}

export const NotificationsProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const [notificationsData, setNotificationsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  const FETCH_LIMIT = 3;
  const START_LIMIT = 8;
  /**
   * Creates a notification document in Firestore.
   *
   * visit declined/cancelled:    visit_decline
   * visit reminder:              visit_reminder
   */
  async function createNotification({
    userId,
    type,
    message,

    routingPath,
    routingId,

    subjectAvatarUrl = "",
    subjectDisplayName = "",
    subjectId = "",

    title = "",
    priority = "low",
  }) {
    const notificationRef = doc(collection(db, "notifications"));
    const notificationData = {
      id: notificationRef.id,
      userId,
      subject: {
        avatarUrl: subjectAvatarUrl,
        displayName: subjectDisplayName,
        id: subjectId,
      },
      readAt: false,
      type,
      routing: {
        path: routingPath,
        id: routingId,
      },
      createdAt: Timestamp.now(),
      title,
      message,
      priority,
    };

    try {
      await setDoc(notificationRef, notificationData);
    } catch (error) {
      console.error("Error creating notification:", error);
    }
  }

  const markAllRead = async () => {
    // Reference to the user's notifications
    const notificationsQuery = query(
      collection(db, "notifications"),
      where("userId", "==", currentUser?.uid),
      where("readAt", "==", false)
    );

    // Reference to the user's private document
    const userPrivateDocRef = doc(db, "usersPrivate", currentUser?.uid);

    try {
      // Retrieve all unread notifications
      const querySnapshot = await getDocs(notificationsQuery);
      if (querySnapshot.empty) {
        console.log("No unread notifications to update.");
        return;
      }

      // Batch write to update all notifications as read
      const batch = writeBatch(db);
      querySnapshot.forEach((doc) => {
        batch.update(doc.ref, { readAt: new Date() });
      });

      // Commit the batch
      await batch.commit();
      console.log("All notifications marked as read.");

      // Use a transaction to safely update the user's notification count
      await runTransaction(db, async (transaction) => {
        const userPrivateDoc = await transaction.get(userPrivateDocRef);
        if (!userPrivateDoc.exists()) {
          throw "User private document does not exist!";
        }

        // Directly set notificationCount to 0
        transaction.update(userPrivateDocRef, {
          notificationCount: 0,
        });
      });
    } catch (e) {
      console.error("An error occurred: ", e);
    }
  };

  useEffect(() => {
    if (!currentUser) {
      setLoading(false);
      return;
    }

    const q = query(
      collection(db, "notifications"),
      where("userId", "==", currentUser.uid),
      orderBy("createdAt", "desc"),
      limit(START_LIMIT)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedNotifications = [];
      snapshot.forEach((doc) => {
        fetchedNotifications.push({ id: doc.id, ...doc.data() });
      });

      if (fetchedNotifications.length > 0) {
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
        setHasMore(fetchedNotifications.length <= START_LIMIT);
      } else {
        setHasMore(false);
      }
      setNotificationsData(fetchedNotifications);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [currentUser]);

  const fetchMoreNotifications = () => {
    return new Promise((resolve, reject) => {
      if (!lastVisible || !hasMore) {
        resolve([]); // Resolve with an empty array to indicate no more items were fetched
        return;
      }

      const nextQuery = query(
        collection(db, "notifications"),
        where("userId", "==", currentUser.uid),
        orderBy("createdAt", "desc"),
        startAfter(lastVisible),
        limit(FETCH_LIMIT)
      );

      getDocs(nextQuery)
        .then((snapshot) => {
          const newNotifications = [];
          snapshot.forEach((doc) => {
            newNotifications.push({ id: doc.id, ...doc.data() });
          });

          if (newNotifications.length > 0) {
            setNotificationsData((prev) => [...prev, ...newNotifications]);
            setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
          }

          if (newNotifications.length < FETCH_LIMIT) {
            setHasMore(false);
          }

          resolve(newNotifications); // Resolve the promise with the new notifications
        })
        .catch((error) => {
          console.error("Error fetching more notifications:", error);
          reject(error); // Reject the promise if an error occurs
        });
    });
  };

  return (
    <NotificationContext.Provider
      value={{
        notificationsData,
        createNotification,
        loading,
        fetchMoreNotifications,
        hasMore,
        markAllRead,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
