import {
  Check,
  DownloadingOutlined,
  ExploreOutlined,
  ShareOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDialog } from "../../contexts/DialogProvider";
import { useHelpers } from "../../contexts/HelpersProvider";
import HelperDiscoverCard from "../discover/HelperDiscoverCard";
import { DateTime } from "luxon";

const HelperResults = () => {
  const {
    helpers,
    resetFilters,
    loadMoreHelpers,
    loading,
    setLoading,
    helpersExist,
    allFetched,
    selectedSkills,
    selectedTimeline,
    itemsPerRow,
    setItemsPerRow,
    selectedDate,
  } = useHelpers();

  const { handleOpenInvite } = useDialog();

  const helperLoadingSkeleton = () => {
    return (
      <Box
        sx={{
          width: { xs: "auto", sm: "100%" },
          overflow: "hidden",
          mx: { xs: -1, sm: 0 },
          px: { xs: 0, sm: 0 },
          py: { xs: 0, sm: 0 },
          color: "black",
          backgroundColor: "white",
          boxSizing: "border-box",
          border: "1px solid rgba(0, 0, 0, 0)",
          transition: "box-shadow, border, 0.15s ease-in-out", // Smooth transition for shadow
        }}
      >
        <Box
          sx={{
            width: "100%",
            textTransform: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-between",
            overflow: "hidden",
            p: { xs: 1, sm: 2 },
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 2,
                flexDirection: "column",
                alignItems: "flex-start",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Skeleton
                  variant="rectangular"
                  sx={{
                    width: { xs: "50%", sm: "100%" },
                    mx: { xs: "unset", sm: "auto" },
                    height: { xs: "unset", sm: "unset" },
                    aspectRatio: "1 / 1", // Ensures the element is always square
                    borderRadius: "15px",
                  }}
                />
                <Box
                  sx={{
                    maxWidth: { xs: "350px", sm: "unset" },
                    mx: "auto",
                    width: "100%",
                  }}
                >
                  <Skeleton width="60%" height={28} sx={{ mb: 0.5 }} />
                  <Skeleton width="40%" height={20} sx={{ mb: 0.5 }} />

                  <Skeleton width="40%" height={20} sx={{ mb: 0.5 }} />
                  <Skeleton width="40%" height={20} sx={{ mb: 0.5 }} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  function calculateItemsPerRow() {
    const gridContainer = document.querySelector(".grid-container"); // Replace with your actual container selector
    const gridItemMinWidth = 220; // Minimum item width
    const gridGap = window.innerWidth < 600 ? 24 : 16; // Gap based on breakpoint (adjust according to your theme breakpoints)

    if (gridContainer) {
      const containerWidth = gridContainer.clientWidth;
      const itemsPerRow = Math.floor(
        (containerWidth + gridGap) / (gridItemMinWidth + gridGap)
      );
      return itemsPerRow;
    }
    return 0;
  }

  // Effect for calculating items per row on mount and resizing
  useEffect(() => {
    const handleResize = () => {
      setItemsPerRow(calculateItemsPerRow());
    };

    handleResize(); // Calculate initially on mount

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getHelpersAvailableOnDate = () => {
    // If helpers is not defined or is not an array, return an empty array
    if (!Array.isArray(helpers)) {
      return [];
    }

    // Return all helpers if selectedDate is an empty string
    if (selectedDate === "") {
      return helpers;
    }

    return helpers.filter((helper) => {
      if (Array.isArray(helper.availability)) {
        console.log("helper.availability: ", helper.availability);
        return helper?.availability?.some((window) => {
          const startDateTime = DateTime.fromJSDate(window?.start.toDate());
          const endDateTime = DateTime.fromJSDate(window?.end.toDate());

          return (
            startDateTime.hasSame(selectedDate, "day") &&
            endDateTime.hasSame(selectedDate, "day")
          );
        });
      }
      return false;
    });
  };

  return (
    <>
      {helpersExist || helpersExist === null ? (
        <>
          <Box
            key={"helper-results"}
            className="grid-container" // Add this line to include the class name
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "repeat(auto-fill, minmax(220px, 1fr))",
              }, // Creates a responsive grid layout
              gap: { xs: 3, sm: 2 },
            }}
          >
            {getHelpersAvailableOnDate().map((helper) => (
              <HelperDiscoverCard
                helper={helper}
                keySkill={selectedSkills[0]}
                key={helper.id} // Assuming `helper.id` is a unique identifier
              />
            ))}
            {loading &&
              Array.from({ length: itemsPerRow }).map(
                (_, index) => (
                  <div key={`skeleton-${index}`}>{helperLoadingSkeleton()}</div>
                ) // Ensure unique key even during loading
              )}
            {allFetched && !loading && (
              <HelperDiscoverCard keySkill={selectedSkills[0]} />
            )}
            {allFetched && helpers.length === 0 && !loading && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  mt: "10vh",
                  mx: "auto",
                }}
              >
                <ExploreOutlined color="primary" sx={{ fontSize: 40, mb: 1 }} />
                <Typography align="center">
                  No helpers found
                  {selectedSkills.length === 0 &&
                    selectedTimeline === "anytime" &&
                    " in your area. Kickstart your local community by sharing with friends!"}
                </Typography>
                {(selectedSkills.length > 0 ||
                  selectedTimeline !== "anytime") && (
                  <Button
                    variant="text"
                    onClick={resetFilters}
                    sx={{
                      textTransform: "none",
                      mt: 1,
                      width: { xs: "100%", sm: "200px" },
                      height: "56px",
                    }}
                  >
                    Reset filters
                  </Button>
                )}
              </Box>
            )}
          </Box>
          {!allFetched && !loading && (
            <Box
              key={"load-more-button"}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                mt: "10vh",
              }}
            >
              <Button
                // size="small"
                onClick={() => loadMoreHelpers(itemsPerRow)}
                sx={{
                  mx: "auto",
                  width: "auto",
                  maxWidth: "max-content",
                  boxSizing: "border-box",
                  textTransform: "none",
                  backgroundColor: "rgba(0, 0, 0, 0.75)", // Black with subtle opacity
                  color: "white", // White text
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.9)", // Slightly less opacity on hover
                  },
                }}
                variant="extended"
              >
                <DownloadingOutlined sx={{ mr: "4px" }} />
                <Typography fontSize={"0.85rem"} fontWeight={"600"}>
                  Load more
                </Typography>
              </Button>
            </Box>
          )}
        </>
      ) : (
        <Box sx={{ maxWidth: "600px", mx: "auto", width: "100%", mt: "5vh" }}>
          <>
            <Typography variant="h4" gutterBottom>
              Kickstart your community
            </Typography>
            <Typography gutterBottom>
              There are no helpers nearby, but you have a fantastic opportunity
              to kickstart something amazing in your community! Invite your
              friends and encourage others to list their services to get the
              ball rolling.
            </Typography>
            <Typography
              sx={{
                mx: 0,
                mt: 2,
                width: "max-content",
              }}
              variant="body1"
              fontWeight={"600"}
            >
              Know a great helper?
            </Typography>
            <Typography
              sx={{
                mx: 0,
                width: "max-content",
                fontSize: { xs: "1.15rem", sm: "0.9rem" },
              }}
            >
              Invite them to join
            </Typography>

            <List sx={{ mx: 0, width: "max-content", mb: 1 }}>
              {[
                "More work opportunities",
                "Extra income on their time",
                "Reach people in the area",
              ].map((reason, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemIcon sx={{ minWidth: "32px" }}>
                    <Check color="disabled" />
                  </ListItemIcon>
                  <Typography variant="body2" color={"text.secondary"}>
                    {reason}
                  </Typography>
                </ListItem>
              ))}
            </List>
            <Button
              startIcon={<ShareOutlined />}
              onClick={handleOpenInvite}
              fullWidth
              variant="outlined"
              sx={{ textTransform: "none", height: "56px" }}
            >
              Invite
            </Button>
          </>{" "}
        </Box>
      )}
    </>
  );
};

export default HelperResults;
