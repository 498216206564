import { Avatar, Box, Divider, Typography } from "@mui/material";
import React from "react";

const ReferencesDetails = ({ references, contentOnly = false }) => {
  if (!references || references.length === 0) return;

  const renderReferences = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row", // Items laid out horizontally
        flexWrap: "wrap", // Allows items to wrap onto multiple lines as needed
        gap: "16px", // Spacing between items
        overflowX: "auto", // Allows horizontal scrolling when items overflow
      }}
    >
      {references.map((reference, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#f5f5f5",
            borderRadius: "30px",
            pl: 1,
            pr: 2,
            py: 1,
          }}
        >
          <Avatar style={{ marginRight: "8px" }}>
            {reference.referenceName
              .split(" ")
              .map((n) => n[0])
              .join("")}
          </Avatar>
          <div>
            <Typography
              variant="body2"
              fontWeight="bold"
              style={{ lineHeight: "1.2" }}
            >
              {reference.referenceName}
            </Typography>
            <Typography
              variant="body2"
              component="span"
              style={{ lineHeight: "1.2" }}
            >
              {reference.phone}
            </Typography>
          </div>
        </Box>
      ))}
    </Box>
  );

  if (contentOnly) return renderReferences();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: 1,
        bgcolor: "background.paper",
        boxSizing: "border-box",
        mx: { xs: 1, sm: 0 },
      }}
    >
      <Divider sx={{ mt: { xs: 2, sm: 4 }, mb: 2 }} />
      <Typography
        variant="h6"
        fontSize={"1.15rem"}
        fontWeight={"600"}
        sx={{ mb: 2 }}
      >
        My references
      </Typography>
      {renderReferences()}
    </Box>
  );
};

export default ReferencesDetails;
