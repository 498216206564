import { KeyboardArrowLeft } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { doc, setDoc } from "firebase/firestore";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthProvider";
import { useUser } from "../../contexts/UserProvider";
import { db } from "../../firebase";
import { validateIdentity } from "../../services/formServices";
import { formatName } from "../../services/stringServices";
import NameField from "../fields/NameField";

const IdentityView = ({
  handleNext = () => {},
  handleBack = () => {},
  canSkip = true,
  showBack = false,
}) => {
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();

  const { userPrivateData } = useUser();
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    firstName: userPrivateData?.firstName || "",
    lastName: userPrivateData?.lastName || "",
    birthday: userPrivateData?.birthday
      ? DateTime.fromISO(userPrivateData.birthday)
      : null,
    gender: userPrivateData?.gender || "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Function to update state based on the current user private data
    setValues({
      firstName: userPrivateData?.firstName || "",
      lastName: userPrivateData?.lastName || "",
      birthday: userPrivateData?.birthday
        ? DateTime.fromISO(userPrivateData.birthday)
        : null,
      gender: userPrivateData?.gender || "",
    });
  }, [userPrivateData]);

  const handleDateChange = (newDate) => {
    // Update the 'birthday' in your state

    setErrors((prevErrors) => ({
      ...prevErrors,
      birthday: "", // Clear any previous error
    }));

    // Make sure to handle the newDate format correctly as per your requirements
    setValues((prevValues) => ({
      ...prevValues,
      birthday: newDate, // Assuming newDate is in the format you need; otherwise, you might need to format it
    }));
  };

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;

    const actualValue = type === "checkbox" ? checked : value;

    setValues((prevValues) => ({ ...prevValues, [name]: actualValue }));

    // Clear error for the field
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    let newError = validateIdentity(values);
    if (Object.keys(newError).length > 0 && !canSkip) {
      setErrors(newError);
      setLoading(false); // Ensure to stop the loading process here if there are validation errors
      return; // Exit the function if there are errors
    }

    try {
      await setDoc(
        doc(db, "usersPublic", currentUser.uid),
        {
          firstName: values.firstName
            ? formatName(values.firstName)
            : undefined,
          lastName: values.lastName ? formatName(values.lastName) : undefined,
          birthMonth: values.birthday
            ? values.birthday.toFormat("yyyy-MM")
            : undefined,
          gender: values.gender,
        },
        { merge: true }
      );

      await setDoc(
        doc(db, "usersPrivate", currentUser.uid),
        {
          firstName: values.firstName
            ? formatName(values.firstName)
            : undefined,
          lastName: values.lastName ? formatName(values.lastName) : undefined,
          birthday: values.birthday
            ? values.birthday.toFormat("yyyy-MM-dd")
            : undefined,
          birthMonth: values.birthday
            ? values.birthday.toFormat("yyyy-MM")
            : undefined,
          gender: values.gender,
        },
        { merge: true }
      );

      handleNext();
    } catch (err) {
      console.log("error: ", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DialogContent>
        {showBack && (
          <ButtonBase
            onClick={handleBack}
            disabled={loading}
            sx={{ color: "text.secondary", my: 1 }}
          >
            <KeyboardArrowLeft /> Back
          </ButtonBase>
        )}
        <Typography
          align="left"
          fontWeight={"600"}
          fontSize={"1.15rem"}
          gutterBottom
        >
          Personal info
        </Typography>
        <Typography align="left" color={"text.secondary"} sx={{ mb: 2 }}>
          This is how others will recognize you.
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexDirection: { xs: "row", sm: "row" },
            }}
          >
            <NameField
              name={"firstName"}
              value={values?.firstName}
              error={errors.firstName}
              handleChange={handleChange}
              label={"First Name"}
            />
            <NameField
              name={"lastName"}
              value={values?.lastName}
              error={errors.lastName}
              handleChange={handleChange}
              label={"Last Name"}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <DatePicker
              sx={{ width: "100%" }}
              label={"Birthday"}
              name="birthday"
              views={["year", "month", "day"]}
              value={values?.birthday} // Use the state value here
              onChange={handleDateChange} // Update the state when the date changes
            />
            <FormHelperText
              id="birth-month-error"
              error={errors.birthday}
              sx={{ ml: "14px" }}
            >
              {errors.birthday
                ? errors.birthday
                : "To sign up, you must be at least 18. Only your birth month and year are shared."}
            </FormHelperText>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <FormControl
              sx={{ width: "100%" }}
              onKeyDown={(e) => {
                if (e.key === "m") {
                  e.preventDefault(); // Prevents the default behavior of the key press
                  handleChange({
                    target: {
                      name: "gender",
                      value: "male",
                    },
                  });
                } else if (e.key === "f") {
                  e.preventDefault();
                  handleChange({
                    target: {
                      name: "gender",
                      value: "female",
                    },
                  });
                }
              }}
            >
              <InputLabel id="gender-label">Gender</InputLabel>
              <Select
                labelId="gender-label"
                name="gender"
                label="Gender"
                value={values?.gender}
                onChange={handleChange}
                error={!!errors.gender}
              >
                <MenuItem value={"female"}>Female</MenuItem>
                <MenuItem value={"male"}>Male</MenuItem>
              </Select>
              {errors.gender && (
                <Typography
                  variant="caption"
                  color="error"
                  sx={{ ml: "14px", mt: "3px" }}
                >
                  {errors.gender}
                </Typography>
              )}
            </FormControl>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "100%",
          }}
        >
          <Button
            disableElevation
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={loading}
            sx={{
              width: "100%",
              height: "56px",
              textTransform: "none",
            }} // Take up half the available width
          >
            {loading ? ( // Conditional rendering based on loading state
              <CircularProgress size={24} color="inherit" />
            ) : (
              <Typography fontWeight={"500"}>Continue</Typography>
            )}
          </Button>
          {canSkip && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: "56px",
                alignItems: "center",
              }}
            >
              <Typography
                align="center"
                onClick={handleNext}
                fontWeight={"500"}
                sx={{ textDecoration: "underline", my: 1, cursor: "pointer" }}
              >
                Skip for now
              </Typography>
            </Box>
          )}
        </Box>
      </DialogActions>
    </>
  );
};

export default IdentityView;
