import { useTheme } from "@emotion/react";
import { Event, ForumOutlined, Stars } from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Box,
  Skeleton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  arrayRemove,
  arrayUnion,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useAuth } from "../../contexts/AuthProvider";
import { useDialog } from "../../contexts/DialogProvider";
import { useUser } from "../../contexts/UserProvider";
import { db } from "../../firebase";
import {
  calculateDistanceGeohash,
  isPointInPolygon,
} from "../../services/locationServices";
import { formatName } from "../../services/stringServices";
import GridButtons from "../buttons/GridButtons";
import ReportButton from "../buttons/ReportButton";
import RatingSummary from "../reviews/RatingSummary";
import BioDetails from "./BioDetails";
import BookingDetails from "./BookingDetails";
import FactsDetails from "./FactsDetails";
import InterestsDetails from "./InterestsDetails";
import MapDetails from "./MapDetails";
import NameRatingLocationDetails from "./NameRatingLocationDetails";
import ReferencesDetails from "./ReferencesDetails";
import SkillsDetails from "./SkillsDetails";

const ProfileCard = ({ userData }) => {
  const [isFavorite, setIsFavorite] = useState(false);

  const { currentUser } = useAuth();
  const { userPrivateData } = useUser();
  const { handleOpenChat, handleOpenBooking, handleOpenReviews } = useDialog();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const inServiceArea = isPointInPolygon(
    { lat: userPrivateData?.lat, lng: userPrivateData?.lng },
    userData?.bounds || []
  );

  const milesBetween = calculateDistanceGeohash(
    userData?.geohash6,
    userPrivateData?.geohash
  );

  const params = new URLSearchParams(window.location.search);
  const skill = params.get("skill");
  const city = params.get("city");
  const state = params.get("state");
  const rate = params.get("rate");
  const firstName = params.get("firstName");
  const seoTitle = `Hire ${formatName(
    firstName
  )} for help with ${skill} near me in ${city}, ${state} | TaskMom`;
  const seoDescription = `Find affordable local help with your tasks. Hire ${firstName} at $${rate}/hour for help with ${skill} in ${city}, ${state}. Start using TaskMom today!`;

  const isHelper =
    userData?.helperStatus === "active" ||
    userData?.helperStatus === "inactive";

  // Assume userPrivateData is already fetched and contains a 'favorites' array
  useEffect(() => {
    if (userPrivateData && userData) {
      const favorites = userPrivateData.favorites || [];
      setIsFavorite(favorites.includes(userData.id));
    }
  }, [userData, userPrivateData]); // Dependency array to re-check whenever userData or userPrivateData changes

  const toggleFavorite = async () => {
    if (!currentUser || !userData) return; // Ensure currentUser and userData are defined

    const userPrivateDocRef = doc(db, "usersPrivate", currentUser.uid); // Reference to the current user's private document

    try {
      const docSnap = await getDoc(userPrivateDocRef);
      if (docSnap.exists()) {
        const favorites = docSnap.data().favorites || [];
        if (favorites.includes(userData.id)) {
          // If already a favorite, remove from the favorites array
          await updateDoc(userPrivateDocRef, {
            favorites: arrayRemove(userData.id),
          });
        } else {
          // If not already a favorite, add to the favorites array
          await updateDoc(userPrivateDocRef, {
            favorites: arrayUnion(userData.id),
          });
        }
      } else {
        console.log("Document does not exist.");
      }
    } catch (error) {
      console.error("Error toggling favorite:", error);
    }
  };

  // Define the options in an array //inServiceArea milesBetween
  let actions = [];

  if (
    userData?.helperStatus === "active" &&
    userData?.id !== currentUser?.uid &&
    (inServiceArea || !userPrivateData?.geohash)
  ) {
    actions.push({
      primary: (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          Book
          {/* <AvailabilityChip user={userData} showIcon={false} /> */}
        </Box>
      ),
      icon: <Event />,
      secondary: "Find a time that works for both of you",
      action: () => handleOpenBooking(userData?.id),
      arrow: true,
    });
  }

  if (milesBetween < 50 || !userPrivateData?.geohash) {
    actions.push({
      primary: "Reviews",
      secondary: (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          Read community reviews
          {userData?.id !== currentUser?.uid && " or write your own"}
          {userData?.totalReviews > 0 && (
            <RatingSummary
              averageRating={userData?.averageRating}
              totalReviews={userData?.totalReviews}
              size={"small"}
            />
          )}
        </Box>
      ),
      icon: <Stars />,
      action: () => handleOpenReviews(userData?.id),
      arrow: true,
    });
  }

  if (
    (milesBetween < 50 && userData?.id !== currentUser?.uid) ||
    !userPrivateData?.geohash
  ) {
    actions.push({
      primary: "Chat",
      secondary: `Start a conversation with ${formatName(userData?.firstName)}`,
      icon: <ForumOutlined />,
      action: () => handleOpenChat([userData]),
      arrow: true,
    });
  }

  const renderReliabilityRating = () => {
    const confirmed =
      userData?.totalVisitsHelped ?? 0 + userData?.totalVisitsReceived ?? 0;
    const missed =
      userData?.totalVisitsCancelled ?? 0 + userData?.totalVisitsDeclined ?? 0;

    if (userData?.totalVisitsHelped || userData?.totalVisitsReceived) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            backgroundColor: "#F5F5F5",
            py: 1,
            px: 2,
            boxSizing: "border-box",
            borderRadius: "15px",
          }}
        >
          <Typography fontWeight={"600"}>
            {((confirmed - (missed || 0)) / confirmed).toFixed(2) * 100}%
          </Typography>
          <Typography variant="caption" color={"text.secondary"}>
            Reliability
          </Typography>
        </Box>
      );
    }
  };

  const renderTotalVisits = () => {
    const totalVisits =
      userData?.totalVisitsHelped ?? 0 + userData?.totalVisitsReceived ?? 0;

    if (userData?.totalVisitsHelped || userData?.totalVisitsReceived) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            backgroundColor: "#F5F5F5",
            py: 1,
            px: 2,
            boxSizing: "border-box",
            borderRadius: "15px",
          }}
        >
          <Typography fontWeight={"600"}>{totalVisits}</Typography>
          <Typography variant="caption" color={"text.secondary"}>
            Total visits
          </Typography>
        </Box>
      );
    }
  };

  const renderJoinDate = () => {
    const joinDate = DateTime.fromJSDate(userData?.createdAt.toDate()).toFormat(
      "MMM yyyy"
    );

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          backgroundColor: "#F5F5F5",
          py: 1,
          px: 2,
          boxSizing: "border-box",
          borderRadius: "15px",
        }}
      >
        <Typography fontWeight={"600"}>{joinDate}</Typography>
        <Typography variant="caption" color={"text.secondary"}>
          Joined
        </Typography>
      </Box>
    );
  };

  const renderLastVisitDate = () => {
    if (userData?.lastVisitDate) {
      const lastVisitDate = DateTime.fromJSDate(
        userData.lastVisitDate.toDate()
      ).toRelative({
        style: "short", // Can be "long", "short", or "narrow"
      });
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            backgroundColor: "#F5F5F5",
            py: 1,
            px: 2,
            boxSizing: "border-box",
            borderRadius: "15px",
          }}
        >
          <Typography fontWeight={"600"}>{lastVisitDate}</Typography>
          <Typography variant="caption" color={"text.secondary"}>
            Last visit
          </Typography>
        </Box>
      );
    }
  };

  const renderContent = () => {
    return (
      <>
        <Helmet>
          <title>{seoTitle}</title>
          <meta name="description" content={seoDescription} />
          <meta
            name="keywords"
            content={`${skill}, ${city}, ${state}, help, tasks, TaskMom, Task Mom, local, jobs`}
          />
        </Helmet>
        <Box
          sx={{
            display: "flex",
            position: "relative",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: 2, sm: 6 },
            maxWidth: "1000px",
            ml: { xs: 0, md: "auto" },
            mr: { xs: 0, md: "auto" },
          }}
        >
          {userData ? (
            <>
              <Box
                sx={{
                  flex: 0.7,
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  maxWidth: "700px",
                }}
              >
                {!inServiceArea && isHelper && userPrivateData?.geohash && (
                  <Alert severity="warning">
                    This helper does not service your location
                  </Alert>
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 2,
                  }}
                >
                  {/* Avatar box */}
                  <Box
                    sx={{
                      position: "relative",
                      mx: { xs: "auto", md: 0 },
                    }}
                  >
                    {/* {userData?.id !== currentUser?.uid && (
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: -6,
                      right: -6,
                      zIndex: 10,
                    }}
                  >
                    <FavoriteButton
                      isFavorite={isFavorite}
                      toggleFavorite={toggleFavorite}
                    />
                  </Box>
                )} */}
                    <Avatar
                      src={userData?.avatarUrl}
                      sx={{ width: 200, height: 200, borderRadius: "15px" }}
                    />
                  </Box>

                  <NameRatingLocationDetails user={userData} />
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns:
                        "repeat(auto-fill, minmax(140px, 1fr))", // Use auto-fill and minmax for responsive columns
                      gap: 1, // sets a gap between the grid items
                      width: "100%", // ensures the box takes the full width of its container
                    }}
                  >
                    {renderReliabilityRating()}
                    {renderTotalVisits()}
                    {renderLastVisitDate()}
                    {renderJoinDate()}
                  </Box>
                </Box>
                {isMobile && (
                  <Box
                    sx={{
                      flex: 0.3,
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                      height: "300px",
                      width: "100%",
                      mt: 1,
                    }}
                  >
                    <GridButtons actions={actions} columns={{ xs: 1 }} />
                  </Box>
                )}

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: 1,
                  }}
                >
                  <BioDetails
                    bio={userData?.bio}
                    firstName={userData?.firstName}
                  />
                  <InterestsDetails
                    interests={userData?.interests}
                    firstName={userData?.firstName}
                  />

                  <FactsDetails factsData={userData?.facts} />

                  {userData.helperStatus === "active" && (
                    <>
                      <BookingDetails
                        hourlyRate={userData?.hourlyRate}
                        minBookingMinutes={userData?.minBookingMinutes}
                        maxBookingMinutes={userData?.maxBookingMinutes}
                      />
                      <SkillsDetails
                        skills={userData?.skills}
                        firstName={userData?.firstName}
                      />
                      <MapDetails bounds={userData?.bounds} />
                      <ReferencesDetails references={userData?.references} />
                    </>
                  )}
                </Box>
                {!isMobile && <ReportButton />}
              </Box>
              {!isMobile && (
                <Box
                  sx={{
                    flex: 0.4,
                    display: "flex",
                    flexDirection: "column",
                    position: { xs: "relative", md: "sticky" },
                    top: 72,
                    mt: 10,
                    height: "300px",
                  }}
                >
                  <GridButtons actions={actions} columns={{ xs: 1 }} />
                </Box>
              )}
              {isMobile && <ReportButton />}
            </>
          ) : (
            renderLoading()
          )}
        </Box>
      </>
    );
  };

  const renderLoading = () => {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Skeleton variant="rounded" width={200} height={200} />
        <Skeleton variant="text" width={210} height={32} />
        <Skeleton variant="text" width={190} height={24} />
      </Box>
    );
  };

  return <>{renderContent()}</>;
};

export default ProfileCard;
