import React, { useState } from "react";
import DatePicker from "./DatePicker";

const MonthView = ({ highlightedDates }) => {
  const [selectedDate, setSelectedDate] = useState();
  const [selectedVisit, setSelectedVisit] = useState();
  const [weekIndex, setWeekIndex] = useState(0);

  const handleDateChange = (date) => {
    setSelectedVisit({}); // Reset selectedVisit and its key
    setSelectedDate(date);
  };

  console.log("++weekIndex: ", weekIndex);

  return (
    <>
      <DatePicker
        onChange={handleDateChange}
        highlightedDates={highlightedDates}
        weekIndex={weekIndex}
        setWeekIndex={setWeekIndex}
      />
      
    </>
  );
};

export default MonthView;
