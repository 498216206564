import { Box } from "@mui/material";
import { collection, getDocs, query, where } from "firebase/firestore";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import MonthView from "../components/calendar/MonthView";
import { useAvailability } from "../contexts/AvailabilityProvider";
import { db } from "../firebase"; // Adjust path as per your project structure

const DevPage = () => {
  const { loadAvailabilityByRange, availability } = useAvailability();
  const [users, setUsers] = useState([]); // Assuming you want to store the fetched users

  useEffect(() => {
    const today = DateTime.now().startOf("day");
    const twoWeeksOut = today.plus({ weeks: 2 }).endOf("day");

    loadAvailabilityByRange(today, twoWeeksOut);

    // const usersQuery = query(
    //   collection(db, "usersPublic"), // Assuming 'usersPublic' was intended
    //   where("skills", "array-contains", "Organizing"),
    //   where("daysAvailable", "array-contains", "2024-04-18")
    // );

    // getDocs(usersQuery)
    //   .then((querySnapshot) => {
    //     const usersData = querySnapshot.docs.map((doc) => ({
    //       id: doc.id,
    //       data: doc.data(),
    //     }));
    //     setUsers(usersData); // Store fetched users in state
    //   })

    // Update highlightedDates based on fetched availability
    return () => {
      // Cleanup logic here if necessary
    };
  }, []);

    const highlightedDates = Array.from(availability.values()).map((avail) =>
      DateTime.fromJSDate(avail.start.toDate())
    );

  console.log("users: ", users);

  return (
    <Box sx={{ width: { xs: "100%", sm: "50%" }, height: "400px" }}>
      <MonthView highlightedDates={highlightedDates} />
    </Box>
  );
};

export default DevPage;
