import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import ConversationsList from "../chat/ConversationsList";
import InstallFab from "../buttons/InstallFab";

const ChatDash = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "calc(100vh - 120px - 32px)",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Typography variant="h4" component="h1" sx={{ height: "52px" }}>
          Chat
        </Typography>
        <Divider sx={{ mx: { xs: -2, sm: 0 } }} />
        <ConversationsList />
      </Box>
      <InstallFab />

    </>
  );
};

export default ChatDash;
