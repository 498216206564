import { CheckCircle } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import Rating from "@mui/material/Rating";
import { Timestamp, collection, doc, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { formatName } from "../../services/stringServices";
import MessageBox from "../boxes/MessageBox";

const WriteReviewForm = ({ reviewer = {}, reviewee = {}, handleClose }) => {
  const [rating, setRating] = useState(0);
  const [tags, setTags] = useState([]);
  const [reviewText, setReviewText] = useState("");
  const [tagOptions, setTagOptions] = useState(); // Example predefined tags
  const [submitted, setSubmitted] = useState(false);

  const tagsByRating = {
    1: [
      "unreliable", // Failed to show up or complete tasks as expected
      "disrespectful", // Lacked courtesy and respect in interactions
      "ignored instructions", // Did not follow given guidelines or requests
      "messy", // Left areas disorganized or dirty after tasks
      "uncommunicative", // Poor or no communication about tasks or needs
      "unsafe", // Did not adhere to safety guidelines or precautions
    ],
    2: [
      "late arrival", // Often arrived later than the agreed time
      "minimally prepared", // Came with minimal preparation or tools needed
      "inflexible", // Struggled to adapt to changes or specific requests
      "lacks initiative", // Rarely took initiative, needed constant direction
      "partially complete", // Frequently left tasks incomplete or partially done
      "slow responder", // Delayed responses to communications or requests
    ],
    3: [
      "on-time", // Arrived and completed tasks in a timely manner
      "polite", // Was courteous and polite in all interactions
      "basic awareness", // Maintained a basic level of cleanliness
      "follows directions", // Generally followed directions well
      "quiet", // Worked quietly, with minimal disturbance
      "needs reminders", // Often needed reminders for tasks or details
    ],
    4: [
      "friendly", // Consistently friendly with others
      "attention to detail", // Attention to detail and thoroughness in tasks
      "good with kids", // Demonstrated positive interaction with children
      "adaptable", // Easily adapted to changes or unexpected situations
      "communicative", // Kept open lines of communication about tasks
      "clean", // Left work areas clean and organized
    ],
    5: [
      "arrived early", // Consistently arrived earlier than expected
      "above and beyond", // Exceeded expectations in task completion
      "great communication", // Very friendly and easy to communicate with
      "very efficient", // Anticipated needs without being asked
      "highly capable", // Anticipated needs without being asked
      "made my day", // Provided exceptional care and attention
    ],
  };

  // Update the tagOptions based on the current rating
  useEffect(() => {
    const updateTags = tagsByRating[rating] || [];
    setTagOptions(updateTags);
    setTags([]);
  }, [rating]);

  if (!reviewer || !reviewee) return;

  const handleTagClick = (tag) => {
    setTags((prevTags) => {
      if (prevTags.includes(tag)) {
        return prevTags.filter((t) => t !== tag);
      } else {
        return [...prevTags, tag];
      }
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const reviewData = {
      rating,
      tags,
      reviewText,
      helpfulCount: 0, // Initially 0 as no one has marked the review as helpful yet
      createdAt: Timestamp.now(),
      reviewerId: reviewer.id,
      revieweeId: reviewee.id,
      reviewer: {
        userId: reviewer.id,
        name: `${formatName(reviewer.firstName)} ${reviewer.lastName
          .charAt(0)
          .toUpperCase()}.`,
        avatarUrl: reviewer.avatarUrl,
        geohash6: reviewer.geohash6,
        city: reviewer.city,
        state: reviewer.state,
        zipCode: reviewer.zipCode,
        gender: reviewer.gender,
        birthMonth: reviewer.birthMonth,
      },
      reviewee: {
        userId: reviewee.id,
        name: `${formatName(reviewee.firstName)} ${reviewee.lastName
          .charAt(0)
          .toUpperCase()}.`,
        avatarUrl: reviewee.avatarUrl,
        geohash6: reviewee.geohash6,
        city: reviewee.city,
        state: reviewee.state,
        zipCode: reviewee.zipCode,
        gender: reviewee.gender,
        birthMonth: reviewee.birthMonth,
      },
      comments: [], // Initially empty
    };

    try {
      const docRef = doc(collection(db, "reviews"));
      await setDoc(docRef, { id: docRef.id, ...reviewData });

      setRating(0);
      setTags([]);
      setReviewText("");
      handleClose();
      // setSubmitted(true);
    } catch (error) {
      console.error("Error submitting the review: ", error);
    }
  };

  const renderForm = () => {
    return (
      <>
        <DialogContent>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2, mb: 2 }}>
            <Rating
              sx={{
                "& .MuiRating-iconFilled": {
                  fontSize: "2.5rem", // Sets the font size of the filled icons
                },
                "& .MuiRating-iconEmpty": {
                  fontSize: "2.5rem", // Sets the font size of the empty icons
                },
                "& .MuiRating-iconHover": {
                  fontSize: "2.5rem", // Sets the font size of the hover icons
                },
              }}
              name="simple-controlled"
              value={rating}
              onChange={(event, newValue) => {
                setRating(newValue);
              }}
            />
          </Box>
          <Box
            sx={{
              my: 2,
              justifyContent: "center",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {tagOptions?.map((tag) => (
              <Chip
                key={tag}
                label={tag}
                onClick={() => handleTagClick(tag)}
                color={tags.includes(tag) ? "primary" : "default"}
                sx={{ mr: 1, mb: 1 }}
              />
            ))}
          </Box>
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            placeholder="Write your review here"
            value={reviewText}
            onChange={(e) => setReviewText(e.target.value)}
            sx={{ mb: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            disabled={!rating || !tags || !reviewText}
            variant="contained"
            sx={{ width: "100%", height: "56px" }}
            onClick={handleSubmit}
          >
            Submit Review
          </Button>
        </DialogActions>
      </>
    );
  };

  const renderSuccess = () => {
    return (
      <Box sx={{ p: 2 }}>
        <MessageBox
          Icon={<CheckCircle color="success" />}
          headerText="Review Created"
          messageText="Thank you for contributing to the community by leaving a review!"
          messageAlign="center"
          buttonText="Go back"
          buttonProps={{ variant: "contained", color: "primary" }}
          handleClick={handleClose}
          mt={2}
        />
      </Box>
    );
  };

  return <>{submitted ? renderSuccess() : renderForm()}</>;
};

export default WriteReviewForm;
