import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

function ExpandableText({ text }) {
  const contentRef = useRef(null);
  const [height, setHeight] = useState("40px"); // Start with height for two lines
  const [isExpanded, setIsExpanded] = useState(false);
  const [showToggle, setShowToggle] = useState(false); // State to control visibility of toggle link

  // Adjust height and check if toggle is needed based on expansion state and text length
  useEffect(() => {
    const checkHeight = () => {
      const contentCurrent = contentRef.current;
      if (contentCurrent) {
        const scrollHeight = `${contentCurrent.scrollHeight}px`;
        const isToggleNeeded = contentCurrent.scrollHeight > 40;
        setShowToggle(isToggleNeeded); // Show toggle only if the scroll height is greater than two lines
        setHeight(isExpanded ? scrollHeight : "40px");
      }
    };

    checkHeight();
    window.addEventListener("resize", checkHeight); // Ensure correct behavior on window resize
    return () => window.removeEventListener("resize", checkHeight); // Cleanup on component unmount
  }, [isExpanded]);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const textStyle = {
    transition: "height 0.3s ease",
    overflow: "hidden",
    height: height,
    cursor: "pointer",
  };

  const linkStyle = {
    color: "darkgray",
    textDecoration: "underline",
    cursor: "pointer",
    position: "absolute",
    right: 0,
    bottom: 0,
    backgroundColor: "#F5F5F5",
    pl: 1,
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Typography
        variant="body2"
        sx={textStyle}
        ref={contentRef}
        onClick={toggleExpansion}
      >
        {text}
      </Typography>
      {showToggle && (
        <Typography variant="body2" sx={linkStyle} onClick={toggleExpansion}>
          {isExpanded ? "less" : "more"}
        </Typography>
      )}
    </Box>
  );
}

export default ExpandableText;
