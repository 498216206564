import { Divider, Typography } from "@mui/material";
import React from "react";
import UserProfileCard from "../header/UserProfileCard";
import InstallFab from "../buttons/InstallFab";

const SettingsDash = () => {
  return (
    <>
      <Typography variant="h4" component="h1" sx={{ height: "52px" }}>
        Account
      </Typography>
      <Divider sx={{ mx: { xs: -2, sm: 0 }, mb: 2 }} />

      <UserProfileCard />
      <InstallFab />
    </>
  );
};

export default SettingsDash;
