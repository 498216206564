import { NotificationsNoneRounded } from "@mui/icons-material";
import { Badge, Box, IconButton, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { useUser } from "../../contexts/UserProvider";

import { useTheme } from "@emotion/react";
import { useNotifications } from "../../contexts/NotificationsProvider";
import ResponsiveDialog from "../dialogs/ResponsiveDialog";
import NotificationsContent from "./NotificationsContent";
import NotificationsPopover from "./NotificationsPopover";

const NotificationBell = () => {
  const { userPrivateData } = useUser();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { notificationsData } = useNotifications();
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);

  // Calculate unread notifications count
  const isNotificationsOpen = Boolean(notificationsAnchorEl);

  const handleNotificationsToggle = (event) => {
    if (isNotificationsOpen) {
      setNotificationsAnchorEl(null);
    } else {
      setNotificationsAnchorEl(event.currentTarget);
    }
  };

  const handleNotificationsClose = () => {
    setNotificationsAnchorEl(null);
  };

  return (
    <Box>
      {/* Notification Bell */}
      <IconButton
        size="large"
        disableRipple
        color="disabled"
        onClick={handleNotificationsToggle}
        sx={{
          padding: "6px",
          height: "54px",
          width: "54px",
          boxSizing: "border-box",
          borderRadius: "30px",
          border: isNotificationsOpen
            ? "1px solid rgba(0, 0, 0, 0.12)"
            : "1px solid rgba(0, 0, 0, 0)",
          transition: "box-shadow, border, 0.3s ease-in-out", // Smooth transition for shadow
          boxShadow: isNotificationsOpen
            ? "0 5px 10px 0 rgba(0,0,0,0.15)"
            : "none", // Drop shadow when open
          "&:hover": {
            border: "1px solid rgba(0, 0, 0, 0.12)",
            boxShadow: "0 5px 10px 0 rgba(0,0,0,0.15)", // Slightly more pronounced shadow on hover
          },
        }}
      >
        <Badge badgeContent={userPrivateData?.notificationCount} color="error">
          {isNotificationsOpen ? (
            <NotificationsNoneRounded
              sx={{ color: "black", fontSize: "1.5rem" }}
            />
          ) : (
            <NotificationsNoneRounded
              sx={{ color: "black", fontSize: "1.5rem" }}
            />
          )}
        </Badge>
      </IconButton>
      {isMobile ? (
        <ResponsiveDialog
          open={isNotificationsOpen}
          onClose={handleNotificationsClose}
          title={"Notifications"}
          width="400px"
          anchor="right"
          fullHeight={true}
        >
          <NotificationsContent
            notifications={notificationsData}
            onClose={handleNotificationsClose}
          />
        </ResponsiveDialog>
      ) : (
        <NotificationsPopover
          anchorEl={notificationsAnchorEl}
          open={isNotificationsOpen}
          onClose={handleNotificationsClose}
          notifications={notificationsData}
        />
      )}
    </Box>
  );
};

export default NotificationBell;
