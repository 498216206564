import {
  collection,
  endAt,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  startAt,
  where,
} from "firebase/firestore";
import { geohashQueryBounds } from "geofire-common";
import { DateTime } from "luxon";
import React, { createContext, useContext, useRef, useState } from "react";
import { db } from "../firebase";
import { decodeGeoHash, isPointInPolygon } from "../services/locationServices";
import { useAuth } from "./AuthProvider";
import { useUser } from "./UserProvider";

const UserContext = createContext();

export const useAvailability = () => useContext(UserContext);

export const AvailabilityProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const { userPrivateData } = useUser();

  const [availability, setAvailability] = useState(new Map());
  const listenersRef = useRef({});
  const [availabilityExist, setAvailabilityExist] = useState(new Map());
  const [queryStates, setQueryStates] = useState([]);
  const [loading, setLoading] = useState(true);

  const userCoords = decodeGeoHash(userPrivateData?.geohash || "c28gcw");
  const bounds = geohashQueryBounds(userCoords, 5000); // Example radius, adjust as necessary
  const unsubscribeHandles = useRef([]);

  const loadAvailabilityByRange = async (startDate, endDate) => {
    const startDay = DateTime.fromISO(startDate);
    const endDay = DateTime.fromISO(endDate);
    const initialQueryStates = bounds.map(() => ({
      lastVisible: null,
      hasMore: true,
    }));

    let currentDay = startDay;

    while (currentDay <= endDay) {
      await loadAvailabilityByDate(1, currentDay, initialQueryStates);
      currentDay = currentDay.plus({ days: 1 });
    }
  };

  const loadAvailabilityByDate = async (
    desiredNumber = 1,
    currentDay,
    qStates
  ) => {
    let totalFetchedAvailability = new Map(); // Use a Map instead of an array
    let updatedQueryStates = [...qStates]; // Copy the current state to update it
    let availabilityToFetch = desiredNumber;

    while (availabilityToFetch > 0) {
      let fetchedInThisRound = 0;

      // Ensure bounds are only calculated once this round and then reused
      const availabilityQueries = constructQuery(
        bounds,
        currentDay,
        updatedQueryStates.map((query) => query?.lastVisible) // Ensure you're using the lastVisible doc from the state
      );

      for (const [index, availabilityQuery] of availabilityQueries.entries()) {
        if (updatedQueryStates[index]?.hasMore === false) continue;

        try {
          const querySnapshot = await getDocs(availabilityQuery);
          // await new Promise((resolve) => setTimeout(resolve, 500));
          const fetchedAvailability = querySnapshot.docs
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
            .filter((availability) =>
              isPointInPolygon(userCoords, availability.bounds)
            );

          fetchedAvailability.forEach((availability) => {
            totalFetchedAvailability.set(availability.id, availability); // Store with ID as the key
          });
          fetchedInThisRound += fetchedAvailability.length;

          // Update the state for the current query
          updatedQueryStates[index] = {
            lastVisible:
              querySnapshot.docs[querySnapshot.docs.length - 1] || null,
            hasMore: querySnapshot.docs.length > 0,
          };
        } catch (error) {
          console.error("Failed to fetch availability:", error);
        }
      }

      // Check if all 'hasMore' flags are false, indicating no more availability can be fetched from any bounds
      const allQueriesExhausted = updatedQueryStates.every(
        (state) => !state.hasMore
      );
      if (allQueriesExhausted) {
        break; // Break the loop if all queries are exhausted
      }

      availabilityToFetch = desiredNumber - totalFetchedAvailability.length;
    }

    // setAvailability((prev) => [...prev, ...totalFetchedAvailability]);
    // setAvailability using the values from the map
    // setAvailability using the values from the map
    setAvailability((prev) => {
      // Create a set of existing availability IDs for fast lookup
      const existingIds = new Set(Array.from(prev.keys())); // Convert Map keys to an array
      const newAvailability = Array.from(
        totalFetchedAvailability.values()
      ).filter((availability) => !existingIds.has(availability.id));

      // Combine existing and new availability into a new Map
      const updatedMap = new Map(prev); // Create a new Map from the previous Map
      newAvailability.forEach((availability) => {
        updatedMap.set(availability.id, availability); // Add or overwrite only the new or updated entries
      });

      return updatedMap;
    });

    setQueryStates(updatedQueryStates); // Update the state with the new lastVisible docs
    setLoading(false);

    const currentDateKey = currentDay.toISODate(); // Converts DateTime to an ISO date string (YYYY-MM-DD)

    if (
      totalFetchedAvailability.size === 0 &&
      availability.length === 0 &&
      availabilityExist === null
    ) {
      setAvailabilityExist((prev) => new Map(prev).set(currentDateKey, false));
    } else {
      setAvailabilityExist((prev) => new Map(prev).set(currentDateKey, true));
    }
  };

  console.log("availability: ", availability);

  const constructQuery = (bounds, currentDay, lastVisibleDocs = []) => {
    const startTimestamp = DateTime.fromJSDate(
      currentDay.startOf("day").toJSDate()
    );
    const endTimestamp = DateTime.fromJSDate(
      currentDay.endOf("day").toJSDate()
    );

    return bounds.map(([start, end], index) => {
      let baseQuery = query(
        collection(db, "availability"),
        where("start", ">=", startTimestamp.toJSDate()),
        where("end", "<=", endTimestamp.toJSDate())
        // where("availabilityStatus", "==", "active")
      );

      // if (selectedSkills && selectedSkills.length > 0) {
      //   const skillNames = selectedSkills.map((skill) => skill.name);
      //   baseQuery = query(
      //     baseQuery,
      //     where("skills", "array-contains-any", skillNames)
      //   );
      // }

      // if (selectedTimeline && selectedTimeline !== "anytime") {
      //   baseQuery = query(
      //     baseQuery,
      //     where(`availability.${selectedTimeline}`, ">", 0)
      //   );
      // }

      baseQuery = query(
        baseQuery,
        orderBy("geohash6"),
        startAt(start),
        endAt(end)
      );

      // Apply pagination based on the last visible document for this range, if present
      const afterDoc = lastVisibleDocs[index];
      if (afterDoc) {
        baseQuery = query(baseQuery, startAfter(afterDoc));
      }

      baseQuery = query(baseQuery, limit(1));

      return baseQuery;
    });
  };

  const value = {
    availability,
    loadAvailabilityByRange,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

/**
 *   // Adjusted to accept userId and include it in the rangeKey for uniqueness
  const loadAndListenAvailabilityInRange = async (userId, start, end) => {
    if (!userId) return;

    const rangeKey = `${userId}_${start.toISODate()}_${end.toISODate()}`;
    if (listenersRef.current[rangeKey]) {
      // Avoid setting up duplicate listeners
      return;
    }

    const startTimestamp = Timestamp.fromDate(start.toJSDate());
    const endTimestamp = Timestamp.fromDate(end.toJSDate());

    // Query now matches availability entries to the provided userId
    const availabilityQuery = query(
      collection(db, "availability"),
      where("start", ">=", startTimestamp),
      where("end", "<=", endTimestamp),
      where("userId", "==", userId) // Adjusted query to match the userId directly
    );

    const unsubscribe = onSnapshot(availabilityQuery, (querySnapshot) => {
      const newAvailabilityData = new Map(availability);
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        newAvailabilityData.set(doc.id, {
          ...data,
          startTime: DateTime.fromJSDate(data.start.toDate()),
          endTime: DateTime.fromJSDate(data.end.toDate()),
        });
      });
      setAvailability(newAvailabilityData);
    });

    listenersRef.current[rangeKey] = unsubscribe;
  };

  // Cleanup function
  const cleanupListeners = () => {
    Object.values(listenersRef.current).forEach((unsubscribe) => unsubscribe());
    listenersRef.current = {};
  };

  // Cleanup on component unmount
  useEffect(() => {
    return () => cleanupListeners();
  }, []);
 */
