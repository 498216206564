import {
  Timestamp,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { DateTime } from "luxon";
import { db } from "../firebase";

export const TRAVEL_BUFFER = 30; // minutes

// newWindow must be Luxon
export const addAvailability = async ({ userId, newAvailability }) => {
  const { start, end, ...newOtherProps } = newAvailability;
  const userAvailabilityRef = doc(db, "usersPublic", userId);

  let hasShortDurations = false; // Flag to track short durations

  try {
    const docSnap = await getDoc(userAvailabilityRef);
    let availability =
      docSnap.exists() && Array.isArray(docSnap.data().availability)
        ? docSnap.data().availability
        : [];
    const minBookingMinutes = docSnap.data().minBookingMinutes || 0; // Default to 0 if undefined
    const userSkills = docSnap.data().skills || []; // Default to 0 if undefined

    // Input is already in luxon
    let unifiedStart = start;
    let unifiedEnd = end;

    // Initialize an object to accumulate otherProps from existing availability
    let accumulatedOtherProps = {};

    // Check for overlap and merge periods
    availability = availability.reduce((acc, current) => {
      const currentStart = DateTime.fromJSDate(current.start.toDate());
      const currentEnd = DateTime.fromJSDate(current.end.toDate());

      // Check for overlap
      if (currentEnd >= unifiedStart && currentStart <= unifiedEnd) {
        // Merge periods
        unifiedStart =
          currentStart < unifiedStart ? currentStart : unifiedStart;
        unifiedEnd = currentEnd > unifiedEnd ? currentEnd : unifiedEnd;

        // Accumulate other properties
        accumulatedOtherProps = {
          ...accumulatedOtherProps,
          ...current.otherProps,
        };
      } else {
        // Only keep non-overlapping availability
        acc.push(current);
      }
      return acc;
    }, []);

    // Merging newOtherProps into accumulatedOtherProps to let existing props take precedence
    const finalOtherProps = { ...newOtherProps, ...accumulatedOtherProps };

    // Fetch visits and "punch out" visit times from availability
    const visits = await fetchActiveVisitsForDay(
      userId,
      unifiedStart,
      unifiedEnd
    );
    let finalAvailability = availability;
    let previousEnd = unifiedStart;

    for (const [index, visit] of visits.entries()) {
      if (visit.status === "cancelled") continue;

      const startWithBuffer = visit.start.minus({ minutes: TRAVEL_BUFFER });
      const endWithBuffer = visit.end.plus({ minutes: TRAVEL_BUFFER });

      if (previousEnd < startWithBuffer) {
        const duration = startWithBuffer.diff(previousEnd, "minutes").minutes;
        if (duration >= minBookingMinutes) {
          // Push the gap as a free availability slot
          finalAvailability.push({
            ...finalOtherProps,
            id: createUniqueIdFromDates(previousEnd, startWithBuffer),
            // day: unifiedStart.toISODate(),

            start: Timestamp.fromDate(previousEnd.toJSDate()),
            end: Timestamp.fromDate(startWithBuffer.toJSDate()),
            // duration: startWithBuffer.diff(previousEnd, "minutes").minutes,
          });
        } else {
          hasShortDurations = true;
        }
      }
      previousEnd = endWithBuffer > previousEnd ? endWithBuffer : previousEnd;

      // Handle the last gap to the end of availability
      if (index === visits.length - 1 && previousEnd < unifiedEnd) {
        const duration = unifiedEnd.diff(previousEnd, "minutes").minutes;
        if (duration >= minBookingMinutes) {
          finalAvailability.push({
            ...finalOtherProps,
            id: createUniqueIdFromDates(previousEnd, unifiedEnd),
            // day: unifiedStart.toISODate(),

            start: Timestamp.fromDate(previousEnd.toJSDate()),
            end: Timestamp.fromDate(unifiedEnd.toJSDate()),
            // duration: unifiedEnd.diff(previousEnd, "minutes").minutes,
            // Include any other properties as needed
          });
        } else {
          hasShortDurations = true;
        }
      }
    }

    // If there are no overlapping visits, create the original unified availability
    if (visits.length === 0) {
      const duration = unifiedEnd.diff(unifiedStart, "minutes").minutes;
      if (duration >= minBookingMinutes) {
        finalAvailability.push({
          ...finalOtherProps,
          id: createUniqueIdFromDates(unifiedStart, unifiedEnd),
          // day: unifiedStart.toISODate(),
          // userId,
          start: Timestamp.fromDate(unifiedStart.toJSDate()),
          end: Timestamp.fromDate(unifiedEnd.toJSDate()),
          // duration: unifiedEnd.diff(unifiedStart, "minutes").minutes,
        });
      } else {
        hasShortDurations = true;
      }
    }

    // Generate the skill-date combinations
    const skillDateCombinations = createSkillDateCombinations(
      userSkills,
      finalAvailability
    );

    // Write back to Firestore with final availability array
    await setDoc(
      userAvailabilityRef,
      {
        availability: finalAvailability,
        skillDateLookup: skillDateCombinations,
      },
      { merge: true }
    );

    console.log("Availability updated successfully with visits punched out.");
    return { hasShortDurations }; // Return the flag
  } catch (error) {
    console.error("Error updating the availability: ", error.message);
    throw error;
  }
};

// Function to create a unique ID from DateTime objects
const createUniqueIdFromDates = (start, end) => {
  return `${start.toISODate()}_${start.toFormat(
    "HHmmss"
  )}-${end.toISODate()}_${end.toFormat("HHmmss")}`;
};

const fetchActiveVisitsForDay = async (userId, start, end) => {
  const visitsRef = collection(db, "visits");

  // Only filter based on the start time, within the day of the provided start DateTime
  const startOfDay = start.startOf("day").toJSDate();
  const endOfDay = start.endOf("day").toJSDate();

  // Query to use participants array
  const visitsQuery = query(
    visitsRef,
    where("participants", "array-contains", userId),
    where("start", ">=", startOfDay),
    where("start", "<=", endOfDay)
  );

  // Execute the query
  const visitsSnapshot = await getDocs(visitsQuery);

  // Map over the documents and convert dates
  const visitsData = visitsSnapshot.docs
    .map((doc) => {
      const visit = doc.data();
      return {
        ...visit,
        start: DateTime.fromJSDate(visit.start.toDate()),
        end: DateTime.fromJSDate(visit.end.toDate()),
      };
    })
    .filter((visit) => {
      const bufferedStart = start.minus({ minutes: TRAVEL_BUFFER });
      const bufferedEnd = end.plus({ minutes: TRAVEL_BUFFER });

      // Filter visits within the buffered range and not cancelled
      return (
        visit.end > bufferedStart &&
        visit.start < bufferedEnd &&
        visit.status !== "cancelled"
      );
    })
    .sort((a, b) => a.start.valueOf() - b.start.valueOf()); // Sort by start time

  return visitsData;
};

const createSkillDateCombinations = (skills, availability) => {
  const now = DateTime.now(); // Get the current time
  const combinationSet = new Set(); // Using a Set to ensure uniqueness

  skills.forEach((skill) => {
    availability.forEach((avail) => {
      const startDate = DateTime.fromJSDate(avail.start.toDate());
      if (startDate > now) {
        // Only consider future dates
        const date = startDate.toISODate(); // Format date as ISO YYYY-MM-DD
        combinationSet.add(`${skill}-${date}`); // Add combination to the Set
      }
    });
  });

  // Convert Set to Array and limit the number of entries to 400
  return Array.from(combinationSet).slice(0, 400);
};

export const deleteAvailabilitySeries = async (userId, recurringId) => {
  // Reference to the user's availability document
  const userAvailabilityRef = doc(db, "usersPublic", userId);

  try {
    // Fetch the current availability document
    const docSnap = await getDoc(userAvailabilityRef);
    if (!docSnap.exists()) {
      console.log("No availability found for user.");
      return;
    }

    // Filter out the availabilities that match the recurringId
    const userSkills = docSnap.data().skills || [];
    const updatedAvailabilities = docSnap
      .data()
      .availabilities.filter(
        (availability) => availability.recurringId !== recurringId
      );

    // Generate the skill-date combinations
    const skillDateCombinations = createSkillDateCombinations(
      userSkills,
      updatedAvailabilities
    );

    // Update the document with the filtered availabilities
    await updateDoc(userAvailabilityRef, {
      availability: updatedAvailabilities,
      skillDateLookup: skillDateCombinations,
    });
    console.log("Availability series deleted successfully.");
  } catch (error) {
    console.error("Error deleting the availability series: ", error.message);
    throw error;
  }
};

export const deleteAvailabilityAllFuture = async (userId, startTimestamp) => {
  // Ensure startDate is parsed into a Luxon DateTime
  const startDate = DateTime.fromJSDate(new Date(startTimestamp));

  const userAvailabilityRef = doc(db, "usersPublic", userId);

  try {
    // Fetch the current availability document
    const docSnap = await getDoc(userAvailabilityRef);
    if (!docSnap.exists()) {
      console.log("No availability found for user.");
      return;
    }

    // Filter out future availabilities starting from the given startDate
    const currentAvailabilities = docSnap.data().availability || [];
    const userSkills = docSnap.data().skills || [];

    const updatedAvailabilities = currentAvailabilities.filter(
      (availability) => {
        const availabilityStart = DateTime.fromJSDate(
          availability.start.toDate()
        );
        return availabilityStart < startDate; // Keep only availabilities before the startDate
      }
    );

    // Generate the skill-date combinations
    const skillDateCombinations = createSkillDateCombinations(
      userSkills,
      updatedAvailabilities
    );

    // Update the document with the remaining availabilities
    await updateDoc(userAvailabilityRef, {
      availability: updatedAvailabilities,
      skillDateLookup: skillDateCombinations,
    });
    console.log("Deleted all future availabilities successfully.");
  } catch (error) {
    console.error("Error deleting future availabilities: ", error.message);
    throw error;
  }
};

export const deleteAvailabilitySeriesFuture = async (
  userId,
  recurringId,
  startTimestamp
) => {
  // Ensure startDate is parsed into a Luxon DateTime
  const startDate = DateTime.fromJSDate(new Date(startTimestamp));

  const userAvailabilityRef = doc(db, "usersPublic", userId);

  try {
    // Fetch the current availability document
    const docSnap = await getDoc(userAvailabilityRef);
    if (!docSnap.exists()) {
      console.log("No availability document found for user.");
      return;
    }

    // Get current availabilities from the document
    const currentAvailabilities = docSnap.data().availability || [];
    const userSkills = docSnap.data().skills || [];

    // Filter out the future availabilities with the specified recurringId
    const updatedAvailabilities = currentAvailabilities.filter(
      (availability) => {
        const availabilityStart = DateTime.fromJSDate(
          availability.start.toDate()
        );
        return !(
          availability.recurringId === recurringId &&
          availabilityStart >= startDate
        );
      }
    );

    // Generate the skill-date combinations
    const skillDateCombinations = createSkillDateCombinations(
      userSkills,
      updatedAvailabilities
    );

    // Update the document with the filtered availabilities
    await updateDoc(userAvailabilityRef, {
      availability: updatedAvailabilities,
      skillDateLookup: skillDateCombinations,
    });
    console.log("Deleted future availabilities in the series successfully.");
  } catch (error) {
    console.error(
      "Error deleting future availabilities in the series: ",
      error.message
    );
    throw error;
  }
};

export const deleteSingleAvailability = async (userId, availabilityId) => {
  console.log("++userId: ", userId);
  console.log("++availabilityId: ", availabilityId);

  const userAvailabilityRef = doc(db, "usersPublic", userId);

  try {
    // Fetch the current availability document
    const docSnap = await getDoc(userAvailabilityRef);
    if (!docSnap.exists()) {
      console.log("No availability document found for user.");
      return;
    }

    // Get current availabilities from the document
    const currentAvailabilities = docSnap.data().availability || [];
    const userSkills = docSnap.data().skills || [];

    // Filter out the availability with the specified ID
    const updatedAvailabilities = currentAvailabilities.filter(
      (availability) => availability.id !== availabilityId
    );

    // Generate the skill-date combinations
    const skillDateCombinations = createSkillDateCombinations(
      userSkills,
      updatedAvailabilities
    );

    // Update the document with the remaining availabilities
    await updateDoc(userAvailabilityRef, {
      availability: updatedAvailabilities,
      skillDateLookup: skillDateCombinations,
    });
    console.log("Single availability deleted successfully.");
  } catch (error) {
    console.error("Error deleting single availability: ", error.message);
    throw error;
  }
};
