import { DeleteForever, Event, Settings } from "@mui/icons-material";
import { Box, DialogContent, Divider, Skeleton } from "@mui/material";
import { arrayRemove, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthProvider";
import { useDashboard } from "../../contexts/DashboardContext";
import { useVisits } from "../../contexts/VisitProvider";
import { db } from "../../firebase";
import { formatAddress } from "../../services/locationServices";
import MessageBox from "../boxes/MessageBox";
import ReportButton from "../buttons/ReportButton";
import ResponsiveDialog from "../dialogs/ResponsiveDialog";
import StyledAlert from "../styled/StyledAlert";
import CancelVisit from "./CancelVisit";
import DateTimeSection from "./DateTimeSection";
import LocationSection from "./LocationSection";
import PersonsSection from "./PersonSection";
import TagsDescriptionSection from "./TagsDescriptionSection";

const VisitCard = ({ visitId }) => {
  const { changeTab } = useDashboard();
  const {
    visitsData,
    loadVisitById,
    loadingVisits,
    setLoadingVisits,
    setVisitsData,
  } = useVisits(); // Now expecting an object
  const { currentUser } = useAuth();
  const [openReschedule, setOpenReschedule] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);

  const visit = visitId ? visitsData[visitId] : null;

  useEffect(() => {
    // If there's a visitId but the corresponding visit data isn't found in visitsData...
    if (visitId && !visitsData[visitId]) {
      loadVisitById(visitId);
    } else {
      setLoadingVisits(false);
    }
  }, [visitId]); // Depend on visitId and visitsData so it re-runs when either changes

  const [privateDetails, setPrivateDetails] = useState(null); // Step 1: State for private data

  useEffect(() => {
    let unsubscribe = () => {}; // Define an empty unsubscribe function

    if (visitId) {
      const privateDetailsRef = doc(
        db,
        "visits",
        visitId,
        "privateData",
        "details"
      );

      // Subscribe to real-time updates
      unsubscribe = onSnapshot(
        privateDetailsRef,
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            setPrivateDetails(docSnapshot.data()); // Update state with fetched data
          } else {
            console.log("No private details found.");
            setPrivateDetails(null); // Reset or handle the absence of data as needed
          }
        },
        (error) => {
          console.error("Error fetching private details:", error);
          setLoadingVisits(false); // Ensure loading is set to false on error
          // Handle error...
        }
      );
    }

    // Cleanup function to unsubscribe from the listener
    return () => {
      unsubscribe();
    };
  }, [visitId]);

  const userId = currentUser?.uid;

  const role =
    userId === visit?.requester.id
      ? "requester"
      : visit?.helpers[userId]
      ? "helper"
      : "unknown";

  let otherParticipants = {};
  let myResponse = "";
  const currentUserUid = currentUser?.uid; // Assuming currentUser object is available and contains uid

  if (visit) {
    // Add the requester to the participants object, unless they are the current user
    if (visit.requester && visit.requester.id !== currentUserUid) {
      otherParticipants[visit.requester.id] = {
        ...visit.requester,
        role: "requester",
      };
      if (visit.requester.id === currentUserUid)
        myResponse = visit.requester.responseStatus;
    }

    // Add helpers to the participants object, excluding the current user
    if (visit.helpers) {
      Object.values(visit.helpers).forEach((helper) => {
        if (helper.id !== currentUserUid) {
          otherParticipants[helper.id] = {
            ...helper,
            role: "helper",
          };
        }
        if (helper.id === currentUserUid) myResponse = helper.responseStatus;
      });
    }
  }

  const action = role === "requester" ? "Cancel" : "Decline";

  const visitStart = visit?.start?.seconds
    ? DateTime.fromSeconds(visit?.start.seconds, { zone: "local" })
    : DateTime.local(); // Default to current time if not loaded

  const visitEnd = visit?.end?.seconds
    ? DateTime.fromSeconds(visit?.end.seconds, { zone: "local" })
    : DateTime.local(); // Default to current time if not loaded

  const isPastEvent = visitStart < DateTime.local();

  const address = formatAddress({
    line1: privateDetails?.line1,
    line2: privateDetails?.line2,
    city: privateDetails?.city,
    state: privateDetails?.state,
    zipCode: privateDetails?.zipCode,
  });

  const removeVisit = async () => {
    // Derive the document reference from visitId
    const visitDocRef = doc(db, "visits", visitId);

    try {
      // Remove the current user from the list of participants
      await updateDoc(visitDocRef, {
        participants: arrayRemove(currentUser.uid),
      });
    } catch (error) {
      console.error("Error removing from the visit: ", error.message);
    }
    // Remove the visit from local state
    setVisitsData((prevState) => {
      const newState = { ...prevState };
      delete newState[visitId]; // Remove the visit from the local state
      return newState;
    });
  };

  return (
    <>
      {loadingVisits ? (
        <Box sx={{ maxWidth: "600px", mx: "auto", mt: "50px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              width: "100%",

              mb: 2,
              mb: "100px",
            }}
          >
            <Box sx={{ width: "100%", gap: 1 }}>
              <Skeleton width="80%" height={32} />
              <Skeleton width="80%" height={24} />
              <Skeleton width="80%" height={24} />
              <Skeleton width="80%" height={24} />
            </Box>
            <Box sx={{ width: "100%" }}>
              <Skeleton height={56} />
              <Skeleton height={56} />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center˝",
              gap: 2,
            }}
          >
            <Skeleton variant="circular" height={72} width={72} />
            <Box sx={{ width: "auto" }}>
              <Skeleton width="150px" height={32} />
              <Skeleton width="100px" height={24} />
            </Box>
          </Box>
        </Box>
      ) : visit ? (
        <>
          <DialogContent
            sx={{
              maxWidth: "600px",
              mx: { xs: 0, sm: "auto" },
            }}
          >
            {visit?.status === "cancelled" ? (
              <StyledAlert
                severity="error"
                desktopDescription={"This visit is cancelled."}
                mobileTitle={"This visit is cancelled."}
                buttonText={"Remove"}
                buttonProps={{ textTransform: "none" }}
                buttonStartIcon={<DeleteForever />}
                handleClick={() => removeVisit()}
                mx={-2}
                mt={-1}
              />
            ) : (
              myResponse === "declined" && (
                <StyledAlert
                  severity="error"
                  desktopDescription={"You declined this visit."}
                  mobileTitle={"You declined this visit."}
                  buttonText={"Remove"}
                  buttonProps={{ textTransform: "none" }}
                  buttonStartIcon={<DeleteForever />}
                  handleClick={() => removeVisit()}
                  mx={-1}
                  mt={-1}
                />
              )
            )}
            <DateTimeSection
              start={visitStart}
              myResponse={myResponse}
              end={visitEnd}
              helpers={visit?.helpers}
              line1={privateDetails?.line1}
              line2={privateDetails?.line2}
              role={role}
              setOpenReschedule={setOpenReschedule}
              setOpenCancel={setOpenCancel}
              status={visit?.status}
            />

            <Divider sx={{ my: 2, mx: -2 }} />
            <PersonsSection persons={otherParticipants} />
            {role === "helper" &&
              visit?.status !== "cancelled" &&
              myResponse !== "declined" && (
                <>
                  <Divider sx={{ my: 2, mx: -2 }} />
                  <LocationSection address={address} />
                </>
              )}
            <Divider sx={{ my: 2, mx: -2 }} />

            <TagsDescriptionSection
              tags={visit?.taskTags}
              description={visit?.taskDescription}
              isPastEvent={isPastEvent}
              visitId={visitId}
              helperSkills={
                visit?.helpers && Object.values(visit.helpers)?.[0]?.skills
              }
              role={role}
              status={visit?.status}
            />
            <Divider sx={{ my: 2, mx: -2 }} />
            <ReportButton visitId={visitId} role={role} />
          </DialogContent>
          <ResponsiveDialog
            open={openReschedule}
            onClose={() => setOpenReschedule(false)}
            title={"Reschedule"}
            width="600px"
            anchor="right"
            fullHeight={true}
          >
            <DialogContent>
              <MessageBox
                Icon={<Settings color="primary" />}
                headerText={`Coming soon!`}
                messageText={`The reschedule feature is in development! In the meantime, you can cancel the visit and then book with the same helper.`}
                buttonText="Close"
                buttonProps={{
                  variant: "contained",
                  color: "primary",
                  textTransform: "none",
                }}
                handleClick={() => setOpenReschedule(false)}
                mt={0}
              />
            </DialogContent>
          </ResponsiveDialog>
          <ResponsiveDialog
            open={openCancel}
            onClose={() => setOpenCancel(false)}
            title={action}
            width="400px"
            anchor="right"
            fullHeight={true}
          >
            <CancelVisit
              role={role}
              handleClose={() => setOpenCancel(false)}
              visitStart={visitStart}
              visitId={visitId}
              visit={visit}
            />
          </ResponsiveDialog>
        </>
      ) : (
        <MessageBox
          Icon={<Event color="inherit" />}
          headerText={`Visit cancelled`}
          messageText={`The visit you're trying to view is unavailable.`}
          buttonText="Go to calendar"
          messageAlign="center"
          buttonProps={{
            variant: "contained",
            color: "warning",
            textTransform: "none",
          }}
          handleClick={() => changeTab("Calendar")}
          // mt={0}
        />
      )}
    </>
  );
};

export default VisitCard;
