import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useRef } from "react";
import DashboardHeader from "../components/dashboard/DashboardHeader";
import MobileNav from "../components/dashboard/MobileNav";
import Sidebar from "../components/dashboard/Sidebar";
import SingleLineFooter from "../components/footer/SingleLineFooter";
import { useActivity } from "../contexts/ActivityProvider";
import { useDashboard } from "../contexts/DashboardContext";
import { useHelpers } from "../contexts/HelpersProvider";
import { SKILL_LIST } from "../services/skillServices";
import { Helmet } from "react-helmet";

function DashboardPage() {
  const { selectedTab, changeTab, tabs, inStandaloneMode } = useDashboard();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { helpers, loadMoreHelpers, loading, setLoading, allFetched } =
    useHelpers();

  const {
    activities,
    setActivities,
    lastVisible,
    setLastVisible,
    setIsLoading,
    isLoading,
    loadMoreActivities,
    hasMore,
    setShowScrollToTop,
  } = useActivity();

  // Scroll to the top of every new tab or page click (THIS ONE WORKS)
  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = 0;
    } else {
      window.scrollTo(0, 0);
    }
  }, [selectedTab, isMobile]);

  const renderMobileContent = () => {
    const tab = tabs.find((t) => t.label === selectedTab);
    if (!tab) {
      return <div>Select a tab from the sidebar.</div>;
    }

    const NavOffset = inStandaloneMode ? "72px" : "56px";

    const Component = tab.component;

    // For "Explore" tab, return without the div wrapper
    if (selectedTab === "Explore" || selectedTab === "Home") {
      return (
        <div
          onScroll={
            selectedTab === "Home"
              ? handleHomeScroll
              : selectedTab === "Explore"
              ? handleExploreScroll
              : () => {}
          }
          ref={listRef}
          style={{
            height: `calc(100svh - ${NavOffset})`, // Subtracts 72px from the full viewport height
            overflowY: "auto", // Enables scrolling
            padding: "16px",
            paddingBottom: "48px",
            boxSizing: "border-box",
            width: "100vw",
            scrollbarWidth: "none", // Firefox
            msOverflowStyle: "none", // Internet Explorer 10+
            "::-webkit-scrollbar": {
              display: "none", // Chrome, Safari, newer versions of Opera
            },
          }}
        >
          <Component />
        </div>
      );
    } else {
      // For all other tabs, return with the div wrapper
      return (
        <div
          ref={listRef}
          style={{
            height: `calc(100svh - ${NavOffset})`, // Subtracts 72px from the full viewport height
            overflowY: "auto", // Enables scrolling
            padding: "16px",
            paddingBottom: "48px",
            boxSizing: "border-box",
            width: "100vw",
          }}
        >
          <Component />
        </div>
      );
    }
  };

  const renderDesktopContent = () => {
    const tab = tabs.find((t) => t.label === selectedTab);
    if (!tab) {
      return <div>Select a tab from the sidebar.</div>;
    }

    const Component = tab.component;
    if (selectedTab === "Chat") {
      return (
        <div
          ref={listRef}
          style={{
            height: `calc(100vh - 80px - 32px)`,
            overflowY: "auto", // Enables scrolling
            boxSizing: "border-box",
            display: "flex",
            flexGrow: 1,
            width: "50%", // !!!!!!!!!!!!! somehow changing this from 100% to anything less (50%) made all my calendar flex woes go away and fixed
            flexDirection: "column",
            justifyContent: "flex-start",
            position: "relative ",
          }}
        >
          <Box
            sx={{
              pt: 5,
              px: 5,
              boxSizing: "border-box",
            }}
          >
            <Component />
          </Box>
        </div>
      );
    } else if (selectedTab === "Explore" || selectedTab === "Home") {
      return (
        <div
          onScroll={
            selectedTab === "Home"
              ? handleHomeScroll
              : selectedTab === "Explore"
              ? handleExploreScroll
              : () => {}
          }
          ref={listRef}
          style={{
            height: `calc(100vh - 80px - 32px)`,
            overflowY: "auto", // Enables scrolling
            boxSizing: "border-box",
            display: "flex",
            flexGrow: 1,
            width: "50%", // !!!!!!!!!!!!! somehow changing this from 100% to anything less (50%) made all my calendar flex woes go away and fixed
            flexDirection: "column",
            justifyContent: "flex-start",
            position: "relative ",
          }}
        >
          <Box
            sx={{
              p: 5,
              boxSizing: "border-box",
              transition: "padding 0.5s ease", // Smooth transition for padding changes
            }}
          >
            <Component />
          </Box>
        </div>
      );
    } else {
      return (
        <div
          style={{
            height: `calc(100vh - 80px - 32px)`,
            overflowY: "auto", // Enables scrolling
            boxSizing: "border-box",
            display: "flex",
            flexGrow: 1,
            width: "50%", // !!!!!!!!!!!!! somehow changing this from 100% to anything less (50%) made all my calendar flex woes go away and fixed
            flexDirection: "column",
            justifyContent: "flex-start",
            position: "relative ",
          }}
        >
          <Box
            sx={{
              p: 5,
              boxSizing: "border-box",
              transition: "padding 0.5s ease", // Smooth transition for padding changes
            }}
          >
            <Component />
          </Box>
        </div>
      );
    }
  };

  const listRef = useRef(null);

  const handleExploreScroll = useCallback(() => {
    const { scrollTop, scrollHeight, clientHeight } = listRef.current;

    if (
      scrollTop + clientHeight >= scrollHeight - 370 && // card height
      !allFetched &&
      !loading
    ) {
      setLoading(true); // Set loading to true before fetching
      // Fetch more notifications if not already loading and if more items are available
      loadMoreHelpers();
    }
  }, [allFetched, loadMoreHelpers, loading]);

  const handleHomeScroll = useCallback(() => {
    const { scrollTop, scrollHeight, clientHeight } = listRef.current;

    const isNearTop = listRef.current.scrollTop <= 10;

    // Update the visibility of the scroll-to-bottom button
    setShowScrollToTop(!isNearTop);

    if (
      scrollTop + clientHeight >= scrollHeight - 100 &&
      hasMore &&
      !isLoading
    ) {
      setIsLoading(true); // Set loading to true before fetching

      // Fetch more notifications if not already loading and if more items are available
      loadMoreActivities().then(() => {
        setIsLoading(false); // Reset loading state once fetching is complete
      });
    }
  }, [hasMore, loadMoreActivities, isLoading]);

  const skillNames = SKILL_LIST.map((skill) => skill.name).join(", ");

  return (
    <div style={{ display: "flex" }}>
      <Helmet>
        <title>TaskMom: Affordable Local Help for Busy Parents</title>
        <meta
          name="description"
          content="TaskMom is the place to find affordable help with various tasks. Find skilled helpers for meal prep, organizing, errands, cleaning, and more. Connect with local helpers and free up your time!"
        />
        <meta
          property="og:title"
          content="TaskMom: Affordable Local Help for Busy Parents"
        />
        <meta
          property="og:description"
          content="TaskMom is a platform where you can find skilled helpers for a variety of tasks around the house. Book help for cooking, organizing, cleaning, and more. Connect with local helpers at affordable rates."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.taskmom.com" />
        <meta
          name="keywords"
          content={`local help, skilled helpers, household tasks, affordable rates, TaskMom, ${skillNames}`}
        />
        <link rel="canonical" href="https://www.taskmom.com/" />
      </Helmet>
      {/* Hidden H1 for SEO, not displayed to users */}
      <h1 style={{ position: "absolute", left: "-9999px" }}>
        Affordable Help for Busy Parents with TaskMom. Try Task Mom today!
      </h1>
      {!isMobile && (
        <div
          style={{
            position: "relative",
            width: "100%",
            top: 0,
            height: "100vh",
            overflow: "hidden",
          }}
        >
          <DashboardHeader />

          <div
            style={{
              display: "flex",
              height: "calc(100vh - 80px)",
              flexDirection: "row",
              width: "100%",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Sidebar />

            {renderDesktopContent()}
          </div>
          <SingleLineFooter />
        </div>
      )}

      {isMobile && (
        <>
          {/* Inner container that holds the content */}
          {renderMobileContent()}
          <MobileNav />
        </>
      )}
    </div>
  );
}

export default DashboardPage;
