import {
  Avatar,
  Box,
  Chip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import NameRatingLocationDetails from "../profile/NameRatingLocationDetails";
import StyledProgress from "../styled/StyledProgress";
import RatingSummary from "./RatingSummary";

const ReviewSummary = ({ user }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (!user) return null;

  const {
    averageRating,
    totalReviews,
    starRatingCounter = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 },
    tagsCounter,
  } = user;

  // if (!averageRating || !totalReviews || !starRatingCounter || !tagsCounter)
  //   return null;

  const maxStarCount =
    (starRatingCounter && Math.max(...Object.values(starRatingCounter))) || 1; // Find highest count for scaling the bars

  const formattedAverageRating =
    averageRating !== null && averageRating !== undefined
      ? parseFloat(averageRating.toFixed(2))
      : "No reviews";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
        borderRadius: "15px",
        // width: "100%",
        p: 2,
        backgroundColor: "white",
        border: `1px solid rgba(0,0,0,0)`,
        boxShadow: "0px 10px 20px rgba(0,0,0,0.1), 0px 3px 6px rgba(0,0,0,0.1)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: { xs: "center", sm: "flex-start" },
          width: "100%",
          gap: 2,
          mx: "auto",
        }}
      >
        <Avatar
          src={user?.avatarUrl}
          sx={{
            width: { xs: "100px", sm: "100px" },
            height: { xs: "unset", sm: "unset" },
            maxHeight: "200px",
            aspectRatio: "1 / 1", // Ensures the element is always square
            borderRadius: "15px",
          }}
        />
        <NameRatingLocationDetails user={user} nameClickable={true} />
      </Box>
      <Box sx={{ width: "100%", boxSizing: "border-box" }}>
        {/* <RatingSummary
          averageRating={averageRating}
          totalReviews={totalReviews}
          size={isMobile ? "normal" : "large"}
        /> */}

        {[5, 4, 3, 2, 1].map((star) => (
          <Box key={star} sx={{ display: "flex", alignItems: "center", mb: 1 }}>
            <Typography sx={{ mr: 2 }}>{star}</Typography>
            <StyledProgress
              variant={"determinate"}
              value={(starRatingCounter[star] / maxStarCount) * 100}
              borderRadius={"5px"}
            />
          </Box>
        ))}

        {tagsCounter && (
          <>
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
              Tags
            </Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
              {Object.entries(tagsCounter).map(([tag, count]) => (
                <Chip key={tag} label={`${tag} (${count})`} />
              ))}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ReviewSummary;
