import React from "react";
import TextField from "@mui/material/TextField";

const PhoneField = ({ phone, error, handleChange, helperText = "" }) => {
  const handlePhoneChange = (e) => {
    let x = e.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    e.target.value = !x[2]
      ? x[1]
      : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    handleChange(e);
  };
  return (
    <TextField
      fullWidth
      name="phone"
      type="tel"
      label="Phone"
      value={phone}
      onChange={handlePhoneChange}
      error={!!error}
      helperText={error ? error : helperText}
    />
  );
};
export default PhoneField;
