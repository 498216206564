import { KeyboardArrowLeft } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormHelperText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthProvider";
import { useUser } from "../../contexts/UserProvider";
import { db } from "../../firebase";
import { validateBounds } from "../../services/formServices";
import GoogleMapsDrawing from "../maps/GoogeMapsDrawing";

const BoundsView = ({
  handleNext = () => {},
  handleBack = () => {},
  canSkip = true,
  showBack = false,
  buttonText = "Continue",
}) => {
  const [loading, setLoading] = useState(false);
  const { userPrivateData } = useUser();
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    bounds: userPrivateData.bounds || [],
  });
  const [showMaxBookingMinutes, setShowMaxBookingMinutes] = useState(false);

  const { currentUser } = useAuth();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setValues({
      bounds: userPrivateData.bounds || [],
    });
  }, [userPrivateData?.bounds]);

  const handleSubmit = async () => {
    setLoading(true);

    let newError = validateBounds(values);
    if (Object.keys(newError).length > 0 && !canSkip) {
      setErrors(newError);
      setLoading(false); // Ensure to stop the loading process here if there are validation errors
      return; // Exit the function if there are errors
    }
    try {
      await setDoc(
        doc(db, "usersPublic", currentUser.uid),
        {
          bounds: values.bounds,
        },
        { merge: true }
      );

      await setDoc(
        doc(db, "usersPrivate", currentUser.uid),
        {
          bounds: values.bounds,
        },
        { merge: true }
      );

      handleNext();
    } catch (err) {
      console.log("error: ", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          p: 2,
          minHeight: "300px",
          alignItems: "flex-start",
        }}
      >
        {showBack && (
          <ButtonBase
            onClick={handleBack}
            disabled={loading}
            sx={{ color: "text.secondary", my: 1 }}
          >
            <KeyboardArrowLeft /> Back
          </ButtonBase>
        )}
        <Typography
          align="left"
          fontWeight={"600"}
          fontSize={"1.15rem"}
          gutterBottom
        >
          Set your service area
        </Typography>
        <Typography align="left" color={"text.secondary"} sx={{ mb: 2 }}>
          Only people within this area will see your profile and be able to book
          visits.
        </Typography>
        <Box
          sx={{
            height: "auto",
            width: { xs: "100vw", sm: "100%" },
            mx: { xs: -2, sm: 0 },
            boxSizing: "content-box",
          }}
        >
          {/* <LocationMapWithDrawing
            initialBounds={values.bounds || []}
            onBoundsChange={(bounds) => {
              setValues((prevValues) => ({
                ...prevValues,
                bounds,
              }));
              setErrors((prevErrors) => ({
                ...prevErrors,
                bounds: [], // assuming 'setErrors' is your function to set errors
              }));
            }}
            initialCenter={{
              lat: 48.5126,
              lng: -122.6127,
            }}
          /> */}
          <GoogleMapsDrawing
            onBoundsChange={(bounds) => {
              setValues((prevValues) => ({
                ...prevValues,
                bounds,
              }));
            }}
            initialBounds={userPrivateData?.bounds || []}
            initialCenter={{
              lat: userPrivateData?.lat || "",
              lng: userPrivateData?.lng || "",
            }}
          />
        </Box>

        <FormHelperText error={errors.bounds}>
          {errors.bounds
            ? errors.bounds
            : "Your service area will be visible to the public."}{" "}
        </FormHelperText>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "100%",
          }}
        >
          <Button
            disableElevation
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={loading}
            sx={{
              width: "100%",
              height: "56px",
              textTransform: "none",
            }} // Take up half the available width
          >
            {loading ? ( // Conditional rendering based on loading state
              <CircularProgress size={24} color="inherit" />
            ) : (
              <Typography fontWeight={"500"}>{buttonText}</Typography>
            )}
          </Button>

          {canSkip && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: "56px",
                alignItems: "center",
              }}
            >
              <Typography
                align="center"
                onClick={handleNext}
                fontWeight={"500"}
                sx={{ textDecoration: "underline", my: 1, cursor: "pointer" }}
              >
                Skip for now
              </Typography>
            </Box>
          )}
        </Box>
      </DialogActions>
    </>
  );
};

export default BoundsView;
