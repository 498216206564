import {
  CakeOutlined,
  CameraAltOutlined,
  Campaign,
  ComputerOutlined,
  DiamondOutlined,
  DirectionsBike,
  DirectionsRun,
  Event,
  FamilyRestroomOutlined,
  FitnessCenterOutlined,
  FlightTakeoffOutlined,
  LibraryMusicOutlined,
  LocalCafeOutlined,
  LocalFloristOutlined,
  MenuBookOutlined,
  MovieFilterOutlined,
  PaletteOutlined,
  ParkOutlined,
  PersonSearch,
  PetsOutlined,
  Phonelink,
  PublicOutlined,
  ScienceOutlined,
  SoupKitchenOutlined,
  SpaOutlined,
  SportsBasketballOutlined,
  SportsFootballOutlined,
  SportsSoccerOutlined,
  StarBorderOutlined,
  TerrainOutlined,
} from "@mui/icons-material";

export const generalFaq = [
  {
    question: {
      title: "What types of tasks can I book a helper for?",
    },
    answer:
      "Helpers can assist with a wide range of tasks, including meal preparation, household organization, laundry, light housekeeping, babysitting, running errands, and even help with planning trips and parties. If you have a specific task in mind, you can search for helpers with the skills that match your needs.",
  },
  {
    question: {
      title: "Can I chat or talk to a helper before booking?",
    },
    answer:
      "Of course! Our platform features an in-app chat system that allows you to communicate directly with potential helpers without the need to share sensitive information like your phone number. You can discuss task specifics, availability, or any special requirements directly through the chat.",
  },
  {
    question: {
      title: "Can I book a helper for a same-day task?",
    },
    answer:
      "Yes, depending on the helper's availability. TaskMom’s platform shows real-time availability, allowing you to book assistance for immediate needs, depending on the helper's same-day preferences. However, we recommend booking in advance to ensure the best match.",
  },
  {
    question: {
      title: "What happens if I need to cancel or reschedule a booking?",
    },
    answer:
      "TaskMom offers easy scheduling features through our app. You can reschedule or cancel bookings with just a few taps. Cancelling same-day may hurt your reliability score, which is used to transparently show everyones reliability on the platform.",
  },
  {
    question: {
      title: "How can I become a helper on TaskMom?",
    },
    answer:
      "To become a helper for your local community, start by filling out an application on our website. You'll need to provide some basic information about yourself and provide information about the services you offer. Once created, you can set your availability and start accepting tasks.",
  },
  {
    question: {
      title: "What is the TaskMom pricing model?",
    },
    answer:
      "Currently, TaskMom is free to use as we work towards establishing our platform. We are collaborating with our users to determine the most suitable pricing model, whether it be subscription-based or fee-based. In making this decision, we are committed to upholding our dedication to affordability and quality, while ensuring we invest the necessary resources to develop and maintain the platform effectively.",
  },
  {
    question: {
      title: "How do I pay my helper?",
    },
    answer:
      "Helpers on TaskMom set their own rates and are paid in person. TaskMom is not involved in private transactions between both parties. Payments is expected at the end of a visit.",
  },
  {
    question: {
      title: "How do I book a visit with a helper?",
    },
    answer:
      "Booking a visit on TaskMom is simple and user-friendly. Once you’ve found a helper whose skills and availability match your needs, you can schedule a visit directly from their profile. Choose the date and time that works best for you, and specify the tasks you need help with. You can also add notes to customize the visit according to your requirements.",
  },
  {
    question: {
      title: "How can we be sure that a helper is trustworthy?",
      // subtitle: "A popular React UI library",
    },
    answer:
      "At TaskMom, community safety and trust are paramount. While all helpers are required to have at least one referral to join, we encourage all users to conduct their own due diligence. To further enhance trust, we encourage you to share your experience through reviews, building a transparent and reliable resource.",
    // icon: <Help />, // Example icon from MUI
  },
  {
    question: {
      title: "What happens if I forget or miss a booked visit?",
    },
    answer:
      "TaskMom understands that life can be unpredictable. If you miss a visit, please contact the user as soon as possible to apologize and explain the situation. Depending on the circumstances, you can offer to reschedule the visit. Repeated missed visits without communication may affect your standing on the platform.",
  },
  // Add more FAQ objects here
];

export const helperFaq = [
  {
    question: {
      title: "Can I choose the tasks and hours I work?",
    },
    answer:
      "Absolutely! TaskMom offers the flexibility to choose when you work and the tasks you take on. Our platform allows you to manage your availability and match with tasks that fit your skills and schedule.",
  },
  {
    question: {
      title: "How can I get more visits through TaskMom?",
    },
    answer:
      "To get more visits on TaskMom, share your profile link on social media, via email, and within your community. Ensure your profile stands out by clearly listing your skills and experiences, and adding photos that showcase your work. Ask satisfied clients to leave positive reviews, as this can boost your credibility. Stay active by keeping your availability updated and responding quickly to inquiries. These steps can help attract more clients and increase your bookings.",
  },
  // Add more FAQ objects here
];

export const INTEREST_LIST = [
  {
    name: "Traveling",
    Icon: FlightTakeoffOutlined,
  },
  {
    name: "Photography",
    Icon: CameraAltOutlined,
  },
  {
    name: "Painting",
    Icon: PaletteOutlined,
  },
  {
    name: "Music",
    Icon: LibraryMusicOutlined,
  },
  {
    name: "Cooking",
    Icon: SoupKitchenOutlined,
  },
  {
    name: "Baking",
    Icon: CakeOutlined,
  },
  {
    name: "Coffee",
    Icon: LocalCafeOutlined,
  },
  {
    name: "Hiking",
    Icon: TerrainOutlined,
  },
  {
    name: "Gym",
    Icon: FitnessCenterOutlined,
  },
  {
    name: "Running",
    Icon: DirectionsRun,
  },
  {
    name: "Cycling",
    Icon: DirectionsBike,
  },
  {
    name: "Soccer",
    Icon: SportsSoccerOutlined,
  },
  {
    name: "Football",
    Icon: SportsFootballOutlined,
  },
  {
    name: "Basketball",
    Icon: SportsBasketballOutlined,
  },
  {
    name: "Gardening",
    Icon: LocalFloristOutlined,
  },
  {
    name: "Family",
    Icon: FamilyRestroomOutlined,
  },
  {
    name: "Pets",
    Icon: PetsOutlined,
  },
  {
    name: "Meditation",
    Icon: SpaOutlined,
  },
  {
    name: "Camping",
    Icon: ParkOutlined,
  },
  {
    name: "Reading",
    Icon: MenuBookOutlined,
  },
  {
    name: "Fashion",
    Icon: DiamondOutlined,
  },
  {
    name: "Filmmaking",
    Icon: MovieFilterOutlined,
  },
  {
    name: "Politics",
    Icon: PublicOutlined,
  },
  {
    name: "Astronomy",
    Icon: StarBorderOutlined,
  },
  {
    name: "Science",
    Icon: ScienceOutlined,
  },
  {
    name: "Technology",
    Icon: ComputerOutlined,
  },
];

export const welcomeHelper = [
  {
    icon: Event,
    title: "Set Availability",
    subtitle:
      "Set and keep your availability updated to reflect when you can work.",
    bullets: [
      "Add one-time or recurring availability",
      "Get booked during your listed times",
      "Remove if no longer free, declining hurts your reliability",
    ],
  },
  {
    icon: Campaign,
    title: "Spread the Word",
    subtitle: "Share your personal booking link with others online",
    bullets: [
      "Increase your total hours and income",
      "Direct others to book with you online",
      "Create a loyal client base through your outreach efforts",
    ],
  },
  {
    icon: Phonelink,
    title: "All-in-one",
    subtitle: "Manage all your service businesses in one place",
    bullets: [
      "Manage your skills and offerings",
      "Reduce back-and-forth communications",
      "Consolidate your schedule, insights, and chat in one place",
    ],
  },
  {
    icon: PersonSearch,
    title: "Search Ranking",
    subtitle: "Help your profile show up higher in the search results",
    bullets: [
      "Encourage great reviews from happy clients",
      "Avoid declining visits unless absolutely necessary",
      "Stay active and keep availability up to date",
    ],
  },
];