// EmailCheckView.js
import { keyframes } from "@emotion/react";
import {
  KeyboardArrowLeft,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Button,
  ButtonBase,
  CircularProgress,
  DialogActions,
  DialogContent,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";

const fadeInMoveDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const LoginView = ({ handleClose, setView, email, showBack = false }) => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    password: "",
  });

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;

    const actualValue = type === "checkbox" ? checked : value;

    setValues((prevValues) => ({ ...prevValues, [name]: actualValue }));

    // Clear error for the field
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const auth = getAuth();
    const newError = {}; // initialize newError object here

    try {
      await signInWithEmailAndPassword(auth, email, values.password);

      handleClose();
    } catch (error) {
      console.log(error.code);
      switch (error.code) {
        case "auth/missing-password":
          newError.password = "You must enter your password.";
          break;
        case "auth/invalid-email":
          newError.email = "The email address is badly formatted.";
          break;
        case "auth/user-not-found":
          newError.email = "There is no user with this email.";
          break;
        case "auth/wrong-password":
          newError.password = "The password is invalid.";
          break;
        default:
          newError.general =
            "An unknown error occurred. Please try again later.";
          break;
      }
      setErrors(newError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DialogContent>
        {showBack && (
          <ButtonBase
            onClick={() => setView("EMAIL")}
            disabled={loading}
            sx={{ color: "text.secondary", my: 1 }}
          >
            <KeyboardArrowLeft /> Back
          </ButtonBase>
        )}
        <Typography
          align="left"
          fontWeight={"600"}
          fontSize={"1.15rem"}
          gutterBottom
        >
          Welcome back
        </Typography>
        <Typography align="left" color={"text.secondary"} sx={{ mb: 2 }}>
          Enter your password to login.
        </Typography>
        <TextField
          fullWidth
          label="Password"
          variant="outlined"
          name="password"
          type={showPassword ? "text" : "password"}
          value={values.password}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((prevState) => !prevState)} // Inline arrow function here
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={!!errors.password}
          helperText={errors.password}
        />
        <Typography
          align="left"
          color="primary"
          fontSize={"0.8rem"}
          fontWeight={"600"}
          onClick={() => {
            setView("FORGOT");
          }}
          sx={{
            mt: 1,
            ml: 1,
            cursor: "pointer",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          Forgot password?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          // type="submit" // Changed to type submit to trigger form submission
          variant="contained"
          onClick={handleSubmit}
          disableElevation
          disabled={loading}
          fullWidth
          sx={{ height: "56px", textTransform: "none" }}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <Typography fontWeight={"500"}>Login</Typography>
          )}
        </Button>
      </DialogActions>
    </>
  );
};

export default LoginView;
