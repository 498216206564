import { ArrowBack, CheckCircle } from "@mui/icons-material";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import { Timestamp, addDoc, collection, doc } from "firebase/firestore";
import React, { useState } from "react";
import { db } from "../../firebase";
import MessageBox from "../boxes/MessageBox";

const CreateReportForm = ({
  claimant = {},
  defendant = {},
  visit = {},
  handleClose,
  handleBack,
  selectedReport,
}) => {
  const [tags, setTags] = useState([]);
  const [reportText, setReportText] = useState("");
  const predefinedTags = ["helpful", "friendly", "unreliable", "late"]; // Example predefined tags
  const [submitted, setSubmitted] = useState(false);

  if (!claimant) return;

  const handleTagClick = (tag) => {
    setTags((prevTags) => {
      if (prevTags.includes(tag)) {
        return prevTags.filter((t) => t !== tag);
      } else {
        return [...prevTags, tag];
      }
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const newReportRef = doc(collection(db, "reports"));

    const reportData = {
      id: newReportRef.id, // get id before creating
      issue: selectedReport.label,
      reportText,
      visitId: visit.id,
      status: "submitted",
      createdAt: Timestamp.now(),
      claimantId: claimant.id,
      defendantId: defendant?.id || "",
      claimant: {
        userId: claimant.id,
        firstName: claimant.firstName,
        lastName: claimant.lastName,
        avatarUrl: claimant.avatarUrl,
        gender: claimant.gender,
        birthMonth: claimant.birthMonth,
      },
      defendant: {
        userId: defendant?.id || "",
        firstName: defendant?.firstName || "",
        lastName: defendant?.lastName || "",
        avatarUrl: defendant?.avatarUrl || "",
        gender: defendant?.gender || "",
        birthMonth: defendant?.birthMonth || "",
      },
      comments: [], // Initially empty
      geohash6: visit.geohash6,
      city: visit.city,
      state: visit.state,
      zipCode: visit.zipCode,
    };

    try {
      await addDoc(collection(db, "reports"), reportData);
      setTags([]);
      setReportText("");
      setSubmitted(true);
    } catch (error) {
      console.error("Error submitting the report: ", error);
    }
  };

  const renderForm = () => {
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2, mb: 2 }}>
          <IconButton onClick={handleBack} size="large" sx={{ my: "auto" }}>
            <ArrowBack color="primary" />
          </IconButton>
          <Box>
            <Typography variant="h6">{selectedReport?.primary}</Typography>
            <Typography variant="body2">{selectedReport?.secondary}</Typography>
          </Box>
        </Box>
        <TextField
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          placeholder="Write your report here"
          value={reportText}
          onChange={(e) => setReportText(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Button
          onClick={handleSubmit}
          disabled={!tags || !reportText}
          variant="contained"
          sx={{ width: "100%", height: "56px" }}
        >
          Submit Report
        </Button>
      </>
    );
  };

  const renderSuccess = () => {
    return (
      <Box>
        <MessageBox
          Icon={<CheckCircle color="success" />}
          headerText="Report Created"
          messageText="Thank you for keeping your community safe and accountable by leaving a report."
          messageAlign="center"
          buttonText="Go back"
          buttonProps={{ variant: "contained", color: "primary" }}
          handleClick={handleClose}
          mt={0}
        />
      </Box>
    );
  };

  return <>{submitted ? renderSuccess() : renderForm()}</>;
};

export default CreateReportForm;
