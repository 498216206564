import { CheckCircle } from "@mui/icons-material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect } from "react";

const ConfirmationButton = ({
  onClick, // Passed click handler from the parent
  originalText = "Click me",
  loadingText = "Loading...",
  successText = "Success!",
  onRedirect = () => {},
  isDisabled = false, // Custom disabled logic from the parent
  isLoading = false, // Loading state passed from the parent
  isSuccess = false, // Success state passed from the parent
  ...rest // Capture any additional props passed to ConfirmationButton
}) => {
  useEffect(() => {
    if (isSuccess) {
      // Redirect on success after a delay, if needed
      const timeout = setTimeout(() => {
        onRedirect();
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [isSuccess]);

  // The actual click handling logic is passed from the parent, enabling custom behaviors
  const handleButtonClick = (event) => {
    if (!isDisabled && !isLoading) {
      onClick(event); // Call the parent's onClick handler
    }
  };

  return (
    <Button
      variant="contained"
      disableElevation
      color={isSuccess ? "success" : "primary"}
      onClick={handleButtonClick}
      disabled={isDisabled || isLoading}
      startIcon={isSuccess ? <CheckCircle /> : null}
      endIcon={
        isLoading ? <CircularProgress size={24} color="inherit" /> : null
      }
      sx={{ textTransform: "none", ...rest.sx }} // Apply sx styles
      {...rest} // Apply any other props
    >
      {isSuccess ? successText : isLoading ? loadingText : originalText}
    </Button>
  );
};

export default ConfirmationButton;
