import { Add, Event, Loupe, Remove, Repeat } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  Fab,
  FormControlLabel,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthProvider";
import { useSnackbar } from "../../contexts/SnackbarProvider";
import { useUser } from "../../contexts/UserProvider";
import { addAvailability } from "../../services/windowServicesV2";
import ResponsiveDialog from "../dialogs/ResponsiveDialog";
import StyledSwitch from "../styled/StyledSwitch";

const AddAvailabilityButton = ({
  initialStartTime = "",
  initialEndTime = "",
  initialDate = "",
}) => {
  const { showSnackbar } = useSnackbar();

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [open, setOpen] = useState(false);
  const { currentUser } = useAuth();
  const { userPublicData } = useUser();
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    initialDate
      ? DateTime.fromISO(initialDate, { zone: timeZone })
      : DateTime.now().setZone(timeZone)
  );

  const [startTime, setStartTime] = useState(
    initialStartTime ||
      DateTime.now().setZone(timeZone).set({ hour: 10, minute: 0 })
  );
  const [endTime, setEndTime] = useState(
    initialEndTime ||
      DateTime.now().setZone(timeZone).set({ hour: 16, minute: 0 })
  );
  const [repeatsWeekly, setRepeatsWeekly] = useState(false);
  const [selectedDays, setSelectedDays] = useState([selectedDate.weekday]);
  const [numOfWeeks, setNumOfWeeks] = useState(1);

  const { minBookingMinutes, bookingDaysInAdvance, geohash6, skills, bounds } =
    userPublicData;

  const userId = currentUser.uid;

  useEffect(() => {
    if (initialDate && !open) {
      setSelectedDate(DateTime.fromISO(initialDate, { zone: timeZone }));
    }
  }, [initialDate, timeZone]);

  useEffect(() => {
    // Get the weekday of the selected date
    const newSelectedDay = selectedDate.weekday;

    // Update the selectedDays state to only include this day
    setSelectedDays([newSelectedDay]);
  }, [selectedDate]);

  useEffect(() => {
    if (initialStartTime) {
      setStartTime(DateTime.fromISO(initialStartTime).setZone(timeZone));
    }
    if (initialEndTime) {
      setEndTime(DateTime.fromISO(initialEndTime).setZone(timeZone));
    }
  }, [initialStartTime, initialEndTime, timeZone]);

  const modifyTime = (field, action) => {
    let time = field === "start" ? startTime : endTime;
    let adjustedTime =
      action === "add"
        ? time.plus({ minutes: 15 })
        : time.minus({ minutes: 15 });

    if (field === "start") {
      setStartTime(adjustedTime);
    } else if (field === "end") {
      setEndTime(adjustedTime);
    }
  };

  // funciton called when new availability slot is dragged and created
  const handleCreateAvailability = async () => {
    setLoading(true);
    setOpen(false);
    // Generate a consistent recurringId before the loops if the event repeats weekly
    const consistentRecurringId = repeatsWeekly
      ? `${userId}-${DateTime.now().toMillis()}`
      : null;

    // Check if the event is repeating or not
    const weeksToIterate = repeatsWeekly ? numOfWeeks : 1;
    const daysToIterate = repeatsWeekly ? selectedDays : [selectedDate.weekday];

    // Loop through the number of weeks
    for (let week = 0; week < weeksToIterate; week++) {
      // Loop through each selected or current day
      for (let day of daysToIterate) {
        const dayOffset = repeatsWeekly
          ? (day - selectedDate.weekday + 7) % 7
          : 0; // Calculate day offset from the selected date for repeating events
        const dateOfAvailability = selectedDate.plus({
          days: dayOffset + week * 7,
        });

        // Check to ensure the day to add is greater than the selectedDate
        if (dateOfAvailability.startOf("day") >= selectedDate.startOf("day")) {
          let start = dateOfAvailability.set({
            hour: startTime.hour,
            minute: startTime.minute,
          });

          let end = dateOfAvailability.set({
            hour: endTime.hour,
            minute: endTime.minute,
          });

          // Ensure end time does not exceed the limits of the day
          if (end.hour > 23) {
            end = end.set({ hour: 23, minute: 0 });
          }

          if (start.hour < 6) {
            start = start.set({ hour: 6, minute: 0 });
          }

          // Check for minimum duration of the availability slot
          if (end.diff(start, "minutes").minutes < minBookingMinutes) continue;

          const newAvailability = {
            start,
            end,
            // duration: end.diff(start, "minutes").minutes,
            // timeZone,
            // minBookingMinutes,
            // bookingDaysInAdvance,
            // geohash6,
            // skills,
            // bounds,
            recurringId: consistentRecurringId, // Use the pre-generated ID for all slots
          };

          try {
            if (userId) {
              const { hasShortDurations } = await addAvailability({
                userId,
                newAvailability,
              });
              if (hasShortDurations) {
                showSnackbar(
                  "Skipped any slots shorter than your minimum.",
                  "Info"
                );
              }
            }
          } catch (error) {
            console.error("Error creating availability:", error);
            // Handle errors appropriately
          }
        }
      }
    }

    setLoading(false);
  };

  return (
    <>
      <Fab
        // size="small"
        onClick={() => setOpen(true)}
        sx={{
          position: "fixed",
          bottom: { xs: "calc(72px + 16px)", sm: 60 },
          mx: "auto",
          width: "200px",
          left: 0,
          right: 0,
          boxSizing: "border-box",
          textTransform: "none",
          backgroundColor: "rgba(0, 0, 0, 0.75)", // Black with subtle opacity
          color: "white", // White text
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.9)", // Slightly less opacity on hover
          },
        }}
        variant="extended"
      >
        <Loupe sx={{ mr: "4px" }} />
        <Typography fontSize={"0.85rem"} fontWeight={"600"}>
          Availability
        </Typography>
      </Fab>
      <ResponsiveDialog
        open={open}
        onClose={() => setOpen(false)}
        title="Add availability"
        width="400px"
        anchor="bottom"
        fullHeight={true}
      >
        <DialogContent
          sx={{
            p: 2,
          }}
        >
          <Box sx={{ p: 2 }}>
            <Stack gap={2} direction="column" alignItems="center">
              {/* <Typography sx={{ mb: 1 }}>
                Add availability to the calendar.
              </Typography> */}

              {/* Repeat control */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                  width: "100%",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    sx={{ mb: 0 }}
                  >
                    <Repeat />
                    <Typography variant="body1" fontWeight="600">
                      Repeat
                    </Typography>
                  </Stack>
                  <Box
                    sx={{
                      px: 1,
                      py: 0.5,
                      minWidth: "50px",
                      justifyContent: "center",
                      display: "flex",
                      backgroundColor: repeatsWeekly
                        ? "hsl(100, 100%, 90%)"
                        : "hsl(0, 100%, 90%)",
                      borderRadius: "10px",
                    }}
                  >
                    <Typography variant="caption">
                      {repeatsWeekly ? "weekly" : "off"}
                    </Typography>
                  </Box>
                </Box>
                <StyledSwitch
                  checked={repeatsWeekly}
                  onChange={(e) => setRepeatsWeekly(e.target.checked)}
                />
              </Box>

              <Divider sx={{ my: 2, width: "100%" }} />

              {/* Start time controls */}
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="center"
              >
                <IconButton
                  disabled={startTime <= startTime.set({ hour: 8, minute: 0 })}
                  onClick={() => modifyTime("start", "subtract")}
                >
                  <Remove />
                </IconButton>

                <Card
                  elevation={0}
                  sx={{
                    width: "200px",
                    textAlign: "center",
                    backgroundColor: "#e0e0e0",
                    borderRadius: "10px",
                    padding: "4px",
                    boxSizing: "border-box",
                  }}
                >
                  <Typography component="span" fontWeight="600">
                    Start:{" "}
                  </Typography>
                  <Typography component="span">
                    {startTime.toFormat("h:mm a")}
                  </Typography>
                </Card>
                <IconButton
                  disabled={startTime.plus({ minutes: 15 }) >= endTime}
                  onClick={() => modifyTime("start", "add")}
                >
                  <Add />
                </IconButton>
              </Stack>

              {/* End time controls */}
              <Stack direction="row" spacing={2} alignItems="center">
                <IconButton
                  disabled={endTime.minus({ minutes: 15 }) <= startTime}
                  onClick={() => modifyTime("end", "subtract")}
                >
                  <Remove />
                </IconButton>
                <Card
                  elevation={0}
                  sx={{
                    width: "200px",
                    textAlign: "center",
                    backgroundColor: "#e0e0e0",
                    borderRadius: "10px",
                    padding: "4px",
                    boxSizing: "border-box",
                  }}
                >
                  <Typography component="span" fontWeight="600">
                    End:{" "}
                  </Typography>
                  <Typography component="span">
                    {endTime.toFormat("h:mm a")}
                  </Typography>
                </Card>
                <IconButton
                  disabled={endTime >= endTime.set({ hour: 18, minute: 0 })}
                  onClick={() => modifyTime("end", "add")}
                >
                  <Add />
                </IconButton>
              </Stack>

              <Divider sx={{ my: 2, width: "100%" }} />

              {/* Select date */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    sx={{ mb: 0 }}
                  >
                    <Event />
                    <Typography
                      variant="body1"
                      fontWeight={"600"}
                      sx={{ mb: 0 }}
                    >
                      {repeatsWeekly ? "Start date" : "Date"}
                    </Typography>
                  </Stack>
                </Box>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <MobileDatePicker
                    // label="Day"
                    inputFormat="MM/dd/yyyy"
                    sx={{ width: "150px" }}
                    value={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Box>

              {repeatsWeekly && (
                <>
                  <Stack
                    direction="row"
                    justifyContent="space-around" // Adjust to space-around for even spacing
                    flexWrap="wrap" // Allow wrapping for responsiveness
                    sx={{ width: "100%" }} // Ensure the stack takes full width of its container
                  >
                    {["M", "Tu", "W", "Th", "F", "Sa", "Su"].map(
                      (day, index) => (
                        <Box
                          key={day}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            // px: 1, // Apply horizontal padding for spacing, adjust as needed
                            width: { xs: "14%", sm: "14%", md: "10%" }, // Adjust the width for responsiveness
                            minWidth: "32px", // Minimum width to prevent too much squishing
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedDays.includes(index + 1)}
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    fontSize: { xs: 32, sm: 24 }, // Keep your custom icon size
                                  },
                                }}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSelectedDays([
                                      ...selectedDays,
                                      index + 1,
                                    ]);
                                  } else {
                                    setSelectedDays(
                                      selectedDays.filter(
                                        (d) => d !== index + 1
                                      )
                                    );
                                  }
                                }}
                              />
                            }
                            sx={{ mx: 0 }}
                          />
                          <Typography variant="caption">{day}</Typography>
                        </Box>
                      )
                    )}
                  </Stack>

                  <Stack direction="row" alignItems="center" spacing={2}>
                    <IconButton
                      onClick={() => setNumOfWeeks(numOfWeeks - 1)}
                      disabled={numOfWeeks <= 1}
                    >
                      <Remove />
                    </IconButton>
                    <Card
                      elevation={0}
                      sx={{
                        width: "200px",
                        textAlign: "center",
                        backgroundColor: "#e0e0e0",
                        borderRadius: "10px",
                        padding: "4px",
                        boxSizing: "border-box",
                      }}
                    >
                      <Typography>
                        Repeat for {numOfWeeks}{" "}
                        {numOfWeeks > 1 ? "weeks" : "week"}
                      </Typography>
                    </Card>
                    <IconButton onClick={() => setNumOfWeeks(numOfWeeks + 1)}>
                      <Add />
                    </IconButton>
                  </Stack>
                </>
              )}
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={(repeatsWeekly && selectedDays.length === 0) || loading}
            onClick={() => handleCreateAvailability()}
            sx={{
              boxSizing: "border-box",
              width: { xs: "100%", sm: "100%" },
              height: "56px",
            }}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Save"}
          </Button>
        </DialogActions>
      </ResponsiveDialog>
    </>
  );
};

export default AddAvailabilityButton;
