import { IosShare } from "@mui/icons-material";
import {
  Avatar,
  Box,
  DialogContent,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthProvider";
import { formatName } from "../../services/stringServices";
import ResponsiveDialog from "../dialogs/ResponsiveDialog";
import NameRatingLocationDetails from "../profile/NameRatingLocationDetails";
import CopyUrlButton from "./CopyUrlButton";

const UserShareButton = ({ user }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const { currentUser } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Constructing the necessary properties
  const url = `${window.location.origin}/profile/${user?.id}${
    currentUser?.uid ? `?ref=${currentUser.uid}` : ""
  }`;
  const title = `${formatName(user?.firstName)} ${formatName(
    user?.lastName
  )}'s profile on TaskMom!`;
  const text = `Check out ${formatName(user?.firstName)} ${formatName(
    user?.lastName
  )}'s profile on TaskMom!`;
  const fileUrl = user?.avatarUrl;

  const handleShare = async (event) => {
    event.stopPropagation();

    if (navigator.share) {
      const shareData = { title, text, url };

      if (fileUrl) {
        try {
          const response = await fetch(fileUrl);
          const blob = await response.blob();
          const file = new File([blob], "share.jpg", { type: blob.type });
          shareData.files = [file];
        } catch (err) {
          console.error("Error fetching image for sharing: ", err);
        }
      }

      try {
        await navigator.share(shareData);
      } catch (err) {
        console.error("Error sharing: ", err);
        // Check if the error is due to the user cancelling the share
        if (err.name !== "AbortError") {
          setOpenDialog(true); // Open dialog only on actual errors
        }
      }
    } else {
      setOpenDialog(true); // Open dialog if sharing is not supported
    }
  };

  return (
    <>
      <IconButton
        onClick={handleShare}
        size="large"
        disableRipple
        color="disabled"
        sx={{
          ...(!isMobile && {
            padding: "6px",
            height: "54px",
            width: "54px",
            boxSizing: "border-box",
            borderRadius: "30px",
            border: openDialog
              ? "1px solid rgba(0, 0, 0, 0.12)"
              : "1px solid rgba(0, 0, 0, 0)",
            transition: "box-shadow, border, 0.3s ease-in-out", // Smooth transition for shadow
            backgroundColor: "white",
            boxShadow: openDialog ? "0 5px 10px 0 rgba(0,0,0,0.15)" : "none", // Drop shadow when open
            "&:hover": {
              border: "1px solid rgba(0, 0, 0, 0.12)",
              boxShadow: "0 5px 10px 0 rgba(0,0,0,0.15)", // Slightly more pronounced shadow on hover
            },
          }),
        }}
      >
        <IosShare sx={{ color: "black" }} />
      </IconButton>

      <ResponsiveDialog
        open={openDialog}
        title="Share profile"
        width="500px"
        anchor="bottom"
        onClose={() => setOpenDialog(false)}
        zIndex={2100}
      >
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 2,
              width: "100%",
              my: 2,
            }}
          >
            <Avatar
              src={fileUrl}
              sx={{
                width: "100px",
                height: "unset",
                maxHeight: "200px",
                aspectRatio: "1 / 1", // Ensures the element is always square
                borderRadius: "15px",
              }}
            />
            <NameRatingLocationDetails user={user} nameClickable={false} />
          </Box>
          <CopyUrlButton url={url} />
        </DialogContent>
      </ResponsiveDialog>
    </>
  );
};

export default UserShareButton;
