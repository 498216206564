import React from "react";

import {
  AccessTime,
  PhonelinkRing,
  PlaylistAddCheck,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CardMedia,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const BusyMoms = ({ handleScroll }) => {
  const navigate = useNavigate();

  const bulletPoints = [
    {
      text: "Stop the multitask marathon and let your TaskMom helper free you for what really matters.",
      Icon: PlaylistAddCheck, // Replace with your chosen icon
    },
    {
      text: "Delegate like a boss. Let your personal assistant smooth out the bumps in your week.",
      Icon: PhonelinkRing, // Replace with your chosen icon
    },
    {
      text: "Lift the weight off your shoulders. Breathe easy and reclaim your time.",
      Icon: AccessTime, // Replace with your chosen icon
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column-reverse", sm: "row-reverse" },
        alignItems: "center",
        maxWidth: "1000px",
        mx: { xs: 2, sm: "auto" },
        mb: { xs: 0, sm: 6 },
        mt: { xs: 0, sm: 10 },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: { xs: 1, sm: 4 },
          flex: 1, // This allows the box to grow and take up equal space
          maxWidth: "100%", // Adjusts maxWidth to allow the box to grow
        }}
      >
        <Typography
          align="center"
          sx={{
            fontSize: { xs: "h3.fontSize", sm: "h2.fontSize" }, // Responsive font sizes
            fontWeight: { xs: "h2.fontWeight", sm: "h2.fontWeight" }, // Responsive font weights
            lineHeight: "inherit",
            mb: 1,
            mx: "auto",
            mt: { xs: 1, sm: 0 }, // Adjusts margin top based on screen size
          }}
        >
          Reset Focus
        </Typography>
        <List>
          {bulletPoints.map(({ text, Icon }, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <Button
          variant="contained"
          disableElevation
          onClick={() => navigate("/")}
          sx={{
            width: { xs: "100%", sm: "300px" },
            height: "56px",
            textTransform: "none",
            mx: "auto",
          }}
        >
          Start delegating
        </Button>
      </Box>
      <CardMedia
        component="img"
        image="https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/public%2Ffamily-beach.jpg?alt=media&token=7f99694c-ece5-4c14-9d93-7294c2d74218"
        alt="Family at beach"
        sx={{
          width: { xs: "100%", sm: "50%" }, // Ensures the image takes up half the width on sm screens and up
          height: { sm: "auto" }, // Adjusts height automatically to maintain aspect ratio
          aspectRatio: { xs: "3 / 2", sm: "inherit" }, // Maintains aspect ratio on xs, adjusts on sm
          borderRadius: "15px",
          flex: 1, // This will allow it to grow and take up equal space
        }}
      />
    </Box>
  );
};

export default BusyMoms;
