import { Box, Divider, Typography } from "@mui/material";
import React from "react";

const BookingDetails = ({
  hourlyRate = "",
  minBookingMinutes = "",
  maxBookingMinutes = "",
  showTitle = true,
}) => {
  const formatTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    let timeString = "";

    if (hours > 0) {
      timeString += `${hours} hour `;
    }
    if (mins > 0) {
      timeString += `${mins} minute`;
    }
    return timeString.trim();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: 1,
        bgcolor: "background.paper",
        boxSizing: "border-box",
        mx: { xs: 1, sm: 0 },
      }}
    >
      {showTitle && (
        <>
          <Divider sx={{ mt: { xs: 2, sm: 4 }, mb: 2 }} />
          <Typography
            variant="h6"
            fontSize={"1.15rem"}
            fontWeight={"600"}
            sx={{ mb: 2 }}
          >
            Booking details
          </Typography>
        </>
      )}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#f5f5f5",
            borderRadius: "15px",
            px: 2,
            py: 1,
          }}
        >
          <Typography
            variant="body1"
            sx={{
              whiteSpace: "nowrap",
            }}
          >
            <strong>${hourlyRate}</strong> hourly rate
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            boxSizing: "border-box",
            backgroundColor: "#f5f5f5",
            borderRadius: "15px",
            px: 2,
            py: 1,
          }}
        >
          <Typography variant="body1">
            <strong>{formatTime(minBookingMinutes)}</strong> minimum
          </Typography>
        </Box>
        {maxBookingMinutes && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              boxSizing: "border-box",
              backgroundColor: "#f5f5f5",
              borderRadius: "15px",
              px: 2,
              py: 1,
            }}
          >
            <Typography variant="body1">
              <strong>{formatTime(maxBookingMinutes)}</strong> maximum
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BookingDetails;
