// src/contexts/UserContext.js
import {
  collection,
  doc,
  endAt,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAt,
  where,
} from "firebase/firestore";
import { geohashQueryBounds } from "geofire-common";
import React, { createContext, useContext, useEffect, useState } from "react";
import { db } from "../firebase";
import { isPointInPolygon } from "../services/locationServices";
import { useAuth } from "./AuthProvider";

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {

  const [userPublicData, setUserPublicData] = useState(null);
  const [userPrivateData, setUserPrivateData] = useState(null);

  const [userPublicLoading, setUserPublicLoading] = useState(true);
  const [userPrivateLoading, setUserPrivateLoading] = useState(true);

  const [userAvailabilityLoading, setUserAvailabilityLoading] = useState(true);

  const [helpersData, setHelpersData] = useState(null);
  const [helpersLoading, setHelpersLoading] = useState(true);

  const { currentUser } = useAuth(); // Use your auth provider to get the current user

  // Subscription to usersPublic collection
  useEffect(() => {
    if (currentUser?.uid) {
      const unsubscribe = onSnapshot(
        doc(db, "usersPublic", currentUser.uid),
        (doc) => {
          if (doc.exists()) {
            setUserPublicData(doc.data());
          }
          setUserPublicLoading(false); // Indicate loading is done
        }
      );

      // Cleanup function
      return () => unsubscribe();
    } else {
      setUserPublicLoading(false); // Ensure loading is set to false if there's no currentUser
    }
  }, [currentUser?.uid]); // Dependency on currentUser.uid

  // Subscription to usersPrivate collection
  useEffect(() => {
    if (currentUser?.uid) {
      const unsubscribe = onSnapshot(
        doc(db, "usersPrivate", currentUser.uid),
        (doc) => {
          if (doc.exists()) {
            setUserPrivateData(doc.data());
          }
          setUserPrivateLoading(false); // Indicate loading is done
        }
      );

      // Cleanup function
      return () => unsubscribe();
    } else {
      setUserPrivateLoading(false); // Ensure loading is set to false if there's no currentUser
    }
  }, [currentUser?.uid]); // Similar dependency but handled separately

  // Queried GEOHASH EMPLOYEES
  useEffect(() => {
    if (!userPrivateData?.geohash) return;

    setHelpersLoading(true); // Indicate loading starts

    const userCoordinates = [userPrivateData.lat, userPrivateData.lng];

    // Define the radius within which you want to find helpers, in meters.
    const radiusInM = 5 * 1609.34; // e.g., 10 miles to meters

    const bounds = geohashQueryBounds(userCoordinates, radiusInM);

    // This Map helps avoid duplicate helpers and easily manage the real-time data
    const allHelpers = new Map();

    const unsubscribe = bounds.map(([start, end]) => {
      const q = query(
        collection(db, "usersPublic"),
        where("helperStatus", "==", "active"),
        orderBy("geohash6"),
        startAt(start),
        endAt(end)
      );

      return onSnapshot(q, (querySnapshot) => {
        let helpersUpdated = false;

        querySnapshot.docChanges().forEach((change) => {
          if (change.type === "added" || change.type === "modified") {
            const helperData = change.doc.data();

            if (
              helperData?.bounds &&
              isPointInPolygon(userCoordinates, helperData.bounds)
            ) {
              allHelpers.set(change.doc.id, helperData);
              helpersUpdated = true;
            }
          } else if (change.type === "removed") {
            helpersUpdated = true;
            allHelpers.delete(change.doc.id);
          }
        });

        if (helpersUpdated) {
          // Update state with all aggregated helpers
          const aggregatedHelpers = Object.fromEntries(allHelpers);
          setHelpersData(aggregatedHelpers);
          setHelpersLoading(false); // Indicate loading is done after receiving initial data
        }
      });
    });

    // In case there are no bounds or the query returns immediately empty
    if (bounds.length === 0 || unsubscribe.length === 0) {
      setHelpersLoading(false);
    }

    // Cleanup function
    return () => {
      unsubscribe.forEach((unsub) => unsub());
      setHelpersLoading(false); // Cleanup loading state when component unmounts or data fetching is cancelled
    };
  }, [userPrivateData?.geohash]); // Dependency array

  const updateUserSetup = async ({ property, value }) => {

    // Assuming `userId` is available and identifies the document in your Firestore collection
    const userId = currentUser?.uid; // You need to replace this with actual user ID logic
    const userDocRef = doc(db, "usersPrivate", userId); // Replace "users" with your actual collection name

    try {
      await setDoc(
        userDocRef,
        {
          [property]: value, // Assuming you want to set this to true
        },
        { merge: true }
      );
    } catch (error) {
      console.error("Error updating user onboarding status:", error);
    }
  };

  const value = {
    userPublicData,
    userPublicLoading,
    userPrivateData,
    userPrivateLoading,
    userAvailabilityLoading,
    helpersData,
    helpersLoading,
    updateUserSetup,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
