import {
  AccessTime,
  AddCircleOutline,
  DoNotDisturb,
  DoNotDisturbOnOutlined,
  EventAvailable,
  GroupAdd,
  PersonAddAlt1,
  RateReview,
  Schedule,
} from "@mui/icons-material";
import { Avatar, Badge, Box, ListItemButton, Typography } from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import { useDialog } from "../../contexts/DialogProvider";

// Custom function to create an Avatar with an icon and specific background color
const createIconAvatar = (IconComponent, backgroundColor) => (
  <Avatar
    sx={{
      bgcolor: backgroundColor,
      color: "#fff",
      width: "24px",
      height: "24px",
    }}
  >
    <IconComponent />
  </Avatar>
);

// Mapping of notification types to icons with circular backgrounds
const notificationIcons = {
  visit_cancelled: createIconAvatar(DoNotDisturb, "#de2a2a"),
  visit_rescheduled: createIconAvatar(EventAvailable, "green"),
  visit_reminder: createIconAvatar(Schedule, "hsl(190,100%,30%)"),
  visit_declined: createIconAvatar(DoNotDisturbOnOutlined, "hsl(30,100%,50%)"),
  visit_booked: createIconAvatar(AddCircleOutline, "hsl(100,100%,30%)"),
  visit_applicant: createIconAvatar(GroupAdd, "purple"),
  user_new: createIconAvatar(PersonAddAlt1, "pink"),
  review_new: createIconAvatar(RateReview, "yellow"),
  availability_new: createIconAvatar(AccessTime, "grey"),
};

const NotificationItem = ({ notification, onClick = () => {} }) => {
  const { handleOpenVisit, handleOpenReviews } = useDialog();

  const icon = notificationIcons[notification.type];
  const timeAgo = DateTime.fromJSDate(
    notification.createdAt.toDate() // Convert Firestore Timestamp to JavaScript Date
  ).toRelative();

  const handleClick = async () => {
    // Handle the click based on the routing path
    switch (notification.routing.path) {
      case "visit":
        handleOpenVisit(notification.routing.id);
        break;
      case "reviews":
        handleOpenReviews(notification.routing.id);
        break;
      // Add additional cases for other paths here
      default:
        console.log("Unhandled notification path:", notification.routing.path);
    }

    onClick(notification.id);
  };

  return (
    <ListItemButton
      onClick={handleClick}
      alignItems="flex-start"
      sx={{
        display: "flex",
        alignItems: "center",
        p: 2,
      }}
    >
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 24,
              height: 24,
            }}
          >
            {icon}
          </Box>
        }
      >
        <Avatar
          alt={notification.subject.displayName}
          src={notification.subject.avatarUrl}
          sx={{
            width: "56px",
            height: "56px",
          }}
        />
      </Badge>
      <Box sx={{ flexGrow: 1, ml: 2, position: "relative" }}>
        <Typography variant="subtitle1" component="div">
          {notification.title}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          component="div"
          sx={{ mr: 2 }}
        >
          {notification.type == "visit_reminder" ? (
            <>
              {notification.message}{" "}
              <strong>{notification.subject.displayName}</strong>
            </>
          ) : (
            <>
              <strong>{notification.subject.displayName}</strong>{" "}
              {notification.message}
            </>
          )}
          <Typography
            variant="caption"
            sx={{ display: "block", color: "text.secondary" }}
          >
            {timeAgo}
          </Typography>
        </Typography>
        {notification.readAt == false && (
          <Box
            sx={{
              position: "absolute",
              right: 0,
              top: "50%",
              transform: "translateY(-50%)",
              width: 10,
              height: 10,
              bgcolor: "primary.main",
              borderRadius: "50%",
            }}
          />
        )}
      </Box>
    </ListItemButton>
  );
};

export default NotificationItem;
