import { keyframes } from "@emotion/react";
import {
  Alert,
  Avatar,
  Box,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import {
  Timestamp,
  collection,
  doc,
  getDocs,
  query,
  runTransaction,
  setDoc,
  where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { DateTime } from "luxon";
import React, { useEffect, useRef, useState } from "react";
import { useActivity } from "../../contexts/ActivityProvider";
import { useChat } from "../../contexts/ChatProvider";
import { useDialog } from "../../contexts/DialogProvider";
import { useNotifications } from "../../contexts/NotificationsProvider";
import { useSnackbar } from "../../contexts/SnackbarProvider";
import { db, functions } from "../../firebase";
import { SKILL_LIST } from "../../services/skillServices";
import { formatName } from "../../services/stringServices";
import { TRAVEL_BUFFER } from "../../services/windowServicesV2";
import { ConfirmationAnimation } from "../animations.js/ConfirmationAnimation";
import ConfirmationButton from "../buttons/ConfirmationButton";
import StyledChecklist from "../styled/StyledChecklist";
import VisibilitySelector from "./VisibilitySelector";

const fadeInMoveDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const AnimatedBox = styled(Box)(({ theme }) => ({
  animation: `${fadeInMoveDown} 0.3s ease-out`,
}));

const SmoothBox = styled(Box)(({ theme, height }) => ({
  transition: "height 0.3s ease",
  height: `${height}px`, // Dynamic height based on content
  minHeight: "300px",
}));

function getStyles(tag, taskTags, theme) {
  return {
    fontWeight:
      taskTags.indexOf(tag) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const CreateBooking = ({
  availability = {},
  helperPublicData = { skills: SKILL_LIST.map((skill) => skill.name) },
  requesterPrivateData,
  handleClose,
  setLoading,
  loading,
  type = "book",
}) => {
  const [duration, setDuration] = useState("");
  const [startTime, setStartTime] = useState("");
  const [taskTags, setTaskTags] = useState([]);
  const [alertHeight, setAlertHeight] = useState("0px");
  const { showSnackbar } = useSnackbar();
  const { createNotification } = useNotifications();
  const { sendMessage } = useChat();
  const { createActivity } = useActivity();
  const [isPublic, setIsPublic] = useState(true);

  const [contentHeight, setContentHeight] = useState(300);
  const contentRef = useRef(null);

  const [taskDescription, setTaskDescription] = useState("");
  const [step, setStep] = useState(1);
  const [durationOptions, setDurationOptions] = useState([]);
  const [startTimeOptions, setStartTimeOptions] = useState([]);
  const [success, setSuccess] = useState(false);
  const [visitId, setVisitId] = useState("");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // Initial state setup for availabilityStart and availabilityEnd
  const [availabilityStart, setAvailabilityStart] = useState(
    DateTime.local().set({
      hour: 6,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
  );
  const [availabilityEnd, setAvailabilityEnd] = useState(
    DateTime.local().set({
      hour: 23,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
  );
  const { handleOpenVisit } = useDialog();

  console.log("availabilityStart: ", availabilityStart);

  const [minBookingMinutes, setMinBookingMinutes] = useState(
    helperPublicData?.minBookingMinutes || 15
  );

  const isHelperEmpty = Object.keys(helperPublicData).length === 1;

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.clientHeight);
    }
  }, [contentRef.current?.clientHeight]); // Recalculate when content size changes

  // useEffect hook to update availabilityStart and availabilityEnd when availability changes
  useEffect(() => {
    if (availability?.start && availability?.end) {
      let newStart = DateTime.fromJSDate(availability.start.toDate()).setZone(
        Intl.DateTimeFormat().resolvedOptions().timeZone
      );
      let newEnd = DateTime.fromJSDate(availability.end.toDate()).setZone(
        Intl.DateTimeFormat().resolvedOptions().timeZone
      );

      console.log("hello");

      setAvailabilityStart(newStart);
      setAvailabilityEnd(newEnd);

      if (!startTime) return;

      if (loading) return;

      if (
        newStart > startTime ||
        (duration && newEnd < startTime?.plus({ minutes: duration }))
      ) {
        setAlertHeight("56px");
        setDuration("");
      }
    }
  }, [availability.start, availability.end]);

  // Calculate the start time menu
  useEffect(() => {
    const options = [];
    let currentTime = availabilityStart;

    if (!availabilityEnd || !availabilityEnd) return;

    // Adjusting the availability end time by subtracting the minimum booking minutes
    const adjustedAvailabilityEnd = availabilityEnd.minus({
      minutes: minBookingMinutes,
    });

    while (currentTime <= adjustedAvailabilityEnd) {
      options.push({
        label: currentTime.toFormat("h:mm a"), // for display
        value: currentTime?.toISO(), // ISO string to identify Luxon DateTime object
        dateTime: currentTime, // store the Luxon object for easy retrieval
      });
      currentTime = currentTime.plus({ minutes: 15 });
    }

    setStartTimeOptions(options);
  }, [availabilityEnd, availabilityStart, minBookingMinutes]);

  // Calculate the duration menu
  useEffect(() => {
    if (!availabilityEnd || !availabilityEnd) return;

    const diffMinutes = availabilityEnd.diff(startTime, "minutes").minutes;

    console.log("diffMinutes: ", diffMinutes);

    console.log(
      "helperPublicData.maxBookingMinutes != null: ",
      helperPublicData.maxBookingMinutes != null
    );

    let totalMinutes =
      helperPublicData.maxBookingMinutes !== null &&
      helperPublicData.maxBookingMinutes !== ""
        ? Math.min(diffMinutes, helperPublicData.maxBookingMinutes)
        : diffMinutes;

    if (Number.isNaN(totalMinutes)) {
      totalMinutes = diffMinutes;
    }

    console.log("totalMinutes: ", totalMinutes);
    console.log(
      "helperPublicData.minBookingMinutes: ",
      helperPublicData.minBookingMinutes
    );

    const MINIMUM_BOOKING_MINUTES = 30; // Default minimum booking duration

    // Get the minimum booking minutes from helperPublicData or use the default
    const min = helperPublicData.minBookingMinutes ?? MINIMUM_BOOKING_MINUTES;

    const options = [];
    for (let i = min; i <= totalMinutes; i += 15) {
      console.log("inside i:  ", i);
      const hours = Math.floor(i / 60);
      const minutes = i % 60;
      const formattedDuration = `${
        hours > 0 ? `${hours} hour${hours > 1 ? "s" : ""} ` : ""
      }${minutes > 0 ? `${minutes} minute${minutes > 1 ? "s" : ""}` : ""}`;
      options.push({ value: i, label: formattedDuration });
    }

    setDurationOptions(options);
  }, [availabilityEnd, availabilityStart, startTime]);

  if (!availabilityEnd || !availabilityEnd) return;

  const handleRedirect = () => {
    handleOpenVisit(visitId);
    setSuccess(false);
    setStartTime("");
    setTaskTags([]);
    setTaskDescription("");
    setDuration("");
    setStep(1);
    handleClose();
  };

  const handleChange = (itemValue) => {
    setTaskTags((prevTags) => {
      const currentTags = Array.isArray(prevTags) ? [...prevTags] : [];

      if (currentTags.includes(itemValue)) {
        // Remove item if it's already in the array
        return currentTags.filter((tag) => tag !== itemValue);
      } else {
        // Add item to the array if it's not already included
        return [...currentTags, itemValue];
      }
    });

    // Additional logic based on step
    if (step === 3) setStep(4);
  };

  const handleConfirmAndBook = async () => {
    setLoading(true);

    // Assuming duration is in minutes and you convert it to a Luxon Duration to add to startTime
    const endTime = startTime?.plus({ minutes: parseInt(duration, 10) });

    try {
      await createVisit({
        helperPublicData,
        requesterPrivateData,
        startTime, // Luxon DateTime object
        endTime, // Calculated Luxon DateTime object for the end time
        duration: parseInt(duration, 10), // Ensure duration is an integer
        taskDescription,
        taskTags,
      });

      // Proceed with UI update only if createVisit is successful
      // setStep(5);
      setSuccess(true);

      // handleClose();
    } catch (error) {
      // Handle the error, such as showing an error message to the user
      console.error("The selected time is no longer available:", error.message);
      // Optionally, show an error message to the user with a UI component, like a snackbar
      showSnackbar("The selected time is no longer available.", "warning");
      // handleClose();
    } finally {
      setLoading(false);
    }
  };

  const handleStartTimeChange = (e) => {
    const selectedISO = e.target.value;
    const selectedOption = startTimeOptions.find(
      (option) => option.value === selectedISO
    );
    if (selectedOption) {
      setStartTime(selectedOption.dateTime); // Set the Luxon DateTime object
      setAlertHeight("0px");
      if (step === 1) setStep(2);
    }
  };

  const handleDurationChange = (e) => {
    setDuration(e.target.value); // Set the Luxon DateTime object
    if (step === 2) setStep(3);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MENU_MIN_WIDTH = 250;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: MENU_MIN_WIDTH,
        zIndex: 2100,
      },
    },
    zIndex: 2100,
  };

  const total = ((duration * helperPublicData.hourlyRate) / 60).toFixed(2);

  const createVisit = async ({
    helperPublicData = {},
    requesterPrivateData = {},
    startTime = null,
    endTime = null,
    duration = 0,
    taskDescription = "",
    taskTags = [],
  }) => {
    // Convert startTime and endTime to Firestore Timestamps
    const formattedStartTime = Timestamp.fromDate(startTime.toJSDate());
    const formattedEndTime = Timestamp.fromDate(endTime.toJSDate());

    console.log("helperPublicData: ", helperPublicData);

    let helpersMap = {};
    let participants = [requesterPrivateData.id];

    if (!isHelperEmpty) {
      participants.unshift(helperPublicData.id); // Push to the front of the array

      helpersMap = {
        [helperPublicData.id]: {
          id: helperPublicData.id,
          avatarUrl: helperPublicData.avatarUrl,
          firstName: helperPublicData.firstName,
          lastName: helperPublicData.lastName,
          hourlyRate: helperPublicData.hourlyRate,
          skills: helperPublicData.skills,
          gender: helperPublicData.gender,
          birthMonth: helperPublicData.birthMonth,
          responseStatus: "accepted",
        },
      };
    }

    const publicData = {
      participants,
      helpers: helpersMap, // Store the helpers as a map
      requester: {
        id: requesterPrivateData.id,
        avatarUrl: requesterPrivateData.avatarUrl,
        firstName: requesterPrivateData.firstName,
        lastName: requesterPrivateData.lastName,
        gender: requesterPrivateData.gender,
        birthMonth: requesterPrivateData.birthMonth,
        // Add any additional requester fields here
      },
      start: formattedStartTime,
      end: formattedEndTime,
      taskDescription,
      taskTags,
      createdAt: Timestamp.now(),
      createdBy: requesterPrivateData.id,
      // line1: requesterPrivateData.line1,
      // line2: requesterPrivateData.line2,
      city: requesterPrivateData.city,
      state: requesterPrivateData.state,
      zipCode: requesterPrivateData.zipCode,
      // directions: requesterPrivateData.directions,
      // lat: requesterPrivateData.lat,
      // lng: requesterPrivateData.lng,
      geohash6: requesterPrivateData.geohash.substring(0, 6),
      // phone: requesterPrivateData.phone,
      // email: requesterPrivateData.email,
      status: "confirmed",
      notificationsSent: false,
      timeZone,
    };

    const privateData = {
      line1: requesterPrivateData.line1,
      line2: requesterPrivateData.line2,
      city: requesterPrivateData.city,
      state: requesterPrivateData.state,
      zipCode: requesterPrivateData.zipCode,
      directions: requesterPrivateData.directions,
      lat: requesterPrivateData.lat,
      lng: requesterPrivateData.lng,
      geohash: requesterPrivateData.geohash,
      phone: requesterPrivateData.phone,
      email: requesterPrivateData.email,
    };


    if (isHelperEmpty) {
      publicData.helpersNeeded = 1;
      publicData.status = "open";
    }

    const formattedDate = startTime.toFormat("cccc, LLLL d");

    const messageContent = `${formatName(
      requesterPrivateData.firstName
    )} booked a visit on ${formattedDate}`;

    let newVisitRef;

    console.log("isHelperEmpty: ", isHelperEmpty);

    try {
      await runTransaction(db, async (transaction) => {
        const visitsRef = collection(db, "visits");

        newVisitRef = doc(visitsRef);

        if (!isHelperEmpty) {
          const bufferedStart = startTime
            .minus({ minutes: TRAVEL_BUFFER })
            .toJSDate();
          const bufferedEnd = endTime
            .plus({ minutes: TRAVEL_BUFFER })
            .toJSDate();

          console.log("visitsRef: ", visitsRef);

          const overlapQuery = query(
            visitsRef,
            where("participants", "array-contains", helperPublicData.id),
            where("start", "<", Timestamp.fromDate(bufferedEnd)),
            where("end", ">", Timestamp.fromDate(bufferedStart)),
            where("status", "!=", "cancelled")
          );

          const visitsSnapshot = await getDocs(overlapQuery);

          if (!visitsSnapshot.empty) {
            console.log("++throwing error");
            throw new Error(
              "The selected time is no longer available due to overlapping visits."
            );
          }
        }

        // Now set the new visit data
        transaction.set(newVisitRef, { id: newVisitRef.id, ...publicData });
      });
      const privateDetailsRef = doc(
        collection(newVisitRef, "privateData"),
        "details"
      );
      setDoc(privateDetailsRef, privateData);

      // create activity -----

      let users = [
        {
          firstName: requesterPrivateData.firstName,
          avatarUrl: requesterPrivateData.avatarUrl,
          id: requesterPrivateData.id,
        },
      ];

      if (!isHelperEmpty) {
        users.push({
          firstName: helperPublicData.firstName,
          avatarUrl: helperPublicData.avatarUrl,
          id: helperPublicData.id,
        });
      }

      const activityType = !isHelperEmpty
        ? isPublic
          ? "public_visit_booked"
          : "private_visit_booked"
        : "job_post"; // Provide a valid return value for this case

      let activityData = {
        type: activityType,
        city: requesterPrivateData.city,
        state: requesterPrivateData.state,
        geohash6: requesterPrivateData.geohash.substring(0, 6),
        description: isPublic ? taskDescription : "",
        tasks: taskTags,
        bounds: helperPublicData?.bounds || [],
        users,
      };

      if (isHelperEmpty) {
        activityData.date = Timestamp.fromDate(
          availabilityStart.toJSDate() // Convert Luxon DateTime to JavaScript Date
        );
        activityData.visitId = newVisitRef.id;
      }

      await createActivity(activityData);

      // end ----

      if (!isHelperEmpty) {
        // Call the Cloud Function fire-and-forget to adjust the helpers availability
        const subtractWindow = httpsCallable(functions, "subtractWindow");
        subtractWindow({
          userId: helperPublicData.id,
          windowToSubtract: {
            start: startTime.toISO(), // Convert Luxon DateTime to ISO string
            end: endTime.toISO(), // Convert Luxon DateTime to ISO string
          },
        });

        createNotification({
          userId: helperPublicData.id,
          type: "visit_booked",
          message: `booked a visit with you on ${startTime.toFormat(
            "cccc, LLLL d"
          )}`,
          routingPath: "visit",
          routingId: newVisitRef.id,
          subjectAvatarUrl: requesterPrivateData.avatarUrl,
          subjectDisplayName: formatName(requesterPrivateData.firstName),
          subjectId: requesterPrivateData.id,
        });

        sendMessage({
          message: messageContent,
          otherParticipants: [helperPublicData],
          routingCollection: "visits",
          routingId: newVisitRef.id,
        });
      }

      // Store the document ID immediately as it is synchronous
      setVisitId(newVisitRef.id);

      console.log("Visit created successfully without any time conflicts.");
    } catch (error) {
      console.error("Error during visit creation: ", error.message);

      throw new Error("The selected time is no longer available.");
    }
  };

  return (
    <>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          p: 2,
          minHeight: "300px",
          zIndex: 2100,
        }}
      >
        <SmoothBox height={contentHeight}>
          <div ref={contentRef}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mx: "auto",
                width: "100%",
                height: "100%",
                bgcolor: "background.paper",
                gap: 2,
                pb: 2,
                boxSizing: "border-box",
                height: "100%",
                flexGrow: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  alignItems: "center",
                  mx: "auto",
                }}
              >
                <Avatar
                  src={helperPublicData?.avatarUrl}
                  sx={{ width: 56, height: 56, borderRadius: "15px" }}
                />
                <Box>
                  <Typography fontWeight={"600"}>
                    {formatName(helperPublicData.firstName)}{" "}
                    {formatName(helperPublicData.lastName)}
                  </Typography>
                  <Typography>
                    {availabilityStart.toFormat("cccc, LLLL d")}
                  </Typography>
                </Box>
              </Box>
              {isHelperEmpty && (
                <DatePicker
                  sx={{ width: "100%" }}
                  label={"Date"}
                  name="Date"
                  views={["month", "day"]}
                  // value={calendarDate} // Use the state value here
                  onChange={(newDate) => {
                    // setCalendarDate(newDate); // Set the new calendar date

                    setAvailabilityStart(
                      newDate.set({
                        hour: 6,
                        minute: 0,
                        second: 0,
                        millisecond: 0,
                      })
                    );

                    setAvailabilityEnd(
                      newDate.set({
                        hour: 23,
                        minute: 0,
                        second: 0,
                        millisecond: 0,
                      })
                    );
                  }}
                />
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  gap: 2,
                }}
              >
                {step >= 1 && step < 5 && (
                  <Box sx={{ width: "100%", zIndex: 2100 }}>
                    <FormControl fullWidth>
                      <InputLabel id="start-time-label">Start Time</InputLabel>
                      <Select
                        labelId="start-time-label"
                        id="start-time-select"
                        value={startTime && startTime?.toISO()} // This assumes startTime is always a Luxon object
                        label="Start Time"
                        onChange={handleStartTimeChange}
                        MenuProps={MenuProps}
                      >
                        {startTimeOptions.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Box
                      sx={{
                        height: alertHeight,
                        overflow: "hidden",
                        transition: "height 0.3s ease-in-out",
                      }}
                    >
                      <Alert color="warning" sx={{ my: 1 }}>
                        This time is no longer available
                      </Alert>
                    </Box>
                  </Box>
                )}
                {step >= 2 && step < 5 && (
                  <Box sx={{ width: "100%" }}>
                    <AnimatedBox>
                      <FormControl fullWidth>
                        <InputLabel id="duration-label">Duration</InputLabel>
                        <Select
                          labelId="duration-label"
                          id="duration-select"
                          value={duration}
                          label="Duration"
                          onChange={handleDurationChange}
                          MenuProps={MenuProps}
                        >
                          {durationOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </AnimatedBox>
                  </Box>
                )}
              </Box>

              {step >= 3 && step < 5 && (
                <AnimatedBox>
                  <StyledChecklist
                    columns={isMobile ? 2 : 3}
                    items={SKILL_LIST.filter((skill) =>
                      helperPublicData.skills.includes(skill.name)
                    )}
                    checkedItems={taskTags}
                    onChange={handleChange}
                  />
                </AnimatedBox>
              )}
              {step >= 4 && step < 5 && (
                <AnimatedBox>
                  <TextField
                    fullWidth
                    label="Task Description"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={taskDescription}
                    onChange={(e) => setTaskDescription(e.target.value)}
                    sx={{ mb: 1 }}
                  />
                  <VisibilitySelector
                    isPublic={isPublic}
                    setIsPublic={setIsPublic}
                  />
                  <div style={{ height: "8px" }} />
                  <Typography
                    align="left"
                    variant="caption"
                    color={"text.secondary"}
                  >
                    <span>
                      By selecting <strong>Confirm and book</strong>, I agree to
                      the updated{" "}
                      <a
                        href="/terms-of-service"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "black" }} // Added inline style here
                      >
                        Terms of Service
                      </a>{" "}
                      and{" "}
                      <a
                        href="/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "black" }} // Added inline style here
                      >
                        Privacy Policy
                      </a>
                      .
                    </span>
                  </Typography>
                </AnimatedBox>
              )}
              {step === 5 && (
                <AnimatedBox
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <>
                    <ConfirmationAnimation size="xlarge" />
                    <Typography>Booking confirmed!</Typography>
                  </>
                </AnimatedBox>
              )}
            </Box>
          </div>
        </SmoothBox>
      </DialogContent>

      <DialogActions
        sx={{
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
          justifyContent: "space-between",
          flexDirection: { xs: "column", sm: "row" },
          zIndex: 2100,
        }}
      >
        {!isHelperEmpty ? (
          <Typography sx={{ pb: 2, pt: 1, color: "text.secondary" }}>
            <strong>Total</strong> (estimated): ${total}
          </Typography>
        ) : (
          <Typography sx={{ pb: 2, pt: 1, color: "text.secondary" }}>
          </Typography>
        )}
        <ConfirmationButton
          originalText={"Confirm and book"}
          loadingText={"Booking..."}
          successText={"Success!"}
          onClick={handleConfirmAndBook}
          onRedirect={handleRedirect}
          isLoading={loading}
          isSuccess={success}
          isDisabled={
            !taskDescription || taskTags.length === 0 || !duration || !startTime
          }
          sx={{
            minWidth: { xs: "100%", sm: "200px" },
            textTransform: "none",
            height: "56px",
          }}
        />
      </DialogActions>
    </>
  );
};

export default CreateBooking;
