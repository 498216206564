import {
  AssistantOutlined,
  BedOutlined,
  CelebrationOutlined,
  CleaningServicesOutlined,
  DesignServicesOutlined,
  DevicesOutlined,
  DriveEtaOutlined,
  EscalatorWarningOutlined,
  FitnessCenter,
  FlightOutlined,
  GrassOutlined,
  HandymanOutlined,
  HardwareOutlined,
  InventoryOutlined,
  LocalFloristOutlined,
  LocalLaundryServiceOutlined,
  MusicNoteOutlined,
  PetsOutlined,
  PhotoCameraOutlined,
  RestaurantOutlined,
  SchoolOutlined,
  StrollerOutlined
} from "@mui/icons-material";

export const SKILL_LIST = [
  {
    name: "Organization",
    Icon: InventoryOutlined,
    question: "Know a great organizer?",
  },
  {
    name: "Laundry",
    Icon: LocalLaundryServiceOutlined,
    question: "Know a great maid?",
  },
  {
    name: "Meal prep",
    Icon: RestaurantOutlined,
    question: "Know a great cook?",
  },
  {
    name: "Housekeeping",
    Icon: BedOutlined,
    question: "Know a great housekeeper?",
  },
  {
    name: "Babysitting",
    Icon: StrollerOutlined,
    question: "Know a great babysitter?",
  },
  {
    name: "Errands",
    Icon: DriveEtaOutlined,
    question: "Know a great errand runner?",
  },
  {
    name: "Nanny",
    Icon: EscalatorWarningOutlined,
    question: "Know a great nanny?",
  },
  {
    name: "Pet care",
    Icon: PetsOutlined,
    question: "Know a great pet lover?",
  },
  {
    name: "Tutoring",
    Icon: SchoolOutlined,
    question: "Know a great tutor?",
  },
  {
    name: "Yard work",
    Icon: GrassOutlined,
    question: "Know a great yard worker?",
  },
  {
    name: "Assistant",
    Icon: AssistantOutlined,
    question: "Know a great assistant?",
  },
  {
    name: "Trip planning",
    Icon: FlightOutlined,
    question: "Know a great trip planner?",
  },
  {
    name: "Party planning",
    Icon: CelebrationOutlined,
    question: "Know a great party planner?",
  },
  {
    name: "Deep cleaning",
    Icon: CleaningServicesOutlined,
    question: "Know a great deep cleaner?",
  },
  {
    name: "Interior Design",
    Icon: DesignServicesOutlined,
    question: "Know a great interior designer?",
  },
  {
    name: "Photography",
    Icon: PhotoCameraOutlined,
    question: "Know a great photographer?",
  },
  {
    name: "Handyman",
    Icon: HardwareOutlined,
    question: "Know a great handyman?",
  },
  {
    name: "Guitar lessons",
    Icon: MusicNoteOutlined,
    question: "Know a great guitar teacher?",
  },
  {
    name: "Tech help",
    Icon: DevicesOutlined,
    question: "Know a great tech person?",
  },
  {
    name: "Gardening",
    Icon: LocalFloristOutlined,
    question: "Know a great gardener?",
  },
  {
    name: "Assembly",
    Icon: HandymanOutlined,
    question: "Know a great problem solver?",
  },
  // {
  //   name: "Sports training",
  //   Icon: SportsBasketballOutlined,
  //   question: "Know a great sports trainer?",
  // },
  // {
  //   name: "Day Laborers",
  //   Icon: HomeRepairServiceOutlined,
  //   question: "Know a great day laborer?",
  // },
  {
    name: "Heavy lifting",
    Icon: FitnessCenter,
    question: "Know a strong person?",
  },
  // { name: "Life coach", Icon: Sports, question: "Know a great life coach?" },
  // {
  //   name: "Student advisor",
  //   Icon: MenuBookOutlined,
  //   question: "Know a great student advisor?",
  // },
  // { name: "Student advisor", Icon: CleaningServicesOutlined },
];
