import { Popover } from "@mui/material";
import React from "react";
import UserProfileCard from "./UserProfileCard";

const ProfilePopover = ({ anchorEl, open, onClose }) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      sx={{
        width: 350,
        mt: 2,
        zIndex: "1400",
      }}
    >
      <UserProfileCard onClose={onClose} />
    </Popover>
  );
};

export default ProfilePopover;
