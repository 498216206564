// Adjusted NotificationsPopover.js with Popover
import { Popover } from "@mui/material";
import React from "react";
import NotificationsContent from "./NotificationsContent";

const NotificationsPopover = ({ anchorEl, open, onClose, notifications }) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      slotProps={{
        paper: {
          sx: {
            width: "350px", // Apply width constraint here
          },
        },
      }}
      sx={{
        zIndex: "1400",
        mt: 2,
      }}
    >
      <NotificationsContent
        notifications={notifications}
        onClose={onClose}
      />
    </Popover>
  );
};

export default NotificationsPopover;
