import { AppBar, Box, Toolbar } from "@mui/material";
import React, { useState } from "react";
import SearchBar from "../discover/SearchBar";

import { useDashboard } from "../../contexts/DashboardContext";
import { useUser } from "../../contexts/UserProvider";
import TaskMomLogo from "../../svgs/TaskMomLogo";
import BecomeHelperButton from "../buttons/BecomeHelperButton";
import ProfileHeaderButton from "../buttons/ProfileHeaderButton";
import NotificationBell from "../notifications/NotificationBell";
import PostAJob from "../buttons/PostAJob";

const DashboardHeader = () => {
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);

  const { changeTab } = useDashboard();
  const { userPrivateData } = useUser();

  const isProfileOpen = Boolean(profileAnchorEl);

  const handleProfileClose = () => {
    console.log("closing notifications");
    setProfileAnchorEl(null);
  };

  return (
    <AppBar
      position="sticky"
      component="nav"
      sx={{
        height: "80px",
        zIndex: "1000",
        backgroundColor: "white",
        top: 0,
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        px: { sm: "0px", md: "32px" },
        boxSizing: "border-box",
      }}
      elevation={0}
    >
      <Toolbar sx={{ gap: 1, my: "auto" }}>
        <Box sx={{display: "flex", flexDirection: "row", gap: 4, alignItems: "center" }}>
          <Box
            width="150px"
            onClick={() => changeTab("Explore")}
            sx={{ cursor: "pointer" }}
          >
            <TaskMomLogo color="#000" />
          </Box>
        <PostAJob />
        </Box>
        {/* Placeholder for SearchBar */}
        <SearchBar
          anchorEl={profileAnchorEl}
          open={isProfileOpen}
          onClose={handleProfileClose}
        />

        <Box sx={{ display: "flex", gap: 0 }}>
          {!userPrivateData?.isHelper && <BecomeHelperButton />}

          {/* Notification Bell */}
          <NotificationBell />
        </Box>

        {/* Profile Avatar Icon */}
        <ProfileHeaderButton />
      </Toolbar>
    </AppBar>
  );
};

export default DashboardHeader;
