import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import GoogleMapsDisplay from "../maps/GoogleMapsDisplay";

const MapDetails = ({ bounds = [] }) => {
  if (bounds.length === 0) return;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: 1,
        bgcolor: "background.paper",
        boxSizing: "border-box",
        mx: { xs: 1, sm: 0 },
      }}
    >
      <Divider sx={{ mt: { xs: 2, sm: 4 }, mb: 2 }} />
      <Typography
        variant="h6"
        fontSize={"1.15rem"}
        fontWeight={"600"}
        sx={{ mb: 2 }}
      >
        My service area
      </Typography>
      <GoogleMapsDisplay initialBounds={bounds} />
    </Box>
  );
};

export default MapDetails;
