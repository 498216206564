import { Button, DialogContent, useMediaQuery, useTheme } from "@mui/material";
import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserHelperProfile from "../components/account/UserHelperProfile";
import UserPersonalInfo from "../components/account/UserPersonalInfo";
import MassEmailForm from "../components/admin/MassEmailForm";
import CreateProfile from "../components/auth/CreateProfile";
import InstallInstructions from "../components/auth/InstallInstructions";
import LoginOrSignUp from "../components/auth/LoginOrSignUp";
import WalkthroughView from "../components/auth/WalkthroughView";
import CreateBooking from "../components/booking/CreateBooking";
import Conversation from "../components/chat/Conversation";
import BookingDash from "../components/dashboard/BookingDash";
import FAQDash from "../components/dashboard/FAQDash";
import InviteDash from "../components/dashboard/InviteDash";
import ProfileDash from "../components/dashboard/ProfileDash";
import ReviewsDash from "../components/dashboard/ReviewsDash";
import ResponsiveDialog from "../components/dialogs/ResponsiveDialog";
import FeedbackForm from "../components/forms/FeedbackForm";
import VisitCard from "../components/visits/VisitCard";
import { findOrCreateConversation } from "../services/chatServices";
import { welcomeHelper } from "../services/variableServices";
import { useAuth } from "./AuthProvider";
import { useDashboard } from "./DashboardContext";
import { useUser } from "./UserProvider";

const DialogContext = createContext();

export const DialogProvider = ({ children }) => {
  const { userPrivateData } = useUser();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [currentZIndex, setCurrentZIndex] = useState(1200);

  const { currentUser } = useAuth();

  const { changeTab } = useDashboard();

  const [openProfile, setOpenProfile] = useState(false);
  const [profileId, setProfileId] = useState({});
  const [profileZ, setProfileZ] = useState(1200);
  const handleOpenProfile = (userId) => {
    if (isMobile) {
      setCurrentZIndex(currentZIndex + 1);
      setProfileZ(currentZIndex);
      setProfileId(userId);
      setOpenProfile(true);
    } else {
      navigate(`/profile/${userId}`);
    }
  };

  const handleCloseProfile = () => {
    setOpenProfile(false);
    setProfileId(null);
  };

  const accountSetup =
    userPrivateData?.accountSetup === "complete" ||
    userPrivateData?.accountSetup === "show_success";

  const [openChat, setOpenChat] = useState(false);
  const [conversation, setConversation] = useState({});
  const [chatZ, setChatZ] = useState(1200);

  const handleOpenChat = async (otherParticipants) => {
    if (!currentUser || currentUser?.isAnonymous) {
      setOpenLoginOrSignUp(true);
      return;
    }

    if (!accountSetup) {
      setOpenCreateProfile(true);
      return;
    }

    // Including currentUser in otherParticipants for unified handling
    const allParticipants = [userPrivateData, ...otherParticipants];

    const convo = await findOrCreateConversation(allParticipants);

    if (isMobile) {
      setCurrentZIndex(currentZIndex + 1);
      setChatZ(currentZIndex);
      setConversation(convo);
      setOpenChat(true);
    } else {
      navigate(`/chat/${convo.id}`);
    }
  };

  const [reviewId, setReviewId] = useState(null);
  const [openReviews, setOpenReviews] = useState(false);
  const [reviewsZ, setReviewsZ] = useState(1200);
  const handleOpenReviews = (userId) => {
    if (isMobile) {
      setCurrentZIndex(currentZIndex + 1);
      setReviewsZ(currentZIndex);

      setReviewId(userId);
      setOpenReviews(true);
    } else {
      navigate(`/reviews/${userId}`);
    }
  };

  const [openPersonalInfo, setOpenPersonalInfo] = useState(false);
  const [personalInfoZ, setPersonalInfoZ] = useState(1200);
  const handleOpenPersonalInfo = () => {
    if (isMobile) {
      setCurrentZIndex(currentZIndex + 1);
      setPersonalInfoZ(currentZIndex);
      setOpenPersonalInfo(true);
    } else {
      navigate(`/account/personal-info`);
    }
  };

  const [openHelperProfile, setOpenHelperProfile] = useState(false);
  const [helperProfileZ, setHelperProfileZ] = useState(1200);
  const handleOpenHelperProfile = () => {
    if (isMobile) {
      setCurrentZIndex(currentZIndex + 1);
      setHelperProfileZ(currentZIndex);
      setOpenHelperProfile(true);
    } else {
      navigate(`/account/helper-profile`);
    }
  };
  const handleCloseHelperProfile = () => {
    setOpenHelperProfile(false);
  };

  const [openInvite, setOpenInvite] = useState(false);
  const [inviteZ, setInviteZ] = useState(1200);
  const handleOpenInvite = () => {
    if (isMobile) {
      setCurrentZIndex(currentZIndex + 1);
      setInviteZ(currentZIndex);
      setOpenInvite(true);
    } else {
      navigate(`/invite`);
    }
  };
  const handleCloseInvite = () => {
    setOpenInvite(false);
  };

  const [openInstallApp, setOpenInstallApp] = useState(false);
  const [installAppZ, setInstallAppZ] = useState(1200);
  const handleOpenInstallApp = () => {
    setCurrentZIndex(currentZIndex + 1);
    setInstallAppZ(currentZIndex);
    setOpenInstallApp(true);
  };

  const [openCreateProfile, setOpenCreateProfile] = useState(false);
  const [createProfileZ, setCreateProfileZ] = useState(1200);
  const [profileCanSkip, setProfileCanSkip] = useState(false);
  const [profileSetupType, setProfileSetupType] = useState(false);
  // const [profileHeaderTitle, setProfileHeaderTitle] = useState(false);

  const handleOpenCreateProfile = ({
    canSkip = false,
    setupType = "profile",
    // headerTitle = "Complete your profile",
  } = {}) => {
    setProfileCanSkip(canSkip);
    // setProfileHeaderTitle(headerTitle);
    setProfileSetupType(setupType);
    setCurrentZIndex(currentZIndex + 1);
    setCreateProfileZ(currentZIndex);
    setOpenCreateProfile(true);
  };
  const handleCloseCreateProfile = () => {
    setOpenCreateProfile(false);
    // setSelectedVisitId(null);
  };

  const [openLoginOrSignUp, setOpenLoginOrSignUp] = useState(false);
  const [loginOrSignUpTitle, setLoginOrSignUpTitle] = useState(false);
  const [loginOrSignUpSetupType, setLoginOrSignUpSetupType] = useState(null);
  const [loginOrSignUpZ, setLoginOrSignUpZ] = useState(1200);
  const handleOpenLoginOrSignUp = ({ setupType = null } = {}) => {
    setLoginOrSignUpSetupType(setupType);
    setCurrentZIndex(currentZIndex + 1);
    setLoginOrSignUpZ(currentZIndex);
    setOpenLoginOrSignUp(true);
  };

  const [openFAQ, setOpenFAQ] = useState(false);
  const [FAQZ, setFAQZ] = useState(1200);
  const handleOpenFAQ = () => {
    if (isMobile) {
      setCurrentZIndex(currentZIndex + 1);
      setFAQZ(currentZIndex);
      setOpenFAQ(true);
    } else {
      navigate(`/faq`);
    }
  };

  // Phased out to get better sitemap SEO
  const [openLanding, setOpenLanding] = useState(false);
  const [LandingZ, setLandingZ] = useState(1200);
  const handleOpenLanding = () => {
    // if (isMobile) {
    //   setCurrentZIndex(currentZIndex + 1);
    //   setLandingZ(currentZIndex);
    //   setOpenLanding(true);
    // } else {
    window.open("/landing", "_blank");
    // }
  };

  const [openFeedback, setOpenFeedback] = useState(false);
  const [feedbackZ, setFeedbackZ] = useState(1200);
  const handleOpenFeedback = () => {
    setCurrentZIndex(currentZIndex + 1);
    setFeedbackZ(currentZIndex);
    setOpenFeedback(true);
  };

  const [openMassEmailForm, setOpenMassEmailForm] = useState(false);
  const [massEmailFormZ, setMassEmailFormZ] = useState(1200);
  const handleOpenMassEmailForm = () => {
    setCurrentZIndex(currentZIndex + 1);
    setMassEmailFormZ(currentZIndex);
    setOpenMassEmailForm(true);
  };

  const [openWalkthrough, setOpenWalkthrough] = useState(false);
  const [walkthroughZ, setWalkthroughZ] = useState(1200);
  const [walkthroughSlides, setWalkthroughSlides] = useState(welcomeHelper);
  const handleOpenWalkthrough = ({ type = "profile" } = {}) => {
    setWalkthroughSlides(type === "helper" ? welcomeHelper : []);
    setCurrentZIndex(currentZIndex + 1);
    setWalkthroughZ(currentZIndex);
    setOpenWalkthrough(true);
  };

  const [visitId, setVisitId] = useState(null);
  const [openVisit, setOpenVisit] = useState(false);
  const [visitZ, setVisitZ] = useState(1200);
  const handleOpenVisit = (visitId) => {
    if (isMobile) {
      setCurrentZIndex(currentZIndex + 1);
      setVisitZ(currentZIndex);

      setVisitId(visitId);
      setOpenVisit(true);
    } else {
      navigate(`/visit/${visitId}`);
    }
  };
  const handleCloseVisit = () => {
    setOpenVisit(false);
    // setSelectedVisitId(null);
  };

  const [openCreateBooking, setOpenCreateBooking] = useState(false);
  const [creating, setCreating] = useState(false);
  const [createBookingZ, setCreateBookingZ] = useState(1200);
  const handleOpenCreateBooking = () => {
    if (!currentUser || currentUser?.isAnonymous) {
      handleOpenLoginOrSignUp();
      return;
    }

    if (!userPrivateData?.accountSetup) {
      handleOpenCreateProfile();
      return;
    }

    setCurrentZIndex(currentZIndex + 1);
    setCreateBookingZ(currentZIndex);
    setOpenCreateBooking(true);
  };

  const [bookingId, setBookingId] = useState(null);
  const [openBooking, setOpenBooking] = useState(false);
  const [bookingZ, setBookingZ] = useState(1200);
  const handleOpenBooking = (bookingId) => {
    if (isMobile) {
      setCurrentZIndex(currentZIndex + 1);
      setBookingZ(currentZIndex);
      setBookingId(bookingId);
      setOpenBooking(true);
    } else {
      navigate(`/booking/${bookingId}`);
    }
  };

  const handleCloseBooking = () => {
    setOpenBooking(false);
    // setSelectedBookingId(null);
  };

  const handleCloseAll = () => {
    setOpenProfile(false);
    setOpenChat(false);
    setOpenReviews(false);
    setOpenVisit(false);
  };

  const contextValue = {
    handleOpenProfile,
    handleCloseProfile,
    handleOpenCreateProfile,
    handleCloseCreateProfile,
    handleOpenChat,
    handleOpenReviews,
    handleOpenVisit,
    handleCloseVisit,
    handleOpenBooking,
    handleCloseBooking,
    handleOpenPersonalInfo,
    handleCloseAll,
    handleOpenHelperProfile,
    handleCloseHelperProfile,
    handleOpenInvite,
    handleCloseInvite,
    handleOpenFAQ,
    handleOpenLoginOrSignUp,
    handleOpenLanding,
    handleOpenFeedback,
    handleOpenInstallApp,
    handleOpenWalkthrough,
    handleOpenMassEmailForm,
    handleOpenCreateBooking,
  };

  return (
    <DialogContext.Provider value={contextValue}>
      {children}
      <ResponsiveDialog
        open={openProfile}
        onClose={() => setOpenProfile(false)}
        width="400px"
        anchor="right"
        fullHeight={true}
        zIndex={profileZ}
      >
        <ProfileDash inputId={profileId} showMobileHeader={false} />
      </ResponsiveDialog>
      <ResponsiveDialog
        open={openChat}
        onClose={() => setOpenChat(false)}
        title="Chat"
        width="600px"
        anchor="right"
        fullHeight={true}
        zIndex={chatZ}
      >
        <Conversation conversation={conversation} />
      </ResponsiveDialog>
      <ResponsiveDialog
        open={openReviews}
        onClose={() => setOpenReviews(false)}
        title="Reviews"
        width="600px"
        anchor="right"
        fullHeight={true}
        zIndex={reviewsZ}
      >
        {reviewId && (
          <DialogContent>
            <ReviewsDash inputId={reviewId} />
          </DialogContent>
        )}
      </ResponsiveDialog>
      <ResponsiveDialog
        open={openVisit}
        onClose={handleCloseVisit}
        title="Visit details"
        width="600px"
        zIndex={visitZ}
        anchor="right"
        fullHeight={true}
      >
        <VisitCard visitId={visitId} />
      </ResponsiveDialog>
      <ResponsiveDialog
        open={openBooking}
        onClose={() => setOpenBooking(false)}
        title={"Availability"}
        width="400px"
        anchor="right"
        fullHeight={true}
        zIndex={bookingZ}
      >
        <BookingDash inputId={bookingId} />
      </ResponsiveDialog>
      <ResponsiveDialog
        open={openPersonalInfo}
        onClose={() => setOpenPersonalInfo(false)}
        title={"My profile"}
        width="400px"
        anchor="right"
        fullHeight={true}
        zIndex={personalInfoZ}
        actions={
          <Button onClick={() => handleOpenProfile(currentUser?.uid)}>
            View
          </Button>
        }
      >
        <DialogContent sx={{ px: 0 }}>
          <UserPersonalInfo />
        </DialogContent>
      </ResponsiveDialog>
      <ResponsiveDialog
        open={openHelperProfile}
        onClose={() => setOpenHelperProfile(false)}
        title={"Helper Preferences"}
        width="400px"
        anchor="right"
        fullHeight={true}
        zIndex={helperProfileZ}
        actions={
          <Button onClick={() => handleOpenProfile(currentUser?.uid)}>
            View
          </Button>
        }
      >
        <DialogContent sx={{ px: 0 }}>
          <UserHelperProfile />
        </DialogContent>
      </ResponsiveDialog>
      <ResponsiveDialog
        open={openFAQ}
        onClose={() => setOpenFAQ(false)}
        title={"FAQ"}
        width="400px"
        anchor="right"
        fullHeight={true}
        zIndex={FAQZ}
      >
        <DialogContent>
          <FAQDash />
        </DialogContent>
      </ResponsiveDialog>
      {/* <ResponsiveDialog
        open={openLanding}
        onClose={() => setOpenLanding(false)}
        title={"Landing"}
        width="400px"
        anchor="right"
        fullHeight={true}
        zIndex={LandingZ}
      >
        <DialogContent sx={{ p: 0, position: "relative" }}>
          <Hero showHeader={false} />
        </DialogContent>
      </ResponsiveDialog> */}
      <ResponsiveDialog
        open={openCreateProfile}
        onClose={() => setOpenCreateProfile(false)}
        title={
          profileSetupType === "profile"
            ? "Complete your profile"
            : "Become a helper"
        }
        width="600px"
        anchor="bottom"
        fullHeight={true}
        zIndex={createProfileZ}
      >
        {/* {openCreateProfile && ( */}
        <CreateProfile
          handleClose={() => setOpenCreateProfile(false)}
          canSkip={profileCanSkip}
          setupType={profileSetupType}
          isOpen={openCreateProfile} // triggers the refresh of form completion
        />
        {/* )} */}
      </ResponsiveDialog>

      <ResponsiveDialog
        open={openLoginOrSignUp}
        onClose={() => setOpenLoginOrSignUp(false)}
        title={loginOrSignUpTitle}
        width="600px"
        anchor="bottom"
        fullHeight={true}
        zIndex={1500}
      >
        <LoginOrSignUp
          handleClose={() => setOpenLoginOrSignUp(false)}
          setHeaderTitle={setLoginOrSignUpTitle}
          setupType={loginOrSignUpSetupType}
        />
      </ResponsiveDialog>
      <ResponsiveDialog
        open={openInvite}
        onClose={() => setOpenInvite(false)}
        title={"Invite"}
        width="600px"
        anchor="right"
        fullHeight={true}
        zIndex={inviteZ}
      >
        <DialogContent>
          <InviteDash />
        </DialogContent>
      </ResponsiveDialog>
      <ResponsiveDialog
        open={openInstallApp}
        onClose={() => setOpenInstallApp(false)}
        title={"Install app"}
        width="600px"
        anchor="bottom"
        fullHeight={true}
        zIndex={installAppZ}
      >
        <DialogContent>
          <InstallInstructions />
        </DialogContent>
      </ResponsiveDialog>
      <ResponsiveDialog
        open={openWalkthrough}
        onClose={() => setOpenWalkthrough(false)}
        title={"Setup Complete"}
        width="600px"
        anchor="bottom"
        fullHeight={true}
        zIndex={walkthroughZ}
      >
        <WalkthroughView
          slidesData={walkthroughSlides}
          handleClose={() => setOpenWalkthrough(false)}
        />
      </ResponsiveDialog>
      <ResponsiveDialog
        open={openFeedback}
        onClose={() => setOpenFeedback(false)}
        title="Leave feedback"
        width="500px"
        anchor="bottom"
        fullHeight={true}
        zIndex={feedbackZ}
      >
        <FeedbackForm handleClose={() => setOpenFeedback(false)} />
      </ResponsiveDialog>
      <ResponsiveDialog
        open={openMassEmailForm}
        onClose={() => setOpenMassEmailForm(false)}
        title="Mass email"
        width="500px"
        anchor="bottom"
        fullHeight={true}
        zIndex={massEmailFormZ}
      >
        {openMassEmailForm && (
          <MassEmailForm handleClose={() => setOpenMassEmailForm(false)} />
        )}
      </ResponsiveDialog>
      <ResponsiveDialog
        zIndex={2000}
        open={openCreateBooking}
        onClose={() => setOpenCreateBooking(false)}
        title={"New Job Post"}
        width="600px"
        fullHeight={true}
      >
        <CreateBooking
          requesterPrivateData={userPrivateData}
          setLoading={setCreating}
          loading={creating}
          handleClose={() => setOpenCreateBooking(false)}
        />
      </ResponsiveDialog>
      {/* Add more dialogs here as needed */}
    </DialogContext.Provider>
  );
};

export const useDialog = () => useContext(DialogContext);
