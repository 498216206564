import { Box, Skeleton } from "@mui/material";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useDialog } from "../../contexts/DialogProvider";
import { useSnackbar } from "../../contexts/SnackbarProvider";
import { addAvailability } from "../../services/windowServicesV2";
import ColHeader from "./ColHeader";
import DayColumn from "./DayColumn";
import TimeMarker from "./TimeMarker";

const ColumnView = ({
  numColumns,
  startDay = "",
  visits = {},
  userId,
  timeZone,
  truncateView = false,
  display = "days", // or "avatar"
  height = "600px",
  availability = [],
  hideAvailability = false,
  type = "self",
  onClickAvailability = () => {},
  minBookingMinutes = 30,
  bookingDaysInAdvance = 2,
  geohash6 = "",
  bounds = [],
  skills = [],
  users = [],
}) => {
  const { handleOpenVisit } = useDialog();

  const [cols, setCols] = useState([]);
  const [visitsExist, setVisitsExist] = useState(false);

  const { showSnackbar } = useSnackbar();

  let dayStart, dayEnd;

  useEffect(() => {
    if (display === "days") {
      const colsArray = [];
      for (let i = 0; i < numColumns; i++) {
        const newDate = DateTime.fromJSDate(startDay).plus({ days: i }); // Generate the new date
        colsArray.push({ date: newDate }); // Push an object with 'date' as key
      }
      setCols(colsArray);
    } else if (display === "avatar") {
      setCols(users);
    }
  }, [numColumns, startDay]);

  useEffect(() => {
    if (visits && Object.keys(visits).length > 0) {
      setVisitsExist(true);
    } else {
      setVisitsExist(false);
    }
  }, [visits]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Set the timeout to the estimated duration of your heavy computation
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 20); // Adjust this time to your needs

    // Cleanup the timeout if the component unmounts before the timeout completes
    return () => clearTimeout(timer);
  }, []);

  if (!startDay) return;

  const filterAvailabilityForDay = (luxonDate) => {
    if (!availability || Object.keys(availability).length === 0) return [];

    return Object.entries(availability).reduce(
      (filteredWindows, [key, window]) => {
        // Assuming 'window.start' and 'window.end' are Firebase Timestamps
        const windowStart = DateTime.fromJSDate(
          window?.start?.toDate()
        ).setZone(timeZone);
        const windowEnd = DateTime.fromJSDate(window?.end?.toDate()).setZone(
          timeZone
        );

        // console.log("luxonDate: ", luxonDate);

        // Check if the window falls on the same day
        if (
          windowStart.hasSame(luxonDate, "day") ||
          windowEnd.hasSame(luxonDate, "day") ||
          (windowStart.startOf("day") <= luxonDate.startOf("day") &&
            windowEnd.endOf("day") >= luxonDate.endOf("day"))
        ) {
          // console.log("on same day!");
          // Push the converted start and end luxon along with the original window data
          filteredWindows.push({
            ...window,
            start: windowStart,
            end: windowEnd,
          });
        }
        return filteredWindows;
      },
      []
    );
  };

  const handleSelectVisit = (visit) => {
    handleOpenVisit(visit.id);
  };

  let minTimeInMinutes = 24 * 60; // Latest possible time in minutes (23:59)
  let maxTimeInMinutes = 0; // Earliest possible time in minutes (00:00)

  if (truncateView && visitsExist) {
    let minTimeInMinutes = Infinity;
    let maxTimeInMinutes = -Infinity;

    cols.forEach((col) => {
      const day = col.date; // Get the date from the object
      // Convert visits object to array of its values for iteration
      Object.values(visits).forEach((visit) => {
        // Assuming visits' startTime and endTime are Firestore Timestamps
        const visitStart = DateTime.fromMillis(
          visit.startTime.toMillis()
        ).setZone(timeZone);
        const visitEnd = DateTime.fromMillis(visit.endTime.toMillis()).setZone(
          timeZone
        );

        if (visitStart.hasSame(day, "day") || visitEnd.hasSame(day, "day")) {
          const updateTimeBounds = (time) => {
            const timeMinutes = time.hour * 60 + time.minute;
            minTimeInMinutes = Math.min(minTimeInMinutes, timeMinutes);
            maxTimeInMinutes = Math.max(maxTimeInMinutes, timeMinutes);
          };

          updateTimeBounds(visitStart);
          updateTimeBounds(visitEnd);
        }
      });
    });

    let dayStart, dayEnd;
    if (minTimeInMinutes !== Infinity && maxTimeInMinutes !== -Infinity) {
      const dayStartHour = Math.floor(minTimeInMinutes / 60);
      const dayStartMinute = minTimeInMinutes % 60;
      const dayEndHour = Math.floor(maxTimeInMinutes / 60);
      const dayEndMinute = maxTimeInMinutes % 60;

      dayStart = DateTime.local()
        .set({ hour: dayStartHour, minute: dayStartMinute })
        .minus({ minutes: 15 });
      dayEnd = DateTime.local()
        .set({ hour: dayEndHour, minute: dayEndMinute })
        .plus({ minutes: 15 });
    } else {
      // Fallback to a default range if no visits are found for the days
      dayStart = DateTime.local().set({ hour: 6, minute: 0 }); // Assuming startDay is a Luxon DateTime object
      dayEnd = DateTime.local().set({ hour: 23, minute: 0 }); // Adjust as necessary based on your actual startDay value
    }
  } else {
    // Standard time range if truncateView is false or no visits exist
    dayStart = DateTime.local().set({ hour: 6, minute: 0 }); // Assuming startDay is a Luxon DateTime object
    dayEnd = DateTime.local().set({ hour: 23, minute: 0 }); // Adjust as necessary based on your actual startDay value
  }

  // funciton called when new availability slot is dragged and created
  const handleCreateAvailability = async ({ start, end }) => {
    // Return if start and end are on different days
    if (!start.hasSame(end, "day")) return;

    if (hideAvailability) return;

    // Force end time to 6 PM if it's later than that
    if (end.hour > 23) {
      end = end.set({ hour: 23, minute: 0, second: 0, millisecond: 0 });
    }

    // Force start time to 8 AM if it's earlier than that
    if (start.hour < 6) {
      start = start.set({ hour: 6, minute: 0, second: 0, millisecond: 0 });
    }

    const startOfTheWeek = DateTime.local().setZone(timeZone).startOf("week"); // This will be Sunday of the current week

    if (start < startOfTheWeek) {
      return; // Skip if start is before the start of the week
    }

    if (end.diff(start, "minutes").minutes < 30) return;

    try {
      const newAvailability = {
        start,
        end,
        // duration: end.diff(start, "minutes").minutes,
        // minBookingMinutes,
        // bookingDaysInAdvance,
        // geohash6,
        // skills,
        // bounds,
        // timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      if (userId) {
        // await addWindowLegacy(availability, newAvailability, userId);
        const { hasShortDurations } = await addAvailability({
          userId,
          newAvailability,
        });
        if (hasShortDurations) {
          showSnackbar("Skipped any slots shorter than your minimum.", "info");
        }
      }
    } catch (error) {
      console.error("Error creating availability:", error);
    }
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          display: "grid",
          gridTemplateColumns: "repeat(7, 1fr)",
          gap: 1,
          opacity: 0.1,
        }}
      >
        {Array.from(new Array(10 * 7)).map((_, index) => (
          <Skeleton variant="rounded" key={index} width="100%" height="100%" />
        ))}
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          bgcolor: "white",
          // borderRadius: "15px",
          overflow: "hidden",
          pl: { xs: "35px", sm: 6 },
          // border: "1px solid rgba(0, 0, 0, 0.12)",
          zIndex: 15,
          userSelect: "none", // Disable text selection across browsers
          WebkitTouchCallout: "none", // Disable touch callouts on iOS
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            position: "relative",
            zIndex: "10",
            overflowX: "hidden",
            pt: { xs: 0, sm: 1 },
            userSelect: "none", // Disable text selection across browsers
            WebkitTouchCallout: "none", // Disable touch callouts on iOS
          }}
        >
          {cols.map((col, index) => (
            <ColHeader key={index} date={col?.date} avatar={col?.avatarUrl} />
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            position: "relative",
            zIndex: "10",
            userSelect: "none", // Disable text selection across browsers
            WebkitTouchCallout: "none", // Disable touch callouts on iOS
          }}
        >
          <TimeMarker start={dayStart} end={dayEnd} height={height} />

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              position: "relative",
              flexGrow: 1,
              overflow: "hidden",
              userSelect: "none", // Disable text selection across browsers
              WebkitTouchCallout: "none", // Disable touch callouts on iOS
            }}
          >
            {cols.map((col, index) => (
              <DayColumn
                key={col.date ? col.date.toString() : `fallback-${index}`}
                date={col.date ? col.date : ""}
                visits={visits}
                userId={userId}
                timeZone={timeZone}
                start={dayStart}
                end={dayEnd}
                index={index}
                onSelectVisit={handleSelectVisit}
                height={height}
                availability={
                  hideAvailability
                    ? []
                    : filterAvailabilityForDay(col.date ? col.date : "")
                } // Pass the availability data unless we hide it
                onCreateAvailability={handleCreateAvailability}
                visitsExist={visitsExist}
                type={type}
                onClickAvailability={onClickAvailability}
                minBookingMinutes={minBookingMinutes}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ColumnView;
