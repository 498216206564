import {
  CalendarToday,
  Event,
  EventAvailable,
  Palette,
  Swipe,
} from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Box,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { doc, onSnapshot } from "firebase/firestore";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../contexts/AuthProvider";
import { useDialog } from "../../contexts/DialogProvider";
import { useSnackbar } from "../../contexts/SnackbarProvider";
import { useUser } from "../../contexts/UserProvider";
import { useVisits } from "../../contexts/VisitProvider";
import { db } from "../../firebase";
import { isPointInPolygon } from "../../services/locationServices";
import { formatName } from "../../services/stringServices";
import CreateBooking from "../booking/CreateBooking";
import DesktopBackButton from "../buttons/DesktopBackButton";
import HelpButton from "../buttons/HelpButton";
import WeekView from "../calendar/WeekView";
import ResponsiveDialog from "../dialogs/ResponsiveDialog";
import BookingDetails from "../profile/BookingDetails";
import NameRatingLocationDetails from "../profile/NameRatingLocationDetails";
import StyledAlert from "../styled/StyledAlert";

const BookingDash = ({ inputId }) => {
  const [openReviewForm, setOpenReviewForm] = useState(false);
  const [userData, setUserData] = useState(null);
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [selectedAvailability, setSelectedAvailability] = useState({});
  const [openCreateBooking, setOpenCreateBooking] = useState(false);
  const { showSnackbar } = useSnackbar();
  const [creating, setCreating] = useState(false);
  const { visitsData } = useVisits();
  const { handleOpenCreateProfile, handleOpenLoginOrSignUp, handleOpenChat } =
    useDialog();
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { userPublicData, userPrivateData } = useUser();

  const accountSetup =
    userPrivateData?.accountSetup === "complete" ||
    userPrivateData?.accountSetup === "show_success";

  const { id: urlId } = useParams(); // Rename destructured id to avoid naming conflict

  const inArea = isPointInPolygon(
    { lat: userPrivateData?.lat, lng: userPrivateData?.lng },
    userData?.bounds || []
  );

  // Determine the id to use, preferring URL parameter over inputId
  const id = urlId || inputId;

  const helpData = [
    {
      header: "Tap to book",
      body: "Tap on a green availablily window to book a visit during that time slot. If there are no green availability windows, this helper is unavailable.",
      icon: EventAvailable,
    },
    {
      header: "Color coding",
      body: "Your existing visits will show up in purple and the helpers availability will show up as green. If you're a helper, visits booked with you show up as blue.",
      icon: Palette,
    },
    {
      header: "Switch between weeks",
      body: "Use the left and right keyboard arrows or the buttons to navigate through different weeks on the calendar.",
      icon: Event,
    },
    {
      header: "View visit details",
      body: "Click on any scheduled visit on the calendar to see detailed information.",
      icon: CalendarToday,
    },
    {
      header: "Swipe to view more",
      body: "On mobile devices, you can swipe left or right to navigate through the calendar or lists.",
      icon: Swipe,
    },

    // Add more items as needed
  ];

  useEffect(() => {
    if (!id) return;

    setLoading(true);

    // Listen for real-time updates to userData data
    const userDataDocRef = doc(db, "usersPublic", id);
    const unsubscribeUserData = onSnapshot(
      userDataDocRef,
      (doc) => {
        if (doc.exists()) {
          setUserData({ id: doc.id, ...doc.data() });
        } else {
          console.log("No such userData!");
        }
      },
      (error) => {
        console.error("Error fetching userData: ", error);
      }
    );

    setLoading(false);

    // Cleanup function to unsubscribe from the listeners when the component unmounts
    return () => {
      unsubscribeUserData();
    };
  }, [id]);

  useEffect(() => {
    if (!userData) return;

    const getNextAvailability = () => {
      if (
        !Array.isArray(userData?.availability) ||
        userData?.availability.length === 0
      )
        return null;

      const sortedAvailability = userData?.availability.sort((a, b) => {
        const startA = a.start.toDate();
        const startB = b.start.toDate();
        return startA - startB;
      });

      const now = DateTime.now()
        .plus({
          days: userData?.bookingDaysInAdvance || 0,
        })
        .startOf("day"); // Move to the start of that day

      const nextAvailable = sortedAvailability.find((slot) => {
        const slotStartDate = DateTime.fromJSDate(slot.start.toDate());
        return slotStartDate > now;
      });

      return nextAvailable
        ? DateTime.fromJSDate(nextAvailable.start.toDate())
        : null;
    };

    const nextAvailableDate = getNextAvailability();
    if (nextAvailableDate) {
      setCalendarDate(nextAvailableDate.toJSDate());
    } else {
      setCalendarDate(null);
    }
  }, [userData?.availability, userData?.bookingDaysInAdvance]);

  console.log("calendarDate: ", calendarDate);

  // Keep the availability data fresh
  useEffect(() => {
    // Check if there is a selectedAvailability and userData?.availability is not empty
    if (selectedAvailability.id) {
      const updatedAvailability = userData?.availability.find(
        (avail) => avail.id === selectedAvailability.id
      );

      // Assuming updatedAvailability.start and updatedAvailability.end are Firestore Timestamps
      const availabilityDuration =
        updatedAvailability?.end.toMillis() -
        updatedAvailability?.start.toMillis();

      // Convert minBookingMinutes to milliseconds for comparison (min * 60 * 1000)
      const minBookingDurationMs = userData?.minBookingMinutes * 60 * 1000;

      // If the selected availability still exists in the updated data, update it
      if (updatedAvailability && availabilityDuration >= minBookingDurationMs) {
        setSelectedAvailability(updatedAvailability);
      } else {
        // If the selected availability no longer exists, reset selectedAvailability
        // This step is optional and depends on how you want to handle this case
        if (!creating) {
          // showSnackbar("The selected time is no longer available", "warning");
          setOpenCreateBooking(false); // Optionally close the booking dialog if the selected availability is no longer valid
        }

        setSelectedAvailability({});
      }
    }
  }, [userData?.availability]); // Depend on userData?.availability to trigger this effect

  const handleClickAvailability = ({ window, event }) => {
    if (!inArea && userPrivateData?.geohash) return;

    if (!currentUser || currentUser?.isAnonymous) {
      handleOpenLoginOrSignUp();
      return;
    }

    if (!accountSetup) {
      handleOpenCreateProfile();
      return;
    }

    const id = window.id;

    // Assuming userData?.availability is an array of objects
    const availability = userData?.availability.find(
      (avail) => avail.id === id
    );

    setSelectedAvailability(availability);
    setOpenCreateBooking(true);
  };

  if (!userData) return null;

  // Calculate the start of the day in the future that respects minBookingDaysInAdvance
  const minAllowedDate = DateTime.now()
    .plus({ days: userData?.bookingDaysInAdvance || 2 }) // Add the minimum days in advance
    .startOf("day"); // Move to the start of that day

  // Filter userData?.availability to include only availability starting from minAllowedDate
  const filteredAvailabilityData = Array.isArray(userData?.availability)
    ? userData?.availability.filter((availability) => {
        // Convert Firestore Timestamp to Luxon DateTime for comparison
        const availabilityStartDateTime = DateTime.fromJSDate(
          availability.start.toDate()
        );
        return availabilityStartDateTime >= minAllowedDate;
      })
    : [];

  console.log("calendarDate: ", calendarDate);
  console.log("loading: ", loading);

  return (
    <>
      {!isMobile && (
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-start",
              height: "52px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
              <DesktopBackButton />

              <Typography
                variant="h4"
                component="h1"
                sx={{ ml: { xs: 0, sm: 0 } }}
              >
                Availability
              </Typography>
            </Box>
            <HelpButton helpData={helpData} />
          </Box>
          <Divider sx={{ mx: { xs: -2, sm: 0 }, mb: 2 }} />
        </Box>
      )}
      {calendarDate === null && !loading && (
        <Box sx={{ p: 1 }}>
          <StyledAlert
            desktopTitle={"No Availability"}
            desktopDescription={`${formatName(
              userData?.firstName
            )} has no availability on the calendar. Start a chat instead!`}
            mobileTitle={`${formatName(
              userData?.firstName
            )} has no availability on the calendar. Start a chat instead!`}
            buttonText={"Chat"}
            handleClick={() => handleOpenChat([userData])}
          />
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row-reverse" },
          width: { xs: "100%", sm: "auto" },
          // height: "600px",
          position: "relative",
          gap: { xs: 1, sm: 4 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: { xs: "block", sm: "sticky" },
            top: 20,
            // height: "300px",
            backgroundColor: "white",
            border: `1px solid rgba(0,0,0,0)`,
            boxShadow:
              "0px 10px 20px rgba(0,0,0,0.1), 0px 3px 6px rgba(0,0,0,0.1)",
            flex: 0.15,
            borderRadius: "35px",
            p: 2,
            m: { xs: 2, sm: 0 },
            mt: { xs: 2, sm: 2 },
            height: "min-content",
            overflow: "hidden",
            minWidth: { xs: "unset", sm: "200px" },
            maxWidth: { xs: "unset", sm: "200px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "row", sm: "column" },
              alignItems: "center",
              gap: 2,
              width: "100%",
            }}
          >
            {!inArea && userPrivateData?.geohash && (
              <Alert severity="warning">
                This helper does not service your location
              </Alert>
            )}
            <Avatar
              src={userData?.avatarUrl}
              sx={{
                width: { xs: "100px", sm: "100%" },
                height: { xs: "unset", sm: "unset" },
                maxHeight: "200px",
                aspectRatio: "1 / 1", // Ensures the element is always square
                borderRadius: "15px",
              }}
            />
            <NameRatingLocationDetails user={userData} nameClickable={true} />
          </Box>
          {!isMobile && (
            <BookingDetails
              hourlyRate={userData?.hourlyRate}
              minBookingMinutes={userData?.minBookingMinutes}
              maxBookingMinutes={userData?.maxBookingMinutes}
            />
          )}
          {/* )} */}
        </Box>
        <Box
          sx={{
            flex: 0.85,
            minWidth: "370px",
            // display: "block",
            width: { xs: "100%", sm: "calc(80vw - 200px - 255px)" },
          }}
        >
          <WeekView
            initialDay={calendarDate ?? new Date()}
            visits={visitsData}
            userId={userPublicData?.id}
            availability={filteredAvailabilityData}
            // isMobile={false}
            // height={boxHeight - 48}
            type={"create_booking"}
            onClickAvailability={handleClickAvailability}
            minBookingMinutes={userData?.minBookingMinutes}
            bookingDaysInAdvance={userData?.bookingDaysInAdvance}
            geohash6={userData?.geohash6}
            skills={userData?.skills}
          />
        </Box>
      </Box>
      <ResponsiveDialog
        zIndex={2000}
        open={openCreateBooking}
        onClose={() => setOpenCreateBooking(false)}
        title={"New booking"}
        width="600px"
        fullHeight={true}
      >
        <CreateBooking
          availability={selectedAvailability}
          helperPublicData={userData}
          requesterPrivateData={userPrivateData}
          setLoading={setCreating}
          loading={creating}
          handleClose={() => setOpenCreateBooking(false)}
        />
      </ResponsiveDialog>
    </>
  );
};

export default BookingDash;
