import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import React from "react";
import { SKILL_LIST } from "../../services/skillServices";

const TaskTagsField = ({
  label,
  options = SKILL_LIST.map((skill) => skill.name),
  selectedTags,
  onChange,
  sx,
}) => {
  console.log("options: ", options);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    onChange(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <FormControl sx={{ width: "100%", ...sx }}>
      <InputLabel id="tag-selector-label">{label}</InputLabel>
      <Select
        labelId="tag-selector-label"
        id="tag-selector"
        multiple
        value={selectedTags}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" label={label} />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        // Assuming a generic menuProps if needed
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TaskTagsField;
