import { Box, Typography } from "@mui/material";
import Rating from "@mui/material/Rating";
import React from "react";

const RatingSummary = ({ averageRating, totalReviews, size = "normal" }) => {
  const hasReviews = totalReviews !== null && totalReviews !== undefined;

  const formattedAverageRating = hasReviews
    ? averageRating.toFixed(1) // This will convert the number to a string, rounding to 1 decimal place and always including it.
    : "No reviews";

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: { xs: "center", sm: "flex-start" },
      }}
    >
      <Typography
        variant="h6"
        sx={{
          mr: 1,
          mt: "4px",
          fontSize:
            size === "normal"
              ? "1.5rem"
              : size === "large"
              ? "1.8rem"
              : size === "small"
              ? "1.2rem"
              : "1.5rem", // Sets the font size of the filled icons
          fontWeight: "600",
        }}
      >
        {formattedAverageRating}
      </Typography>
      {hasReviews && (
        <Rating
          value={averageRating}
          precision={0.1}
          readOnly
          key={`${averageRating}-${totalReviews}`}
          sx={{
            "& .MuiRating-iconFilled": {
              fontSize:
                size === "normal"
                  ? "2rem"
                  : size === "large"
                  ? "2.5rem"
                  : size === "small"
                  ? "1.5rem"
                  : "2rem", // Sets the font size of the filled icons
            },
            "& .MuiRating-iconEmpty": {
              fontSize:
                size === "normal"
                  ? "2rem"
                  : size === "large"
                  ? "2.5rem"
                  : size === "small"
                  ? "1.5rem"
                  : "2rem", // Sets the font size of the filled icons
            },
            "& .MuiRating-iconHover": {
              fontSize:
                size === "normal"
                  ? "2rem"
                  : size === "large"
                  ? "2.5rem"
                  : size === "small"
                  ? "1.5rem"
                  : "2rem", // Sets the font size of the filled icons
            },
          }}
        />
      )}
      {totalReviews && (
        <Typography sx={{ ml: 1, color: "text.secondary", mt: "2px" }}>
          ({totalReviews} review{totalReviews > 1 ? "s" : ""})
        </Typography>
      )}
    </Box>
  );
};

export default RatingSummary;
