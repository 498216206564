import { KeyboardArrowLeft } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  DialogActions,
  DialogContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthProvider";
import { useUser } from "../../contexts/UserProvider";
import { db } from "../../firebase";
import { INTEREST_LIST } from "../../services/variableServices";
import StyledChecklist from "../styled/StyledChecklist";

const InterestsView = ({
  handleNext = () => {},
  handleBack = () => {},
  canSkip = true,
  showBack = false,
}) => {
  const [loading, setLoading] = useState(false);
  const { userPrivateData } = useUser();
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    interests: userPrivateData?.interests || "",
  });

  const { currentUser } = useAuth();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setValues({
      interests: userPrivateData?.interests || "",
    });
  }, [userPrivateData?.interests]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await setDoc(
        doc(db, "usersPublic", currentUser.uid),
        {
          interests: values.interests,
        },
        { merge: true }
      );

      await setDoc(
        doc(db, "usersPrivate", currentUser.uid),
        {
          interests: values.interests,
        },
        { merge: true }
      );

      handleNext();
    } catch (err) {
      console.log("error: ", err);
    } finally {
      setLoading(false);
    }
  };

  const handleMultipleChoiceChange = (source) => {
    setValues((prevValues) => {
      const isSelected = prevValues.interests.includes(source);
      return {
        ...prevValues,
        interests: isSelected
          ? prevValues.interests.filter((s) => s !== source)
          : [...prevValues.interests, source],
      };
    });
  };

  return (
    <>
      <DialogContent>
        {showBack && (
          <ButtonBase
            onClick={handleBack}
            disabled={loading}
            sx={{ color: "text.secondary", my: 1 }}
          >
            <KeyboardArrowLeft /> Back
          </ButtonBase>
        )}
        <Typography
          align="left"
          fontWeight={"600"}
          fontSize={"1.15rem"}
          gutterBottom
        >
          Your interests
        </Typography>
        <Typography align="left" color={"text.secondary"} sx={{ mb: 2 }}>
          Share your interests with others. These can be great conversation
          starters!
        </Typography>
        <StyledChecklist
          items={INTEREST_LIST}
          columns={isMobile ? 1 : 2}
          checkedItems={values?.interests}
          onChange={handleMultipleChoiceChange}
        />
        {errors.interests && (
          <Typography variant="caption" color="error">
            {errors.interests}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "100%",
          }}
        >
          <Button
            disableElevation
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={loading}
            sx={{
              width: "100%",
              height: "56px",
              textTransform: "none",
            }} // Take up half the available width
          >
            {loading ? ( // Conditional rendering based on loading state
              <CircularProgress size={24} color="inherit" />
            ) : (
              <Typography fontWeight={"500"}>Continue</Typography>
            )}
          </Button>

          {canSkip && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: "56px",
                alignItems: "center",
              }}
            >
              <Typography
                align="center"
                onClick={handleNext}
                fontWeight={"500"}
                sx={{ textDecoration: "underline", my: 1, cursor: "pointer" }}
              >
                Skip for now
              </Typography>
            </Box>
          )}
        </Box>
      </DialogActions>
    </>
  );
};

export default InterestsView;
