export const formatName = (str) => {
  if (typeof str !== "string" || !str) {
    // Return the original input if it's not a string or is falsy (empty, null, undefined)
    return str;
  }

  return str
    .split(" ")
    .map((word) =>
      word ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : ""
    )
    .join(" ");
};

export const trimLowercaseString = (str) => {
  if (typeof str !== "string" || !str) {
    // Return the original input if it's not a string or is falsy (empty, null, undefined)
    return str;
  }

  return str.trim().toLowerCase();
};