import { Box, Button, useTheme } from "@mui/material";
import React, { useEffect } from "react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/keyboard";
import "swiper/css/mousewheel";
import "swiper/css/navigation";

import { History } from "@mui/icons-material";
import { useVisits } from "../../contexts/VisitProvider";
import VisitListItem from "./VisitListItem";

const VisitViewer = () => {
  const {
    fetchInitialVisits,
    loadFutureVisits,
    loadPastVisits,
    visits,
    hasMorePastVisits,
    hasMoreFutureVisits,
  } = useVisits();

  const theme = useTheme();

  // const swiperRef = useRef(null);

  // useEffect(() => {
  //   const swiper = swiperRef.current.swiper;
  //   if (swiper) {
  //     swiper.update();
  //   }
  // }, [visits]);

  return (
    <>
      <Box sx={{ maxWidth: 800, mx: "auto", gap: 2, flexDirection: "column", display: "flex" }}>
        {/* <Swiper
        ref={swiperRef}
        direction="vertical"
        slidesPerView={5}
        modules={[Mousewheel, Keyboard]}
        mousewheel={true}
        keyboard={{
          enabled: true,
        }}
        style={{ maxHeight: "100%" }}
      > */}
        {hasMorePastVisits && (
          <Button
            onClick={loadPastVisits}
            fullWidth
            variant="text"
            startIcon={<History />}
            sx={{
              color: theme.palette.grey[500],
              height: { xs: "60px", sm: "56px" },
              textTransform: "none",
            }}
          >
            Load previous
          </Button>
        )}
        {visits.map((visit, index) => (
          // <SwiperSlide
          //   key={index}
          //   style={{ maxHeight: "83px" }}
          // >
          <VisitListItem visit={visit} />
          // </SwiperSlide>
        ))}
        {/* </Swiper> */}
        {hasMoreFutureVisits && (
          <Button
            onClick={loadFutureVisits}
            fullWidth
            variant="text"
            startIcon={<History />}
            sx={{
              color: theme.palette.grey[500],
              height: { xs: "60px", sm: "56px" },
              textTransform: "none",
            }}
          >
            Load future
          </Button>
        )}
      </Box>
    </>
  );
};

export default VisitViewer;
