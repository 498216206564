/* global google */

import { Draw, NearMe } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { douglasPeucker } from "../../services/mapServices";

function GoogleMapsDrawing({
  initialBounds,
  initialCenter,
  onBoundsChange = () => {},
}) {
  const mapRef = useRef(null);
  const map = useRef(null);
  const poly = useRef(null);
  const drawing = useRef(null);

  const [polygonBounds, setPolygonBounds] = useState([]);
  const [locating, setLocating] = useState(false);
  const [userLocationMarker, setUserLocationMarker] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const clearCurrentDrawing = () => {
    if (drawing.current) {
      drawing.current.setMap(null);
      drawing.current = null;
    }
  };

  const disableMapOptions = () => {
    if (map.current) {
      map.current.setOptions({
        draggable: false,
        zoomControl: false,
        scrollwheel: false,
        gestureHandling: "none", // Disable gesture handling when drawing
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        clickableIcons: false, // Disable clicks on POIs
        disableDoubleClickZoom: true,
      });
    }
  };

  const enableMapOptions = () => {
    if (map.current) {
      map.current.setOptions({
        draggable: true,
        zoomControl: true,
        scrollwheel: true,
        fullscreenControl: false,
        streetViewControl: false,
        clickableIcons: false, // Disable clicks on POIs
        disableDoubleClickZoom: true,
        gestureHandling: "auto", // Re-enable gesture handling after drawing
      });
    }
  };

  const navigateToCurrentLocation = () => {
    setLocating(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        map.current.setCenter(pos);
        map.current.setZoom(12); // You can adjust the zoom level as needed
        setLocating(false);
      });
    }
  };

  const getUserLocation = () => {
    setLocating(true); // Start locating process

    if (navigator.geolocation && !userLocationMarker) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          // Clear existing marker if it exists
          if (userLocationMarker) {
            userLocationMarker.setMap(null);
          }

          // Create a new marker at the current location
          const marker = new google.maps.Marker({
            position: pos,
            map: map.current,
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              fillColor: "#4285F4",
              fillOpacity: 1,
              scale: 8,
              strokeColor: "white",
              strokeWeight: 2,
            },
          });
          setUserLocationMarker(marker);
          setLocating(false); // Successfully located, stop locating
        },
        () => {
          setLocating(false); // Failed to locate, stop locating
        }
      );
    } else {
      setLocating(false); // Geolocation is not supported by the browser
    }
  };

  useEffect(() => {
    if (map.current && initialBounds?.length > 0) {
      const bounds = new google.maps.LatLngBounds();
      initialBounds.forEach((point) => {
        bounds.extend(new google.maps.LatLng(point.lat, point.lng));
      });

      map.current.fitBounds(bounds);
      initializePolygonWithBounds(initialBounds);
      const listener = map.current.addListener("bounds_changed", () => {
        const currentZoom = map.current.getZoom();
        map.current.setZoom(currentZoom); // Optionally adjust the zoom level here
        google.maps.event.removeListener(listener);
      });
    } else if (map.current && initialCenter.lat && initialCenter.lng) {
      map.current.setCenter(
        new google.maps.LatLng(initialCenter.lat, initialCenter.lng)
      );
    }
  }, [initialBounds, initialCenter]);

  useEffect(() => {
    if (map.current && polygonBounds?.length > 0) {
      const bounds = new google.maps.LatLngBounds();
      polygonBounds.forEach((point) => {
        bounds.extend(new google.maps.LatLng(point.lat, point.lng));
      });

      map.current.fitBounds(bounds);
      initializePolygonWithBounds(polygonBounds);
      const listener = map.current.addListener("bounds_changed", () => {
        const currentZoom = map.current.getZoom();
        map.current.setZoom(currentZoom); // Optionally adjust the zoom level here
        google.maps.event.removeListener(listener);
      });
    }
  }, [polygonBounds]);

  const initializePolygonWithBounds = (bounds) => {
    // Clear existing polygon if it exists
    // setPolygonBounds([]);
    if (poly.current) {
      poly.current.setMap(null);
      poly.current = null;
    }

    const path = bounds.map(
      (point) => new google.maps.LatLng(point.lat, point.lng)
    );
    poly.current = new google.maps.Polygon({
      map: map.current,
      paths: path,
      clickable: false,
    });

    // setPolygonBounds(bounds); // Set the initial state for polygon bounds
  };

  const drawFreeHand = (event) => {
    event.preventDefault();
    clearCurrentDrawing(); // Clear any existing drawing
    drawing.current = new google.maps.Polyline({
      map: map.current,
      clickable: false,
    });

    const moveHandler = (e) => {
      if (poly.current) {
        poly.current.setMap(null);
        poly.current = null;
      }

      const latLng =
        e.latLng ||
        map.current
          .getProjection()
          .fromPointToLatLng(
            new google.maps.Point(e.touches[0].clientX, e.touches[0].clientY)
          );
      drawing.current.getPath().push(latLng);
    };

    map.current.addListener("mousemove", moveHandler);
    map.current.addListener("touchmove", moveHandler);

    const endDrawing = () => {
      google.maps.event.clearListeners(map.current, "mousemove");
      google.maps.event.clearListeners(map.current, "touchmove");

      const path = drawing.current.getPath();
      drawing.current.setMap(null);

      // Convert path to array and apply Douglas-Peucker simplification
      const simplifiedPath = douglasPeucker(
        path.getArray().map((p) => ({ lat: p.lat(), lng: p.lng() })),
        0.001
      );

      // Update the polygon bounds state with the simplified path coordinates
      const pathBounds = simplifiedPath.map((p) => ({
        lat: p?.lat,
        lng: p?.lng,
      }));

      console.log("pathBounds: ", pathBounds);

      if (pathBounds?.length > 2) {
        setPolygonBounds(pathBounds);
        onBoundsChange(pathBounds);
      }

      // Clear the drawing listeners from the map
      enableMapOptions();
    };

    map.current.addListener("mouseup", endDrawing);
    map.current.addListener("touchend", endDrawing);
  };

  const initialize = () => {
    const center = new google.maps.LatLng(
      initialCenter.lat || 48.47,
      initialCenter.lng || -122.7
    );
    const mapOptions = {
      zoom: 12,
      center: center,
      streetViewControl: false,
      fullscreenControl: false,
      mapTypeControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };
    map.current = new google.maps.Map(mapRef.current, mapOptions);
  };

  useEffect(() => {
    initialize();
    getUserLocation();
  }, []);

  return (
    <div>
      <Box
        sx={{
          borderRadius: { xs: 0, sm: "15px" },
          overflow: "hidden",
          // border: "1px solid rgba(0, 0, 0, 0.12)",
          position: "relative",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <div ref={mapRef} style={{ height: "350px", width: "100%" }}></div>
        <Button
          variant="contained"
          color="inherit"
          sx={{
            bgcolor: "white",
            borderRadius: "5px",
            fill: "#f5f5f5",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
            width: "auto",
            position: "absolute",
            top: 0,
            left: 0,
            m: { xs: 1, sm: 2 },
            boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px",
            height: "42px",
            justifyContent: "center",
            pr: { xs: 0, sm: "15px" },
            pl: { xs: 0, sm: "10px" },
            textTransform: "none",
          }}
          onClick={() => {
            disableMapOptions();
            // Clear the existing polygon if it exists
            // Reset the polygon bounds state
            setPolygonBounds([]);

            if (drawing.current) {
              drawing.current.setMap(null);
              drawing.current = null;
            }

            // Now attach the event listeners to start a new drawing session
            mapRef.current.addEventListener("mousedown", drawFreeHand, {
              passive: false,
            });
            mapRef.current.addEventListener("touchstart", drawFreeHand, {
              passive: false,
            });
          }}
        >
          <Draw color="primary" />
          {!isMobile && <Typography color="black">Draw</Typography>}
        </Button>
        <Button
          variant="contained"
          color="inherit"
          sx={{
            bgcolor: "white",
            borderRadius: "5px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
            position: "absolute",
            top: 50,
            left: 0, // Adjust based on the size of the first button
            m: { xs: 1, sm: 2 },
            boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px",
            height: "42px",
            justifyContent: "center",
            pr: { xs: 0, sm: "15px" },
            pl: { xs: 0, sm: "10px" },
            textTransform: "none",
          }}
          onClick={navigateToCurrentLocation}
        >
          {locating ? (
            <>
              <CircularProgress size={24} thickness={6} color={"inherit"} />
            </>
          ) : (
            <>
              <NearMe color="primary" />
            </>
          )}
        </Button>
      </Box>
    </div>
  );
}

export default GoogleMapsDrawing;
