import {
  KeyboardArrowLeft,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import {
  EmailAuthProvider,
  createUserWithEmailAndPassword,
  linkWithCredential,
} from "firebase/auth";
import { Timestamp, doc, setDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db, functions } from "../../firebase";
import { validateIdentity } from "../../services/formServices";
import NameField from "../fields/NameField";

const SignUpView = ({ setView, email, showBack = false }) => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    birthday: null,
    gender: "", // Add this line for gender
    password: "",
  });

  const navigate = useNavigate();

  const handleDateChange = (newDate) => {
    // Update the 'birthday' in your state

    setErrors((prevErrors) => ({
      ...prevErrors,
      birthday: "", // Clear any previous error
    }));

    // Make sure to handle the newDate format correctly as per your requirements
    setValues((prevValues) => ({
      ...prevValues,
      birthday: newDate, // Assuming newDate is in the format you need; otherwise, you might need to format it
    }));
  };

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;

    const actualValue = type === "checkbox" ? checked : value;

    setValues((prevValues) => ({ ...prevValues, [name]: actualValue }));

    // Clear error for the field
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
    }
  };

  // Only call once the user is already logged in
  const countReferral = async (uid) => {
    const query = new URLSearchParams(window.location.search);
    let referralParam = query.get("ref");

    // If referralParam is not found in URL, check local storage
    if (!referralParam) {
      referralParam = localStorage.getItem("referrerId");
      if (!referralParam) {
        return; // Exit if there's no referralParam in both URL and local storage
      }
    }

    try {
      // Create/update a document in usersPrivate collection with the referrer.id
      const privateDocRef = doc(db, "usersPrivate", uid); // Use UID for document ID
      await setDoc(
        privateDocRef,
        { referrerId: referralParam },
        { merge: true }
      );

      // Reference the Cloud Function
      const incrementReferralCount = httpsCallable(
        functions,
        "incrementReferralCount"
      );
      // fire and forget
      incrementReferralCount({ referralId: referralParam }).catch((error) => {
        console.error("Error incrementing referral count:", error);
      });
    } catch (error) {
      console.error("Error processing referral:", error);
    }
  };

  const formatName = (name) => name.trim().toLowerCase();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    let newError = validateIdentity(values);
    if (Object.keys(newError).length > 0) {
      setErrors(newError);
      setLoading(false); // Ensure to stop the loading process here if there are validation errors
      return; // Exit the function if there are errors
    }

    let userCredential;

    try {
      // Check if currently signed-in user is anonymous
      if (auth.currentUser && auth.currentUser?.isAnonymous) {
        // Create an email/password credential
        const credential = EmailAuthProvider.credential(email, values.password);
        // Link the anonymous account to the email/password credential
        userCredential = await linkWithCredential(auth.currentUser, credential);
      } else {
        userCredential = await createUserWithEmailAndPassword(
          auth,
          email || "asdf",
          values.password || "asdf"
        );
      }

      // Common logic for after account creation or linking
      const uid = userCredential.user.uid;

      // Create a new document in usersPublic collection with createdAt timestamp
      await setDoc(
        doc(db, "usersPublic", uid),
        {
          id: uid,
          createdAt: Timestamp.now(),
          totalVisitsReceived: 0,
          appInstalled: false,
          accountSetup: "in_progress",
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          isHelper: false,
          firstName: values.firstName
            ? formatName(values.firstName)
            : undefined,
          lastName: values.lastName ? formatName(values.lastName) : undefined,
          birthMonth: values.birthday
            ? values.birthday.toFormat("yyyy-MM")
            : undefined,
          gender: values.gender,
        },
        { merge: true }
      );

      await setDoc(
        doc(db, "usersPrivate", uid),
        {
          id: uid,
          createdAt: Timestamp.now(),   
          // totalVisitsReceived: 0, //  we only track this in public because that's what our stat counter does
          appInstalled: false,       
          accountSetup: "in_progress",
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          isHelper: false,
          firstName: values.firstName
            ? formatName(values.firstName)
            : undefined,
          lastName: values.lastName ? formatName(values.lastName) : undefined,
          birthday: values.birthday
            ? values.birthday.toFormat("yyyy-MM-dd")
            : undefined,
          birthMonth: values.birthday
            ? values.birthday.toFormat("yyyy-MM")
            : undefined,
          gender: values.gender,
          email,
        },
        { merge: true }
      );

      // Pass the UID to countReferral
      await countReferral(uid);

      setView("WELCOME");
    } catch (err) {
      switch (err.code) {
        case "auth/invalid-email":
          newError.email = "The email address is badly formatted.";
          break;
        case "auth/email-already-in-use":
          newError.email =
            "The email address is already in use by another account.";
          break;
        case "auth/weak-password":
          newError.password = "The password must be 6 characters long or more.";
          break;
        default:
          newError.general =
            "An unknown error occurred. Please try again later.";
          break;
      }
      setErrors(newError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DialogContent>
        {showBack && (
          <ButtonBase
            onClick={() => setView("EMAIL")}
            disabled={loading}
            sx={{ color: "text.secondary", mt: 1, mb: 2 }}
          >
            <KeyboardArrowLeft /> Back
          </ButtonBase>
        )}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexDirection: { xs: "row", sm: "row" },
            }}
          >
            <NameField
              name={"firstName"}
              value={values?.firstName}
              error={errors.firstName}
              handleChange={handleChange}
              label={"First Name"}
            />
            <NameField
              name={"lastName"}
              value={values?.lastName}
              error={errors.lastName}
              handleChange={handleChange}
              label={"Last Name"}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <DatePicker
              sx={{ width: "100%" }}
              label={"Birthday"}
              name="birthday"
              views={["year", "month", "day"]}
              value={values?.birthday} // Use the state value here
              onChange={handleDateChange} // Update the state when the date changes
            />
            <FormHelperText
              id="birth-month-error"
              error={errors.birthday}
              sx={{ ml: "14px" }}
            >
              {errors.birthday
                ? errors.birthday
                : "To sign up, you must be at least 18. Only your birth month and year are shared."}
            </FormHelperText>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <FormControl
              sx={{ width: "100%" }}
              onKeyDown={(e) => {
                if (e.key === "m") {
                  e.preventDefault(); // Prevents the default behavior of the key press
                  handleChange({
                    target: {
                      name: "gender",
                      value: "male",
                    },
                  });
                } else if (e.key === "f") {
                  e.preventDefault();
                  handleChange({
                    target: {
                      name: "gender",
                      value: "female",
                    },
                  });
                }
              }}
            >
              <InputLabel id="gender-label">Gender</InputLabel>
              <Select
                labelId="gender-label"
                name="gender"
                label="Gender"
                value={values?.gender}
                onChange={handleChange}
                error={!!errors.gender}
              >
                <MenuItem value={"female"}>Female</MenuItem>
                <MenuItem value={"male"}>Male</MenuItem>
              </Select>
              {errors.gender && (
                <Typography
                  variant="caption"
                  color="error"
                  sx={{ ml: "14px", mt: "3px" }}
                >
                  {errors.gender}
                </Typography>
              )}
            </FormControl>
          </Box>
          <TextField
            fullWidth
            label="Password"
            variant="outlined"
            name="password"
            type={showPassword ? "text" : "password"}
            value={values.password}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((prevState) => !prevState)} // Inline arrow function here
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={!!errors.password}
            helperText={errors.password}
          />
          <Typography align="left" variant="caption" color={"text.secondary"}>
            <span>
              By selecting <strong>Agree and continue</strong>, I agree to
              TaskMom's{" "}
              <a
                href="/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "black" }} // Added inline style here
              >
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                href="/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "black" }} // Added inline style here
              >
                Privacy Policy
              </a>
              .
            </span>
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disableElevation
          disabled={loading}
          sx={{
            width: "100%",
            height: "56px",
            textTransform: "none",
          }} // Take up half the available width
        >
          {loading ? ( // Conditional rendering based on loading state
            <CircularProgress size={24} color="inherit" />
          ) : (
            <Typography fontWeight={"500"}>Agree and continue</Typography>
          )}
        </Button>
      </DialogActions>
    </>
  );
};

export default SignUpView;
