// src/contexts/AuthContext.js
import { onAuthStateChanged } from "firebase/auth";
import React, { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../firebase";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const storeReferralInLocalStorage = () => {
    const query = new URLSearchParams(window.location.search);
    const referralParam = query.get("ref");

    if (referralParam) {
      localStorage.setItem("referrerId", referralParam);
    } else {
    }
  };

  useEffect(() => {
    storeReferralInLocalStorage();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
