// EmailCheckView.js
import { keyframes } from "@emotion/react";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import { fetchSignInMethodsForEmail, getAuth } from "firebase/auth";
import React, { useState } from "react";

const fadeInMoveDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const EmailView = ({ setView, email, setEmail = () => {} }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    email,
  });

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;

    const actualValue = type === "checkbox" ? checked : value;

    setValues((prevValues) => ({ ...prevValues, [name]: actualValue }));
    setEmail(value);
    // Clear error for the field
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // This prevents the form from submitting in the traditional way

    if (!values.email) {
      setErrors({ email: "Please enter your email." });
      return;
    }

    setLoading(true);

    const auth = getAuth();
    const newError = {}; // initialize newError object here

    try {
      const signInMethods = await fetchSignInMethodsForEmail(
        auth,
        values.email
      );
      if (signInMethods.length === 0) {
        // No sign-in methods returned, email does not exist in Auth system
        console.log("Email does not exist. Redirecting to sign up.");
        // Proceed to SignUp view
        setView("SIGNUP");
      } else {
        // Sign-in methods found, email exists
        console.log("Email exists. Redirecting to login.");
        setView("LOGIN");
      }
    } catch (error) {
      console.log(error.code);
      switch (error.code) {
        case "auth/missing-password":
          newError.password = "You must enter your password.";
          break;
        case "auth/invalid-email":
          newError.email = "The email address is badly formatted.";
          break;
        case "auth/user-not-found":
          newError.email = "There is no user with this email.";
          break;
        case "auth/wrong-password":
          newError.password = "The password is invalid.";
          break;
        default:
          newError.general =
            "An unknown error occurred. Please try again later.";
          break;
      }
      setErrors(newError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DialogContent>
        <Typography variant="h6" fontWeight={"500"} sx={{ mb: 2 }}>
          Welcome to TaskMom
        </Typography>
        <TextField
          fullWidth
          label="Email"
          variant="outlined"
          name="email"
          type="email"
          value={values.email}
          onChange={handleChange}
          error={!!errors.email}
          helperText={
            errors.email
              ? errors.email
              : "Your email will be your username for sign-in purposes."
          }
        />
        <div style={{ display: "flex", flexGrow: 1 }} />
      </DialogContent>
      <DialogActions>
        <Button
          // type="submit" // Changed to type submit to trigger form submission
          onClick={handleSubmit}
          variant="contained"
          disableElevation
          disabled={loading}
          fullWidth
          sx={{ height: "56px", textTransform: "none" }}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <Typography fontWeight={"500"}>Continue</Typography>
          )}
        </Button>
      </DialogActions>
    </>
  );
};

export default EmailView;
