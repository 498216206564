import { useTheme } from "@emotion/react";
import { Check } from "@mui/icons-material";
import { Box, Button, Divider, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useNotifications } from "../../contexts/NotificationsProvider";
import NotificationsList from "./NotificationsList";

const NotificationsContent = ({ notifications, onClose }) => {
  const { markAllRead } = useNotifications();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleMarkAllRead = () => {
    markAllRead();
    onClose(); // Assuming onClose is a function you've defined to close the notification UI
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          p: { xs: 0, sm: 2 },
          justifyContent: "space-between",
        }}
      >
        {!isMobile && <Typography variant="h6">Notifications</Typography>}
        <Button
          startIcon={<Check />}
          variant="text"
          disabled={notifications.length === 0}
          sx={{
            textTransform: "none",
            width: { xs: "100%", sm: "auto" },
            height: { xs: "56px", sm: "auto" },
            borderBottom: { xs: "1px solid rgba(0,0,0,0.15)", sm: "none" }, // Slightly more pronounced shadow on hover
            borderRadius: "0px",
          }}
          onClick={handleMarkAllRead}
        >
          Mark All Read
        </Button>
      </Box>
      {!isMobile && (
        <Divider sx={{ position: "absolute", left: 0, right: 0 }} />
      )}
      <NotificationsList notifications={notifications} onClose={onClose} />
    </>
  );
};

export default NotificationsContent;
