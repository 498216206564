import { useTheme } from "@emotion/react";
import { Check, PersonAdd, ShareOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { useDialog } from "../../contexts/DialogProvider";
import AvailabilityDayDetails from "../profile/AvailabilityDayDetails";
import NameRatingLocationDetails from "../profile/NameRatingLocationDetails";
import RateAvailabilityDetails from "../profile/RateAvailabilityDetails";
import SkillBubbles from "../profile/SkillBubbles";

function HelperDiscoverCard({ helper = {}, keySkill = {} }) {
  const {
    avatarUrl = "",
    firstName,
    lastName,
    bio,
    skills,
    hourlyRate,
    city,
    state,
    zipCode,
    availability = [],
    id,
    totalReviews,
    averageRating,
  } = helper;

  const { handleOpenProfile, handleOpenInvite } = useDialog();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const firstNonZeroAvailability =
    ["threeDay", "thisWeek", "nextWeek"].find(
      (key) => availability[key] !== 0
    ) || "none";

  const availabilityMessages = {
    threeDay: { label: "within 3 days", color: "success" },
    thisWeek: { label: "this week", color: "info" },
    nextWeek: { label: "next week", color: "warning" },
    none: { label: "limited", color: "error" },
  };

  const handleClick = () => {
    if (Object.keys(helper).length > 0) {
      handleOpenProfile(id);
    } else {
      handleOpenInvite();
    }
  };

  return (
    <ListItemButton
      sx={{
        textTransform: "none",
        display: "flex",
        flexDirection: "column",
        borderRadius: { xs: "22px", sm: "30px" },
        width: { xs: "auto", sm: "100%" },
        height: "100%",
        // overflow: "hidden",
        mx: { xs: -1, sm: 0 },
        p: { xs: 1, sm: 2 },
        color: "black",
        backgroundColor: "white",
        boxSizing: "border-box",
        border: "1px solid rgba(0, 0, 0, 0)",
        transition: "box-shadow, border, 0.15s ease-in-out", // Smooth transition for shadow
        boxShadow: "none", // Drop shadow when open
        "&:hover": {
          border: isMobile
            ? "1px solid rgba(0, 0, 0, 0)"
            : "1px solid rgba(0, 0, 0, 0.12)",
          backgroundColor: "white",
          boxShadow: isMobile ? "none" : "0 5px 10px 0 rgba(0,0,0,0.15)", // Slightly more pronounced shadow on hover
        },
        "&:focus-visible, &:focus": {
          backgroundColor: "transparent", // Override for focus-visible state
          outline: "none", // Optional: Remove focus outline if visually distracting
        },
        "&:active": {
          backgroundColor: "transparent", // Consistent with other states
          outline: "none", // Optional: Remove focus outline if visually distracting
        },
        "&.Mui-focusVisible": {
          backgroundColor: "transparent", // Explicitly targeting the class that might be causing the issue
          outline: "none", // Optional: Remove focus outline if visually distracting
        },
      }}
      onClick={handleClick}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1,
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        {Object.keys(helper).length > 0 ? (
          <Avatar
            alt={`${firstName} ${lastName}`}
            src={avatarUrl}
            sx={{
              width: { xs: "50%", sm: "100%" },
              mx: "0",
              height: { xs: "unset", sm: "unset" },
              aspectRatio: "1 / 1", // Ensures the element is always square
              borderRadius: "15px",
            }}
          />
        ) : (
          <Box
            sx={{
              width: { xs: "50%", sm: "100%" },
              mx: "0",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: { xs: "unset", sm: "unset" },
              aspectRatio: "1 / 1", // Ensures the element is always square
              borderRadius: "15px",
              backgroundColor: "rgba(0, 0, 0, 0.03)",
              // border: "4px solid rgba(0, 0, 0, 0.06)",
              boxSizing: "border-box",
            }}
          >
            <PersonAdd sx={{ fontSize: 100, color: "lightgray" }} />
          </Box>
        )}
        <Box
          sx={{
            maxWidth: { xs: "350px", sm: "unset" },
            mx: "auto",
            width: "100%",
          }}
        >
          {Object.keys(helper).length > 0 ? (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                gap: 0.5,
              }}
            >
              <NameRatingLocationDetails user={helper} />
              <RateAvailabilityDetails user={helper} showIcon={false} />
              <SkillBubbles skills={helper.skills} keySkill={keySkill} />
              <AvailabilityDayDetails availability={helper.availability} />
            </Box>
          ) : (
            <>
              <Typography
                sx={{
                  fontSize: { xs: "1rem", sm: "0.8rem" },
                  mx: 0,
                  width: "max-content",
                }}
                fontWeight={"600"}
              >
                {keySkill.question || "Know a great helper?"}
              </Typography>
              <Typography
                sx={{
                  mx: 0,
                  width: "max-content",
                  fontSize: { xs: "1.15rem", sm: "0.9rem" },
                }}
              >
                Invite them to join
              </Typography>

              <List sx={{ mx: 0, width: "max-content" }}>
                {[
                  "More work opportunities",
                  "Extra income on their time",
                  "Reach people in the area",
                ].map((reason, index) => (
                  <ListItem key={index} disablePadding>
                    <ListItemIcon sx={{ minWidth: "32px" }}>
                      <Check fontSize="small" color="disabled" />
                    </ListItemIcon>
                    <Typography variant="caption" color={"text.secondary"}>
                      {reason}
                    </Typography>
                  </ListItem>
                ))}
              </List>
              <Button
                startIcon={<ShareOutlined />}
                fullWidth
                variant="outlined"
                sx={{ textTransform: "none", height: "56px" }}
              >
                Invite
              </Button>
            </>
          )}
        </Box>
      </Box>
    </ListItemButton>
  );
}

export default HelperDiscoverCard;
