import { Alert } from "@mui/material";
import { DateTime } from "luxon";
import React from "react";

const DateChip = ({ date }) => {
  // Convert Firebase Timestamp to Luxon DateTime in the local timezone if necessary
  // Assuming 'date' is either a Luxon DateTime object or a Firebase Timestamp
  const normalizedDate = date.toMillis
    ? DateTime.fromMillis(date.toMillis()).setZone("local") // Ensure local timezone
    : DateTime.fromJSDate(date).setZone("local"); // Convert JS Date to Luxon DateTime in local timezone if needed

  const now = DateTime.now(); // Already in local timezone
  const diff = normalizedDate
    .diff(now.startOf("day"), ["days", "weeks"])
    .toObject();

  // Determine the label based on the difference
  let label;

  if (diff.days <= 0 ) {
    return;
  }

  if (diff.days < 1) {
    label = "Today";
  } else if (diff.days < 2) {
    label = "Tomorrow";
  } else if (diff.days < 7) {
    label = `In ${Math.round(diff.days)} days`;
  } else {
    label = `In ${Math.round(diff.weeks)} weeks`;
  }

  return (
    <Alert
      severity="info"
      icon={false}
      sx={{
        padding: "0px 5px",
        margin: 0,
        width: "max-content",
        borderRadius: "6px",
        justifyContent: "center",
        "& .MuiAlert-message": {
          // Target the message part specifically
          padding: "4px", // Adjust this value as needed
        },
      }}
    >
      {label}
    </Alert>
  );
};

export default DateChip;
