import { useTheme } from "@emotion/react";
import {
  AccountCircle,
  AccountCircleOutlined,
  ChecklistOutlined,
  DownloadOutlined,
  HelpOutline,
  KeyboardArrowRight,
  Login,
  Logout,
  Mail,
  PersonAddOutlined,
  ReviewsOutlined,
  ThumbUpOffAltOutlined,
  WavingHandOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { getAuth, signOut } from "firebase/auth";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthProvider";
import { useDashboard } from "../../contexts/DashboardContext";
import { useDialog } from "../../contexts/DialogProvider";
import { useUser } from "../../contexts/UserProvider";
import { formatName } from "../../services/stringServices";
import SingleLineFooter from "../footer/SingleLineFooter";

const UserProfileCard = ({ onClose = () => {} }) => {
  // Destructuring user data for easy access
  const { userPrivateData } = useUser();
  const { currentUser } = useAuth();
  const { changeTab, inStandaloneMode } = useDashboard();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    handleOpenPersonalInfo,
    handleOpenCreateProfile,
    handleOpenHelperProfile,
    handleOpenInvite,
    handleOpenFAQ,
    handleOpenLoginOrSignUp,
    handleOpenLanding,
    handleOpenFeedback,
    handleOpenInstallApp,
    handleOpenMassEmailForm,
  } = useDialog();
  const { avatarUrl, firstName, lastName, phone } = userPrivateData || {};

  const { email = "" } = currentUser || {};

  const navigate = useNavigate();

  const handleViewPersonalInfo = () => {
    handleOpenPersonalInfo();
    onClose();
  };

  const handleViewHelperProfile = () => {
    handleOpenHelperProfile();
    onClose();
  };

  const handleViewInvite = () => {
    handleOpenInvite();
    onClose();
  };

  const accountSetup =
    userPrivateData?.accountSetup === "complete" ||
    userPrivateData?.accountSetup === "show_success";

  const helperSetup =
    userPrivateData?.helperSetup === "complete" ||
    userPrivateData?.helperSetup === "show_success";

  const formattedName =
    userPrivateData?.firstName && userPrivateData?.lastName
      ? `${formatName(firstName)} ${formatName(lastName)}`
      : "Guest User";

  const handleLogOut = async () => {
    const auth = getAuth();
    console.log(auth.currentUser);
    try {
      navigate("/");
      await signOut(auth);
      console.log("User signed out successfully");
      // You can also set any state or dispatch any actions here if you're using Redux or React Context for global state management.
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const handleBecome = () => {
    if (!currentUser || currentUser?.isAnonymous) {
      handleOpenLoginOrSignUp({ setupType: "helper" });
      onClose();
      return;
    } else {
      handleOpenCreateProfile({ setupType: "helper", canSkip: true });
      onClose();
    }
  };

  return (
    <>
      <Box
        sx={{
          m: 2,
          display: "flex",
          width: { xs: "auto", sm: "300px" },
          boxSizing: "border-box",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar src={avatarUrl} sx={{ width: 96, height: 96, mb: 1 }} />
        <Typography variant="h6">{formattedName}</Typography>
        <Typography variant="body1">{email}</Typography>
        <Typography variant="body2" color="text.secondary">
          {phone}
        </Typography>
      </Box>
      <Divider sx={{ my: 1 }} />
      {(!currentUser || currentUser?.isAnonymous) && (
        <>
          <ListItemButton
            onClick={() => {
              handleOpenLoginOrSignUp();
              onClose();
            }}
          >
            <ListItemIcon>
              <AccountCircle />
            </ListItemIcon>
            <ListItemText primary={<strong>Sign up</strong>} />
            <KeyboardArrowRight
              color="disabled"
              style={{ marginLeft: "auto" }}
            />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              handleOpenLoginOrSignUp();
              onClose();
            }}
          >
            <ListItemIcon>
              <Login />
            </ListItemIcon>
            <ListItemText primary="Login" />
            <KeyboardArrowRight
              color="disabled"
              style={{ marginLeft: "auto" }}
            />
          </ListItemButton>
        </>
      )}
      {!accountSetup && currentUser && !currentUser?.isAnonymous && (
        <ListItemButton
          onClick={() => {
            handleOpenCreateProfile({ canSkip: true });
            onClose();
          }}
        >
          <ListItemIcon>
            <Badge color="error" variant="dot">
              <ChecklistOutlined />
            </Badge>
          </ListItemIcon>
          <ListItemText primary="Complete Profile" />
          <KeyboardArrowRight color="disabled" style={{ marginLeft: "auto" }} />
        </ListItemButton>
      )}
      {currentUser && !currentUser?.isAnonymous && (
        <ListItemButton onClick={handleViewPersonalInfo}>
          <ListItemIcon>
            <AccountCircleOutlined />
          </ListItemIcon>
          <ListItemText primary="My profile" />
          <KeyboardArrowRight color="disabled" style={{ marginLeft: "auto" }} />
        </ListItemButton>
      )}
      {helperSetup && (
        <ListItemButton onClick={handleViewHelperProfile}>
          <ListItemIcon>
            <WavingHandOutlined />
          </ListItemIcon>
          <ListItemText primary="Helper Preferences" />
          <KeyboardArrowRight color="disabled" style={{ marginLeft: "auto" }} />
        </ListItemButton>
      )}
      {currentUser && (
        <ListItemButton onClick={handleViewInvite}>
          <ListItemIcon>
            <PersonAddOutlined />
          </ListItemIcon>
          <ListItemText primary="Invite Others" />
          <KeyboardArrowRight color="disabled" style={{ marginLeft: "auto" }} />
        </ListItemButton>
      )}
      {(currentUser?.uid === "Qlogr7qFCFfbErNzxbkk0hY9wzl2" ||
        currentUser?.uid === "1A0pQL713PMQVswqW6Vr8InNnA8M") && (
        <ListItemButton onClick={handleOpenMassEmailForm}>
          <ListItemIcon>
            <Mail />
          </ListItemIcon>
          <ListItemText primary="Send mass email" />
          <KeyboardArrowRight color="disabled" style={{ marginLeft: "auto" }} />
        </ListItemButton>
      )}
      {!userPrivateData?.isHelper && (
        <ListItemButton onClick={handleBecome}>
          <ListItemIcon>
            <WavingHandOutlined />
          </ListItemIcon>
          <ListItemText primary="Become a helper" />
          <KeyboardArrowRight color="disabled" style={{ marginLeft: "auto" }} />
        </ListItemButton>
      )}
      <Divider sx={{ my: 1 }} />

      <ListItemButton
        onClick={() => {
          handleOpenFAQ();
          onClose();
        }}
      >
        <ListItemIcon>
          <HelpOutline />
        </ListItemIcon>
        <ListItemText primary="FAQs" />
        <KeyboardArrowRight color="disabled" style={{ marginLeft: "auto" }} />
      </ListItemButton>
      <ListItemButton
        component="a"
        href="/landing" // Link to your landing page
        target="_blank" // Opens in a new tab
        onClick={() => {
          onClose(); // If you need to close a parent modal or menu
        }}
      >
        <ListItemIcon>
          <ReviewsOutlined />
        </ListItemIcon>
        <ListItemText primary="TaskMom overview" />
        <KeyboardArrowRight color="disabled" style={{ marginLeft: "auto" }} />
      </ListItemButton>
      {currentUser && !currentUser?.isAnonymous && (
        <ListItemButton
          onClick={() => {
            handleOpenFeedback();
            onClose();
          }}
        >
          <ListItemIcon>
            <ThumbUpOffAltOutlined />
          </ListItemIcon>
          <ListItemText primary="App feedback" />
          <KeyboardArrowRight color="disabled" style={{ marginLeft: "auto" }} />
        </ListItemButton>
      )}
      {!inStandaloneMode && (
        <ListItemButton
          onClick={() => {
            handleOpenInstallApp();
            onClose();
          }}
        >
          <ListItemIcon>
            <DownloadOutlined />
          </ListItemIcon>
          <ListItemText primary="Install app" />
          <KeyboardArrowRight color="disabled" style={{ marginLeft: "auto" }} />
        </ListItemButton>
      )}
      {currentUser && !currentUser?.isAnonymous && (
        <>
          <Divider sx={{ my: 1 }} />
          <ListItemButton onClick={handleLogOut}>
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText primary="Log Out" />
            <KeyboardArrowRight
              color="disabled"
              style={{ marginLeft: "auto" }}
            />
          </ListItemButton>
        </>
      )}
      <Box sx={{ mt: 1 }} />
      {isMobile && <SingleLineFooter />}
    </>
  );
};

export default UserProfileCard;
