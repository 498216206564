import { useTheme } from "@emotion/react";
import { Box, useMediaQuery } from "@mui/material";
import { Timestamp, doc, updateDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { useActivity } from "../../contexts/ActivityProvider";
import { useAuth } from "../../contexts/AuthProvider";
import { useDialog } from "../../contexts/DialogProvider";
import { useUser } from "../../contexts/UserProvider";
import { db, functions } from "../../firebase";
import StyledProgress from "../styled/StyledProgress";
import AboutView from "./AboutView";
import AddressView from "./AddressView";
import AvatarView from "./AvatarView";
import BookingPreferencesView from "./BookingPreferencesView";
import BoundsView from "./BoundsView";
import CompleteView from "./CompleteView";
import FactsView from "./FactsView";
import IdentityView from "./IdentityView";
import InterestsView from "./InterestsView";
import PhoneView from "./PhoneView";
import ReferencesView from "./ReferencesView";
import SkillsRateView from "./SkillsRateView";

const CreateProfile = ({
  handleClose,
  setHeaderTitle = () => {},
  canSkip = true,
  setupType = "profile",
  isOpen,
}) => {
  const { currentUser } = useAuth();
  const { userPrivateData } = useUser();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [view, setView] = useState("IDENTITY"); // 'ADDRESS', 'AVATAR', 'ABOUT'
  const [completeForm, setCompleteForm] = useState(false);
  const { handleOpenWalkthrough } = useDialog();

  const { createActivity } = useActivity();

  const initialOrder = [
    { name: "IDENTITY", title: "Finish signing up" },
    { name: "ADDRESS", title: "Address Details" },
    { name: "PHONE", title: "Phone Number" },
    { name: "AVATAR", title: "Avatar Setup" },
    { name: "ABOUT", title: "About yourself" },
    { name: "INTERESTS", title: "Your interests" },
    { name: "FACTS", title: "Fun Facts" },
    { name: "SKILLS_RATE", title: "Rate and skills" },
    { name: "BOOKING_PREFERENCES", title: "Booking preferences" },
    { name: "BOUNDS", title: "Service area" },
    { name: "REFERENCES", title: "Work references" },

    // { name: "BIO", title: "About You" },
    // Add more views as needed
  ];

  // Define the order of views as an array of objects
  const [viewOrder, setViewOrder] = useState(initialOrder);

  const IdentityComplete = !!(
    userPrivateData?.firstName &&
    userPrivateData?.lastName &&
    userPrivateData?.birthday &&
    userPrivateData?.gender
  );

  const userFacts = userPrivateData?.facts || {}; // Safely access the facts object or default to an empty object

  const InterestsComplete = userPrivateData?.interests?.length > 0;

  const FactsComplete =
    [
      userFacts.myFunFact,
      userFacts.myAllAbout,
      userFacts.mySecretTalent,
      userFacts.myPets,
      userFacts.myFamily,
    ].filter(Boolean).length >= 3;

  const PhoneComplete = !!userPrivateData?.phone;

  const AboutComplete = !!userPrivateData?.bio;

  const AddressComplete = !!(
    userPrivateData?.line1 &&
    userPrivateData?.city &&
    userPrivateData?.state &&
    userPrivateData?.zipCode &&
    userPrivateData?.geohash
  );

  const SkillsRateComplete = !!(
    userPrivateData?.skills?.length > 0 && userPrivateData?.hourlyRate
  );

  const AvatarComplete = !!userPrivateData?.avatarUrl;

  const BoundsComplete = !!(userPrivateData?.bounds?.length > 0);

  const ReferencesComplete = !!(userPrivateData?.references?.length > 0);

  const BookingPreferencesComplete = !!(
    userPrivateData?.bookingDaysInAdvance && userPrivateData?.minBookingMinutes
  );

  // Listen to complete profile setup
  useEffect(() => {
    const completeAccountSetup = async () => {
      if (userPrivateData?.accountSetup === "complete") return;
      // If the user is done completeing their account
      if (
        IdentityComplete &&
        PhoneComplete &&
        AvatarComplete &&
        AddressComplete &&
        AboutComplete
      ) {
        try {
          const usersPublicDocRef = doc(db, "usersPublic", currentUser.uid);
          const usersPrivateDocRef = doc(db, "usersPrivate", currentUser.uid);

          await updateDoc(usersPrivateDocRef, {
            accountSetup: "complete",
            isHelper: false,
          });
          await updateDoc(usersPublicDocRef, {
            accountSetup: "complete",
            isHelper: false,
          });

          const incrementZipCodeCounter = httpsCallable(
            functions,
            "incrementZipCodeCounter"
          );
          incrementZipCodeCounter({
            zipCode: userPrivateData?.zipCode,
            counterType: "userCount",
          }).catch((error) => {
            console.error("Error incrementing zip code counter:", error);
          });

          // Here we call createActivity for a "new_user" activity type
          await createActivity({
            type: "new_user",
            city: userPrivateData?.city,
            state: userPrivateData?.state,
            geohash6: userPrivateData?.geohash?.substring(0, 6),
            users: [
              {
                firstName: userPrivateData?.firstName,
                avatarUrl: userPrivateData?.avatarUrl,
                id: currentUser.uid, // Assuming 'id' is the user's ID from somewhere in your function
              },
            ],
          });
        } catch (error) {
          console.error("Error during account setup:", error);
        }
      }
    };
    // Execute the function
    completeAccountSetup();
  }, [userPrivateData]);

  // Listen to complete helper setup
  useEffect(() => {
    const completeHelperSetup = async () => {
      if (userPrivateData?.helperSetup === "complete") return;
      // If the user is done completeing their account
      if (
        userPrivateData?.accountSetup === "complete" &&
        SkillsRateComplete &&
        BookingPreferencesComplete &&
        BoundsComplete &&
        ReferencesComplete
      ) {
        try {
          handleOpenWalkthrough({ type: "helper" });
          const usersPublicDocRef = doc(db, "usersPublic", currentUser.uid);
          const usersPrivateDocRef = doc(db, "usersPrivate", currentUser.uid);

          await updateDoc(usersPrivateDocRef, {
            helperSetup: "complete",
            helperStatus: "active",
            isHelper: true,
            helperCreatedAt: Timestamp.now(),
          });
          await updateDoc(usersPublicDocRef, {
            helperStatus: "active",
            isHelper: true,
            totalVisitsHelped: 0, //  we only track this in public because that's what our stat counter does
            helperCreatedAt: Timestamp.now(),
          });

          const incrementZipCodeCounter = httpsCallable(
            functions,
            "incrementZipCodeCounter"
          );
          incrementZipCodeCounter({
            zipCode: userPrivateData?.zipCode,
            counterType: "helperCount",
          }).catch((error) => {
            console.error("Error incrementing zip code counter:", error);
          });

          // Here we call createActivity for a "new_user" activity type
          await createActivity({
            type: "new_helper",
            city: userPrivateData?.city,
            state: userPrivateData?.state,
            geohash6: userPrivateData?.geohash?.substring(0, 6),
            users: [
              {
                firstName: userPrivateData?.firstName,
                avatarUrl: userPrivateData?.avatarUrl,
                id: currentUser.uid, // Assuming 'id' is the user's ID from somewhere in your function
              },
            ],
          });
        } catch (error) {
          console.error("Error during account setup:", error);
        }
      }
    };
    // Execute the function
    completeHelperSetup();
  }, [userPrivateData]);

  useEffect(() => {
    // Filter out the views based on the existing user data

    // Don't continue until the user is loaded
    if (!userPrivateData) return;

    // Don't adjust the view while we're actively in the form or if the accountSetup is complete
    if (setupType === "profile" && userPrivateData?.accountSetup === "complete")
      return;

    // Don't adjust the view while we're actively in the form or if the helperSetup is complete
    if (setupType === "helper" && userPrivateData?.helperSetup === "complete")
      return;

    const filteredViewOrder = initialOrder
      .filter((viewObj) => {
        if (viewObj.name === "IDENTITY" && IdentityComplete) return false;
        if (viewObj.name === "PHONE" && PhoneComplete) return false;
        if (viewObj.name === "AVATAR" && AvatarComplete) return false;
        if (viewObj.name === "ADDRESS" && AddressComplete) return false;
        if (viewObj.name === "ABOUT" && AboutComplete) return false;
        if (viewObj.name === "INTERESTS" && InterestsComplete) return false;
        if (viewObj.name === "FACTS" && FactsComplete) return false;

        if (
          viewObj.name === "SKILLS_RATE" &&
          (setupType === "profile" || SkillsRateComplete)
        )
          return false;
        if (
          viewObj.name === "BOOKING_PREFERENCES" &&
          (setupType === "profile" || BookingPreferencesComplete)
        )
          return false;
        if (
          viewObj.name === "BOUNDS" &&
          (setupType === "profile" || BoundsComplete)
        )
          return false;
        if (
          viewObj.name === "REFERENCES" &&
          (setupType === "profile" || ReferencesComplete)
        )
          return false;

        return true;
      })
      .map((viewObj, index, array) => ({
        ...viewObj,
        showBack: index !== 0, // Only show back button if it's not the first item
      }));

    setViewOrder(filteredViewOrder);

    // Set the initial view to the first in the new order or handle if no views are needed
    if (filteredViewOrder.length > 0) {
      setView(filteredViewOrder[0].name);
      setHeaderTitle(filteredViewOrder[0].title);
    } else {
      handleClose();
    }
  }, [setupType, isOpen]); // Dependency array ensures this effect runs whenever userPrivateData changes

  const currentIndex = viewOrder.findIndex((viewObj) => viewObj.name === view);
  const totalSteps = viewOrder.length;
  const formProgress = (currentIndex / (totalSteps - 1)) * 90 + 5;

  const handleNext = async () => {
    if (currentIndex >= 0 && currentIndex < viewOrder.length - 1) {
      const nextViewObj = viewOrder[currentIndex + 1];

      // If the current step is valid, update the view to the next one in the sequence
      setView(nextViewObj.name);

      // Optionally, update the header title or perform other actions when transitioning to the next view
      setHeaderTitle(nextViewObj.title); // This assumes you have a method to update the header title accordingly
    } else {
      // FIX IN FUTURE - this is so that the userPrivateData state variable updates before we call. Would be fixed if values were stored in this top level.
      handleClose();
    }
  };

  const handleBack = () => {
    // Determine if there's a previous view in the sequence
    if (currentIndex > 0) {
      // Check there is a previous view
      const prevViewObj = viewOrder[currentIndex - 1];

      // Update the view to the previous one in the sequence
      setView(prevViewObj.name);

      // Optionally, update the header title or perform other actions when transitioning to the previous view
      setHeaderTitle(prevViewObj.title); // This assumes you have a method to update the header title accordingly
    } else {
      // Optionally, handle if this is the first step and user attempts to go back (e.g., close the modal or redirect)
    }
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          left: { xs: 0, sm: -10 },
          right: { xs: 0, sm: -10 },
          width: { xs: "100%", sm: "calc(100% + 20px)" },
        }}
      >
        <StyledProgress
          variant={"determinate"}
          value={formProgress}
          borderRadius={isMobile ? 0 : "5px"}
        />
      </Box>
      {view === "IDENTITY" && (
        <IdentityView
          handleNext={handleNext}
          canSkip={canSkip}
          showBack={false}
        />
      )}
      {view === "PHONE" && (
        <PhoneView
          handleNext={handleNext}
          handleBack={handleBack}
          canSkip={canSkip}
          showBack={viewOrder.find((v) => v.name === "PHONE")?.showBack}
        />
      )}
      {view === "ADDRESS" && (
        <AddressView
          handleNext={handleNext}
          handleBack={handleBack}
          canSkip={canSkip}
          showBack={viewOrder.find((v) => v.name === "ADDRESS")?.showBack}
        />
      )}
      {view === "AVATAR" && (
        <AvatarView
          handleNext={handleNext}
          handleBack={handleBack}
          canSkip={canSkip}
          showBack={viewOrder.find((v) => v.name === "AVATAR")?.showBack}
        />
      )}
      {view === "ABOUT" && (
        <AboutView
          handleNext={handleNext}
          handleBack={handleBack}
          canSkip={canSkip}
          showBack={viewOrder.find((v) => v.name === "ABOUT")?.showBack}
        />
      )}
      {view === "INTERESTS" && (
        <InterestsView
          handleNext={handleNext}
          handleBack={handleBack}
          canSkip={true}
          showBack={viewOrder.find((v) => v.name === "INTERESTS")?.showBack}
        />
      )}
      {view === "FACTS" && (
        <FactsView
          handleNext={handleNext}
          handleBack={handleBack}
          canSkip={true}
          showBack={viewOrder.find((v) => v.name === "FACTS")?.showBack}
        />
      )}
      {view === "SKILLS_RATE" && (
        <SkillsRateView
          handleNext={handleNext}
          handleBack={handleBack}
          canSkip={false}
          showBack={viewOrder.find((v) => v.name === "SKILLS_RATE")?.showBack}
        />
      )}
      {view === "BOOKING_PREFERENCES" && (
        <BookingPreferencesView
          handleNext={handleNext}
          handleBack={handleBack}
          canSkip={false}
          showBack={
            viewOrder.find((v) => v.name === "BOOKING_PREFERENCES")?.showBack
          }
        />
      )}
      {view === "BOUNDS" && (
        <BoundsView
          handleNext={handleNext}
          handleBack={handleBack}
          canSkip={false}
          showBack={viewOrder.find((v) => v.name === "BOUNDS")?.showBack}
        />
      )}
      {view === "REFERENCES" && (
        <ReferencesView
          handleNext={handleNext}
          handleBack={handleBack}
          canSkip={false}
          showBack={viewOrder.find((v) => v.name === "REFERENCES")?.showBack}
        />
      )}
      {view === "COMPLETE" && <CompleteView />}
    </>
  );
};

export default CreateProfile;
