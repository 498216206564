import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useUser } from "../../contexts/UserProvider";
import { generalFaq, helperFaq } from "../../services/variableServices";
import DesktopBackButton from "../buttons/DesktopBackButton";
import FAQAccordion from "../faq/FAQAccordion";

const FAQDash = () => {
  const theme = useTheme();

  const { userPrivateData } = useUser();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const accountSetup =
    userPrivateData?.accountSetup === "complete" ||
    userPrivateData?.accountSetup === "show_success";

  const helperSetup =
    userPrivateData?.helperSetup === "complete" ||
    userPrivateData?.helperSetup === "show_success";

  return (
    <div style={{ position: "relative" }}>
      {!isMobile && (
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-start",
              height: "52px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
              <DesktopBackButton />

              <Typography
                variant="h4"
                component="h1"
                sx={{ ml: { xs: 0, sm: 0 } }}
              >
                FAQ
              </Typography>
            </Box>
            {/* {userData && (
            <Box sx={{ top: -6, position: "relative" }}>
              <UserShareButton user={userData} />
            </Box>
          )} */}
          </Box>
          <Divider sx={{ mx: { xs: -2, sm: 0 }, mb: 2 }} />
        </Box>
      )}
      <Box sx={{ maxWidth: "600px", mx: "auto" }}>
        <FAQAccordion
          data={[...(helperSetup ? helperFaq : []), ...generalFaq]}
        />
      </Box>
    </div>
  );
};

export default FAQDash;
