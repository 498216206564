import React from "react";

import {
  EventAvailable,
  PhonelinkRing,
  PlaylistAddCheck,
} from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ChatSection from "./ChatSection";

const FriendConversation = ({ handleScroll }) => {
  const navigate = useNavigate();

  const bulletPoints = [
    {
      text: "Stop the multitask marathon and let us free you to focus on what matters.",
      Icon: PlaylistAddCheck, // Replace with your chosen icon
    },
    {
      text: "Delegate like a boss. Let your personal assistant smooth out the bumps in your week.",
      Icon: PhonelinkRing, // Replace with your chosen icon
    },
    {
      text: "Lift the weight off your shoulders. Breathe easy and reclaim your time.",
      Icon: EventAvailable, // Replace with your chosen icon
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column-reverse", sm: "row-reverse" },
        // boxShadow: "hsl(190deg 100% 35.79% / 15%) 0px 8px 9px 0px",
        // border: "1px solid hsl(190deg 100% 35.79% / 55%)",
        alignItems: "center",
        maxWidth: "1000px",
        backgroundColor: "hsla( 190, 100%, 95%, 1)",
        borderRadius: { xs: "15px", sm: "50px" },
        mx: { xs: 2, sm: "auto" },
        mb: { xs: 0, sm: 4 },
        mt: { xs: 0, sm: 2 },
        p: { xs: 0, sm: 10 },
      }}
    >
      <Box>
        <ChatSection />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 4,
          flex: 1, // This allows the box to grow and take up equal space
          maxWidth: "100%", // Adjusts maxWidth to allow the box to grow
        }}
      >
        <Typography
          align="center"
          sx={{
            fontSize: { xs: "h3.fontSize", sm: "h2.fontSize" }, // Responsive font sizes
            fontWeight: { xs: "h2.fontWeight", sm: "h2.fontWeight" }, // Responsive font weights
            lineHeight: "inherit",
            mb: 2,
            mx: "auto",
            mt: { xs: 1, sm: 0 }, // Adjusts margin top based on screen size
          }}
        >
          Share with Friends
        </Typography>
        <Typography sx={{ mb: 4 }}>
          Share TaskMom with a friend and when they join, you'll both have more
          time for fun!
        </Typography>
        <Button
          variant="contained"
          disableElevation
          onClick={() => navigate("/")}
          sx={{
            width: { xs: "100%", sm: "300px" },
            height: "56px",
            textTransform: "none",
            mx: "auto",
          }}
        >
          Get started
        </Button>
      </Box>
    </Box>
  );
};

export default FriendConversation;
