import { Box, DialogContent, Typography } from "@mui/material";
import React from "react";
import LegalHeader from "../components/header/LegalHeader";

// Terms of Service content structured for dynamic rendering
const termsContent = [
  {
    header: "Introduction",
    content: [
      'Welcome to TaskMom, the community-driven platform designed to connect Requesters seeking assistance with everyday tasks and Helpers willing to lend their skills and time. Linked Lives, LLC ("we", "us", "our") provides a user-friendly interface for facilitating these connections through our website and mobile application (collectively, "Platform"). These Terms of Service ("Terms") govern your access to and use of the Platform.',
    ],
  },
  {
    header: "Acceptance of Terms",
    content: [
      "By accessing or using our Platform, you confirm your agreement to be bound by these Terms, including any policies referenced herein. If you are accessing or using the Platform on behalf of a minor, you accept these Terms on their behalf and agree to be responsible for their compliance. If you do not agree with these Terms, you must not use the Platform.",
    ],
  },
  {
    header: "Eligibility and Account Registration",
    content: [
      "The Platform is available to individuals who are 18 years of age or older. Minors may use the Platform under the supervision of a parent or legal guardian who has agreed to these Terms. The parent or legal guardian is responsible for all activities of the minor and assumes all liability for the minor’s use of the Platform.",
      "To access certain features of the Platform, you must register for an account. By registering, you agree to provide accurate, current, and complete information and to update this information to maintain its accuracy.",
    ],
  },
  {
    header: "Platform Use",
    content: [
      'TaskMom serves as a marketplace to facilitate connections between Requesters seeking assistance ("Requesters") and Helpers offering their services ("Helpers"). We are not a party to any agreements or transactions made between Requesters and Helpers.',
      "While TaskMom currently offers free access to the Platform, we reserve the right to introduce fees or charges for certain services or transactions in the future. We will provide advance notice of any changes to our fee structure.",
    ],
  },
  {
    header: "User Conduct and Responsibilities",
    content: [
      "Users are expected to conduct themselves in a professional, respectful manner at all times. Harassment, abuse, or engaging in illegal activities through the Platform is strictly prohibited.",
      "Requesters and Helpers are responsible for the legality and accuracy of the content they post on the Platform, including task descriptions and profiles.",
      "Users agree to comply with all applicable laws and regulations in their use of the Platform and in any transactions they enter into through the Platform.",
    ],
  },
  {
    header: "Intellectual Property Rights",
    content: [
      "TaskMom and its licensors retain all rights, title, and interest in the Platform, including all software, text, graphics, logos, images, and other content provided by TaskMom. Users are granted a limited, non-exclusive license to access and use the Platform for personal, non-commercial purposes only.",
    ],
  },
  {
    header: "Disclaimers and Limitations of Liability",
    content: [
      'The Platform is provided "as is" without warranties of any kind, express or implied. TaskMom does not guarantee the quality, safety, or legality of the services advertised, the truth or accuracy of listings, or the ability of Requesters and Helpers to complete a transaction.',
      "TaskMom shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages resulting from or in connection with your use of the Platform.",
    ],
  },
  {
    header: "Dispute Resolution",
    content: [
      "In the event of a dispute between a Requester and a Helper, we encourage the parties to resolve the matter amicably among themselves. TaskMom does not offer mediation services and does not guarantee any outcomes.",
    ],
  },
  {
    header: "Amendments to Terms",
    content: [
      "TaskMom reserves the right to modify these Terms at any time. Any changes will be effective immediately upon posting to the Platform, and your continued use of the Platform after such posting constitutes your acceptance of the new Terms.",
    ],
  },
  {
    header: "Governing Law and Jurisdiction",
    content: [
      "These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which TaskMom is registered, without giving effect to any principles of conflicts of law.",
    ],
  },
  {
    header: "Contact Us",
    content: [
      "For any questions or concerns regarding these Terms or the Platform, please contact us at alex@ourlinkedlives.com.",
    ],
  },
  // Include additional sections here as needed
];

const TermsOfServicePage = () => {
  return (
    <>
      <LegalHeader />

      <DialogContent>
        <Box sx={{ maxWidth: "800px", mx: "auto", my: "50px" }}>
          <Typography variant="h4" gutterBottom>
            TaskMom Terms of Service
          </Typography>
          <Typography variant="h6" fontWeight={"500"} gutterBottom>
            Last Updated: March 25, 2024
          </Typography>
          {termsContent.map((section, index) => (
            <div key={index}>
              <Typography variant="h5" gutterBottom>
                {section.header}
              </Typography>
              {section.content.map((paragraph, pIndex) => (
                <Typography key={pIndex} paragraph>
                  {paragraph}
                </Typography>
              ))}
            </div>
          ))}
          <Typography>
            By using the TaskMom Platform, you acknowledge that you have read,
            understood, and agreed to be bound by these Terms of Service.
          </Typography>
        </Box>
      </DialogContent>
    </>
  );
};

export default TermsOfServicePage;
