import {
  Close,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Today,
} from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { DateTime } from "luxon";
import React, { useRef } from "react";
import "swiper/css";
import "swiper/css/keyboard";
import "swiper/css/navigation";
import "swiper/less/mousewheel";

import { Keyboard, Mousewheel } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useHelpers } from "../../contexts/HelpersProvider";

const DatePicker = ({
  highlightedDates = [],
  onChange,
  weekIndex,
  setWeekIndex,
}) => {
  const { selectedDate } = useHelpers();

  const swiperRef = useRef(null);
  const theme = useTheme();

  // const currentHighlights = new Set(
  //   highlightedDates.map((d) => DateTime.fromISO(d.start).toISODate())
  // );

  const currentHighlights = new Set(highlightedDates.map((d) => d.toISODate()));

  console.log("currentHighlights: ", currentHighlights);

  const handleDateSelection = (day, dateString, weekIndex) => {
    onChange(day);
  };

  const renderDay = (day, weekIndex, monthIndex) => {
    // Convert Luxon DateTime to a string in the format YYYY-MM-DD
    const dateString = day.toISODate();

    const isHighlightedDay = currentHighlights.has(dateString);

    const isSelected = selectedDate && selectedDate?.toISODate() === dateString;

    const today = DateTime.now().toISODate() === dateString;

    let buttonStyle = {
      width: "40px", // Fixed width
      height: "40px", // Fixed height, equal to width to form a square
    };

    if (!isHighlightedDay) {
      buttonStyle = {
        ...buttonStyle,
        backgroundColor: "",
        color: "rgba(0, 0, 0, 0.38)",
      };
    }

    if (isHighlightedDay) {
      buttonStyle = {
        ...buttonStyle,
        backgroundColor: "",
        color: "rgba(0, 0, 0, 0.8)",
        // border: "1px solid hsl(190,100%,30%)",
      };
    }

    if (today) {
      buttonStyle = {
        ...buttonStyle,
        // fontWeight: "800",
        // border: "1px solid rgba(0, 0, 0, 0.6)", // Add black outline if current day
        color: "hsl(190,100%,30%)",
      };
    }

    if (isSelected) {
      buttonStyle = {
        ...buttonStyle,
        backgroundColor: theme.palette.primary.main,
        color: "white",
      };
    }

    const key = `${dateString}-${isHighlightedDay}`;

    const dayContent = (
      // Replace this with your day rendering logic
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          minWidth: 0,
        }}
        key={key}
      >
        <IconButton
          style={buttonStyle}
          onClick={() => handleDateSelection(day, dateString, weekIndex)}
          // disabled={!isHighlightedDay}
        >
          <Typography
            // fontSize={today ? "1rem" : "0.75rem"}
            fontWeight={isHighlightedDay ? "600" : ""}
            fontSize={"0.9rem"}
          >
            {day.toFormat("d")}
          </Typography>
        </IconButton>
      </div>
    );

    return dayContent;
  };

  const generateCalendarDaysWeek = (week) => {
    const days = [];
    // Calculate the start of the calendar view, which is a few weeks before the selected week
    const calendarStart = week.startOf("week");

    let weekStart = calendarStart;

    // Generate six weeks of days
    for (let i = 0; i < 6; i++) {
      const weekDays = Array(7)
        .fill(0)
        .map((_, index) => weekStart.plus({ days: index }));
      days.push(weekDays);
      // Move to the next week
      weekStart = weekStart.plus({ weeks: 1 });
    }

    return days;
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          width: "100%",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
          display: "flex",
        }}
      >
        <Box
          sx={{
            display: "flex",
            my: 1,
            alignItems: "center",
            transition: "transform 0.3s ease",
            transform:
              weekIndex !== 0 ? "translateX(0px)" : "translateX(-28px)", // Adjust the 10px to your preference
          }}
        >
          <IconButton
            onClick={() => swiperRef.current.swiper.slideTo(0)}
            style={{
              transition: "opacity 0.3s ease, transform 0.3s ease",
              opacity: weekIndex !== 0 ? 1 : 0,
            }}
          >
            <Today color={"primary"} />
          </IconButton>

          <Typography fontWeight={"500"} fontSize={"1rem"}>
            {DateTime.now()
              .plus({ weeks: weekIndex })
              .endOf("week")
              .toFormat("MMMM yyyy")}
          </Typography>
        </Box>
        {selectedDate && (
          <Button
            sx={{ height: "32px" }} // Ensures the button is aligned to the far right
            onClick={() => {
              onChange("");
            }} // Resets the selected date
            startIcon={<Close />}
            variant="text"
            size="small"
            color="inherit"
          >
            Clear
          </Button>
        )}
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
        {/* Calendar display */}
        <Box sx={{ flex: 1 }}>
          <div style={{ display: "flex" }}>
            {["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"].map((d, idx) => (
              <div
                key={idx}
                style={{
                  flex: 1,
                  minWidth: 0,
                  textAlign: "center",
                  marginBottom: "16px",
                  color: "rgba(0, 0, 0, 0.7)",
                  fontWeight: "550",
                  fontSize: "0.75rem",
                }}
              >
                {d}
              </div>
            ))}
          </div>
          <Swiper
            ref={swiperRef}
            onSlideChange={(swiper) => setWeekIndex(swiper.activeIndex)}
            direction="vertical" // Ensure this is set to 'vertical'
            slidesPerView={2} // Shows 2 rows at any time
            initialSlide={0}
            mousewheel={true}
            keyboard={{
              enabled: true,
            }}
            style={{ maxHeight: "100px" }}
            modules={[Mousewheel, Keyboard]}
          >
            {[...Array(12)].map(
              (
                _,
                viewIndex // Adjust the array length as per your needs
              ) => (
                <SwiperSlide
                  key={viewIndex}
                  style={{ height: "90px", maxHeight: "50px" }}
                >
                  {(() => {
                    const week = DateTime.now()
                      .plus({ weeks: viewIndex })
                      .startOf("week");

                    const weekDays = generateCalendarDaysWeek(week);

                    return (
                      <div key={viewIndex}>
                        <div style={{ display: "flex" }}>
                          {weekDays[0].map((day) =>
                            renderDay(day, viewIndex * 2, viewIndex)
                          )}
                        </div>
                      </div>
                    );
                  })()}
                </SwiperSlide>
              )
            )}
          </Swiper>
        </Box>

        {/* Swiper navigation up down buttons */}
        <ButtonGroup
          orientation="vertical"
          variant="contained"
          sx={{
            backgroundColor: "#F5F5F5",
            justifyContent: "space-between",
            borderRadius: "150px", // Rounded borders
            boxShadow: "none", // Removes shadow if desired
            "& .MuiButtonGroup-grouped": {
              border: 0, // Removes borders between buttons
              "&:not(:last-of-type)": {
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)", // Only add border between buttons
              },
              minWidth: "40px", // Ensure buttons are not too wide
              height: "40px", // Fixed height for each button
              "&:hover": {
                backgroundColor: "#E0E0E0", // Lighter shade on hover
              },
            },
          }}
        >
          <IconButton
            onClick={() => swiperRef.current.swiper.slidePrev()}
            size="large"
            sx={{ borderRadius: "150px 150px 0 0", height: "50%" }} // Rounded top
            disabled={weekIndex === 0}
          >
            <KeyboardArrowUp />
          </IconButton>
          <IconButton
            disabled={weekIndex === 10}
            onClick={() => swiperRef.current.swiper.slideNext()}
            size="large"
            sx={{ borderRadius: "0 0 150px 150px", height: "50%" }} // Rounded bottom
          >
            <KeyboardArrowDown />
          </IconButton>
        </ButtonGroup>
      </Box>
    </Box>
  );
};

export default DatePicker;
