import React from "react";
import { useRouteError } from "react-router-dom";
import { Container, Typography, Box } from "@mui/material";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <Container component="main" maxWidth="sm" sx={{ mt: 8 }}>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h4" gutterBottom>
          Oops!
        </Typography>
        <Typography variant="body1" gutterBottom>
          Sorry, an unexpected error has occurred.
        </Typography>
        <Typography variant="body2" style={{ fontStyle: "italic" }}>
          {error.statusText || error.message}
        </Typography>
      </Box>
    </Container>
  );
}
