import { Button } from "@mui/material";
import React from "react";
import { useDialog } from "../../contexts/DialogProvider";

const PostAJob = () => {
  const { handleOpenCreateBooking } = useDialog();

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleOpenCreateBooking}
        disableRipple
        // color="inherit"
        sx={{
          textTransform: "none",
          height: { xs: "56px", sm: "auto" },
          width: { xs: "100%", sm: "120px" },
          px: 2,
          color: "black",
          padding: "6px",
          height: "54px",
          backgroundColor: "white",
          boxSizing: "border-box",
          borderRadius: "30px",
          border: "1px solid rgba(0, 0, 0, 0.12)",
          transition: "box-shadow, border, 0.3s ease-in-out", // Smooth transition for shadow
          boxShadow: "none", // Drop shadow when open
          "&:hover": {
            border: "1px solid rgba(0, 0, 0, 0.12)",
            backgroundColor: "white",
            boxShadow: "0 5px 10px 0 rgba(0,0,0,0.15)", // Slightly more pronounced shadow on hover
          },
        }}
        // startIcon={<Feedback />}
      >
        Post a job
      </Button>
    </>
  );
};

export default PostAJob;
