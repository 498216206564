import { Avatar, Badge, Button } from "@mui/material";
import React, { useState } from "react";
import { useUser } from "../../contexts/UserProvider";

import { MenuRounded } from "@mui/icons-material";
import { useAuth } from "../../contexts/AuthProvider";
import ProfilePopover from "../header/ProfilePopover";

const DashboardHeader = () => {
  const { userPrivateData } = useUser();

  const [profileAnchorEl, setProfileAnchorEl] = useState(null);

  const accountSetup =
    userPrivateData?.accountSetup === "complete" ||
    userPrivateData?.accountSetup === "show_success";

  const { currentUser } = useAuth();

  const isProfileOpen = Boolean(profileAnchorEl);

  const handleProfileToggle = (event) => {
    if (isProfileOpen) {
      setProfileAnchorEl(null);
    } else {
      setProfileAnchorEl(event.currentTarget);
    }
  };

  const handleProfileClose = () => {
    setProfileAnchorEl(null);
  };

  return (
    <>
      {/* Profile Avatar Icon */}
      <Badge
        color="error"
        variant="dot"
        // Ensures the dot is visible if the condition is true, otherwise not
        badgeContent={
          !accountSetup && currentUser && !currentUser?.isAnonymous ? " " : 0
        }
        sx={{
          // Correct the syntax for the sx prop
          "& .MuiBadge-dot": {
            transform: "translate(5px, 5px)", // Moves the dot to the right and up relative to its normal position
          },
        }}
      >
        <Button
          size="large"
          aria-label="account of current user"
          aria-haspopup="true"
          // color="disabled" might not give the desired visual effect. If you meant to disable the button, use the disabled prop.
          // If it's for styling, consider using the sx prop for colors.
          variant="outlined"
          disableRipple
          sx={{
            padding: "6px",
            minWidth: "94px",
            border: "1px solid rgba(0, 0, 0, 0.12)",
            borderRadius: "30px",
            transition: "box-shadow 0.3s ease-in-out", // Smooth transition for shadow
            boxShadow: isProfileOpen ? "0 5px 10px 0 rgba(0,0,0,0.15)" : "none", // Drop shadow when open
            backgroundColor: "rgba(0, 0, 0, 0)",
            transition: "box-shadow, border, backgroundColor, 0.3s ease-in-out", // Smooth transition for shadow
            "&:hover": {
              boxShadow: "0 5px 10px 0 rgba(0,0,0,0.15)", // Slightly more pronounced shadow on hover
              border: "1px solid rgba(0, 0, 0, 0.15)",
              backgroundColor: "rgba(0, 0, 0, 0)",
            },
          }}
          onClick={handleProfileToggle}
        >
          <MenuRounded sx={{ ml: 1, color: "#616161" }} />
          <Avatar
            src={userPrivateData?.avatarUrl}
            sx={{ marginLeft: 1 }}
          />{" "}
          {/* Added some space between the icon and the avatar */}
        </Button>
      </Badge>
      <ProfilePopover
        anchorEl={profileAnchorEl}
        open={isProfileOpen}
        onClose={handleProfileClose}
      />
    </>
  );
};

export default DashboardHeader;
