import { useTheme } from "@emotion/react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useDialog } from "../../contexts/DialogProvider";
import AvailabilityChip from "./AvailabilityChip";

function RateAvailabilityDetails({ user, showIcon = true, size="normal" }) {
  const {
    avatarUrl,
    firstName,
    lastName,
    bio,
    skills,
    hourlyRate,
    city,
    state,
    zipCode,
    availability = [],
    id,
    totalReviews,
    averageRating,
  } = user;

  const { handleOpenProfile } = useDialog();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const firstNonZeroAvailability =
    ["threeDay", "thisWeek", "nextWeek"].find(
      (key) => availability[key] !== 0
    ) || "none";

  const availabilityMessages = {
    threeDay: { label: "within 3 days", color: "success" },
    thisWeek: { label: "this week", color: "info" },
    nextWeek: { label: "next week", color: "warning" },
    none: { label: "limited", color: "disabled" },
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          flexWrap: "wrap",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <Typography
          variant="body1"
          color="text.primary"
          fontSize={size === "normal" ? "1rem" : "0.8rem"}
          sx={{ minWidth: "90px" }}
        >
          <strong>${hourlyRate}</strong> hourly
        </Typography>
        {/* <AvailabilityChip user={user} showColor={true} showIcon={showIcon} /> */}
      </Box>
    </Box>
  );
}

export default RateAvailabilityDetails;
