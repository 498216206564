import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { DateTime } from "luxon";
import React, { useEffect, useRef, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { useVisits } from "../../contexts/VisitProvider";
import AddAvailabilityButton from "../buttons/AddAvailabilityButton";
import ColumnView from "./ColumnView";

const WeekView = ({
  initialDay,
  userId,
  availability,
  type = "self",
  showAddAvailability = false,
  onClickAvailability = () => {},
  minBookingMinutes = 30,
  bookingDaysInAdvance = 2,
  geohash6 = "",
  visits = {},
  skills = [],
  bounds = [],
}) => {
  const { loadVisitsInRange } = useVisits();
  const initialSunday = DateTime.fromJSDate(new Date(initialDay)).startOf(
    "week"
  );

  console.log("==> initialDay: ", initialDay)

  const swiperRef = useRef(null);
  const [weeks, setWeeks] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(2); // Start from an index that shows the initialSunday

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") {
        swiperRef.current?.slidePrev();
      } else if (event.key === "ArrowRight") {
        swiperRef.current?.slideNext();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const bufferWeeks = Array.from({ length: 5 }, (_, i) =>
      initialSunday.plus({ weeks: i - 2 })
    );
    setWeeks(bufferWeeks);
  }, [initialDay]);

  useEffect(() => {
    if (weeks.length > 0) {
      // Load visits for the current week
      const currentWeekStart = weeks[currentIndex].startOf("day"); // Ensure it's the start of the day
      const currentWeekEnd = weeks[currentIndex].plus({ days: 6 }).endOf("day"); // End of the day for the last day of the week

      // Call the function with the precise start and end times of the current week
      loadVisitsInRange(currentWeekStart, currentWeekEnd);
    }
  }, [weeks, currentIndex, loadVisitsInRange]); // Dependency array

  const handleSlideChange = (swiper) => {
    setCurrentIndex(swiper.activeIndex); // Update currentIndex based on the swiper's active index
    let newWeeks = [...weeks];

    if (swiper.activeIndex === 1) {
      // Prepend a new week to the start of the array
      const newWeek = weeks[0].minus({ weeks: 1 });
      newWeeks.unshift(newWeek);

      // Update the weeks array with the newly added week
      setWeeks(newWeeks);

      // Use a timeout to ensure state updates and swiper re-renders have completed
      swiper.slideTo(2, 0, false);
    } else if (swiper.activeIndex === newWeeks.length - 2) {
      // Append a new week at the end of the array
      const newWeek = weeks[weeks.length - 1].plus({ weeks: 1 });
      newWeeks.push(newWeek);

      // Update the weeks array with the newly added week
      setWeeks(newWeeks);
    }
  };

  const formatWeekRange = (weekStartDate) => {
    const endDate = weekStartDate.plus({ days: 6 }); // Add 6 days to get the end of the week
    return `${weekStartDate.toFormat("MMMM d")} - ${endDate.toFormat("d")}`;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={() => swiperRef.current?.slidePrev()}>
              <ArrowBackIos color="primary" />
            </IconButton>
            <Typography align="center" variant="h6" sx={{ minWidth: "200px" }}>
              {weeks.length > 0
                ? formatWeekRange(weeks[currentIndex])
                : "Loading..."}
            </Typography>
            <IconButton onClick={() => swiperRef.current?.slideNext()}>
              <ArrowForwardIos color="primary" />
            </IconButton>
          </Box>
        </Box>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          onSlideChange={handleSlideChange}
          initialSlide={currentIndex}
          touchStartPreventDefault={false}
        >
          {weeks.map((week, index) => (
            <SwiperSlide key={index}>
              <ColumnView
                key={index}
                numColumns={7}
                startDay={week.startOf("day").toJSDate()}
                visits={visits}
                userId={userId}
                timeZone={timeZone}
                truncateView={false}
                availability={availability}
                type={type}
                onClickAvailability={onClickAvailability}
                minBookingMinutes={minBookingMinutes}
                bookingDaysInAdvance={bookingDaysInAdvance}
                geohash6={geohash6}
                skills={skills}
                bounds={bounds}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      {showAddAvailability && <AddAvailabilityButton initialDate={weeks[currentIndex]} />}
    </Box>
  );
};

export default WeekView;
