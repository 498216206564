import { Box, DialogContent, Typography } from "@mui/material";
import React from "react";
import LegalHeader from "../components/header/LegalHeader";

// Terms of Service content structured for dynamic rendering
const termsContent = [
  {
    header: "Introduction",
    content: [
      'Welcome to TaskMom! Your privacy and the security of your personal data are of paramount importance to us. Linked Lives, LLC ("TaskMom," "we," "us," "our") is dedicated to protecting the privacy of our users ("Requesters," "Helpers," "you") who connect and transact on our platform for community assistance through our website and mobile application (collectively, the "Platform"). This Privacy Policy ("Policy") describes how we collect, use, share, and protect your personal information and your rights regarding your information.',
    ],
  },
  {
    header: "Acceptance of Privacy Policy",
    content: [
      "By accessing or using our Platform, registering an account, or engaging in our services, you agree to the terms and practices described in this Policy. If you are a parent or legal guardian using the Platform on behalf of a minor, you consent to this Policy on their behalf. We may update this Policy occasionally, so please review it periodically.",
    ],
  },
  {
    header: "Information We Collect",
    content: [
      "Directly from You: We collect information that you provide us directly, such as when you create an account, post a task, offer help, communicate with us or other users, and respond to surveys. This information may include your name, email address, profile picture, preferences, feedback, and any other information you choose to provide.",
      "Automatically through the Platform: When you use our Platform, we may automatically collect information related to your device, browsing actions, and patterns, including details of your visits to our Platform, traffic data, location data, logs, and the resources that you access.",
      "From Third Parties: We may receive information about you from other sources, such as social media platforms when you connect your social media account with our Platform, and from partners and service providers that assist us in offering the Platform to you.",
    ],
  },
  {
    header: "How We Use Your Information",
    content: [
      "To Provide and Manage the Platform: We use your information to operate, maintain, and provide to you the features and functionality of the Platform.",
      "To Improve and Personalize Your Experience: We use your information to understand how our Platform is used and to improve and personalize your experience on our Platform.",
      "For Communication: We use your information to communicate with you about your account, our services, updates to our terms, conditions, and policies, and for promotional and marketing purposes.",
      "For Security and Legal Compliance: We may use your information to prevent fraud, resolve disputes, enforce our terms and policies, and comply with legal and regulatory obligations.",
    ],
  },
  {
    header: "Sharing Your Information",
    content: [
      "With Other Users: When you post a task or offer help, certain information about you may be shared with other users, including your profile information and the contents of your postings.",
      "Service Providers: We share information with service providers that perform services on our behalf, such as payment processing, data analysis, email delivery, hosting services, and customer service.",
      "Legal and Safety Reasons: We may disclose your information if required to do so by law or in the good faith belief that such action is necessary to comply with legal obligations, protect the safety of any person, or protect against legal liability.",
    ],
  },
  {
    header: "Your Rights and Choices",
    content: [
      "Access and Correction: You have the right to access and update your personal information through your account settings on the Platform.",
      "Deletion: You may request the deletion of your personal information, subject to certain exceptions prescribed by law.",
      "Opt-Out: You can opt-out of receiving promotional communications from us by following the unsubscribe instructions provided in the communications.",
    ],
  },
  {
    header: "Security",
    content: [
      "We implement reasonable measures to protect the security of your personal information from unauthorized access, use, alteration, and disclosure. However, the transmission of information via the internet is not completely secure, and we cannot guarantee the security of your personal information transmitted to our Platform.",
    ],
  },
  {
    header: "Contact Us",
    content: [
      "For questions or concerns about our privacy practices, please contact us at alex@ourlinkedlives.com.",
    ],
  },
  // Include additional sections here as needed
];

const PrivacyPolicyPage = () => {
  return (
    <>
      <LegalHeader />
      <DialogContent>
        <Box sx={{ maxWidth: "800px", mx: "auto", my: "50px" }}>
          <Typography variant="h4" gutterBottom>
            TaskMom Privacy Policy
          </Typography>
          <Typography variant="h6" fontWeight={"500"} gutterBottom>
            Last Updated: March 25, 2024
          </Typography>
          {termsContent.map((section, index) => (
            <div key={index}>
              <Typography variant="h5" gutterBottom>
                {section.header}
              </Typography>
              {section.content.map((paragraph, pIndex) => (
                <Typography key={pIndex} paragraph>
                  {paragraph}
                </Typography>
              ))}
            </div>
          ))}
          <Typography>
            This Privacy Policy is part of our commitment to ensuring that your
            personal information is safe and secure. By using our Platform, you
            acknowledge that you have read and understood this Policy.
          </Typography>
        </Box>
      </DialogContent>
    </>
  );
};

export default PrivacyPolicyPage;
