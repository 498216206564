import { Box, Button } from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import { useHelpers } from "../../contexts/HelpersProvider";

const AvailabilityDayDetails = ({ availability }) => {
  const { selectedDate } = useHelpers();

  const formatTime = (dateTime) => {
    // Format the time to include hour, minute, and AM/PM
    let formattedTime = dateTime.toFormat("h:mma");
  
    // Remove ":00" if it exists in the formatted time
    if (formattedTime.includes(":00")) {
      formattedTime = formattedTime.replace(":00", "");
    }
  
    // Convert "AM/PM" to lowercase
    formattedTime = formattedTime.replace(/[AP]M$/, (match) => match.toLowerCase());
  
    return formattedTime;
  };

  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      {Array.isArray(availability) &&
        availability.map((window, idx) => {
          const startDateTime = DateTime.fromJSDate(window?.start.toDate());
          const endDateTime = DateTime.fromJSDate(window?.end.toDate());

          const isSameDay =
            startDateTime.hasSame(selectedDate, "day") &&
            endDateTime.hasSame(selectedDate, "day");

          return (
            isSameDay && (
              <Button
                fullWidth
                key={idx}
                variant="outlined"
                sx={{
                  margin: "4px 0",
                  textTransform: "none",
                }}
              >
                {formatTime(startDateTime)} - {formatTime(endDateTime)}
              </Button>
            )
          );
        })}
    </Box>
  );
};

export default AvailabilityDayDetails;
