import { Delete, KeyboardArrowLeft, PersonAdd } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthProvider";
import { useUser } from "../../contexts/UserProvider";
import { db } from "../../firebase";
import { validateReferences } from "../../services/formServices";
import NameField from "../fields/NameField";
import PhoneField from "../fields/PhoneField";

const ReferencesView = ({
  handleNext = () => {},
  handleBack = () => {},
  canSkip = true,
  showBack = false,
  buttonText = "Continue",
}) => {
  const [loading, setLoading] = useState(false);
  const { userPrivateData } = useUser();
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    references:
      userPrivateData?.references?.map((ref) => ({
        referenceName: ref.referenceName || "",
        phone: ref.phone || "",
      })) || [],
  });
  const [showMaxBookingMinutes, setShowMaxBookingMinutes] = useState(false);

  const { currentUser } = useAuth();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const phraseNumber = values.references.length === 0 ? "" : " another";


  useEffect(() => {
    console.log("userPrivateData?.references: ", userPrivateData?.references);
    // Update the state whenever userPrivateData.references changes
    if (userPrivateData?.references) {
      setValues({
        references: userPrivateData.references.map((ref) => ({
          referenceName: ref.referenceName || "",
          phone: ref.phone || "",
        })),
      });
    }
  }, [userPrivateData?.references]);

  const handleSubmit = async () => {
    setLoading(true);

    let newError = validateReferences(values);
    if (Object.keys(newError).length > 0 && !canSkip) {
      setErrors(newError);
      setLoading(false); // Ensure to stop the loading process here if there are validation errors
      return; // Exit the function if there are errors
    }
    try {
      await setDoc(
        doc(db, "usersPublic", currentUser.uid),
        {
          references: values.references,
        },
        { merge: true }
      );

      await setDoc(
        doc(db, "usersPrivate", currentUser.uid),
        {
          references: values.references,
        },
        { merge: true }
      );

      handleNext();
    } catch (err) {
      console.log("error: ", err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteReference = (index) => {
    setValues((prevValues) => ({
      ...prevValues,
      references: prevValues.references.filter((_, i) => i !== index),
    }));

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      // Remove any errors associated with the reference being deleted
      Object.keys(newErrors).forEach((key) => {
        const match = key.match(/^reference_(\d+)_(.+)$/);
        if (match) {
          const errorIndex = parseInt(match[1], 10);
          const errorField = match[2];
          if (errorIndex === index) {
            // If the error is for the deleted reference, remove it
            delete newErrors[key];
          } else if (errorIndex > index) {
            // Adjust indices of errors for references after the deleted one
            const newKey = `reference_${errorIndex - 1}_${errorField}`;
            newErrors[newKey] = newErrors[key];
            delete newErrors[key];
          }
        }
      });

      return newErrors;
    });
  };

  const handleReferenceChange = (index, field, value) => {
    // Update the specific reference's field (referenceName or phone) with the new value
    const updatedReferences = values.references.map((reference, i) => {
      if (index === i) {
        return { ...reference, [field]: value };
      }
      return reference;
    });

    setValues((prevValues) => ({
      ...prevValues,
      references: updatedReferences,
    }));

    // Dynamically construct the error key for the field being edited
    const errorKey = `reference_${index}_${field}`;

    // Check if there's an error for the field being edited and clear it if present
    if (errors[errorKey]) {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[errorKey]; // Remove the error for the field being edited

        // Also consider clearing the general 'references' error if it exists and all specific errors are resolved
        if (
          Object.keys(newErrors).every((key) => !key.startsWith("reference_"))
        ) {
          delete newErrors["references"];
        }

        return newErrors;
      });
    }
  };

  const handleAddReference = () => {
    // Add a new reference object to the array with empty fields
    setValues((prevValues) => ({
      ...prevValues,
      references: [...prevValues.references, { referenceName: "", phone: "" }],
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      references: null,
    }));
  };

  return (
    <>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          p: 2,
          minHeight: "300px",
          alignItems: "flex-start",
        }}
      >
        {showBack && (
          <ButtonBase
            onClick={handleBack}
            disabled={loading}
            sx={{ color: "text.secondary", my: 1 }}
          >
            <KeyboardArrowLeft /> Back
          </ButtonBase>
        )}
        <Typography
          align="left"
          fontWeight={"600"}
          fontSize={"1.15rem"}
          gutterBottom
        >
          Work references
        </Typography>
        <Typography align="left" color={"text.secondary"} sx={{ mb: 2 }}>
          Reference information will be visible on your profile.
        </Typography>
        {errors.references && (
          <Typography
            variant="caption"
            sx={{
              display: "block",
              color: "error.main", // Use the theme's error color
              marginTop: "8px", // Spacing before the error message
            }}
          >
            {errors.references}
          </Typography>
        )}
        {values.references.map((reference, index) => (
          <>
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
                gap: 2,
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "row-reverse",
                    sm: "row",
                  },
                  width: { xs: "100%", sm: "auto" },
                  justifyContent: {
                    xs: "space-between",
                    sm: "flex-start",
                  },
                  gap: 1,
                }}
              >
                <IconButton
                  aria-label="delete"
                  color="default" // IconButton does not have a 'disabled' color, so use 'default' or another color
                  onClick={() => handleDeleteReference(index)}
                  sx={{ cursor: "pointer" }} // This style is technically not needed as IconButton changes the cursor style by default
                >
                  <Delete color="disabled" />
                </IconButton>
                <Avatar />
              </Box>

              <NameField
                name={"referenceName"}
                value={reference.referenceName}
                error={errors[`reference_${index}_referenceName`]}
                handleChange={(e) =>
                  handleReferenceChange(index, "referenceName", e.target.value)
                }
                label={"Reference Name"}
              />
              <PhoneField
                phone={reference.phone}
                error={errors[`reference_${index}_phone`]}
                handleChange={(e) =>
                  handleReferenceChange(index, "phone", e.target.value)
                }
              />
            </Box>
            <Divider
              sx={{
                my: { xs: 2, sm: 1 },
                mx: { xs: -2, sm: 0 },
                borderBottomWidth: { xs: "4px", sm: "thin" },
              }}
            />
          </>
        ))}
        <Button
          variant="contained"
          color="inherit"
          disableElevation
          startIcon={<PersonAdd />}
          onClick={handleAddReference}
          sx={{
            width: { xs: "100%", sm: "300px" },
            textTransform: "none",
            height: "56px",
          }}
        >
          Add{phraseNumber} reference
        </Button>

        <div style={{ display: "flex", flexGrow: 1, minHeight: "16px" }} />
        <Typography align="left" variant="caption" color={"text.secondary"}>
          Always ask for permission before listing someone as a reference.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "100%",
          }}
        >
          <Button
            disableElevation
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={loading}
            sx={{
              width: "100%",
              height: "56px",
              textTransform: "none",
            }} // Take up half the available width
          >
            {loading ? ( // Conditional rendering based on loading state
              <CircularProgress size={24} color="inherit" />
            ) : (
              <Typography fontWeight={"500"}>{buttonText}</Typography>
            )}
          </Button>

          {canSkip && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: "56px",
                alignItems: "center",
              }}
            >
              <Typography
                align="center"
                onClick={handleNext}
                fontWeight={"500"}
                sx={{ textDecoration: "underline", my: 1, cursor: "pointer" }}
              >
                Skip for now
              </Typography>
            </Box>
          )}
        </Box>
      </DialogActions>
    </>
  );
};

export default ReferencesView;
