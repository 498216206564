import { CheckCircle, Feedback } from "@mui/icons-material";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useChat } from "../../contexts/ChatProvider";
import MessageBox from "../boxes/MessageBox";

const FeedbackForm = ({ handleClose }) => {
  const [feedbackText, setFeedbackText] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const { sendMessage } = useChat();

  const handleSubmit = async (event) => {
    event.preventDefault();

    sendMessage({
      message: feedbackText,
      otherParticipants: [
        {
          id: "Qlogr7qFCFfbErNzxbkk0hY9wzl2",
          firstName: "TaskMom Support",
          lastName: "",
          avatarUrl: "",
        },
      ],
    });

    setSubmitted(true);

    try {
    } catch (error) {
      console.error("Error submitting the feedback: ", error);
    }
  };

  const renderForm = () => {
    return (
      <>
        <DialogContent>
          <Feedback sx={{ mb: 1 }} />
          <Typography
            align="left"
            fontWeight={"600"}
            fontSize={"1.15rem"}
            gutterBottom
          >
            Your feedback helps us improve
          </Typography>
          <Typography align="left" sx={{ mb: 2, color: "text.secondary" }}>
            Share your thoughts, be it a bug report, feature suggestion, or any
            feedback. Let us know below, and we'll work to address it!
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            placeholder="Write your feedback here"
            value={feedbackText}
            onChange={(e) => setFeedbackText(e.target.value)}
            sx={{ mb: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            disabled={!feedbackText}
            variant="contained"
            sx={{ width: "100%", height: "56px", textTransform: "none" }}
            onClick={handleSubmit}
          >
            Submit Feedback
          </Button>
        </DialogActions>
      </>
    );
  };

  const renderSuccess = () => {
    return (
      <Box sx={{ p: 2 }}>
        <MessageBox
          Icon={<CheckCircle color="success" />}
          headerText="Feedback Submitted"
          messageText="Thank you for helping improve the community by leaving feedback!"
          messageAlign="center"
          buttonText="Go back"
          buttonProps={{
            variant: "contained",
            color: "primary",
            textTransform: "none",
          }}
          handleClick={handleClose}
          mt={2}
        />
      </Box>
    );
  };

  return <>{submitted ? renderSuccess() : renderForm()}</>;
};

export default FeedbackForm;
