import {
  Alert,
  AlertTitle,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import * as React from "react";

const StyledAlert = ({
  desktopTitle = "",
  desktopDescription = "",
  mobileTitle = "",
  buttonText = "",
  handleClick = () => {},
  severity = "info",
  buttonProps = {},
  buttonStartIcon = null,
  mx = 0,
  mt = 0,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Alert
      severity={severity}
      action={
        (buttonText || buttonStartIcon) && (
          <Button
            color="inherit"
            startIcon={buttonStartIcon}
            variant="outlined"
            sx={{ height: "56px", minWidth: "100px", ...buttonProps }}
            onClick={handleClick}
          >
            {buttonText}
          </Button>
        )
      }
      sx={{
        display: "flex",
        alignItems: "center",
        [`& .MuiAlert-action`]: {
          padding: 0,
        },
        mx: mx,
        mt: mt,
      }}
    >
      {!isMobile ? (
        <>
          <AlertTitle>{desktopTitle}</AlertTitle>
          {desktopDescription}
        </>
      ) : (
        <>{mobileTitle}</>
      )}
    </Alert>
  );
};

export default StyledAlert;
