import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { ActivityProvider } from "./contexts/ActivityProvider";
import { AuthProvider } from "./contexts/AuthProvider";
import { AvailabilityProvider } from "./contexts/AvailabilityProvider";
import { ChatProvider } from "./contexts/ChatProvider";
import { DashboardProvider } from "./contexts/DashboardContext";
import { DialogProvider } from "./contexts/DialogProvider";
import { HelpersProvider } from "./contexts/HelpersProvider";
import { NotificationsProvider } from "./contexts/NotificationsProvider";
import { SnackbarProvider } from "./contexts/SnackbarProvider";
import { UserProvider } from "./contexts/UserProvider";
import { VisitsProvider } from "./contexts/VisitProvider";
import "./index.css";
import theme from "./theme";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={theme}>
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <BrowserRouter>
        <AuthProvider>
          <UserProvider>
            <SnackbarProvider>
              <DashboardProvider>
                <NotificationsProvider>
                  <HelpersProvider>
                    <AvailabilityProvider>
                      <ChatProvider>
                        <VisitsProvider>
                          <ActivityProvider>
                            <DialogProvider>
                              <App />
                            </DialogProvider>
                          </ActivityProvider>
                        </VisitsProvider>
                      </ChatProvider>
                    </AvailabilityProvider>
                  </HelpersProvider>
                </NotificationsProvider>
              </DashboardProvider>
            </SnackbarProvider>
          </UserProvider>
        </AuthProvider>
      </BrowserRouter>
    </LocalizationProvider>
  </ThemeProvider>
);
