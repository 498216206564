// -- src/firebase.js
// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDfnaq4b87_gEeGLqXVmt7xSseWdJwodHA",
  authDomain: "taskmom-32399.firebaseapp.com",
  projectId: "taskmom-32399",
  storageBucket: "taskmom-32399.appspot.com",
  messagingSenderId: "111247959082",
  appId: "1:111247959082:web:c8aec70bed413ac9fd8f53",
  measurementId: "G-FDGJQENM12",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Firebase that can also be emulated
let auth, db, functions, storage;

if (process.env.NODE_ENV === "development") {
  // Initialize services with emulator in development
  auth = getAuth();
  connectAuthEmulator(auth, "http://localhost:9099/");
  db = getFirestore();
  connectFirestoreEmulator(db, "localhost", 8080);
  functions = getFunctions(app);
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  storage = getStorage();
  connectStorageEmulator(storage, "127.0.0.1", 9199);
} else {
  // Initialize services normally for production
  auth = getAuth();
  db = getFirestore();
  functions = getFunctions(app);
  storage = getStorage();
}

export { analytics, app, auth, db, functions, storage };
