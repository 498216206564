// EmailCheckView.js
import { keyframes } from "@emotion/react";
import { KeyboardArrowLeft, Send } from "@mui/icons-material";
import {
  Button,
  ButtonBase,
  CircularProgress,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import React, { useState } from "react";

const fadeInMoveDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ForgotView = ({ handleClose, setView, email, showBack = false }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [emailSent, setEmailSent] = useState(false);

  const handleSend = async (e) => {
    e.preventDefault();

    setLoading(true);
    const auth = getAuth();

    try {
      await sendPasswordResetEmail(auth, email);
      setErrors({}); // Clear errors if the email was sent successfully
    } catch (error) {
      switch (error.code) {
        case "auth/invalid-email":
          setErrors({ email: "The email address is badly formatted." });
          break;
        case "auth/user-not-found":
          setErrors({ email: "There is no user with this email." });
          break;
        default:
          setErrors({
            general: "An unknown error occurred. Please try again later.",
          });
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DialogContent>
        {showBack && (
          <ButtonBase
            onClick={() => setView("LOGIN")}
            disabled={loading}
            sx={{ color: "text.secondary", my: 1 }}
          >
            <KeyboardArrowLeft /> Back
          </ButtonBase>
        )}
        <Typography
          align="left"
          fontWeight={"600"}
          fontSize={"1.15rem"}
          gutterBottom
        >
          Reset your password
        </Typography>
        <Typography align="left" color={"text.secondary"} sx={{ mb: 2 }}>
          Do you want us to send you a temporary password via email?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          // type="submit" // Changed to type submit to trigger form submission
          variant="contained"
          onClick={handleSend}
          disableElevation
          disabled={loading}
          fullWidth
          startIcon={<Send />}
          sx={{ height: "56px", textTransform: "none" }}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <Typography fontWeight={"500"}>Send</Typography>
          )}
        </Button>
      </DialogActions>
    </>
  );
};

export default ForgotView;
