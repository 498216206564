import { useMediaQuery, useTheme } from "@mui/material";
import {
  collection,
  endAt,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  startAt,
  where,
} from "firebase/firestore";
import { geohashQueryBounds } from "geofire-common";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase";
import { decodeGeoHash, isPointInPolygon } from "../services/locationServices"; // Your custom utilities
import { useUser } from "./UserProvider";

const HelpersContext = createContext();

export const useHelpers = () => useContext(HelpersContext);

export const HelpersProvider = ({ children }) => {
  const [helpers, setHelpers] = useState([]);
  const [helpersExist, setHelpersExist] = useState(null);
  const [itemsPerRow, setItemsPerRow] = useState(5);
  const [selectedDate, setSelectedDate] = useState("");

  const [loading, setLoading] = useState(true);
  const [queryStates, setQueryStates] = useState([]);
  const debounceTimerRef = useRef();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { userPrivateData } = useUser();

  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedTimeline, setSelectedTimeline] = useState("anytime");

  // const userCoords = decodeGeoHash(userPrivateData?.geohash || "c28gcq");
  // const bounds = geohashQueryBounds(userCoords, 5000); // Example radius, adjust as necessary

  const userCoords = userPrivateData?.geohash
    ? decodeGeoHash(userPrivateData.geohash)
    : null;
  const bounds = userCoords ? geohashQueryBounds(userCoords, 5000) : [["", ""]];

  useEffect(() => {
    setHelpers([]);
    setSelectedDate("");
    setLoading(true);

    // Clear any existing timer when effect re-runs
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    // Set a new timer
    debounceTimerRef.current = setTimeout(() => {
      const initialQueryStates = bounds.map(() => ({
        lastVisible: null,
        hasMore: true,
      }));
      console.log("initialQueryStates: ", initialQueryStates);
      console.log("bounds: ", bounds);

      // Prepare for fetching: reset states, show loading, etc.
      setQueryStates(initialQueryStates);
      setLoading(true);
      setHelpers([]);
      // Actual fetch operation
      console.log("itemsPerRow: ", itemsPerRow);
      fetchHelpers(isMobile ? 6 : itemsPerRow, initialQueryStates);
    }, 500);

    // Cleanup function to clear the timer when the component unmounts or dependencies change
    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, [selectedSkills, selectedTimeline, userPrivateData?.geohash]); // Dependencies that trigger debounced fetch

  const fetchHelpers = async (desiredNumber, qStates) => {
    let totalFetchedHelpers = [];
    let updatedQueryStates = [...qStates]; // Copy the current state to update it
    let helpersToFetch = desiredNumber;

    while (helpersToFetch > 0) {
      let fetchedInThisRound = 0;

      // Ensure bounds are only calculated once this round and then reused
      const helpersQueries = constructQuery(
        bounds,
        updatedQueryStates.map((query) => query?.lastVisible) // Ensure you're using the lastVisible doc from the state
      );

      for (const [index, helpersQuery] of helpersQueries.entries()) {
        if (updatedQueryStates[index]?.hasMore === false) continue;

        try {
          const querySnapshot = await getDocs(helpersQuery);
          // await new Promise((resolve) => setTimeout(resolve, 500));
          const fetchedHelpers = querySnapshot.docs
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
            .filter((helper) =>
              bounds[0][0] !== "" ? isHelperValid(helper, bounds) : true
            );

          totalFetchedHelpers.push(...fetchedHelpers);
          fetchedInThisRound += fetchedHelpers.length;

          console.log("index: ", index);
          console.log("querySnapshot.docs.length: ", querySnapshot.docs.length);

          // Update the state for the current query
          updatedQueryStates[index] = {
            lastVisible:
              querySnapshot.docs[querySnapshot.docs.length - 1] || null,
            hasMore: querySnapshot.docs.length > 0,
          };

          console.log("updatedQueryStates[index]: ", updatedQueryStates[index]);
        } catch (error) {
          console.error("Failed to fetch helpers:", error);
        }

        // if (totalFetchedHelpers.length >= desiredNumber) break;
      }

      // Check if all 'hasMore' flags are false, indicating no more helpers can be fetched from any bounds
      const allQueriesExhausted = updatedQueryStates.every(
        (state) => !state.hasMore
      );
      if (allQueriesExhausted) {
        break; // Break the loop if all queries are exhausted
      }

      helpersToFetch = desiredNumber - totalFetchedHelpers.length;
    }

    // setHelpers((prev) => [...prev, ...totalFetchedHelpers]);
    setHelpers((prev) => {
      // Create a set of existing helper IDs for fast lookup
      const existingIds = new Set(prev.map((helper) => helper.id));

      // Filter out helpers whose IDs are already in the existingIds set
      const newHelpers = totalFetchedHelpers.filter(
        (helper) => !existingIds.has(helper.id)
      );

      // Return the new array with added helpers that weren't already present
      return [...prev, ...newHelpers];
    });

    setQueryStates(updatedQueryStates); // Update the state with the new lastVisible docs
    setLoading(false);

    if (
      totalFetchedHelpers.length === 0 &&
      helpers.length === 0 &&
      helpersExist === null
    ) {
      setHelpersExist(false);
    } else {
      setHelpersExist(true);
    }
  };

  const loadMoreHelpers = () => {
    // Trigger loading more helpers, ideally respecting the desiredNumber as needed
    fetchHelpers(itemsPerRow, queryStates); // Assuming you want to load one more helper at a time
  };

  const constructQuery = (bounds, lastVisibleDocs = []) => {
    if (bounds[0][0] !== "") {
      return bounds.map(([start, end], index) => {
        let baseQuery = query(
          collection(db, "usersPublic"),
          where("helperStatus", "==", "active")
        );

        if (selectedSkills && selectedSkills.length > 0) {
          const skillNames = selectedSkills.map((skill) => skill.name);
          baseQuery = query(
            baseQuery,
            where("skills", "array-contains-any", skillNames)
          );
        }

        if (selectedTimeline && selectedTimeline !== "anytime") {
          baseQuery = query(
            baseQuery,
            where(`availability.${selectedTimeline}`, ">", 0)
          );
        }

        baseQuery = query(
          baseQuery,
          orderBy("geohash6"),
          startAt(start),
          endAt(end)
        );

        // Apply pagination based on the last visible document for this range, if present
        const afterDoc = lastVisibleDocs[index];
        if (afterDoc) {
          baseQuery = query(baseQuery, startAfter(afterDoc));
        }

        baseQuery = query(baseQuery, limit(1));

        return baseQuery;
      });
    } else {
      // Return the base query without bounds
      let baseQuery = query(
        collection(db, "usersPublic"),
        where("helperStatus", "==", "active")
      );

      if (selectedSkills && selectedSkills.length > 0) {
        const skillNames = selectedSkills.map((skill) => skill.name);
        baseQuery = query(
          baseQuery,
          where("skills", "array-contains-any", skillNames)
        );
      }

      // Apply pagination based on the last visible document for this range, if present
      const afterDoc = lastVisibleDocs[0];
      if (afterDoc) {
        baseQuery = query(baseQuery, startAfter(afterDoc));
      }

      baseQuery = query(baseQuery, limit(1));

      return [baseQuery]; // Return as an array for consistent handling
    }
  };

  const resetFilters = () => {
    setSelectedSkills([]);
    setSelectedTimeline("anytime");
    navigate("/");
  };

  const isHelperValid = (helper) => {
    return isPointInPolygon(userCoords, helper.bounds);
  };

  const allFetched = queryStates.every((state) => !state.hasMore);

  console.log("selectedDate: ", selectedDate)

  return (
    <HelpersContext.Provider
      value={{
        helpers,
        loading,
        fetchHelpers,
        selectedSkills,
        setSelectedSkills,
        selectedTimeline,
        setSelectedTimeline,
        resetFilters,
        loadMoreHelpers,
        allFetched,
        helpersExist,
        setLoading,
        itemsPerRow,
        setItemsPerRow,
        selectedDate,
        setSelectedDate,
      }}
    >
      {children}
    </HelpersContext.Provider>
  );
};
