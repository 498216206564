// ChatContext.js
import { useMediaQuery, useTheme } from "@mui/material";
import {
  Timestamp,
  collection,
  doc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  runTransaction,
  setDoc,
  startAfter,
  updateDoc,
  where,
} from "firebase/firestore";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { db } from "../firebase";
import { findOrCreateConversation } from "../services/chatServices";
import { useAuth } from "./AuthProvider";
import { useUser } from "./UserProvider";

const ChatContext = createContext();

export function useChat() {
  return useContext(ChatContext);
}

export const ChatProvider = ({ children }) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { userPublicData } = useUser();
  const { currentUser } = useAuth();

  const [conversationsData, setConversationsData] = useState(null);
  const [conversationsLoading, setConversationsLoading] = useState(true);

  const [messagesCache, setMessagesCache] = useState(new Map());
  const listenersRef = useRef({});
  const [lastVisible, setLastVisible] = useState({}); // Store the last visible document for each conversation
  const [noMoreMessages, setNoMoreMessages] = useState({});

  const sendMessage = async ({
    message,
    otherParticipants = [],
    conversation = null,
    routingCollection = null,
    routingId = null,
  }) => {
    if (!message.trim() || (!otherParticipants.length && !conversation)) {
      console.error("Missing message content or participants/conversation");
      return {
        success: false,
        error: "Missing message content or participants/conversation",
      };
    }

    try {
      let convo = conversation;

      if (!convo && otherParticipants.length) {
        const allParticipants = [
          // Assuming currentUser and userPublicData are defined elsewhere
          {
            id: currentUser?.uid,
            firstName: userPublicData.firstName,
            lastName: userPublicData.lastName,
            avatarUrl: userPublicData.avatarUrl,
          },
          ...otherParticipants,
        ];
        convo = await findOrCreateConversation(allParticipants);
      }

      if (!convo || !convo.id) {
        console.error("Conversation could not be found or created");
        return {
          success: false,
          error: "Conversation could not be found or created",
        };
      }

      const messageData = {
        conversationId: convo.id,
        sender: currentUser?.uid,
        firstName: userPublicData?.firstName,
        lastName: userPublicData?.lastName,
        avatarUrl: userPublicData?.avatarUrl,
        content: message,
        createdAt: Timestamp.now(),
        routingCollection,
        routingId,
        participants: convo.participants,
      };

      const docRef = doc(collection(db, "messages"));
      await setDoc(docRef, { id: docRef.id, ...messageData });

      let unreadByUsers = convo.participants
        ? convo.participants.filter((id) => id !== currentUser?.uid)
        : [];

      await updateDoc(doc(db, "conversations", convo.id), {
        lastMessage: message,
        lastMessageTimestamp: Timestamp.now(),
        unreadByUsers,
      });

      return { success: true, messageId: messageData.id }; // Assuming you get an ID for the message
    } catch (error) {
      console.error("Error sending message:", error);
      return { success: false, error: error.message };
    }
  };

  const markConversationAsRead = async (conversation) => {
    const conversationRef = doc(db, "conversations", conversation.id);
    const userPrivateRef = doc(db, "usersPrivate", currentUser?.uid);

    try {
      await runTransaction(db, async (transaction) => {
        // Read the current state of the user's private document
        const userPrivateDoc = await transaction.get(userPrivateRef);
        if (!userPrivateDoc.exists()) {
          throw "User private document does not exist!";
        }

        const userPrivateData = userPrivateDoc.data();
        const unreadConversations = userPrivateData?.unreadConversations || {};
        const currentUnreadCount = unreadConversations[conversation.id] || 0;
        const totalUnreadMessages = userPrivateData?.totalUnreadMessages || 0;

        // Update the conversation document
        if (conversation.unreadByUsers?.includes(currentUser?.uid)) {
          const updatedUnreadByUsers = conversation.unreadByUsers.filter(
            (id) => id !== currentUser?.uid
          );
          transaction.update(conversationRef, {
            unreadByUsers: updatedUnreadByUsers,
          });
        }

        // Update the user's private document
        transaction.update(userPrivateRef, {
          [`unreadConversations.${conversation.id}`]: 0,
          totalUnreadMessages: Math.max(
            0,
            totalUnreadMessages - currentUnreadCount
          ),
        });
      });

      console.log("Transaction successfully committed!");
    } catch (e) {
      console.error("Transaction failed: ", e);
    }
  };

  // Function to initialize messages loading and listener
  const loadAndListenMessages = (conversationId) => {
    if (listenersRef.current[conversationId]) {
      // Listener already exists
      return;
    }

    const messagesRef = collection(db, "messages");
    const initialQuery = query(
      messagesRef,
      where("conversationId", "==", conversationId),
      orderBy("createdAt", "desc"),
      limit(20)
    );

    // Realtime listener for new messages
    const unsubscribe = onSnapshot(initialQuery, (snapshot) => {
      const newMessages = snapshot.docs.reduce((acc, doc) => {
        acc.set(doc.id, { id: doc.id, ...doc.data() });
        return acc;
      }, new Map());

      if (newMessages.size) {
        // Update last visible document
        setLastVisible((prev) => ({
          ...prev,
          [conversationId]: snapshot.docs[snapshot.docs.length - 1],
        }));
      }

      // Update messages in cache
      setMessagesCache((prevCache) => {
        const updatedCache = new Map(prevCache.get(conversationId) || []);
        newMessages.forEach((value, key) => {
          updatedCache.set(key, value);
        });
        return new Map(prevCache).set(conversationId, updatedCache);
      });
    });

    // Save unsubscribe function to stop listening when needed
    listenersRef.current[conversationId] = unsubscribe;
  };

  const loadMoreMessages = async (conversationId) => {
    const lastVisibleMessage = lastVisible[conversationId];

    if (!lastVisibleMessage) {
      return;
    }

    const messagesRef = collection(db, "messages");
    const nextQuery = query(
      messagesRef,
      where("conversationId", "==", conversationId),
      orderBy("createdAt", "desc"),
      startAfter(lastVisibleMessage),
      limit(20)
    );

    const documentSnapshots = await getDocs(nextQuery);
    const moreMessages = documentSnapshots.docs.reduce((acc, doc) => {
      acc.set(doc.id, { id: doc.id, ...doc.data() });
      return acc;
    }, new Map());

    // Check if fewer messages were returned than the limit, indicating the end
    if (Array.from(moreMessages.values()).length < 20) {
      setNoMoreMessages((prev) => ({
        ...prev,
        [conversationId]: true,
      }));
    }

    if (moreMessages.size > 0) {
      // Update last visible document for pagination
      setLastVisible((prev) => ({
        ...prev,
        [conversationId]:
          documentSnapshots.docs[documentSnapshots.docs.length - 1],
      }));

      // Update messages in cache with additional messages
      setMessagesCache((prevCache) => {
        const updatedCache = new Map(prevCache.get(conversationId) || []);
        moreMessages.forEach((value, key) => {
          updatedCache.set(key, value);
        });
        return new Map(prevCache).set(conversationId, updatedCache);
      });
    }
  };

  // Queried conversations
  useEffect(() => {
    if (!currentUser) return;

    const conversationsRef = collection(db, "conversations");
    const q = query(
      conversationsRef,
      where("participants", "array-contains", currentUser?.uid)
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const conversationsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setConversationsData(conversationsData);
    });

    setConversationsLoading(false);

    return () => unsubscribe();
  }, [currentUser]);

  // Clean up listenersRef on unmount
  useEffect(() => {
    return () => {
      Object.values(listenersRef.current).forEach((unsubscribe) =>
        unsubscribe()
      );
    };
  }, []);

  return (
    <ChatContext.Provider
      value={{
        conversationsData,
        conversationsLoading,
        messagesCache,
        loadAndListenMessages,
        loadMoreMessages,
        noMoreMessages,
        sendMessage,
        markConversationAsRead,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
