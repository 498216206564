import { KeyboardArrowLeft } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthProvider";
import { useUser } from "../../contexts/UserProvider";
import { db } from "../../firebase";
import { validateBookingPreferences } from "../../services/formServices";
import StyledSwitch from "../styled/StyledSwitch";

const BookingPreferencesView = ({
  handleNext = () => {},
  handleBack = () => {},
  canSkip = true,
  showBack = false,
}) => {
  const [loading, setLoading] = useState(false);
  const { userPrivateData } = useUser();
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    bookingDaysInAdvance: userPrivateData.bookingDaysInAdvance || "",
    minBookingMinutes: userPrivateData.minBookingMinutes || "",
    maxBookingMinutes: userPrivateData.maxBookingMinutes || null,
  });
  const [showMaxBookingMinutes, setShowMaxBookingMinutes] = useState(false);

  const { currentUser } = useAuth();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setValues({
      bookingDaysInAdvance: userPrivateData.bookingDaysInAdvance || "",
      minBookingMinutes: userPrivateData.minBookingMinutes || 30,
      maxBookingMinutes: userPrivateData.maxBookingMinutes || null,
    });
  }, [
    userPrivateData?.bookingDaysInAdvance,
    userPrivateData?.minBookingMinutes,
    userPrivateData?.maxBookingMinutes,
  ]);

  const handleSubmit = async () => {
    setLoading(true);

    let newError = validateBookingPreferences(values);
    if (Object.keys(newError).length > 0 && !canSkip) {
      setErrors(newError);
      setLoading(false); // Ensure to stop the loading process here if there are validation errors
      return; // Exit the function if there are errors
    }
    try {
      await setDoc(
        doc(db, "usersPublic", currentUser.uid),
        {
          bookingDaysInAdvance: values.bookingDaysInAdvance,
          minBookingMinutes: values.minBookingMinutes,
          maxBookingMinutes: values.maxBookingMinutes,
        },
        { merge: true }
      );

      await setDoc(
        doc(db, "usersPrivate", currentUser.uid),
        {
          bookingDaysInAdvance: values.bookingDaysInAdvance,
          minBookingMinutes: values.minBookingMinutes,
          maxBookingMinutes: values.maxBookingMinutes,
        },
        { merge: true }
      );

      handleNext();
    } catch (err) {
      console.log("error: ", err);
    } finally {
      setLoading(false);
    }
  };

  // Function to generate duration options
  const generateDurationOptions = (startMinutes = 15) => {
    const options = [];
    for (let minutes = startMinutes; minutes <= 480; minutes += 15) {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;
      let label = `${hours > 0 ? `${hours} hour${hours > 1 ? "s" : ""} ` : ""}${
        mins > 0 ? `${mins} minute${mins > 1 ? "s" : ""}` : ""
      }`.trim();
      options.push({ value: minutes, label });
    }
    return options;
  };

  // Assuming this is within your component
  const durationMinOptions = generateDurationOptions();
  const durationMaxOptions = generateDurationOptions(values?.minBookingMinutes);

  const toggleShowMaxBookingMinutes = () => {
    if (showMaxBookingMinutes) {
      setShowMaxBookingMinutes(false);
      setValues((prevValues) => ({
        ...prevValues,
        maxBookingMinutes: null,
      }));
    } else {
      setShowMaxBookingMinutes(true);
    }
  };

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;

    let actualValue = value; // Initialize actualValue with the value directly

    // Convert the value to a number if the input type is 'number'
    if (type === "number") {
      actualValue = parseFloat(value); // Parse the value as a float or integer
    }

    // // Handle checkbox inputs separately
    // if (type === "checkbox") {
    //   actualValue = checked;
    // }

    setValues((prevValues) => ({ ...prevValues, [name]: actualValue }));

    // Clear error for the field
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
    }
  };

  return (
    <>
      <DialogContent>
        {showBack && (
          <ButtonBase
            onClick={handleBack}
            disabled={loading}
            sx={{ color: "text.secondary", my: 1 }}
          >
            <KeyboardArrowLeft /> Back
          </ButtonBase>
        )}
        <Typography
          align="left"
          fontWeight={"600"}
          fontSize={"1.15rem"}
          gutterBottom
        >
          Booking preferences
        </Typography>
        <Typography align="left" color={"text.secondary"} sx={{ mb: 2 }}>
          These preferences define how others are able to book with you.
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <TextField
            type="number" // This makes the input a number type, presenting numeric keypad on mobile devices.
            sx={{ mt: 2, mb: 2 }} // For spacing, theme's default spacing is 8px, so mt: 2 equals 16px.
            fullWidth
            variant="outlined"
            name="bookingDaysInAdvance" // Name it appropriately based on its purpose
            label="Days in advance" // Clear and descriptive labels are crucial for form accessibility.
            value={values.bookingDaysInAdvance} // Ensure this is controlled via your component's state or from formik or similar.
            onChange={handleChange} // Reference your change handler
            placeholder="Days in advance to book"
            error={!!errors.bookingDaysInAdvance} // Boolean casting for error existence check.
            helperText={
              errors.bookingDaysInAdvance
                ? errors.bookingDaysInAdvance // Display the error message if it exists.
                : values.bookingDaysInAdvance
                ? `Require booking at least ${values.bookingDaysInAdvance} day${
                    values.bookingDaysInAdvance > 1 ? "s" : ""
                  } in advance.`
                : "" // You can specify a default message or leave it empty if there's no need to display anything.
            } // Provide a helper text or the error message if any.
            inputProps={{
              min: "1", // Assuming you want at least a one-day advance notice. This prevents input of negative numbers.
              step: "1", // Ensures users can only increment by whole numbers.
            }}
          />
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="duration-label">Booking minimum</InputLabel>
            {isMobile ? (
              <NativeSelect
                defaultValue={15}
                inputProps={{
                  name: "duration",
                  id: "duration-native",
                }}
                onChange={(e) =>
                  handleChange({
                    target: {
                      name: "minBookingMinutes",
                      value: e.target.value,
                      type: "number",
                    },
                  })
                }
              >
                {durationMinOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </NativeSelect>
            ) : (
              <Select
                labelId="duration-label"
                id="duration-select"
                value={values.minBookingMinutes}
                label="Booking minimum"
                onChange={(e) =>
                  handleChange({
                    target: {
                      name: "minBookingMinutes",
                      value: e.target.value,
                      type: "number",
                    },
                  })
                }
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 48 * 4.5 + 8,
                      width: 250,
                    },
                  },
                }}
              >
                {durationMinOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            )}
            <FormHelperText>
              You have a{" "}
              {
                durationMinOptions.find(
                  (option) => option.value === values.minBookingMinutes
                )?.label
              }{" "}
              minimum
            </FormHelperText>
          </FormControl>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              ml: 1,
            }}
          >
            <Typography>Set a booking maximum</Typography>
            <StyledSwitch
              checked={showMaxBookingMinutes}
              onChange={toggleShowMaxBookingMinutes}
            />
          </Box>
          {showMaxBookingMinutes && (
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="duration-label">Booking maximum</InputLabel>
              {isMobile ? (
                <NativeSelect
                  defaultValue={15}
                  inputProps={{
                    name: "duration",
                    id: "duration-native",
                  }}
                  onChange={(e) =>
                    handleChange({
                      target: {
                        name: "maxBookingMinutes",
                        value: e.target.value,
                        type: "number",
                      },
                    })
                  }
                >
                  {durationMaxOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </NativeSelect>
              ) : (
                <Select
                  labelId="duration-label"
                  id="duration-select"
                  value={values.maxBookingMinutes}
                  label="Booking minimum"
                  onChange={(e) =>
                    handleChange({
                      target: {
                        name: "maxBookingMinutes",
                        value: e.target.value,
                        type: "number",
                      },
                    })
                  }
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 48 * 4.5 + 8,
                        width: 250,
                      },
                    },
                  }}
                >
                  {durationMaxOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
              {values.maxBookingMinutes && (
                <FormHelperText>
                  You have a maximum of{" "}
                  {
                    durationMaxOptions.find(
                      (option) => option.value === values.maxBookingMinutes
                    )?.label
                  }
                </FormHelperText>
              )}
            </FormControl>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "100%",
          }}
        >
          <Button
            disableElevation
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={loading}
            sx={{
              width: "100%",
              height: "56px",
              textTransform: "none",
            }} // Take up half the available width
          >
            {loading ? ( // Conditional rendering based on loading state
              <CircularProgress size={24} color="inherit" />
            ) : (
              <Typography fontWeight={"500"}>Continue</Typography>
            )}
          </Button>

          {canSkip && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: "56px",
                alignItems: "center",
              }}
            >
              <Typography
                align="center"
                onClick={handleNext}
                fontWeight={"500"}
                sx={{ textDecoration: "underline", my: 1, cursor: "pointer" }}
              >
                Skip for now
              </Typography>
            </Box>
          )}
        </Box>
      </DialogActions>
    </>
  );
};

export default BookingPreferencesView;
