import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { formatTimeRangeCompact } from "../../services/dateServices";

const AvailabilityItem = ({
  window,
  dayStart,
  dayEnd,
  timeZone,
  type = "self",
  minBookingMinutes = 30,
  onClickAvailability = () => {},
}) => {
  const theme = useTheme();

  // console.log("Window item: ", window);

  if (
    type === "create_booking" &&
    window.end.diff(window.start, "minutes").toObject().minutes <
      minBookingMinutes
  ) {
    return;
  }

  // Extract only the time portion of the window start and end times
  const windowStartTime = window.start.hour * 60 + window.start.minute;
  const windowEndTime = window.end.hour * 60 + window.end.minute;

  // Similarly for the start and end of the day
  const dayStartTime = dayStart.hour * 60 + dayStart.minute;
  const dayEndTime = dayEnd.hour * 60 + dayEnd.minute;

  const totalDayMinutes = dayEndTime - dayStartTime;
  const startMinutes = windowStartTime - dayStartTime;
  const durationMinutes = windowEndTime - windowStartTime;

  // Calculate the top position and height as percentages
  const top = (startMinutes / totalDayMinutes) * 100;
  const windowHeight = (durationMinutes / totalDayMinutes) * 100;

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: `${top}%`,
          height: `${windowHeight}%`,
          left: { xs: 0, sm: 2, md: 5 },
          right: { xs: 2, md: 5 },
          padding: "5px",
          borderRadius: "5px",
          boxSizing: "border-box",
          backgroundColor: theme.palette.success.main,
          opacity: 0.8,
          border: "1px solid hsl(150,100%,20%)",
          zIndex: 0,
          textOverflow: "ellipsis", // Add this to truncate text
          display: "flex",
          flexDirection: "column",
          userSelect: "none",
          overflow: "hidden",
          whiteSpace: "normal", // Allows text to wrap
          wordWrap: "break-word", // Breaks words to prevent overflow
          cursor: "pointer", // Make it visually clear that it's clickable
        }}
        onClick={(event) => onClickAvailability({ event, window })} // Attach the menu open handler
      >
        <Typography
          variant="subtitle2"
          fontWeight={"600"}
          color="white"
          sx={{
            fontSize: { xs: "0.75rem", sm: "0.875rem" },
            lineHeight: { xs: "0.8rem", sm: "0.9rem" },
            mb: 0.5,
          }}
        >
          Availability
        </Typography>
        <Typography variant="caption" lineHeight={"0.8rem"} color="white">
          {formatTimeRangeCompact({
            start: window.start,
            end: window.end,
            timeZone,
          })}
        </Typography>
      </Box>
    </>
  );
};

export default AvailabilityItem;
