import { KeyboardArrowLeft } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  DialogActions,
  DialogContent,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthProvider";
import { useUser } from "../../contexts/UserProvider";
import { db } from "../../firebase";
import { validateSkillsRate } from "../../services/formServices";
import { SKILL_LIST } from "../../services/skillServices";
import StyledChecklist from "../styled/StyledChecklist";

const SkillsRateView = ({
  handleNext = () => {},
  handleBack = () => {},
  canSkip = true,
  showBack = false,
}) => {
  const [loading, setLoading] = useState(false);
  const { userPrivateData } = useUser();
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    skills: userPrivateData?.skills || [],
    hourlyRate: userPrivateData?.hourlyRate || "",
  });

  const { currentUser } = useAuth();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setValues({
      skills: userPrivateData?.skills || [],
      hourlyRate: userPrivateData?.hourlyRate || "",
    });
  }, [userPrivateData?.skills, userPrivateData?.hourlyRate]);

  const handleSubmit = async () => {
    setLoading(true);

    let newError = validateSkillsRate(values);
    if (Object.keys(newError).length > 0 && !canSkip) {
      setErrors(newError);
      setLoading(false); // Ensure to stop the loading process here if there are validation errors
      return; // Exit the function if there are errors
    }
    try {
      await setDoc(
        doc(db, "usersPublic", currentUser.uid),
        {
          skills: values.skills,
          hourlyRate: values.hourlyRate,
          helperSetup: "in_progress"
        },
        { merge: true }
      );

      await setDoc(
        doc(db, "usersPrivate", currentUser.uid),
        {
          skills: values.skills,
          hourlyRate: values.hourlyRate,
          helperSetup: "in_progress"
        },
        { merge: true }
      );

      handleNext();
    } catch (err) {
      console.log("error: ", err);
    } finally {
      setLoading(false);
    }
  };

  const handleMultipleChoiceChange = (source) => {
    setValues((prevValues) => {
      const isSelected = prevValues.skills.includes(source);
      return {
        ...prevValues,
        skills: isSelected
          ? prevValues.skills.filter((s) => s !== source)
          : [...prevValues.skills, source],
      };
    });
  };

  const handleHourlyRateChange = (e) => {
    const { value } = e.target;

    // Ensure the input is a number and format it to two decimal places
    let formattedValue = parseFloat(value).toFixed(2);

    // Update the state with the formatted value. Convert it back to a float to store as a number.
    setValues((prevValues) => ({
      ...prevValues,
      hourlyRate: parseFloat(formattedValue),
    }));

    // Clear any existing error for the hourly rate field
    if (errors.hourlyRate) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        hourlyRate: null,
      }));
    }
  };

  return (
    <>
      <DialogContent>
        {showBack && (
          <ButtonBase
            onClick={handleBack}
            disabled={loading}
            sx={{ color: "text.secondary", my: 1 }}
          >
            <KeyboardArrowLeft /> Back
          </ButtonBase>
        )}
        <Typography
          align="left"
          fontWeight={"600"}
          fontSize={"1.15rem"}
          gutterBottom
        >
          Hourly rate and skills
        </Typography>
        <Typography align="left" color={"text.secondary"} sx={{ mb: 2 }}>
          These are the tasks that you will be available for hire.
        </Typography>
        <TextField
          label="Hourly Rate"
          variant="outlined"
          type="number"
          fullWidth
          value={values.hourlyRate}
          onChange={handleHourlyRateChange}
          error={!!errors.hourlyRate}
          helperText={errors.hourlyRate}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          sx={{ my: 2 }} // Add some margin top for spacing
        />
        {errors.skills && (
          <Typography
            variant="caption"
            color="error"
            sx={{ marginTop: "3px", marginX: "14px" }}
          >
            {errors.skills}
          </Typography>
        )}
        <StyledChecklist
          columns={isMobile ? 1 : 2}
          items={SKILL_LIST}
          checkedItems={values?.skills}
          onChange={handleMultipleChoiceChange}
        />
        <div style={{ display: "flex", flexGrow: 1, minHeight: "16px" }} />
        <Typography align="left" variant="caption" color={"text.secondary"}>
          By selecting these skills, you confirm you're experienced and legally
          capable of performing them.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "100%",
          }}
        >
          <Button
            disableElevation
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={loading}
            sx={{
              width: "100%",
              height: "56px",
              textTransform: "none",
            }} // Take up half the available width
          >
            {loading ? ( // Conditional rendering based on loading state
              <CircularProgress size={24} color="inherit" />
            ) : (
              <Typography fontWeight={"500"}>Continue</Typography>
            )}
          </Button>

          {canSkip && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: "56px",
                alignItems: "center",
              }}
            >
              <Typography
                align="center"
                onClick={handleNext}
                fontWeight={"500"}
                sx={{ textDecoration: "underline", my: 1, cursor: "pointer" }}
              >
                Skip for now
              </Typography>
            </Box>
          )}
        </Box>
      </DialogActions>
    </>
  );
};

export default SkillsRateView;
