import { Help } from "@mui/icons-material";
import { Avatar, Box, Button, DialogContent, Typography } from "@mui/material";
import React, { useState } from "react";
import ResponsiveDialog from "../dialogs/ResponsiveDialog";

const HelpButton = ({ helpData = [] }) => {
  const [open, setOpen] = useState(false);

  return (
    <Box sx={{ maxWidth: "100px" }}>
      <Button
        variant="text"
        onClick={() => setOpen(true)}
        disableRipple
        sx={{
          textTransform: "none",
          height: { xs: "44px", sm: "auto" },
          width: { xs: "100px", sm: "100px" },
          px: 2,
          color: "black",
          height: "44px",
          backgroundColor: "white",
          boxSizing: "border-box",
          borderRadius: "30px",
          border: open
            ? "1px solid rgba(0, 0, 0, 0.12)"
            : "1px solid rgba(0, 0, 0, 0)",
          transition: "box-shadow, border, 0.3s ease-in-out", // Smooth transition for shadow
          boxShadow: open ? "0 5px 10px 0 rgba(0,0,0,0.15)" : "none", // Drop shadow when open
          "&:hover": {
            border: "1px solid rgba(0, 0, 0, 0.12)",
            backgroundColor: "white",
            boxShadow: "0 5px 10px 0 rgba(0,0,0,0.15)", // Slightly more pronounced shadow on hover
          },
        }}
        startIcon={<Help />}
      >
        Help
      </Button>
      <ResponsiveDialog
        open={open}
        onClose={() => setOpen(false)}
        title="Calendar help"
        width="600px"
        anchor="bottom"
        fullHeight={true}
        zIndex={2000}
      >
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          {helpData
            .filter((item) => item.condition !== false) // Only include items where condition is not explicitly false.
            .map((item, index) => {
              const IconComponent = item.icon;
              return (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 3,
                    marginBottom: 2,
                  }}
                >
                  {IconComponent && (
                    <Avatar sx={{ backgroundColor: "#f5f5f5" }}>
                      <IconComponent sx={{ color: "black" }} />
                    </Avatar>
                  )}
                  <Box>
                    <Typography
                      variant="h6"
                      fontSize={"1rem"}
                      fontWeight={"600"}
                    >
                      {item.header}
                    </Typography>
                    <Typography variant="body2">{item.body}</Typography>
                  </Box>
                </Box>
              );
            })}
        </DialogContent>
      </ResponsiveDialog>
    </Box>
  );
};

export default HelpButton;
