import { Route, Routes } from "react-router-dom";
import "./App.css";
import Hero from "./components/landing/Hero";
import DashboardPage from "./pages/DashboardPage";
import DevPage from "./pages/DevPage";
import ErrorPage from "./pages/ErrorPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import TermsOfServicePage from "./pages/TermsOfServicePage";
import SitemapPage from "./pages/SitemapPage";

function App() {
  const dashboardPaths = [
    "/",
    "/dashboard",
    "/favorites",
    "/account",
    "/explore",
    "account/helper-profile",
    "/calendar",
    "/chat",
    "/chat/:conversationParam",
    "/profile/:id",
    "/account/personal-info",
    "/reviews/:id",
    "/booking/:id",
    "/faq",
    "/invite",
    "visit/:id",
  ];

  /**
   * TaskMom MVP:
   *
   * 1. User can sign up
   *  a. Any user can be a helper
   *  b. Flexible data backend
   *  c. Flexible sidebar tabs (customizable?)
   * 2. User can browse other users
   *  a. Filter by stats, skills,
   *  b. View availability
   * 3. User can message other users
   * 4. User encouraged to refer other users
   * 5. Users can review other users
   *
   *
   * Don't get distracted:
   *
   * 1. No Google Maps
   * 2. No booking features
   * 3. No email/text notifications
   *
   */

  return (
    <Routes>
      <Route path="/landing" element={<Hero />} errorElement={<ErrorPage />} />
      <Route path="/dev" element={<DevPage />} errorElement={<ErrorPage />} />
      <Route path="/sitemap" element={<SitemapPage />} errorElement={<ErrorPage />} />


      {/* <Route path="/about" element={<AboutPage />} /> */}
      <Route path="/terms-of-service" element={<TermsOfServicePage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      {/* <Route path="/contact" element={<ContactPage />} /> */}
      {/* <Route path="/signup" element={<LoginOrSignUpPage />} /> */}
      {/* <Route path="/login" element={<LoginOrSignUpPage />} /> */}

      {/* <Route path="/welcome" element={<WelcomeCarouselPage />} /> */}

      {/* Map over the dashboardPaths to render a Route for each path */}
      {dashboardPaths.map((path) => (
        <Route
          key={path} // Important for React list rendering performance and correctness
          path={path}
          element={
            // <ProtectedRoute>
            <DashboardPage />
            // </ProtectedRoute>
          }
        />
      ))}
    </Routes>
  );
}

export default App;
