import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

const SkillsBar = ({ items, checkedItems, onChange }) => {
  const theme = useTheme();
  const scrollRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  const handleItemClick = (itemName) => {
    if (checkedItems.includes(itemName)) {
      onChange(checkedItems.filter((item) => item !== itemName));
    } else {
      onChange([...checkedItems, itemName]);
    }

    // New logic to scroll the clicked item into view
    const itemIndex = items.findIndex((item) => item.name === itemName);
    if (itemIndex >= 0) {
      // Find the DOM element of the clicked item
      const itemElement = scrollRef.current.children[itemIndex];
      const scrollContainer = scrollRef.current;

      if (itemElement && scrollContainer) {
        // Calculate the left offset of the item within the scroll container
        const itemLeftOffset = itemElement.offsetLeft;
        // Calculate the center position of the item
        const itemCenter = itemLeftOffset + itemElement.offsetWidth / 2;
        // Calculate the new scroll position to center the item
        const scrollPosition = itemCenter - scrollContainer.offsetWidth / 2;

        // Smoothly scroll to the new position
        scrollContainer.scrollTo({ left: scrollPosition, behavior: "smooth" });
      }
    }
  };

  const checkForScroll = () => {
    const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
    setShowLeftArrow(scrollLeft > 0);
    setShowRightArrow(scrollLeft < scrollWidth - clientWidth - 10);
  };

  const scroll = (direction) => {
    const { current } = scrollRef;
    if (current) {
      const scrollAmount =
        direction === "left" ? -current.offsetWidth : current.offsetWidth;
      current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  useEffect(() => {
    checkForScroll();
    // Optionally, listen to the scroll event to update arrows visibility dynamically
    const currentScrollRef = scrollRef.current;
    currentScrollRef.addEventListener("scroll", checkForScroll);
    return () => currentScrollRef.removeEventListener("scroll", checkForScroll);
  }, [items]);

  return (
    <Box
      display="flex"
      id={"This box here"}
      alignItems="center"
      overflow="hidden"
      sx={{
        px: { xs: 1, sm: 1 },
        pt: { xs: 1, sm: 1 },
        maxWidth: "100vw",
        boxSizing: "border-box",
        mt: { xs: -2, sm: 0 },
      }}
      position={"relative"}
    >
      <Box
        display="flex"
        overflow="auto"
        ref={scrollRef}
        flexGrow={1}
        sx={{
          // Webkit browsers (Chrome, Safari, newer versions of Edge)
          "&::-webkit-scrollbar": {
            display: "none",
          },
          // Disable scrollbar for IE, Edge, and Firefox
          msOverflowStyle: "none", // IE and Edge
          scrollbarWidth: "none", // Firefox
        }}
      >
        {items.map(({ name, Icon }) => (
          <Button
            variant="outlined"
            key={name}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "0px",
              height: "72px",
              mx: { xs: 1, sm: 2 },
              px: 0,
              pb: 1,
              textTransform: "none",
              boxSizing: "content-box",
              borderBottomWidth: "3px",
              borderTopWidth: "0px",
              borderLeftWidth: "0px",
              borderRightWidth: "0px",
              borderColor: checkedItems.includes(name)
                ? theme.palette.primary.main
                : "rgba(0,0,0,0)", // For unselected items
              backgroundColor: "#fff",
              color: checkedItems.includes(name)
                ? theme.palette.primary.main
                : theme.palette.grey[600], // Adjust the color for unselected items
              "&:hover": {
                backgroundColor: "unset",
                borderTopWidth: "0px",
                borderLeftWidth: "0px",
                borderRightWidth: "0px",
                borderBottomWidth: "3px",
                color: theme.palette.grey[800], // Dark grey for hover text and icon
                borderColor: theme.palette.grey[800], // Dark grey for hover border
              },
              minWidth: "auto",
              whiteSpace: "nowrap",
            }}
            onClick={() => handleItemClick(name)}
          >
            <Icon fontSize="small" sx={{ py: 1.5, color: "inherit" }} />
            <Typography variant="caption" sx={{ color: "inherit" }}>
              {name}
            </Typography>
          </Button>
        ))}
      </Box>
      <Box
        sx={{
          position: "absolute",
          left: 4,
          height: "100%", // Ensure it covers the height of your scroll area
          width: "100px", // Adjust the width based on your design needs
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          background:
            "linear-gradient(to left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)",
          opacity: showLeftArrow ? 1 : 0,
          visibility: showLeftArrow ? "visible" : "hidden",
          transition: "opacity 0.3s ease, visibility 0.3s",
          display: "flex",
          pointerEvents: "none", // Control pointer events based on showRightArrow state
        }}
      >
        <IconButton
          onClick={() => scroll("left")}
          sx={{
            marginRight: "8px",
            backgroundColor: "white",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            pointerEvents: "auto",
            transition: "box-shadow 0.3s ease, transform 0.2s ease",
            "&:hover": {
              backgroundColor: "white",
              boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.2)",
              transform: "translateY(-2px)",
            },
            "&:active": {
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              transform: "translateY(0)",
            },
          }}
        >
          <KeyboardArrowLeft />
        </IconButton>
      </Box>
      <Box
        sx={{
          position: "absolute",
          right: 4,
          height: "100%", // Ensure it covers the height of your scroll area
          width: "100px", // Adjust the width based on your design needs
          alignItems: "center",
          justifyContent: "flex-end",
          background:
            "linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)",
          opacity: showRightArrow ? 1 : 0,
          visibility: showRightArrow ? "visible" : "hidden",
          transition: "opacity 0.3s ease, visibility 0.3s",
          display: "flex",
          pointerEvents: "none", // Control pointer events based on showRightArrow state
        }}
      >
        <IconButton
          onClick={() => scroll("right")}
          sx={{
            marginLeft: "8px",
            pointerEvents: "auto",
            backgroundColor: "white",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            transition: "box-shadow 0.3s ease, transform 0.2s ease",
            "&:hover": {
              backgroundColor: "white",
              boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.2)",
              transform: "translateY(-2px)",
            },
            "&:active": {
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              transform: "translateY(0)",
            },
          }}
        >
          <KeyboardArrowRight />
        </IconButton>
      </Box>
    </Box>
  );
};

export default SkillsBar;
