import {
  Check,
  ContentCopy,
  LocationOnOutlined,
  NearMe,
} from "@mui/icons-material";
import {
  Box,
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const LocationSection = ({ address }) => {

  const [copied, setCopied] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (!copied) return;

    setTimeout(() => setCopied(false), 2000);
  }, [copied]);

  const handleAddressClick = (address) => {
    let url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      address
    )}`;

    // For mobile devices, try to open in native maps app
    if (isMobile) {
      if (navigator.userAgent.indexOf("Android") !== -1) {
        url = `geo:0,0?q=${encodeURIComponent(address)}`;
        window.location.href = url; // Change the current window location
      } else if (
        navigator.userAgent.indexOf("iPhone") !== -1 ||
        navigator.userAgent.indexOf("iPad") !== -1
      ) {
        url = `maps://maps.google.com/maps?daddr=${encodeURIComponent(
          address
        )}&amp;ll=`;
        window.location.href = url; // Change the current window location
      } else {
        window.open(url); // Fallback for other mobile devices
      }
    } else {
      window.open(url, "_blank"); // For non-mobile devices, open in a new tab
    }
  };

  const handleCopy = (data) => {
    navigator.clipboard.writeText(data).then(() => {
      // Handle success
      setCopied(true);
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: { xs: "flex-start", sm: "center" },
        gap: 1,
      }}
    >
      <Box sx={{ flex: { sm: 0.6 } }}>
        <ListItem sx={{ paddingX: "0px" }}>
          <ListItemIcon sx={{ minWidth: "38px" }}>
            <LocationOnOutlined color="primary" />
          </ListItemIcon>
          <ListItemText sx={{ marginRight: 4 }} primary={address} />
        </ListItem>
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: 0.4,
          flexDirection: { xs: "row", sm: "column" },
          gap: 1,
          width: "100%",
        }}
      >
        <Button
          variant="outlined"
          startIcon={<NearMe />}
          color="primary"
          onClick={() => handleAddressClick(address)}
          sx={{
            textTransform: "none",
            width: "100%",
            border: "1px solid rgba(0, 0, 0, 0.2)",
            height: "56px",
          }}
        >
          Navigate
        </Button>
        <Button
          variant="outlined"
          startIcon={
            copied ? <Check color="success" /> : <ContentCopy color="primary" />
          }
          color={copied ? "success" : "primary"}
          onClick={(e) => {
            e.stopPropagation(); // Stop the ListItem's onClick from being triggered
            handleCopy(address);
          }}
          sx={{
            textTransform: "none",
            width: "100%",
            border: "1px solid rgba(0, 0, 0, 0.2)",
            height: "56px",
          }}
        >
          {copied ? "Copied" : "Copy"}
        </Button>
      </Box>
    </Box>
  );
};

export default LocationSection;
