import { Timestamp, doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../firebase"; // Ensure this path matches your Firebase config file

export const findOrCreateConversation = async (participants) => {
  const conversationId = generateConversationId(participants.map((p) => p.id));

  try {
    const conversationRef = doc(db, "conversations", conversationId);
    const conversationSnapshot = await getDoc(conversationRef);

    if (conversationSnapshot.exists()) {
      // Return the existing conversation data
      return { id: conversationId, ...conversationSnapshot.data() };
    } else {
      throw new Error("Document does not exist");
    }
  } catch (err) {
    console.error("error: ", err);
    // Handle document creation or update here in case of error or non-existence
    const conversationRef = doc(db, "conversations", conversationId);
    const createdAt = Timestamp.now();
    const participantDetails = participants.reduce((details, participant) => {
      details[participant.id] = {
        firstName: participant.firstName,
        lastName: participant.lastName,
        avatarUrl: participant.avatarUrl,
      };
      return details;
    }, {});

    await setDoc(
      conversationRef,
      {
        participants: participants.map((p) => p.id),
        participantDetails,
        createdAt,
        lastRead: {},
      },
      { merge: true }
    );

    return {
      id: conversationId,
      participants: participants.map((p) => p.id),
      participantDetails,
      createdAt,
      lastRead: {},
    };
  }
};

export const generateConversationId = (participants) => {
  // Ensure the current user is included and remove duplicates
  const uniqueParticipants = Array.from(new Set([...participants]));
  // Sort IDs to ensure consistency
  uniqueParticipants.sort();
  // Concatenate into a single string to form the ID
  return uniqueParticipants.join("-");
};
