import { Close, Menu } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getAuth } from "firebase/auth";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TaskMomLogo from "../../svgs/TaskMomLogo";

function AuthHeader() {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();

  const tabs = [
    {
      route: "/#services",
      text: "Tasks",
    },
    { route: "/#howItWorks", text: "How it works" },
    { route: "/#about", text: "About" },
    { route: "/#pricing", text: "Pricing" },
    // { route: "/#referral", text: "Refer-a-friend" },
  ];

  const toggleDrawer = (open) => (event) => {
    setDrawerOpen(open);
  };

  const handleSignInClick = async () => {
    if (auth.currentUser) {
      try {
        navigate("/dashboard");
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      }
    } else {
      navigate("/login");
    }
  };

  const list = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      style={{ width: isMobile ? "100%" : "250px" }}
    >
      <nav>
        {tabs.map((tab, index) => (
          <ListItemButton
            key={index}
            onClick={() => navigate(tab.route)} // Updated to navigate to the route
            style={{ width: "100%", justifyContent: "center" }}
          >
            <Box display="flex" alignItems="center" gap={2}>
              <ListItemText primary={tab.text} />
            </Box>
          </ListItemButton>
        ))}
      </nav>
      <Box p={2} gap={2} display="flex" flexDirection="column" minWidth={200}>
        {/* {renderZipCode()} */}
        <Button
          disableElevation
          variant="contained"
          size="large"
          color="primary"
          fullWidth
          onClick={() => navigate("/")} // Updated to navigate to the route
        >
          Get started
        </Button>
      </Box>
    </div>
  );

  return (
    <AppBar
      position="sticky"
      style={{ backgroundColor: "white", zIndex: 1301 }}
      elevation={0}
    >
      <Toolbar style={{ minHeight: isMobile ? "80px" : "100px" }}>
        {isMobile ? (
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <Box width={150}>
              <Link to="/" style={{ textDecoration: "none" }}>
                <TaskMomLogo color="#000" />
              </Link>
            </Box>

            <IconButton aria-label="menu" onClick={toggleDrawer(!drawerOpen)}>
              {drawerOpen ? <Close /> : <Menu />}
            </IconButton>

            <Drawer
              anchor="top"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
              PaperProps={{
                style: {
                  top: "80px",
                  position: "absolute",
                  width: "100%",
                  zIndex: 1, // This makes sure the Drawer stays below the AppBar
                },
              }}
            >
              {list()}
            </Drawer>
          </Box>
        ) : (
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            px={3}
          >
            <Box display="flex" alignItems="center" gap={2}>
              <Box width={200}>
                <Link to="/" style={{ textDecoration: "none" }}>
                  <TaskMomLogo color="#000" />
                </Link>
              </Box>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-evenly"
              flexGrow={1}
            >
              {tabs.map((tab, index) => (
                <Button
                  key={index}
                  onClick={() => navigate(tab.route)} // Updated to navigate to the route
                  style={{
                    textDecoration: "none",
                    color: "black",
                    position: "relative",
                  }}
                >
                  <Typography
                    variant="h6"
                    component="span"
                    fontWeight="400"
                    textTransform={"none"}
                    paddingX={"12px"}
                  >
                    {tab.text}
                  </Typography>
                </Button>
              ))}
            </Box>
            <Box display="flex" alignItems="center" gap={2}>
              {/* {renderZipCode()} */}
              <Button
                onClick={() => navigate("/")} // Updated to navigate to the route
                disableElevation
                variant="contained"
                color="primary"
                sx={{ width: "125px", height: "40px" }}
              >
                Get started
              </Button>
            </Box>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default AuthHeader;
