import { EventBusy } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import { doc, runTransaction, updateDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthProvider";
import { useChat } from "../../contexts/ChatProvider";
import { useNotifications } from "../../contexts/NotificationsProvider";
import { useUser } from "../../contexts/UserProvider";
import { db, functions } from "../../firebase";
import { formatName } from "../../services/stringServices";
import MessageBox from "../boxes/MessageBox";

function CancelVisit({ role, handleClose, visitStart, visitId, visit }) {
  const [cancellationMessage, setCancellationMessage] = useState("");
  const { createNotification } = useNotifications();
  const [isCancelled, setIsCancelled] = useState(false);
  const { sendMessage } = useChat();
  const { userPrivateData } = useUser();
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);

  const action = role === "requester" ? "cancelled" : "declined";
  const actionShort = role === "requester" ? "cancel" : "decline";
  const capitalizedActionShort =
    actionShort.charAt(0).toUpperCase() + actionShort.slice(1);

  const formattedDate = visitStart.toFormat("cccc, LLLL d");

  const createCancellationBlurb = async (action, otherParticipant) => {
    const messageContent = `${formatName(
      userPrivateData.firstName
    )} ${action} the visit on ${formattedDate}`;

    await sendMessage({
      message: messageContent,
      otherParticipants: [otherParticipant],
      routingCollection: "visits",
      routingId: visitId,
    });

    // Send cancellation message if it exists
    if (cancellationMessage.trim().length > 0) {
      await sendMessage({
        message: cancellationMessage,
        otherParticipants: [otherParticipant],
      });
    }
  };

  const helperDecline = async () => {
    setLoading(true);

    await createCancellationBlurb("declined", visit.requester);

    const incrementDeclinedCancelledVisitsStats = httpsCallable(
      functions,
      "incrementDeclinedCancelledVisitsStats"
    );

    incrementDeclinedCancelledVisitsStats({
      zipCode: visit.zipCode,
      timeZone: visit.timeZone,
      updateType: "declined",
    });

    createNotification({
      userId: visit.requester.id,
      type: "visit_declined",
      message: `declined the visit on ${formattedDate}`,
      routingPath: "visit",
      routingId: visitId,
      subjectAvatarUrl: userPrivateData.avatarUrl,
      subjectDisplayName: formatName(userPrivateData.firstName),
      subjectId: userPrivateData.id,
    });

    try {
      await runTransaction(db, async (transaction) => {
        const visitRef = doc(db, "visits", visitId);
        const visitDoc = await transaction.get(visitRef);
        if (!visitDoc.exists()) throw new Error("Document does not exist!");

        const helpersNeeded = (visitDoc.data().helpersNeeded || 0) + 1;

        // Directly update the current user's status to "declined" within the helpers map
        const updatedHelpers = {
          ...visitDoc.data().helpers,
          [currentUser.uid]: {
            ...visitDoc.data().helpers[currentUser.uid],
            responseStatus: "declined",
          },
        };

        transaction.update(visitRef, {
          status: "open",
          helpers: updatedHelpers,
          helpersNeeded,
        });
      });

      setIsCancelled(true);
    } catch (error) {
      console.error("Error updating document: ", error);
    } finally {
      setLoading(false);
    }
  };

  const requesterCancel = async () => {
    setLoading(true);

    const incrementDeclinedCancelledVisitsStats = httpsCallable(
      functions,
      "incrementDeclinedCancelledVisitsStats"
    );

    incrementDeclinedCancelledVisitsStats({
      zipCode: visit.zipCode,
      timeZone: visit.timeZone,
      updateType: "cancelled",
    });

    // Send cancellation message to all helpers
    for (const helper of Object.values(visit.helpers)) {
      await createCancellationBlurb("declined", helper);

      createNotification({
        userId: helper.id,
        type: "visit_cancelled",
        message: `cancelled the visit on ${formattedDate}`,
        routingPath: "visit",
        routingId: visitId,
        subjectAvatarUrl: userPrivateData.avatarUrl,
        subjectDisplayName: formatName(userPrivateData.firstName),
        subjectId: userPrivateData.id,
      });
    }

    try {
      const visitRef = doc(db, "visits", visitId);
      await updateDoc(visitRef, { status: "cancelled" });

      setIsCancelled(true);
    } catch (error) {
      console.error("Error updating document: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAction = () => {
    if (role === "helper") {
      helperDecline();
    } else if (role === "requester") {
      requesterCancel();
    }
  };

  return (
    <>
      {!isCancelled ? (
        <>
          <DialogContent>
            <Box sx={{ maxWidth: 400, mx: "auto" }}>
              <Typography align="center" variant="body1" gutterBottom>
                Are you sure you want to {actionShort}?
              </Typography>
              <TextField
                label={`Write a message`}
                multiline
                rows={4}
                fullWidth
                variant="outlined"
                value={cancellationMessage}
                onChange={(e) => setCancellationMessage(e.target.value)}
                margin="normal"
              />
              <Typography
                variant="caption"
                display="block"
                align="center"
                color="text.secondary"
                gutterBottom
              >
                Cancelling day of will affect your reliability score.
              </Typography>
              {isCancelled && (
                <Typography variant="body2" color="success.main" sx={{ mt: 2 }}>
                  The visit has been cancelled successfully.
                </Typography>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              color="inherit"
              disabled={loading || isCancelled}
              onClick={handleClose}
              sx={{ height: "56px", width: "50%", textTransform: "none" }}
            >
              Go back
            </Button>
            <Button
              disabled={loading || isCancelled}
              variant="text"
              color="inherit"
              onClick={handleAction}
              sx={{ height: "56px", width: "50%", textTransform: "none" }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                `${capitalizedActionShort} visit`
              )}
            </Button>
          </DialogActions>
        </>
      ) : (
        <DialogContent>
          <MessageBox
            Icon={<EventBusy color="inherit" />}
            headerText={`Visit ${action}`}
            messageAlign="center"
            messageText={`Your visit on ${formattedDate} has been ${action}.`}
            buttonText="Go back"
            buttonProps={{
              variant: "contained",
              color: "primary",
              textTransform: "none",
            }}
            handleClick={handleClose}
            mt={1}
            mb={0}
          />
        </DialogContent>
      )}
    </>
  );
}

export default CancelVisit;
