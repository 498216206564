import { Box, Typography, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import React from "react";

const ColHeader = ({ date = "", avatar = "" }) => {
  const theme = useTheme(); // Access the theme

  const isToday = DateTime.local().hasSame(date, "day"); // Check if the date is today

  return (
    <Box
      sx={{
        // border: "1px solid black",
        position: "relative",
        padding: "5px",
        flexGrow: 1,
        justifyContent: "center",
        display: "flex",
        // boxSizing: "border-box",
        userSelect: "none",
      }}
    >
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "hidden",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="caption">{date.toFormat("ccc")}</Typography>
        <Typography
          variant="subtitle2"
          sx={{
            // color: isToday ? "white" : "inherit", // Text color white if today, else default
            fontWeight: isToday ? "bold" : "inherit",
            backgroundColor: isToday
              ? theme.palette.primary.lighter
              : "transparent", // Circle color if today
            borderRadius: isToday ? "50%" : "none", // Circular if today
            width: theme.spacing(4), // Width of the circle if today
            height: theme.spacing(4), // Height of the circle if today
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {date.toFormat("d")}
        </Typography>
      </Box>
    </Box>
  );
};

export default ColHeader;
