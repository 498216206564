import { KeyboardArrowLeft } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormHelperText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthProvider";
import { useUser } from "../../contexts/UserProvider";
import { db } from "../../firebase";
import { validateFacts } from "../../services/formServices";
import ExpandableListItem from "./ExpandableListItem";

const FactsView = ({
  handleNext = () => {},
  handleBack = () => {},
  canSkip = true,
  showBack = false,
  validate = true,
  buttonText = "Continue",
}) => {
  const [loading, setLoading] = useState(false);
  const { userPrivateData } = useUser();
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    myFunFact: userPrivateData?.facts?.myFunFact || "",
    myAllAbout: userPrivateData?.facts?.myAllAbout || "",
    mySecretTalent: userPrivateData?.facts?.mySecretTalent || "",
    myFamily: userPrivateData?.facts?.myFamily || "",
    myPets: userPrivateData?.facts?.myPets || "",
  });

  const { currentUser } = useAuth();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setValues({
      myFunFact: userPrivateData?.facts?.myFunFact || "",
      myAllAbout: userPrivateData?.facts?.myAllAbout || "",
      mySecretTalent: userPrivateData?.facts?.mySecretTalent || "",
      myFamily: userPrivateData?.facts?.myFamily || "",
      myPets: userPrivateData?.facts?.myPets || "",
    });
  }, [userPrivateData?.facts]);

  const handleSubmit = async () => {
    setLoading(true);

    let newError = validateFacts(values);
    if (Object.keys(newError).length > 0 && !canSkip && validate) {
      setErrors(newError);
      setLoading(false); // Ensure to stop the loading process here if there are validation errors
      return; // Exit the function if there are errors
    }
    try {
      await setDoc(
        doc(db, "usersPublic", currentUser.uid),
        {
          facts: {
            myFunFact: values.myFunFact,
            myAllAbout: values.myAllAbout,
            mySecretTalent: values.mySecretTalent,
            myPets: values.myPets,
            myFamily: values.myFamily,
          },
        },
        { merge: true }
      );

      await setDoc(
        doc(db, "usersPrivate", currentUser.uid),
        {
          facts: {
            myFunFact: values.myFunFact,
            myAllAbout: values.myAllAbout,
            mySecretTalent: values.mySecretTalent,
            myPets: values.myPets,
            myFamily: values.myFamily,
          },
        },
        { merge: true }
      );

      handleNext();
    } catch (err) {
      console.log("error: ", err);
    } finally {
      setLoading(false);
    }
  };

  // Function to count how many values are not empty strings
  const countFilledFacts = () => {
    return Object.values(values).reduce((count, value) => {
      return value.trim() !== "" ? count + 1 : count;
    }, 0);
  };

  return (
    <>
      <DialogContent>
        {showBack && (
          <ButtonBase
            onClick={handleBack}
            disabled={loading}
            sx={{ color: "text.secondary", my: 1 }}
          >
            <KeyboardArrowLeft /> Back
          </ButtonBase>
        )}
        <Typography
          align="left"
          fontWeight={"600"}
          fontSize={"1.15rem"}
          gutterBottom
        >
          Share about yourself
        </Typography>
        <Typography align="left" color={"text.secondary"}>
          Choose a few to highlight your your personality
        </Typography>
        <FormHelperText
          id="my-helper-text"
          error={!!errors.facts}
          sx={{ mb: 2 }}
        >
          {errors.facts
            ? errors.facts
            : validate && `${countFilledFacts()}/3 completed`}
        </FormHelperText>
        <ExpandableListItem
          values={values}
          setValues={setValues}
          setErrors={setErrors}
        />
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "100%",
          }}
        >
          <Button
            disableElevation
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={loading}
            sx={{
              width: "100%",
              height: "56px",
              textTransform: "none",
            }} // Take up half the available width
          >
            {loading ? ( // Conditional rendering based on loading state
              <CircularProgress size={24} color="inherit" />
            ) : (
              <Typography fontWeight={"500"}>{buttonText}</Typography>
            )}
          </Button>

          {canSkip && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: "56px",
                alignItems: "center",
              }}
            >
              <Typography
                align="center"
                onClick={handleNext}
                fontWeight={"500"}
                sx={{ textDecoration: "underline", my: 1, cursor: "pointer" }}
              >
                Skip for now
              </Typography>
            </Box>
          )}
        </Box>
      </DialogActions>
    </>
  );
};

export default FactsView;
