import { Check, ContentCopy } from "@mui/icons-material";
import { IconButton, TextField, useMediaQuery, useTheme } from "@mui/material";
import React, { useRef, useState } from "react";

const CopyUrlButton = ({ url, extraText = "" }) => {
  const inputRef = useRef(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));


  const [buttonIcon, setButtonIcon] = useState(<ContentCopy color="inherit" />);
  const [success, setSuccess] = useState(false);

  const handleCopyLink = (event) => {
    event.stopPropagation();
    // Update FAB to show copied state
    navigator.clipboard
      .writeText(url)
      .then(() => {
        // Handle success
        setSuccess(true);
      })
      .catch((err) => {
        // Handle the error
        console.error("Failed to copy text: ", err);
      });

    // Reset FAB after a delay
    setTimeout(() => {
      setSuccess(false);
    }, 3000); // Reset after 3 seconds
  };

  return (
    <TextField
      fullWidth
      onClick={handleCopyLink}
      value={url}
      InputProps={{
        readOnly: true, // Make the input read-only if you're not planning to let the user edit the content
        endAdornment: (
          <IconButton onClick={handleCopyLink}>
            {success ? (
              <Check color="success" />
            ) : (
              <ContentCopy color="inherit" />
            )}
          </IconButton>
        ),
      }}
      inputRef={inputRef} // Use the ref to get direct access to the input element
      sx={{
        backgroundColor: "white", // Light gray background
        "&:hover .MuiInputBase-input": {
          cursor: "pointer", // Change 'text' to 'pointer' if you prefer the pointer cursor
        },
        "& .MuiFormHelperText-root": {
          // Target the helper text
          color: success ? theme.palette.success.main : "disabled", // Use the success color from the theme
        },
      }}
      variant="outlined"
      helperText={
        success
          ? "Copied to clipboard"
          : (isMobile ? "Tap" : "Click") + " to copy" + " " + extraText
      }
    />
  );
};

export default CopyUrlButton;
