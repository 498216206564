// ActivityListItem.js
import { Avatar, Box, ListItem, Typography } from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import { useDialog } from "../../contexts/DialogProvider";
import { convertStateNameToAbbreviation } from "../../services/locationServices";
import { formatName } from "../../services/stringServices";
import SkillBubbles from "../profile/SkillBubbles";
import ExpandableText from "./ExpandableText";

const ActivityListItem = ({ activity }) => {
  const { createdAt, type, tasks, city, state, users, date, visitId } =
    activity;

  const { handleOpenProfile, handleOpenVisit } = useDialog();

  // Function to create a natural language list from an array (e.g., tasks)
  const formatListNaturally = (list) => {
    return (
      list.slice(0, -1).join(", ") +
      (list.length > 1 ? ` and ${list[list.length - 1]}` : "")
    );
  };

  // Handler for clicking a user chip
  const handleUserClick = (userId) => {
    handleOpenProfile(userId);
    console.log(`Clicked on user with ID: ${userId}`); // Replace with your actual functionality, like navigation
  };

  // Rendering helper and requester names
  const renderUserChip = (user, index) => (
    <Box
      key={index}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        marginRight: "2px",
        borderRadius: "16px",
        border: "1px solid transparent", // Optional: Add border if needed
        padding: "4px 4px",
        cursor: "pointer",
        transition: "background-color 0.3s ease",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.04)", // Light grey on hover
        },
      }}
      onClick={() => handleUserClick(user.id)}
    >
      <Avatar
        src={user.avatarUrl}
        alt={user.firstName}
        sx={{
          width: 24,
          height: 24,
          // borderRadius: "50%",
        }}
      />
      <Typography
        sx={{
          fontWeight: "bold",
          // textDecoration: "underline",
        }}
      >
        {formatName(user.firstName)}
      </Typography>
    </Box>
  );

  // Rendering helper and requester names
  const renderDateChip = (user, index) => (
    <Box
      key={index}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        marginRight: "2px",
        borderRadius: "16px",
        border: "1px solid transparent", // Optional: Add border if needed
        padding: "4px 4px",
        cursor: "pointer",
        transition: "background-color 0.3s ease",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.04)", // Light grey on hover
        },
      }}
      onClick={() => handleOpenVisit(visitId)}
    >
      <Typography
        sx={{
          fontWeight: "bold",
          // textDecoration: "underline",
        }}
      >
        {DateTime.fromJSDate(date?.toDate()).toFormat("MMM d")}
      </Typography>
    </Box>
  );

  // Primary text with inline chips for user names
  const renderPrimaryText = () => {
    switch (type) {
      case "new_user":
        return (
          <Box display="flex" alignItems="center">
            {renderUserChip(users[0], 0)}
            <Typography variant="body1">joined</Typography>
          </Box>
        );
      case "private_visit_booked":
        return (
          <Box display="flex" alignItems="center">
            {renderUserChip(users[0], 0)}
            <Typography variant="body1">booked</Typography>
            {renderUserChip(users[1], 1)}
          </Box>
        );
      case "public_visit_booked":
        return (
          <Box display="flex" alignItems="center">
            {renderUserChip(users[0], 0)}
            <Typography variant="body1">booked</Typography>
            {renderUserChip(users[1], 1)}
          </Box>
        );
      case "new_helper":
        return (
          <Box display="flex" alignItems="center">
            {renderUserChip(users[0], 0)}
            <Typography variant="body1">is now a helper</Typography>
          </Box>
        );
      case "new_feature":
        return (
          <Box display="flex" alignItems="center">
            {renderUserChip(users[0], 0)}
            <Typography variant="body1">released a new feature</Typography>
          </Box>
        );
      case "job_post":
        return (
          <Box display="flex" alignItems="center">
            {renderUserChip(users[0], 0)}
            <Typography variant="body1">posted a job</Typography>
            {renderDateChip()}
          </Box>
        );
      default:
        return <Typography variant="body1">New activity</Typography>;
    }
  };

  return (
    <ListItem
      alignItems="flex-start"
      disableGutters
      sx={{
        flexDirection: "column",
        alignItems: "flex-start",
        gap: 0.5,
        backgroundColor: "rgba(0,0,0,0.02)",
        borderRadius: "15px",
        p: 1,
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="body1">{renderPrimaryText()}</Typography>
        <Typography align="right" variant="body2" color="text.secondary">
          {DateTime.fromJSDate(createdAt.toDate()).toRelative({
            style: "short", // Can be "long", "short", or "narrow"
          })}
        </Typography>
      </Box>
      <SkillBubbles skills={tasks} />
      {(type === "public_visit_booked" ||
        type === "job_post" ||
        type === "new_feature") && (
        <ExpandableText text={activity.description} />
      )}
      {city && state && (
        <Typography variant="body2" color="text.secondary">
          {city}, {convertStateNameToAbbreviation(state)}
        </Typography>
      )}
    </ListItem>
  );
};

export default ActivityListItem;
