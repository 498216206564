// DashboardContext.js
import {
  AccountCircle,
  AccountCircleOutlined,
  Comment,
  CommentOutlined,
  Home,
  HomeOutlined,
  InsertInvitation,
  InsertInvitationOutlined,
  PersonSearch,
  PersonSearchOutlined,
  WavingHand,
  WavingHandOutlined,
} from "@mui/icons-material";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useMediaQuery, useTheme } from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import BookingDash from "../components/dashboard/BookingDash";
import CalendarDash from "../components/dashboard/CalendarDash";
import ChatDash from "../components/dashboard/ChatDash";
import DiscoverDash from "../components/dashboard/DiscoverDash";
import FAQDash from "../components/dashboard/FAQDash";
import HelperProfileDash from "../components/dashboard/HelperProfileDash";
import HomeDash from "../components/dashboard/HomeDash";
import InviteDash from "../components/dashboard/InviteDash";
import PersonalInfoDash from "../components/dashboard/PersonalInfoDash";
import ProfileDash from "../components/dashboard/ProfileDash";
import ReviewsDash from "../components/dashboard/ReviewsDash";
import SettingsDash from "../components/dashboard/SettingsDash";
import VisitDash from "../components/dashboard/VisitDash";
import { db } from "../firebase";
import { useAuth } from "./AuthProvider";
import { useUser } from "./UserProvider";

const DashboardContext = createContext();

export const useDashboard = () => useContext(DashboardContext);

export const DashboardProvider = ({ children }) => {
  const [selectedTab, setSelectedTab] = useState("Explore");
  const [queryParams, setQueryParams] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { currentUser } = useAuth();
  const { userPrivateData } = useUser();
  const isHelper = userPrivateData?.isHelper;

  const [inStandaloneMode, setInStandaloneMode] = useState(() => {
    return (
      window.navigator.standalone ||
      window.matchMedia("(display-mode: standalone)").matches
    );
  });

  useEffect(() => {
    if (!currentUser || userPrivateData?.appInstalled || !inStandaloneMode) {
      console.log("Effect skipped");
      return;
    }

    console.log("inStandaloneMode: ", inStandaloneMode);

    async function updateInstallationStatus() {
      const usersPublicDocRef = doc(db, "usersPublic", currentUser.uid);
      const usersPrivateDocRef = doc(db, "usersPrivate", currentUser.uid);

      try {
        await updateDoc(usersPrivateDocRef, {
          appInstalled: true,
        });
        await updateDoc(usersPublicDocRef, {
          appInstalled: true,
        });
      } catch (error) {
        console.error("Failed to update user documents:", error);
      }
    }

    updateInstallationStatus();
  }, [currentUser, userPrivateData?.appInstalled, inStandaloneMode]);

  const tabs = [
    {
      label: "Explore",
      component: DiscoverDash,
      icon: <PersonSearch />,
      outlineIcon: <PersonSearchOutlined />,
      disabled: false,
      path: "/",
      mobile: true,
      desktop: true,
    },
    {
      label: "Home",
      component: HomeDash,
      icon: <Home />,
      outlineIcon: <HomeOutlined />,
      path: "/dashboard",
      mobile: true,
      desktop: true,
    },
    {
      label: isHelper ? "Calendar" : "Visits",
      component: CalendarDash,
      icon: <InsertInvitation />,
      outlineIcon: <InsertInvitationOutlined />,
      disabled: false,
      path: "/calendar",
      mobile: true,
      desktop: true,
    },
    {
      label: "Chat",
      component: ChatDash,
      icon: <Comment />,
      outlineIcon: <CommentOutlined />,
      disabled: false,
      path: "/chat",
      mobile: true,
      desktop: true,
    },
    {
      label: "Account",
      component: SettingsDash,
      icon: <AccountCircle />,
      outlineIcon: <AccountCircleOutlined />,
      disabled: false,
      path: "/account",
      mobile: true,
      desktop: false,
    },
    {
      label: "Profile",
      component: ProfileDash,
      icon: null,
      outlineIcon: null,
      disabled: null,
      path: "/profile/:id",
      mobile: null,
      hidden: true,
    },
    {
      label: "Helper Preferences",
      component: HelperProfileDash,
      icon: <WavingHand />,
      outlineIcon: <WavingHandOutlined />,
      disabled: false,
      path: "/account/helper-profile",
      mobile: null,
      hidden: true,
    },
    {
      label: "Personal Info",
      component: PersonalInfoDash,
      icon: null,
      outlineIcon: null,
      disabled: null,
      path: "/account/personal-info",
      mobile: null,
      hidden: true,
    },
    {
      label: "FAQ",
      component: FAQDash,
      icon: null,
      outlineIcon: null,
      disabled: null,
      path: "/faq",
      mobile: null,
      hidden: true,
    },
    {
      label: "Invite",
      component: InviteDash,
      icon: null,
      outlineIcon: null,
      disabled: null,
      path: "/invite",
      mobile: null,
      hidden: true,
    },
    {
      label: "Visit",
      component: VisitDash,
      icon: null,
      outlineIcon: null,
      disabled: null,
      path: "/visit/:id",
      mobile: null,
      hidden: true,
    },
    {
      label: "Reviews",
      component: ReviewsDash,
      icon: null,
      outlineIcon: null,
      disabled: null,
      path: "/reviews/:id",
      mobile: null,
      hidden: true,
    },
    {
      label: "Booking",
      component: BookingDash,
      icon: null,
      outlineIcon: null,
      disabled: null,
      path: "/booking/:id",
      mobile: null,
      hidden: true,
    },
  ];

  const changeTab = (label) => {
    const tab = tabs.find((t) => t.label === label);
    if (!tab) return;

    console.log("changing tab: ", label);

    if (isMobile && inStandaloneMode) {
      setSelectedTab(tab.label);
    } else {
      navigate(tab.path);
      window.scrollTo(0, 0);
    }
  };

  // Update useEffect to depend on location.pathname
  useEffect(() => {
    if (isMobile && inStandaloneMode) return;
    const currentPath = location.pathname;

    // Try to find an exact match first
    const exactMatch = tabs.find((tab) => tab.path === currentPath);

    if (exactMatch) {
      setSelectedTab(exactMatch.label);
    } else {
      // Extract segments of the path
      const pathSegments = currentPath.split("/").filter(Boolean); // This removes any empty strings

      // Initialize variable to keep track of a potential root match
      let rootMatch = null;

      // Iterate through segments from left to right to find a root match
      for (let i = 0; i < pathSegments.length && !rootMatch; i++) {
        rootMatch = tabs.find((tab) => tab.path.includes(pathSegments[i]));
      }

      if (rootMatch) {
        setSelectedTab(rootMatch.label);
      } else {
        setSelectedTab(null); // or handle as needed for when there's no match
      }
    }
  }, [location.pathname, tabs]); // Depend on location.pathname and tabs to re-run effect on change

  return (
    <DashboardContext.Provider
      value={{
        selectedTab,
        changeTab,
        queryParams,
        setQueryParams,
        tabs,
        inStandaloneMode,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
