import {
  CheckCircle,
  Checklist,
  Event,
  PersonAddOutlined,
  WavingHand,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthProvider";
import { useDashboard } from "../../contexts/DashboardContext";
import { useDialog } from "../../contexts/DialogProvider";
import { useUser } from "../../contexts/UserProvider";
import { db } from "../../firebase";
import UserHelperProfile from "../account/UserHelperProfile";
import MessageBox from "../boxes/MessageBox";
import DesktopBackButton from "../buttons/DesktopBackButton";
import StyledAlert from "../styled/StyledAlert";

const HelperProfileDash = () => {
  const { userPrivateData } = useUser(); // Fetch user data
  const { currentUser } = useAuth();

  const { handleOpenCreateProfile, handleOpenLoginOrSignUp } = useDialog();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { changeTab } = useDashboard();

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  console.log(timeZone);

  // for booking view
  const [calendarDate, setCalendarDate] = useState(new Date());

  // Check if personal info and other necessary onboarding steps are completed
  const setupStatus = userPrivateData?.accountSetup;

  const stage = userPrivateData?.helperSetup || "not_started";

  const progressHelperSetupStage = async (value) => {
    // Assuming `userId` is available and identifies the document in your Firestore collection
    const userId = currentUser.uid; // You need to replace this with actual user ID logic
    const userDocRef = doc(db, "usersPrivate", userId); // Replace "users" with your actual collection name

    try {
      await setDoc(
        userDocRef,
        {
          helperSetup: value, // Assuming you want to set this to true
        },
        { merge: true }
      );
      console.log("User onboarding status updated successfully");
    } catch (error) {
      console.error("Error updating user onboarding status:", error);
    }
  };

  const handleBecome = () => {
    if (!currentUser || currentUser?.isAnonymous) {
      handleOpenLoginOrSignUp({ setupType: "helper" });
      return;
    } else {
      handleOpenCreateProfile({ setupType: "helper" });
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-start",
            height: "52px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
            <DesktopBackButton />

            <Typography
              variant="h4"
              component="h1"
              sx={{ ml: { xs: 0, sm: 0 } }}
            >
              Helper Preferences
            </Typography>
          </Box>
          {/* {userData && (
            <Box sx={{ top: -6, position: "relative" }}>
              <UserShareButton user={userData} />
            </Box>
          )} */}
        </Box>
        <Divider sx={{ mx: { xs: -2, sm: 0 }, mb: 2 }} />
      </Box>

      {/* If the account is not complete, display banner to setup account */}
      {setupStatus !== "complete" && setupStatus !== "show_success" && (
        <StyledAlert
          desktopTitle={"Complete Account"}
          desktopDescription={
            "Finish creating your account before becoming a Helper"
          }
          mobileTitle={"You need to complete your account before starting."}
          buttonText={"Go To"}
          handleClick={() => changeTab("Home")}
        />
      )}

      {stage === "not_started" && (
        <MessageBox
          Icon={<WavingHand color="primary" />}
          headerText="Help Others"
          messageText="Make a difference in your community by becoming a Helper. List your skills, service area, references, availability, and rates."
          buttonText="Setup"
          buttonProps={{ variant: "contained", color: "primary" }}
          hideButton={true}
          customButton={
            (setupStatus === "complete" || setupStatus === "show_success") && (
              <Button
                variant="outlined"
                disableElevation
                startIcon={<PersonAddOutlined />}
                sx={{ height: "56px", width: "100%", textTransform: "none" }}
                onClick={handleBecome}
              >
                Become a helper
              </Button>
            )
          }
        />
      )}

      {stage === "in_progress" && (
        <MessageBox
          Icon={<Checklist color="primary" />}
          headerText="Complete your Setup"
          messageText="Please take a few moments to finish becoming a helper."
          buttonText="Complete"
          messageAlign="center"
          buttonProps={{ variant: "contained", color: "primary" }}
          handleClick={handleBecome}
        />
      )}

      {stage === "show_success" && (
        <MessageBox
          Icon={<CheckCircle color="primary" />}
          headerText="Setup Complete"
          messageText="You are listed as a helper! Set your availability in the calendar. Be sure to keep your availability up to date."
          hideButton={true}
          customButton={
            <Button
              variant="outlined"
              disableElevation
              startIcon={<Event />}
              sx={{ height: "56px", width: "100%", textTransform: "none" }}
              onClick={() => {
                progressHelperSetupStage("complete");
                changeTab("Calendar");
              }}
            >
              Set Availability
            </Button>
          }
        />
      )}

      {stage === "complete" && (
        <>
          <UserHelperProfile />
        </>
      )}
    </div>
  );
};

export default HelperProfileDash;
