import { ArrowUpward } from "@mui/icons-material";
import { Box, IconButton, List, ListItem, Skeleton } from "@mui/material";
import React, { useRef } from "react";
import { useActivity } from "../../contexts/ActivityProvider";
import ActivityListItem from "./ActivityListItem";

const ActivityView = () => {
  const listRef = useRef(null);
  const { activities, showScrollToTop, FETCH_LIMIT, isLoading } = useActivity();

  const renderSkeleton = () => (
    <ListItem
      sx={{ backgroundColor: "#F5F5F5", borderRadius: "15px", p: 1, mt: 1 }}
    >
      <Box
        sx={{ display: "flex", flexDirection: "column", gap: 1, width: "100%" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              p: "4px",
              display: "flex",
              flexDirection: "row",
              gap: 1,
              alignItems: "center",
              width: "100%",
            }}
          >
            <Skeleton variant="circular" width={24} height={24} />
            <Skeleton variant="rounded" width={"40%"} height={20} />
          </Box>
          <Skeleton variant="rounded" width={"20%"} height={20} />
        </Box>
        <Skeleton variant="rounded" width={"30%"} height={20} />
      </Box>
    </ListItem>
  );

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Box
        sx={{
          p: { xs: 0, sm: 2 },
          position: "relative",
          scrollBehavior: "smooth", // Add this line for smooth scrolling
        }}
      >
        <List disablePadding>
          {/* Reverse the order for display, since the latest activities are at the bottom */}
          {[...activities].map((activity, index, arr) => (
            <React.Fragment key={activity.createdAt}>
              <ActivityListItem activity={activity} />
              {index !== arr.length - 1 && <Box sx={{ my: 1 }} />}
            </React.Fragment>
          ))}
        </List>
        {isLoading &&
          Array.from({ length: FETCH_LIMIT }).map((_, index) =>
            renderSkeleton()
          )}

        {/* <Button onClick={() => loadMoreActivities()}>Load more</Button> */}
      </Box>
      {/* <Box
        sx={{
          position: "absolute",
          top: 16,
          left: 0,
          right: 0,
          display: "flex",
          justifyContent: "center",
          pointerEvents: "none",
        }}
      >
        <IconButton
          sx={{
            pointerEvents: "auto",
            backgroundColor: "primary.main",
            opacity: showScrollToTop ? 1 : 0,
            visibility: showScrollToTop ? "visible" : "hidden",
            color: "white",
            transition:
              "opacity 0.15s ease-in-out, visibility 0.15s ease-in-out",
            transitionDelay: showScrollToTop ? "0s" : "0.15s", // Delay hiding visibility until after opacity transition
            "&:hover": {
              backgroundColor: "primary.dark",
            },
          }}
          onClick={() => {
            if (listRef.current) {
              listRef.current.scrollTop = 0; // Scrolls to the top of the element
            }
          }}
        >
          <ArrowUpward />
        </IconButton>
      </Box> */}
    </Box>
  );
};

export default ActivityView;
