import { DateTime } from "luxon";

export const validateIdentity = (values) => {
  let errors = {};
  if (!values.firstName) errors.firstName = "First Name is required";
  if (!values.lastName) errors.lastName = "Last Name is required";
  if (!values.birthday) errors.birthday = "Birthday is required";
  if (values.birthday) {
    const now = DateTime.now();
    const birthday = DateTime.fromJSDate(new Date(values.birthday));
    const age = now.diff(birthday, "years").years;
    if (age < 18) {
      errors.birthday = "You must be 18 or older to create an account.";
    }
  }
  if (!values.gender) errors.gender = "Gender is required";

  return errors;
};

export const validatePhone = (values) => {
  let errors = {};

  if (!values.phone || values.phone.replace(/\D/g, "").length !== 10)
    errors.phone = "Your phone number must be 10 digits.";
  return errors;
};

export const validateAbout = (values) => {
  let errors = {};

  if (!values.bio)
    errors.bio = "Please write a short bio to introduce yourself.";
  return errors;
};

export const validateSkillsRate = (values) => {
  let errors = {};

  if (values.skills.length === 0)
    errors.skills = "At least one skill is required";
  if (!values.hourlyRate || values.hourlyRate <= 0) {
    errors.hourlyRate = "Hourly rate must be greater than 0";
  }
  return errors;
};

export const validateBookingPreferences = (values) => {
  let errors = {};

  if (!values.bookingDaysInAdvance || values.bookingDaysInAdvance < 1)
    errors.bookingDaysInAdvance = "Days in Advance must be at least 1 day.";
  return errors;
};

export const validateBounds = (values) => {
  let errors = {};

  if (!values.bounds || values.bounds.length === 0)
    errors.bounds = "You must draw your service map.";

  return errors;
};

export const validateReferences = (values) => {
  let errors = {};

  // Check if there are no references
  if (values.references.length === 0) {
    errors.references = "At least one reference is required";
  } else {
    // Initialize a flag to indicate if all references are valid
    let allReferencesValid = true;

    // Validate each reference
    values.references.forEach((reference, index) => {
      if (!reference.referenceName || reference.referenceName.trim() === "") {
        errors[`reference_${index}_referenceName`] =
          "Reference name is required";
        allReferencesValid = false;
      }
      if (reference.phone.replace(/\D/g, "").length !== 10) {
        errors[`reference_${index}_phone`] = "Phone number is invalid";
        allReferencesValid = false;
      }
    });
  }

  return errors;
};

export const validateFacts = (values) => {
  let errors = {};

  // Create an array of these values and filter out any falsy values to count only filled entries
  const filledFactsCount = [
    values.myFunFact,
    values.myAllAbout,
    values.mySecretTalent,
    values.myPets,
    values.myFamily,
  ].filter(Boolean).length;

  // Check if less than three facts are filled
  if (filledFactsCount < 3) {
    errors.facts = "Please fill out at least 3 fields.";
  }

  return errors;
};

export const validateAvatar = (values) => {
  let errors = {};

  if (!values.avatarUrl) errors.avatarUrl = "Please add a profile picture.";

  return errors;
};

export const validateAddress = (values) => {
  let errors = {};
  if (!values.line1) errors.line1 = "Address Line 1 is required";
  if (!values.city) errors.city = "City is required";
  if (!values.state) errors.state = "State is required";
  if (!values.zipCode) errors.zipCode = "Zip Code is required";

  return errors;
};

export const validateBio = (values) => {
  let errors = {};

  if (!values.bio) errors.bio = "Please introduce yourself with a short bio";

  return errors;
};
