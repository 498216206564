import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import React from "react";
import { useDialog } from "../../contexts/DialogProvider";
import TaskMomLogo from "../../svgs/TaskMomLogo";

const CompleteView = ({ handleClose = () => {} }) => {
  const { handleOpenCreateProfile } = useDialog();

  return (
    <>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            my: 5,
          }}
        >
          <Box width="200px">
            <TaskMomLogo color="#000" />
          </Box>
        </Box>
        <Typography
          align="left"
          fontWeight={"600"}
          fontSize={"1.15rem"}
          gutterBottom
        >
          Your profile is complete
        </Typography>
        <Typography align="left" color={"text.secondary"}>
          Enjoy the platform!
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={handleClose}
          sx={{
            width: "100%",
            height: "56px",
            textTransform: "none",
          }} // Take up half the available width
        >
          <Typography fontWeight={"500"}>Continue</Typography>
        </Button>
      </DialogActions>
    </>
  );
};

export default CompleteView;
