import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

const SingleLineFooter = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        position: { xs: "relative", sm: "absolute" },
        backgroundColor: { xs: "none", sm: "#F5F5F5" },
        alignItems: "center",
        borderTop: { xs: "none", sm: "1px solid rgba(0,0,0,0.06)" },
        justifyContent: "center",
        height: { xs: "auto", sm: "32px" },
        borderRadius: { xs: "15px", sm: "none" },
        zIndex: 1000,
        px: 2,
        py: { xs: 2, sm: 0 },
        my: { xs: 4, sm: 0 },
        gap: 1,
        bottom: { xs: "unset", sm: 0 },
        left: { xs: "unset", sm: 0 },
        right: { xs: "unset", sm: 0 },
        boxSizing: "border-box",
      }}
    >
      <Typography variant="body2">
        © {new Date().getFullYear()} Linked Lives, LLC. All Rights Reserved.
      </Typography>
      {!isMobile && "・"}
      <Typography variant="body2">
        <a
          href="/terms-of-service"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "black" }} // Added inline style here
          aria-label="Terms of Service" // Adding ARIA label for accessibility
        >
          Terms of Service
        </a>
      </Typography>

      {!isMobile && "・"}

      <Typography variant="body2">
        <a
          href="/sitemap"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "black" }} // Added inline style here
          aria-label="Sitemap" // Adding ARIA label for accessibility
        >
          Sitemap
        </a>
      </Typography>

      {!isMobile && "・"}
      <Typography variant="body2">
        <a
          href="/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "black" }} // Added inline style here
        >
          Privacy Policy
        </a>
      </Typography>
      {!isMobile && "・"}
      <Typography variant="body2">
        <a
          href="/landing"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "black" }} // Added inline style here
          aria-label="Landing" // Adding ARIA label for accessibility
        >
          Landing
        </a>
      </Typography>
    </Box>
  );
};

export default SingleLineFooter;
