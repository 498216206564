import {
  Box,
  Button,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useAuth } from "../../contexts/AuthProvider";
import { useDialog } from "../../contexts/DialogProvider";
import UserPersonalInfo from "../account/UserPersonalInfo";
import DesktopBackButton from "../buttons/DesktopBackButton";

const PersonalInfoDash = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { currentUser } = useAuth();
  const { handleOpenProfile } = useDialog();

  return (
    <div style={{ position: "relative" }}>
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-start",
            height: "52px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
            <DesktopBackButton />

            <Typography
              variant="h4"
              component="h1"
              sx={{ ml: { xs: 0, sm: 0 } }}
            >
              My profile
            </Typography>
          </Box>
          <Button onClick={() => handleOpenProfile(currentUser?.uid)}>
            View profile
          </Button>
        </Box>
        <Divider sx={{ mx: { xs: -2, sm: 0 }, mb: 2 }} />
      </Box>
      <UserPersonalInfo />
    </div>
  );
};

export default PersonalInfoDash;
