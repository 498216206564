import {
  AccessTime,
  Event,
  EventBusy,
  EventRepeat,
  LocationOnOutlined,
  Payment,
} from "@mui/icons-material";
import {
  Box,
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { formatDate, formatTimeRange } from "../../services/dateServices";

const DateTimeSection = ({
  start,
  end,
  helpers = {},
  status,
  role,
  line1,
  line2,
  setOpenReschedule,
  setOpenCancel,
  myResponse,
}) => {
  // Calculate duration in hours
  const durationMinutes = end.diff(start, "minutes").minutes;

  // Calculate total estimated cost for all helpers excluding those who declined
  const estimatedTotal = Object.values(helpers)
    .filter((helper) => helper.responseStatus !== "declined") // Exclude declined helpers
    .reduce((total, helper) => {
      const hourlyRate = helper.hourlyRate || 0;
      return total + (durationMinutes / 60) * hourlyRate;
    }, 0)
    .toFixed(2);

  const renderRequesterActions = () => {
    return (
      <>
        <Button
          variant="outlined"
          color="inherit"
          startIcon={<EventRepeat color="text.secondary" />}
          onClick={() => setOpenReschedule(true)}
          sx={{
            textTransform: "none",
            width: "100%",
            border: "1px solid rgba(0, 0, 0, 0.2)",
            height: "56px",
            color: "text.secondary",
          }}
        >
          Reschedule
        </Button>
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => setOpenCancel(true)}
          startIcon={<EventBusy color="text.secondary" />}
          sx={{
            textTransform: "none",
            width: "100%",
            border: "1px solid rgba(0, 0, 0, 0.2)",
            height: "56px",
            color: "text.secondary",
          }}
        >
          Cancel
        </Button>
      </>
    );
  };

  const renderHelperActions = () => {
    return (
      <>
        {/* <Button
          variant="outlined"
          color="inherit"
          startIcon={<EventRepeat color="text.secondary" />}
          sx={{
            textTransform: "none",
            width: "100%",
            border: "1px solid rgba(0, 0, 0, 0.2)",
            height: "56px",
            color: "text.secondary",
          }}
        >
          Reschedule
        </Button> */}
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => setOpenCancel(true)}
          startIcon={<EventBusy color="text.secondary" />}
          sx={{
            textTransform: "none",
            width: "100%",
            border: "1px solid rgba(0, 0, 0, 0.2)",
            height: "56px",
            color: "text.secondary",
          }}
        >
          Decline
        </Button>
      </>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: { xs: "flex-start", sm: "center" },
        gap: 1,
      }}
    >
      <Box sx={{ flex: { sm: 0.6 } }}>
        <ListItem sx={{ paddingX: "0px", pb: 0 }}>
          <ListItemIcon sx={{ minWidth: "38px" }}>
            <Event color="primary" />
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="h6">{formatDate(start)}</Typography>}
          />
        </ListItem>
        <ListItem sx={{ paddingX: "0px", py: 0 }}>
          <ListItemIcon sx={{ minWidth: "38px" }}>
            <AccessTime color="primary" />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                {formatTimeRange(start, end)}
              </Typography>
            }
          />
        </ListItem>
        {role === "requester" && (
          <ListItem sx={{ paddingX: "0px", py: 0 }}>
            <ListItemIcon sx={{ minWidth: "38px" }}>
              <LocationOnOutlined color="primary" />
            </ListItemIcon>
            <ListItemText
              sx={{ marginRight: 4 }}
              primary={`${line1}${line2 ? ` ${line2}` : ""}`}
            />
          </ListItem>
        )}
        {!isNaN(estimatedTotal) && estimatedTotal > 0 && (
          <ListItem sx={{ paddingX: "0px", pt: 0 }}>
            <ListItemIcon sx={{ minWidth: "38px" }}>
              <Payment color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body1">
                  Estimated total: ${estimatedTotal}
                </Typography>
              }
            />
          </ListItem>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: 0.4,
          flexDirection: { xs: "row", sm: "column" },
          gap: 1,
          width: "100%",
        }}
      >
        {role === "requester" &&
          myResponse !== "declined" &&
          (status === "confirmed" || status === "open") &&
          renderRequesterActions()}
        {role === "helper" &&
          myResponse !== "declined" &&
          (status === "confirmed" || status === "open") &&
          renderHelperActions()}
      </Box>
    </Box>
  );
};

export default DateTimeSection;
