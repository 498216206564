import { useTheme } from "@emotion/react";
import { KeyboardArrowRight, SendToMobileOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Divider,
  ListItemButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { useAuth } from "../../contexts/AuthProvider";
import { useDashboard } from "../../contexts/DashboardContext";
import { useDialog } from "../../contexts/DialogProvider";
import { useUser } from "../../contexts/UserProvider";
import { useVisits } from "../../contexts/VisitProvider";
import ActivityView from "../activity/ActivityView";
import MessageBox from "../boxes/MessageBox";
import CopyUrlButton from "../buttons/CopyUrlButton";
import InstallFab from "../buttons/InstallFab";
import NotificationBell from "../notifications/NotificationBell";

const HomeDash = () => {
  const { userPrivateData, userPrivateLoading, updateUserSetup } = useUser(); // Fetch user data
  const { currentUser } = useAuth();
  const { upcomingVisitId, visitsData } = useVisits();
  const { handleOpenCreateProfile, handleOpenInvite, handleOpenLoginOrSignUp } =
    useDialog();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { changeTab } = useDashboard();

  // Check if personal info and other necessary onboarding steps are completed
  const stage = userPrivateData?.accountSetup || "not_started";

  const accountSetup =
    userPrivateData?.accountSetup === "complete" ||
    userPrivateData?.accountSetup === "show_success";

  const helperSetup =
    userPrivateData?.helperSetup === "complete" ||
    userPrivateData?.helperSetup === "show_success";

  const renderHome = () => {
    return (
      <Box
        sx={{ display: "flex", width: "100%", flexDirection: "column", mt: 2 }}
      >
        {/* {upcomingVisitId &&
        visitsData[upcomingVisitId] &&
        Object.keys(visitsData[upcomingVisitId]).length > 0 ? (
          <>
            <Typography
              variant="body1"
              fontWeight={"600"}
              color={"primary"}
              gutterBottom
              align="center"
            >
              Upcoming Visit
            </Typography>
            <VisitPreviewCard visitId={upcomingVisitId} />
          </>
        ) : (
          !helperSetup && (
            <MessageBox
              Icon={<Explore color="primary" />}
              headerText="Book Help"
              messageText="You have no upcoming visits scheduled. Browse help near you and book whenever you're ready!"
              hideButton={true}
              customButton={
                <Button
                  variant="outlined"
                  disableElevation
                  startIcon={<PersonSearchOutlined />}
                  sx={{ height: "56px", width: "100%", textTransform: "none" }}
                  onClick={() => {
                    changeTab("Explore");
                  }}
                >
                  Find help
                </Button>
              }
              messageAlign="center"
            />
          )
        )} */}
      </Box>
    );
  };

  const handleBecome = () => {
    if (!currentUser || currentUser?.isAnonymous) {
      handleOpenLoginOrSignUp({ setupType: "helper" });
      return;
    } else {
      handleOpenCreateProfile({ setupType: "helper" });
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "relative",
          width: "100%",
        }}
      >
        <Typography variant="h4" component="h1" sx={{ height: "52px" }}>
          Home
        </Typography>
        {isMobile && (
          <Box sx={{ pb: 1, position: "absolute", right: 0, top: -6 }}>
            <NotificationBell />
          </Box>
        )}
      </Box>
      <Divider sx={{ mx: { xs: -2, sm: 0 } }} />
      {/* <GoogleMapsDrawing
        initialBounds={userPrivateData?.bounds}
        initialCenter={{ lat: userPrivateData?.lat, lng: userPrivateData?.lng }}
      /> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column-reverse", md: "row" },
          gap: 2,
        }}
      >
        {/* Main Area */}
        <Box sx={{ flex: 0.6 }}>
          {/* {(!currentUser || currentUser?.isAnonymous) && (
            <MessageBox
              Icon={<Diversity3 color="primary" />}
              headerText="Login or Sign Up"
              messageText="To access all features, please take a few moments to login or sign up."
              buttonText="Login or Sign Up"
              messageAlign="center"
              buttonProps={{
                variant: "contained",
                color: "primary.main",
                textTransform: "none",
              }}
              handleClick={() => handleOpenLoginOrSignUp({ canSkip: true })}
            />
          )}
          {stage !== "complete" && currentUser && !currentUser?.isAnonymous && (
            <MessageBox
              Icon={<Diversity3 color="primary" />}
              headerText="Complete Your Profile"
              messageText="To access all features, please take a few moments to complete your profile."
              buttonText="Complete profile"
              messageAlign="center"
              buttonProps={{
                variant: "contained",
                color: "primary.main",
                textTransform: "none",
              }}
              handleClick={() => handleOpenCreateProfile({ canSkip: true })}
            />
          )} */}
          <ActivityView />

          {/* {stage === "complete" && renderHome()} */}
        </Box>
        {/* Announcement Area */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 0.4,
            gap: 2,
            mt: { xs: 2, sm: 2 },
          }}
        >
          {helperSetup && (
            <MessageBox
              Icon={<SendToMobileOutlined color="primary" />}
              headerText="Get More Jobs"
              messageText="Share your personal booking link with others to get more jobs and grow your client base!"
              hideButton={true}
              messageAlign="center"
              mb={2}
              mt={2}
              customButton={
                <CopyUrlButton
                  url={`${window.location.origin}/profile/${currentUser?.uid}${
                    currentUser?.uid ? `?ref=${currentUser.uid}` : ""
                  }`}
                  extraText={"your personal booking link"}
                />
              }
            />
          )}
          {helperSetup && (
            <Box
              sx={{
                backgroundColor: "white",
                borderRadius: "15px",
                boxShadow:
                  "0px 10px 20px rgba(0,0,0,0.1), 0px 3px 6px rgba(0,0,0,0.1)",
              }}
            >
              <ListItemButton
                onClick={() => changeTab("Calendar")}
                variant="outlined"
                sx={{
                  p: 3,
                  textTransform: "none",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  borderRadius: "15px",
                  transition: "border-color 0.3s ease",
                  border: `1px solid rgba(0,0,0,0)`,
                  "&:hover": {
                    borderColor: theme.palette.grey[500],
                  },
                }}
              >
                <Typography
                  variant="subtitle2"
                  fontSize={"1rem"}
                  fontWeight={"600"}
                >
                  Review your availability
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  A quick review of your availability helps smooth out the
                  booking process and cuts down on the back-and-forth.
                </Typography>
                <Button
                  endIcon={<KeyboardArrowRight />}
                  variant="text"
                  sx={{ textTransform: "none" }}
                  onClick={() => changeTab("Calendar")}
                >
                  Review
                </Button>
              </ListItemButton>
            </Box>
          )}
          <Box
            sx={{
              backgroundColor: "white",
              borderRadius: "15px",
              boxShadow:
                "0px 10px 20px rgba(0,0,0,0.1), 0px 3px 6px rgba(0,0,0,0.1)",
            }}
          >
            <ListItemButton
              onClick={handleOpenInvite}
              variant="outlined"
              sx={{
                p: 3,
                textTransform: "none",
                flexDirection: "column",
                alignItems: "flex-start",
                borderRadius: "15px",
                transition: "border-color 0.3s ease",
                border: `1px solid rgba(0,0,0,0)`,
                "&:hover": {
                  borderColor: theme.palette.grey[500],
                },
                zIndex: 10,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography
                  variant="subtitle2"
                  fontSize={"1rem"}
                  fontWeight={"600"}
                >
                  Refer a friend
                </Typography>
                {userPrivateData?.referralStats?.count && (
                  <Chip
                    size="small"
                    label={`${userPrivateData?.referralStats?.count} joined`}
                  />
                )}
              </Box>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                Share your invite link with a friend interested in finding or
                offering help on Taskmom.
              </Typography>
              <CopyUrlButton
                url={`${window.location.origin}${
                  currentUser?.uid ? `?ref=${currentUser.uid}` : ""
                }`}
              />
            </ListItemButton>
          </Box>
          {accountSetup && !userPrivateData?.isHelper && (
            <Box
              variant="outlined"
              sx={{
                backgroundColor: "white",
                borderRadius: "15px",
                boxShadow:
                  "0px 10px 20px rgba(0,0,0,0.1), 0px 3px 6px rgba(0,0,0,0.1)",
              }}
            >
              <ListItemButton
                variant="outlined"
                onClick={handleBecome}
                sx={{
                  p: 3,
                  textTransform: "none",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  borderRadius: "15px",
                  transition: "border-color 0.3s ease",
                  border: `1px solid rgba(0,0,0,0)`,
                  "&:hover": {
                    borderColor: theme.palette.grey[500],
                  },
                }}
              >
                <Typography
                  variant="subtitle2"
                  fontSize={"1rem"}
                  fontWeight={"600"}
                >
                  Become a helper
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Do you have a skill that you would like to offer? Get started
                  helping others by becoming a Helper.
                </Typography>
                <Button
                  endIcon={<KeyboardArrowRight />}
                  variant="text"
                  sx={{ textTransform: "none" }}
                  onClick={handleBecome}
                >
                  Get started
                </Button>
              </ListItemButton>
            </Box>
          )}
        </Box>
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 0.4,
            gap: 2,
          }}
        >
        </Box> */}
      </Box>
      <InstallFab />
    </>
  );
};

export default HomeDash;
