import {
  Celebration,
  CelebrationOutlined,
  Close,
  CottageOutlined,
  CottageRounded,
  InterestsOutlined,
  InterestsRounded,
  Palette,
  PaletteOutlined,
  Pets,
  PetsOutlined,
} from "@mui/icons-material";
import {
  Box,
  Collapse,
  Divider,
  FormHelperText,
  IconButton,
  ListItemButton,
  ListItemIcon,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const facts = [
  {
    id: "myFunFact",
    icon: <CelebrationOutlined />,
    filledIcon: <Celebration />,

    label: "My fun fact",
    placeholder: "Danced in a flash mob in Times Square",
  },
  {
    id: "myAllAbout",
    icon: <InterestsOutlined />,
    filledIcon: <InterestsRounded />,

    label: "I'm all about",
    placeholder: "Organic gardening and homemade salsa",
  },
  {
    id: "mySecretTalent",
    icon: <PaletteOutlined />,
    filledIcon: <Palette />,

    label: "Secret talent",
    placeholder: "Gourmet dishes using only a microwave",
  },
  {
    id: "myFamily",
    icon: <CottageOutlined />,
    filledIcon: <CottageRounded />,

    label: "My family",
    placeholder: "Raising future astronauts and artists",
  },
  {
    id: "myPets",
    icon: <PetsOutlined />,
    filledIcon: <Pets />,

    label: "My pets",
    placeholder: "A turtle that is surprisingly good at hide-n-seek",
  },
];

const ExpandableListItem = ({ values, setValues, setErrors }) => {
  const [openId, setOpenId] = useState(null);

  const handleExpand = (id) => {
    setOpenId(openId === id ? null : id);
  };

  const handleTextChange = (event, id) => {
    const { value } = event.target;
    setValues({ ...values, [id]: value });
  };

  return (
    <div>
      {facts.map((fact) => (
        <React.Fragment key={fact.id}>
          <ListItemButton
            onClick={() => handleExpand(fact.id)}
            disableRipple
            sx={{
              display: "flex",
              flexDirection: "column",
              borderRadius: "15px",
              my: 1,
              px: { xs: 0, sm: 2 },
              py: 1,
              width: "100%",
              cursor: "pointer",
              backgroundColor: "transparent", // Default background
              transition: "background-color 0.3s ease",
              "&:hover": {
                backgroundColor: (theme) =>
                  openId !== fact.id ? "rgba(0, 0, 0, 0.04)" : "transparent",
              },
              "&:focus-visible, &:focus": {
                backgroundColor: "transparent", // Override for focus-visible state
                outline: "none", // Optional: Remove focus outline if visually distracting
              },
              "&:active": {
                backgroundColor: "transparent", // Consistent with other states
              },
              "&.Mui-focusVisible": {
                backgroundColor: "transparent", // Explicitly targeting the class that might be causing the issue
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: "38px",
                  color: values[fact.id] ? "primary.main" : "unset",
                }}
              >
                {values[fact.id] ? fact.filledIcon : fact.icon}
              </ListItemIcon>
              <Typography
                variant="body1"
                fontWeight={"550"}
                // color={values[fact.id] ? "primary" : "unset"}
              >
                {fact.label}
                {values[fact.id] && openId !== fact.id && ":"}
              </Typography>
              {values[fact.id] && openId !== fact.id && (
                <Typography sx={{ ml: 1 }}>{values[fact.id]}</Typography>
              )}
            </Box>
            <Collapse
              in={openId === fact.id}
              timeout="auto"
              unmountOnExit
              sx={{ width: "100%" }}
            >
              <TextField
                fullWidth
                multiline
                variant="outlined"
                value={values[fact.id] || ""}
                onChange={(e) => {
                  e.stopPropagation(); // Prevent event from bubbling up
                  handleTextChange(e, fact.id);
                  setErrors({});
                }}
                onClick={(e) => e.stopPropagation()} // Add this to handle direct clicks
                margin="normal"
                placeholder={fact.placeholder}
                InputProps={{
                  endAdornment: values[fact.id] ? (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent the expansion toggle
                        handleTextChange({ target: { value: "" } }, fact.id); // Clear the field
                      }}
                      edge="end"
                      size="small"
                    >
                      <Close />
                    </IconButton>
                  ) : null,
                }}
                inputProps={{
                  maxLength: 100,
                  style: {
                    backgroundColor: "white", // Set the background color of the input field only
                    // borderRadius: "15px",
                  },
                }}
              />
              <FormHelperText sx={{ textAlign: "right" }}>
                {(values[fact.id] || "").length}/100
              </FormHelperText>
            </Collapse>
          </ListItemButton>

          <Divider />
        </React.Fragment>
      ))}
    </div>
  );
};

export default ExpandableListItem;
