import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import React, { useState } from "react";
import { db } from "../../firebase";
import NameField from "../fields/NameField";
import PhoneField from "../fields/PhoneField";
import StyledChecklist from "../styled/StyledChecklist";

const UserProperty = ({ propsData, isPrivate, userId }) => {
  const [editMode, setEditMode] = useState(false);
  const [error, setError] = useState("");
  // Transform propsData into a state-friendly format
  const initialState = propsData.reduce((acc, section) => {
    section.fields.forEach((field) => {
      acc[field.name] = field.value;
    });
    return acc;
  }, {});

  const [values, setValues] = useState(initialState);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (name, newValue, fieldType) => {
    // Apply transformation for 'name' fieldType
    if (fieldType === "hourlyRate") {
      newValue = parseFloat(parseFloat(newValue).toFixed(2));
    }

    setValues({ ...values, [name]: newValue });
  };

  const handleMultipleChoice = (name, item) => {
    setValues((prevValues) => {
      // Ensure the target field is always an array
      const currentArray = Array.isArray(prevValues[name])
        ? [...prevValues[name]]
        : [];

      if (currentArray.includes(item)) {
        // Remove item if it's already in the array
        const index = currentArray.indexOf(item);
        currentArray.splice(index, 1);
      } else {
        // Add item to the array if it's not already included
        currentArray.push(item);
      }

      return { ...prevValues, [name]: currentArray };
    });
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    // Reset to original values
    setValues(initialState);
    setEditMode(false);
  };

  const handleSave = async () => {
    setEditMode(false);

    // Create a copy of values to avoid direct state mutation
    const updatedValues = { ...values };

    // Convert name fields to lowercase before saving
    Object.keys(updatedValues).forEach((key) => {
      if (key.toLowerCase().includes("name")) {
        updatedValues[key] = updatedValues[key].toLowerCase();
      }
    });

    try {
      // Update the usersPrivate collection
      const privateRef = doc(db, "usersPrivate", userId);
      await updateDoc(privateRef, updatedValues);

      // If the property is public, also update the usersPublic collection
      if (!isPrivate) {
        const publicRef = doc(db, "usersPublic", userId);
        await updateDoc(publicRef, updatedValues);
      }

    } catch (error) {
      console.error("Error updating user properties:", error);
      setError("Failed to update properties. Please try again.");
    }
  };

  return (
    <>
      {propsData.map((prop, index) => (
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <Typography variant="body1" fontWeight={"600"} sx={{ mb: 0 }}>
              {prop.label}
            </Typography>
            {!prop.disableEdit &&
              (!editMode ? (
                <Button
                  variant="text"
                  size="small"
                  sx={{ textTransform: "none" }}
                  onClick={handleEdit}
                >
                  Edit
                </Button>
              ) : (
                <Button
                  onClick={handleCancel}
                  color="primary"
                  variant="text"
                  size="small"
                  sx={{ textTransform: "none" }}
                >
                  Cancel
                </Button>
              ))}
          </Box>

          {editMode ? (
            <>
              <Typography variant="body1" sx={{ mb: 2 }}>
                {prop.editDescription}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    width: "100%",
                  }}
                >
                  {prop.fields.map((field, fieldIndex) => (
                    <>
                      {field.fieldType === "name" && (
                        <NameField
                          key={fieldIndex}
                          name={"firstName"} // Consider dynamic naming if necessary
                          value={values[field.name]}
                          error={error}
                          handleChange={(e) =>
                            handleChange(
                              field.name,
                              e.target.value,
                              field.fieldType
                            )
                          }
                          label={field.label}
                        />
                      )}
                      {field.fieldType === "phone" && (
                        <PhoneField
                          key={fieldIndex}
                          phone={values[field.name]}
                          error={error}
                          handleChange={(e) =>
                            handleChange(
                              field.name,
                              e.target.value,
                              field.fieldType
                            )
                          }
                        />
                      )}
                      {field.fieldType === "hourlyRate" && (
                        <TextField
                          label={field.label}
                          variant="outlined"
                          type="number"
                          value={values[field.name]}
                          onChange={(e) =>
                            handleChange(
                              field.name,
                              e.target.value,
                              field.fieldType
                            )
                          }
                          error={error}
                          helperText={error}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                      {field.fieldType === "multiline" && (
                        <TextField
                          fullWidth
                          label={field.label}
                          variant="outlined"
                          name={field.name}
                          value={values[field.name]}
                          onChange={(e) =>
                            handleChange(
                              field.name,
                              e.target.value,
                              field.fieldType
                            )
                          }
                          multiline
                          rows={4}
                          inputProps={{
                            maxLength: 300,
                          }} 
                          helperText={`${(values[field.name] || "").length}/300`} // Shows the character count
                          FormHelperTextProps={{
                            style: { textAlign: "right" }, // Inline style to align helper text to the right
                          }}
                        />
                      )}
                      {field.fieldType === "multipleChoice" && (
                        <StyledChecklist
                          columns={isMobile ? 1 : 2}
                          items={field.allItems}
                          checkedItems={values[field.name]}
                          onChange={(item) =>
                            handleMultipleChoice(field.name, item)
                          }
                        />
                      )}
                      {field.fieldType === "bookingDaysInAdvance" && (
                        <TextField
                          type="number" // This makes the input a number type, presenting numeric keypad on mobile devices.
                          variant="outlined"
                          name="bookingDaysInAdvance" // Name it appropriately based on its purpose
                          label="Days in advance" // Clear and descriptive labels are crucial for form accessibility.
                          value={values[field.name]} // Ensure this is controlled via your component's state or from formik or similar.
                          onChange={(e) =>
                            handleChange(
                              field.name,
                              e.target.value,
                              field.fieldType
                            )
                          }
                          inputProps={{
                            min: "1", // Assuming you want at least a one-day advance notice. This prevents input of negative numbers.
                            step: "1", // Ensures users can only increment by whole numbers.
                          }}
                        />
                      )}
                      {field.fieldType === "durationDropdown" && (
                        <FormControl sx={{ width: "100%", borderRadius: "5px", }}>
                          <InputLabel id="duration-label">
                            {field.label}
                          </InputLabel>
                          <Select
                            labelId="duration-label"
                            id="duration-select"
                            value={values[field.name]}
                            label={field.label}
                            onChange={(e) =>
                              handleChange(field.name, e.target.value, "number")
                            }
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 48 * 4.5 + 8,
                                  width: 250,
                                },
                              },
                            }}
                          >
                            {field.options.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                      {/* {field.fieldType === "email" && (
                        <PhoneField
                          key={fieldIndex}
                          phone={values[field.name]}
                          error={error}
                          handleChange={(e) =>
                            handleChange(field.name, e.target.value, field.fieldType)
                          }
                        />
                      )} */}
                    </>
                  ))}
                </Box>
                <Button
                  onClick={handleSave}
                  color="primary"
                  variant="contained"
                  size="large"
                  sx={{
                    textTransform: "none",
                    height: "56px",
                    width: { xs: "100%", sm: "150px" },
                  }}
                >
                  Save
                </Button>
              </Box>
            </>
          ) : (
            <Box sx={{ display: "flex", flexDirection: "row", gap: 0.6 }}>
              {prop.fields.map((field, fieldIndex) => (
                <Typography key={fieldIndex} sx={{ color: "text.secondary" }}>
                  {field.fieldType === "hourlyRate" && "$"}
                  {field.value === null ||
                  field.value === undefined ||
                  field.value.length === 0 ? (
                    <i>Not set</i>
                  ) : field.fieldType === "multipleChoice" ? (
                    field.value.join(", ")
                  ) : field.fieldType === "durationDropdown" ? (
                    field.options.find((option) => option.value === field.value)
                      ?.label
                  ) : field.fieldType === "bookingDaysInAdvance" ? (
                    `${field.value} ${field.value == 1 ? "day" : "days"}`
                  ) : (
                    field.value
                  )}
                </Typography>
              ))}
            </Box>
          )}
        </Box>
      ))}
    </>
  );
};

export default UserProperty;
