import styled from "@emotion/styled";
import {
  Check,
  PersonSearchOutlined,
  WavingHandOutlined,
} from "@mui/icons-material";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";

// Styled ToggleButtonGroup
const StyledToggleButtonGroup = styled(ToggleButtonGroup, {
  shouldForwardProp: (prop) => prop !== "selectedIndex", // Prevent selectedIndex from being forwarded
})(({ theme, selectedIndex }) => ({
  position: "relative",
  backgroundColor: theme.palette.grey[200],
  // boxShadow: "inset 0 0 8px rgba(0,0,0,0.2)", // Inner shadow for a subtle 3D effect
  textTransform: "none",
  overflow: "hidden",
  width: "100%",

  "&::after": {
    boxShadow: `
        inset 0 0 8px rgba(0,0,0,0.2),`,
    // 0 0 40px 2px hsla( 190, 100%, 50%, 0.5)`, // Add a vibrant blue glow effect
    content: '""',
    position: "absolute",
    bottom: 0,
    height: "calc(100% - 8px)",
    width: `calc(100% / 2 - 4px)`, // Assuming there are only two toggle buttons
    backgroundColor: theme.palette.primary.main,
    transition: "transform 300ms ease",
    transform: `translateX(${selectedIndex * 100}%)`,
    borderRadius: "12px",
    margin: "4px",
  },
}));

// Styled ToggleButton
const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: theme.palette.common.white,
    backgroundColor: "transparent",
  },
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
  border: "none",
  overflow: "hidden",
  zIndex: 1,
  minWidth: { xs: "auto", sm: "200px" },
  width: "100%",
  textTransform: "none",
}));

const FindOfferHelp = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [alignment, setAlignment] = useState("left");

  const selectedIndex = alignment === "left" ? 0 : 1;
  const swiperRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") {
        setAlignment("left");
      } else if (event.key === "ArrowRight") {
        setAlignment("right");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(selectedIndex);
    }
  }, [selectedIndex]);

  const handleToggle = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const lists = [
    [
      "Increased work opportunities",
      "Extra income on their own schedule",
      "Help others in their community",
    ], // Offer Help List
    [
      "Find helpers to assist with tasks",
      "Easy and transparent booking",
      "Hire directly and save money",
    ], // Find Help List
  ];

  return (
    <Box sx={{ boxSizing: "border-box" }}>
      <Typography
        align={isMobile ? "center" : "left"}
        sx={{ fontWeight: "600", fontSize: "1.15rem", mb: 2 }}
      >
        Why tell a friend to join?
      </Typography>
      <Box sx={{ width: "100%", mb: 1 }}>
        <StyledToggleButtonGroup
          value={alignment}
          exclusive
          selectedIndex={selectedIndex}
          onChange={handleToggle}
          aria-label="View type"
        >
          <StyledToggleButton
            value="left"
            aria-label="offer help"
            disableRipple
          >
            <WavingHandOutlined fontSize="small" sx={{ mr: 1 }} /> As a helper
          </StyledToggleButton>
          <StyledToggleButton
            value="right"
            aria-label="find help"
            disableRipple
          >
            <PersonSearchOutlined fontSize="small" sx={{ mr: 1 }} /> As a
            requester
          </StyledToggleButton>
        </StyledToggleButtonGroup>
      </Box>

      <Box sx={{ width: "330px" }}>
        <Swiper
          onSlideChange={(swiper) =>
            setAlignment(swiper.activeIndex === 0 ? "left" : "right")
          }
          initialSlide={selectedIndex}
          autoHeight={true}
          allowTouchMove={true}
          ref={swiperRef}
        >
          {lists.map((list, index) => (
            <SwiperSlide key={index}>
              <List disablePadding>
                {list.map((reason, i) => (
                  <ListItem key={i}>
                    <ListItemIcon sx={{ minWidth: "32px" }}>
                      <Check color="success" />
                    </ListItemIcon>
                    <Typography variant="subtitle1" color={"text.secondary"}>
                      {reason}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  );
};

export default FindOfferHelp;
