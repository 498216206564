import { Download } from "@mui/icons-material";
import { Box, Fab, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useDialog } from "../../contexts/DialogProvider";

const InstallFab = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { handleOpenInstallApp } = useDialog();

  const isStandalone = () => {
    return (
      window.navigator.standalone ||
      window.matchMedia("(display-mode: standalone)").matches
    );
  };

  if (!isStandalone() && isMobile) {
    return (
      <Fab
        variant="extended"
        sx={{
          position: "fixed",
          bottom: "calc(72px + 16px)",
          mx: "auto",
          width: "200px",
          left: 0,
          right: 0,
          boxSizing: "border-box",
          textTransform: "none",
          backgroundColor: "rgba(0, 0, 0, 0.75)", // Black with subtle opacity
          color: "white", // White text
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.9)", // Slightly less opacity on hover
          },
        }}
        onClick={handleOpenInstallApp}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "max-content",
            color: "white",
            gap: 1,
          }}
        >
          <Download sx={{ color: "white" }} />
          <Typography fontSize={"0.85rem"} fontWeight={"600"}>
            Install app
          </Typography>
        </Box>
      </Fab>
    );
  }

  return null;
};

export default InstallFab;
