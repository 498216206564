import { ArrowCircleRight, History } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  List,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../contexts/AuthProvider";
import { useChat } from "../../contexts/ChatProvider";
import { useUser } from "../../contexts/UserProvider";
import ConversationTitle from "./ConversationTitle";
import MessageItem from "./MessageItem";

const Conversation = ({ conversation }) => {
  const { currentUser } = useAuth();
  const { userPrivateData } = useUser();
  const {
    messagesCache,
    loadAndListenMessages,
    loadMoreMessages,
    noMoreMessages,
    sendMessage,
    markConversationAsRead,
  } = useChat();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const disableHistory = noMoreMessages[conversation?.id];

  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [lastDoc, setLastDoc] = useState(null);
  const [fetchingOlderMessages, setFetchingOlderMessages] = useState(false);

  const [docLimit, setDocLimit] = useState(20);

  const listRef = useRef();
  const messageInputRef = useRef(null);

  const loadingRef = useRef(false);
  const messagesEndRef = useRef(null); // Add this line to define the ref
  const messagesAnchorRef = useRef(null);
  const isFirstMount = useRef(true);

  useEffect(() => {
    if (conversation?.id) {
      loadAndListenMessages(conversation.id);
    }
  }, [conversation?.id, loadAndListenMessages]);

  useEffect(() => {
    if (conversation?.id) {
      console.log("running first mount");
      isFirstMount.current = true; // Set it to false so next time it will use smooth scrolling
    }
  }, [conversation?.id]);

  useEffect(() => {
    // Check if there are messages for the current conversation

    if (messagesCache.has(conversation?.id)) {
      const messagesMap = messagesCache.get(conversation?.id);

      // Convert Map values to an array and sort them
      const sortedMessages = Array.from(messagesMap.values()).sort(
        (a, b) => a.createdAt.toDate() - b.createdAt.toDate()
      );

      setMessages(sortedMessages);
    } else {
      setMessages([]);
    }
  }, [messagesCache, conversation?.id]);

  const isScrolledToBottom = () => {
    const {
      scrollTop = 0,
      clientHeight = 0,
      scrollHeight = 0,
    } = listRef?.current ?? {};
    // Check if the user is scrolled to the bottom. You might need to adjust the threshold.
    return scrollTop + clientHeight >= scrollHeight - 100; // 5 is a threshold in pixels
  };

  // Load previous messages when the top of the list is reached
  const loadPreviousMessages = () => {
    if (!fetchingOlderMessages && conversation?.id) {
      setFetchingOlderMessages(true);
      loadMoreMessages(conversation.id).then(() =>
        setFetchingOlderMessages(false)
      );
      messagesAnchorRef.current?.scrollIntoView({ behavior: "instant" });
    }
  };

  useEffect(() => {
    if (isFirstMount.current) {
      messagesEndRef.current?.scrollIntoView({ behavior: "instant" });
      if (messages && messages.length > 0) {
        isFirstMount.current = false; // Set it to false so next time it will use smooth scrolling
      }
    } else {
      const shouldScroll = isScrolledToBottom();
      if (!shouldScroll) return;
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]); // Depend on messages so it scrolls down on every message update

  const handleSendMessage = () => {
    setNewMessage("");
    messageInputRef.current.focus();
    sendMessage({ message: newMessage, conversation });
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box
      sx={{
        height: "100%",
        position: "relative",
        overflow: "hidden",
        mt: 0,
        display: "flex",
        flexDirection: "column",
        flex: { xs: 1, sm: 0.6 },
      }}
    >
      {!isMobile && (
        <Box sx={{ mx: "auto", mt: 1 }}>
          <ConversationTitle
            conversation={conversation}
            currentUser={currentUser}
          />
        </Box>
      )}
      {conversation ? (
        <>
          <Box
            ref={listRef}
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              height: "10px",
            }}
          >
            {!loading && messages.length >= 20 && !disableHistory && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={loadPreviousMessages}
                  disabled={fetchingOlderMessages}
                  sx={{ mx: "auto", textTransform: "none" }}
                  endIcon={<History />}
                >
                  <Typography variant="caption" color="disabled">
                    Load previous messages
                  </Typography>
                </Button>
              </Box>
            )}
            {loading && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress size={20} />
              </Box>
            )}
            <List
              sx={{
                bgcolor: "background.paper",
              }}
            >
              <div ref={messagesAnchorRef} />
              {messages.map((message) => (
                <MessageItem key={message.id} message={message} />
              ))}
              <div ref={messagesEndRef} />
            </List>
          </Box>
          <Box
            sx={{
              display: "relative",
              alignItems: "center",
              boxShadow: 10,
              boxSizing: "border-box",
              bottom: 0,
              gap: 2,
              zIndex: 10,
              bgcolor: "background.paper",
            }}
          >
            <TextField
              multiline
              fullWidth
              inputRef={messageInputRef}
              variant="outlined"
              placeholder="Type a message"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyDown={(e) => {
                // Check if the Enter key is pressed without the Shift key
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault(); // Prevents the default action (new line) when Enter is pressed
                  handleSendMessage();
                }
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  // Target the root of the OutlinedInput component
                  borderRadius: "20px", // Set the border radius as needed
                  py: 1,
                },
                mb: 2,
                mt: 2,
                px: 1,
                boxSizing: "border-box",
              }}
              onFocus={() => markConversationAsRead(conversation)} // Add onFocus event handler to mark the conversation as read
              InputProps={{
                endAdornment: newMessage ? (
                  <InputAdornment position="end" sx={{ mr: "-6px" }}>
                    <IconButton
                      onClick={handleSendMessage}
                      edge="end"
                      aria-label="send message"
                    >
                      <ArrowCircleRight
                        color="primary"
                        sx={{ fontSize: "2rem", transform: "rotate(-90deg)" }}
                      />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />
          </Box>
        </>
      ) : (
        <Typography
          align="center"
          sx={{ mt: 10, color: "rgba(0, 0, 0, 0.26)", fontStyle: "italic" }}
        >
          Select a conversation
        </Typography>
      )}
    </Box>
  );
};

export default Conversation;
