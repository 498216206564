import { Draw, Edit } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  Rating,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { collection, doc, onSnapshot, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../contexts/AuthProvider";
import { useDialog } from "../../contexts/DialogProvider";
import { useUser } from "../../contexts/UserProvider";
import { db } from "../../firebase";
import { renderRelativeTime } from "../../services/dateServices";
import { convertStateNameToAbbreviation } from "../../services/locationServices";
import { formatName } from "../../services/stringServices";
import MessageBox from "../boxes/MessageBox";
import CopyUrlButton from "../buttons/CopyUrlButton";
import DesktopBackButton from "../buttons/DesktopBackButton";
import ResponsiveDialog from "../dialogs/ResponsiveDialog";
import WriteReviewForm from "../forms/WriteReviewForm";
import ReviewSummary from "../reviews/ReviewSummary";

const ReviewsDash = ({ inputId }) => {
  const [reviewsData, setReviewsData] = useState([]);
  const [openReviewForm, setOpenReviewForm] = useState(false);
  const [reviewee, setReviewee] = useState(null);
  const { handleOpenCreateProfile, handleOpenLoginOrSignUp } = useDialog();
  const { currentUser } = useAuth();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { userPublicData, userPrivateData } = useUser();

  const { id: urlId } = useParams(); // Rename destructured id to avoid naming conflict

  // Determine the id to use, preferring URL parameter over inputId
  const id = urlId || inputId;

  // Listen for real-time updates to reviews
  useEffect(() => {
    if (!id) return;

    const reviewsQuery = query(
      collection(db, "reviews"),
      where("revieweeId", "==", id)
    );
    const unsubscribeReviews = onSnapshot(
      reviewsQuery,
      (querySnapshot) => {
        const fetchedReviews = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setReviewsData(fetchedReviews);
      },
      (error) => {
        console.error("Error fetching reviews: ", error);
      }
    );

    // Cleanup function to unsubscribe when the component unmounts
    return () => {
      unsubscribeReviews();
    };
  }, [id]);

  // Listen for real-time updates to reviewee data
  useEffect(() => {
    if (!id) return;

    const revieweeDocRef = doc(db, "usersPublic", id);
    const unsubscribeReviewee = onSnapshot(
      revieweeDocRef,
      (doc) => {
        if (doc.exists()) {
          setReviewee({ id: doc.id, ...doc.data() });
        } else {
          console.log("No such reviewee!");
        }
      },
      (error) => {
        console.error("Error fetching reviewee: ", error);
      }
    );

    // Cleanup function to unsubscribe when the component unmounts
    return () => {
      unsubscribeReviewee();
    };
  }, [id]);

  const accountSetup =
    userPrivateData?.accountSetup === "complete" ||
    userPrivateData?.accountSetup === "show_success";

  const handleOpenReviewForm = () => {
    if (!currentUser || currentUser?.isAnonymous) {
      handleOpenLoginOrSignUp();
      return;
    }

    if (!accountSetup) {
      handleOpenCreateProfile();
      return;
    }

    setOpenReviewForm(true);
  };

  const handleCloseReviewForm = () => {
    setOpenReviewForm(false);
  };

  function createReviewMessage(firstName, gender) {
    const formatFirstName = formatName(firstName);
    const pronoun = gender === "male" ? "him" : "her";

    return `${formatFirstName} doesn't have any reviews yet. If you've had a great experience with ${pronoun}, you can help out by writing the first review.`;
  }

  return (
    <>
      {!isMobile ? (
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-start",
              height: "52px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
              <DesktopBackButton />

              <Typography
                variant="h4"
                component="h1"
                sx={{ ml: { xs: 0, sm: 0 } }}
              >
                Reviews
              </Typography>
            </Box>
            {reviewee?.id !== currentUser?.uid && (
              <Button
                variant="outlined"
                onClick={handleOpenReviewForm}
                sx={{
                  textTransform: "none",
                  height: { xs: "56px", sm: "auto" },
                  width: { xs: "100%", sm: "100px" },
                  mt: "4px",
                }}
                startIcon={<Edit />}
              >
                Write
              </Button>
            )}
          </Box>
          <Divider sx={{ mx: { xs: -2, sm: 0 }, mb: 2 }} />
        </Box>
      ) : (
        <Button
          variant="text"
          onClick={handleOpenReviewForm}
          sx={{
            textTransform: "none",
            position: "absolute",
            top: 6,
            right: 6,
            height: { xs: "56px", sm: "auto" },
            width: { xs: "86px", sm: "100px" },
          }}
          startIcon={<Edit />}
        >
          Write
        </Button>
      )}
      <Box
        sx={{
          display: "flex",
          position: "relative",
          flexDirection: { xs: "column", sm: "row" },
          gap: { xs: 2, sm: 6 },
        }}
      >
        <Box
          sx={{
            flex: 0.4,
            display: "flex",
            flexDirection: "column",
            position: { xs: "block", sm: "sticky" },
            top: 100,
            height: "300px",
          }}
        >
          <ReviewSummary user={reviewee} />
        </Box>
        <Box sx={{ flex: 0.6 }}>
          {reviewsData.length === 0 ? (
            reviewee?.id === currentUser?.uid ? (
              <MessageBox
                Icon={<Draw color="primary" />}
                headerText="Get your first review"
                messageText={
                  "Encourage a friend, neighbor or family member to write your first review. Having at least one review greatly increases your credibility. Share the link below."
                }
                messageAlign="center"
                customButton={
                  <CopyUrlButton
                    url={`${window.location.origin}/reviews/${
                      currentUser?.uid ? `?ref=${currentUser.uid}` : ""
                    }`}
                    extraText={"your review link"}
                  />
                }
                hideButton={true}
                mt={"48px"}
              />
            ) : (
              <MessageBox
                Icon={<Draw color="primary" />}
                headerText="Be the first"
                messageText={createReviewMessage(
                  reviewee?.firstName,
                  reviewee?.gender
                )}
                messageAlign="center"
                buttonText="Write a review"
                buttonProps={{
                  variant: "contained",
                  color: "primary",
                  textTransform: "none",
                }}
                handleClick={handleOpenReviewForm}
                mt={"48px"}
              />
            )
          ) : (
            <Box sx={{ mt: 2 }}>
              {reviewsData.length > 0 &&
                reviewsData
                  .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds)
                  .map((review, index) => (
                    <Box key={review.id} sx={{ my: 2 }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                          mb: 1,
                        }}
                      >
                        <Avatar
                          src={review.reviewer.avatarUrl}
                          alt={review.reviewer.name}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography variant="subtitle1" fontWeight="600">
                            {review.reviewer.name}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 0.6,
                            }}
                          >
                            <Typography variant="body2">
                              {review.reviewer.city},{" "}
                              {convertStateNameToAbbreviation(
                                review.reviewer.state
                              )}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              •{" "}
                              {renderRelativeTime(
                                new Date(review.createdAt.seconds * 1000),
                                new Date()
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Rating
                        size="small"
                        value={review.rating}
                        precision={0.1}
                        readOnly
                      />
                      <Typography variant="body1">
                        {review.reviewText}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          flexWrap: "wrap",
                          my: 1,
                        }}
                      >
                        {review.tags.map((tag, index) => (
                          <Chip key={index} label={tag} size="small" />
                        ))}
                      </Box>
                      {index < reviewsData.length - 1 && (
                        <Divider sx={{ my: 3 }} />
                      )}

                      {review.comments.map((comment) => (
                        <Box key={comment.commentId} sx={{ ml: 4 }}>
                          <Typography variant="body2">
                            {comment.name}: {comment.commentText}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  ))}
            </Box>
          )}
        </Box>
      </Box>
      <ResponsiveDialog
        open={openReviewForm}
        onClose={handleCloseReviewForm}
        title="Write a Review"
        width="500px"
        anchor="bottom"
        fullHeight={true}
        zIndex={2000}
      >
        <WriteReviewForm
          reviewer={userPublicData}
          reviewee={reviewee}
          handleClose={handleCloseReviewForm}
        />
      </ResponsiveDialog>
    </>
  );
};

export default ReviewsDash;
