import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import DesktopBackButton from "../buttons/DesktopBackButton";
import VisitCard from "../visits/VisitCard";

// Update the function signature to accept props
const VisitDash = ({ inputId }) => {
  const { id: urlId } = useParams(); // Rename destructured id to avoid naming conflict

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // Determine the id to use, preferring URL parameter over inputId
  const id = urlId || inputId;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {!isMobile && (
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-start",
              height: "52px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
              <DesktopBackButton />

              <Typography
                variant="h4"
                component="h1"
                sx={{ ml: { xs: 0, sm: 0 } }}
              >
                Visit Details
              </Typography>
            </Box>
            {/* {userData && (
            <Box sx={{ top: -6, position: "relative" }}>
              <UserShareButton user={userData} />
            </Box>
          )} */}
          </Box>
          <Divider sx={{ mx: { xs: -2, sm: 0 }, mb: 2 }} />
        </Box>
      )}
      <VisitCard visitId={id} key="profile-dash" />
    </>
  );
};

export default VisitDash;
