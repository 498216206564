import { CheckCircle, KeyboardArrowLeft } from "@mui/icons-material";
import { Box, ButtonBase, Chip, CircularProgress, DialogContent, TextField, Typography } from "@mui/material";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { functions } from "../../firebase";

const SmsView = ({ setView, email, showBack, handleClose }) => {
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [smsStatus, setSmsStatus] = useState(null);
  const [smsError, setSmsError] = useState("");

  useEffect(() => {
    if (code.length === 4) {
      verifyCode();
    }
  }, [code]);

  useEffect(() => {
    // Call handleSendSmsCode to send SMS on component mount
    handleSendSmsCode();
  }, []); // Empty dependency array ensures this runs only once when the component mounts


  const handleSendSmsCode = async () => {
    setSmsStatus("sending"); // Set status to 'sending' before the operation
    try {
      console.log("sending code");
      const sendSmsCode = httpsCallable(functions, "sendSmsCode")
      await sendSmsCode({ email });
      setSmsStatus("success"); // Set status to 'success' after successful operation
      setSmsError("");
    } catch (error) {
      console.error("Error sending SMS: ", error);
      setSmsStatus("error"); // Set status to 'error' in case of failure
      setSmsError(
        error.message ||
          "An error occurred while sending the verification code."
      );
    }
  };

  const verifyCode = async () => {
    setLoading(true);
    try {
      // Call your Firebase Function for token validation
      const functions = getFunctions();
      const validateSmsCode = httpsCallable(functions, "validateSmsCode");

      const { data: validationResponse } = await validateSmsCode({
        code,
        email,
      });

      console.log(
        "validationResponse.customToken: ",
        validationResponse.customToken
      );

      if (validationResponse.status !== "success") {
        throw new Error(validationResponse.message);
      }

      const auth = getAuth();
      await signInWithCustomToken(auth, validationResponse.customToken);
      handleClose();
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    // Filter out non-digit characters
    const filteredValue = inputValue.replace(/\D/g, "");

    // Slice the first 4 digits
    const newCode = filteredValue.slice(0, 4);
    setCode(newCode);
  };

  const renderChip = () => {
    if (loading) {
      return (
        <Chip
          icon={<CircularProgress size={20} sx={{ mx: 2 }} color="primary" />}
          label="Signing in..."
          color="primary"
          sx={{ mb: 2 }}
        />
      );
    }

    if (!error && !loading) {
      return (
        <>
          {smsStatus === "sending" && (
            <Chip
              icon={<CircularProgress size={20} sx={{ mx: 2 }} />}
              label="Sending text message..."
              sx={{ mb: 2 }}
            />
          )}
          {smsStatus === "success" && (
            <Chip
              icon={<CheckCircle />}
              label="Text message sent"
              color="success"
              sx={{ mb: 2 }}
            />
          )}
          {smsStatus === "error" && (
            <Chip label={smsError} color="error" sx={{ mb: 2 }} />
          )}
        </>
      );
    }

    if (error && !loading) {
      return <Chip label={error} color="error" sx={{ mb: 2 }} />;
    }
  };

  return (
    <>
      <DialogContent>
        {showBack && (
          <ButtonBase
            onClick={() => setView("LOGIN")}
            disabled={loading}
            sx={{ color: "text.secondary", my: 1 }}
          >
            <KeyboardArrowLeft /> Back
          </ButtonBase>
        )}
        <Typography
          align="left"
          fontWeight={"600"}
          fontSize={"1.15rem"}
          gutterBottom
        >
          Enter 4-digit code
        </Typography>
        <Typography align="left" color={"text.secondary"} sx={{ mb: 2 }}>
          Your code may take a few moments to arrive to your phone.
        </Typography>
        <Box textAlign="center">
          {renderChip()}

          <TextField
            autoFocus
            fullWidth
            value={code}
            onChange={handleInputChange}
            placeholder="_ _ _ _"
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              maxLength: 4,
              autocomplete: "one-time-code",
            }}
            sx={{
              "& input": {
                textAlign: "center",
                fontSize: "1.5rem",
              },
            }}
          />
        </Box>
      </DialogContent>
    </>
  );
};

export default SmsView;
