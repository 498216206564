import { CheckCircle } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { httpsCallable } from "firebase/functions";
import React, { useState } from "react";
import { functions } from "../../firebase";

const MassEmailForm = () => {
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [helpers, setHelpers] = useState(false);
  const [nonHelpers, setNonHelpers] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const handleBodyChange = (event) => {
    setBody(event.target.value);
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    if (emailSent) return;

    event.preventDefault();
    const formattedBody = body.replace(/\n/g, "\\n"); // Translates newlines to '\\n' for proper formatting
    const sendEmail = httpsCallable(functions, "sendMassEmail");

    try {
      await sendEmail({
        subject,
        body: formattedBody,
        isHelper: helpers,
        isNonHelper: nonHelpers,
      });
      console.log("formattedBody: ", formattedBody);
      setEmailSent(true); // Update state to indicate email has been sent
    } catch (error) {
      console.error("Error sending email:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <TextField
            label="Subject"
            variant="outlined"
            value={subject}
            onChange={handleSubjectChange}
            required
          />
          <Typography sx={{mt: 2}}>
            Hi firstName,
          </Typography>
          <TextField
            variant="standard"
            multiline
            rows={10}
            value={body}
            onChange={handleBodyChange}
            required
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={helpers}
                onChange={(e) => setHelpers(e.target.checked)}
              />
            }
            label="Helpers"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={nonHelpers}
                onChange={(e) => setNonHelpers(e.target.checked)}
              />
            }
            label="Non-Helpers"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          startIcon={
            emailSent ? (
              <CheckCircle />
            ) : loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : null
          }
          color={emailSent ? "success" : "primary"} // Change color to green when email is sent
          disabled={
            emailSent || 
            loading ||
            !subject ||
            !body ||
            body.length < 10 ||
            (!helpers && !nonHelpers)
          }
          onClick={handleSubmit}
          sx={{ width: "100%", height: "56px" }}
        >
          {emailSent ? "Sent" : loading ? "Sending..." : "Send Email"}
        </Button>
      </DialogActions>
    </>
  );
};

export default MassEmailForm;
