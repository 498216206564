import { useTheme } from "@emotion/react";
import { KeyboardArrowRight } from "@mui/icons-material";
import {
  Avatar,
  AvatarGroup,
  Box,
  ListItemButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { useAuth } from "../../contexts/AuthProvider";
import { useDialog } from "../../contexts/DialogProvider";
import {
  formatDateWithBoldDay,
  formatTimeRange,
} from "../../services/dateServices";
import { formatName } from "../../services/stringServices";
import DateChip from "../visits/DateChip";

const VisitListItem = ({ visit }) => {
  const { handleOpenVisit } = useDialog();
  const { currentUser } = useAuth();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const userId = currentUser?.uid;

  // Determine the user's role relative to the visit
  const role =
    userId === visit?.requester.id
      ? "requester"
      : visit?.helpers[userId]
      ? "helper"
      : "unknown";

  // Determine the other users based on the role
  let otherUsers = null;
  if (role === "requester") {
    // If it's a requester, list all helpers that are "accepted"
    otherUsers = Object.values(visit?.helpers || {}).filter(
      (helper) => helper.responseStatus === "accepted"
    );
  } else if (role === "helper") {
    // If it's a helper, show the requester
    otherUsers = [visit?.requester];
  }

  function createGreeting(otherUsers, role) {
    if (!otherUsers || otherUsers.length === 0) return "";

    const preposition =
      role === "requester" ? "with" : role === "helper" ? "for" : "";

    // Handling greeting based on number of users
    if (otherUsers.length === 1) {
      const user = otherUsers[0];
      const formattedFirstName = formatName(user?.firstName);
      const initialLastName = formatName(user?.lastName?.charAt(0));
      return `${preposition} ${formattedFirstName} ${initialLastName}.`;
    } else if (otherUsers.length > 1) {
      // For multiple names, join with commas and "and" before the last name
      const allButLast = otherUsers
        .slice(0, -1)
        .map((user) => formatName(user.firstName));
      const lastUser = formatName(otherUsers[otherUsers.length - 1].firstName);
      const names =
        allButLast.length > 0
          ? `${allButLast.join(", ")} and ${lastUser}`
          : lastUser;
      return `${preposition} ${names}`;
    }

    return ""; // Fallback if no valid role or otherUsers data
  }

  return (
    <ListItemButton
      onClick={() => handleOpenVisit(visit?.id)}
      sx={{
        textTransform: "none",
        borderRadius: "15px",
        justifyContent: "space-between",
        my: 0,
        px: 0,
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 2,
          width: "100%",
        }}
      >
        <AvatarGroup max={4} spacing={"small"}>
          {otherUsers?.map((user, index) => (
            <Avatar
              key={index}
              src={user.avatarUrl}
              alt={`${user.firstName} ${user.lastName}`}
              sx={{
                width: 96,
                height: 96,
                borderRadius: "15px",
                bgcolor: user.avatarUrl ? "transparent" : undefined,
              }}
            >
              {user.avatarUrl ? "" : `${user.firstName[0]}${user.lastName[0]}`}{" "}
              {/* Show initials if no avatar URL */}
            </Avatar>
          ))}
        </AvatarGroup>

        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            {formatDateWithBoldDay(visit?.start)}
            {!isMobile && <DateChip date={visit?.start} />}
          </Box>
          {formatTimeRange(visit?.start, visit?.end)}
          <Typography color={"text.secondary"}>
            {createGreeting(otherUsers, role)}
          </Typography>
          {isMobile && <DateChip date={visit?.start} />}
        </Box>
      </Box>
      <KeyboardArrowRight />
    </ListItemButton>
  );
};

export default VisitListItem;
