/* global google */

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonBase,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
// import LinearProgress from '@mui/material/LinearProgress';

import { ExpandMore, KeyboardArrowLeft, Person } from "@mui/icons-material/";

import { Box } from "@mui/system";

import { getAuth, signInAnonymously } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { geohashForLocation } from "geofire-common";
import { useAuth } from "../../contexts/AuthProvider";
import { useDialog } from "../../contexts/DialogProvider";
import { useUser } from "../../contexts/UserProvider";
import { db } from "../../firebase";
import { validateAddress } from "../../services/formServices";

const AddressView = ({
  handleNext = () => {},
  handleBack = () => {},
  canSkip = false,
  showBack = false,
  buttonText = "Continue",
  showDisclaimer = false,
  showLogin = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { userPrivateData } = useUser();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    line1: userPrivateData?.line1 || "",
    line2: userPrivateData?.line2 || "",
    city: userPrivateData?.city || "",
    state: userPrivateData?.state || "",
    zipCode: userPrivateData?.zipCode || "",
    directions: userPrivateData?.directions || "",
    lat: userPrivateData?.lat || "",
    lng: userPrivateData?.lng || "",
    geohash: userPrivateData?.geohash || "",
  });

  const { handleOpenLoginOrSignUp } = useDialog();

  const [placeSelected, setPlaceSelected] = useState(false);

  const addressRef = useRef(null);

  const { currentUser } = useAuth();

  useEffect(() => {
    setValues({
      line1: userPrivateData?.line1 || "",
      line2: userPrivateData?.line2 || "",
      city: userPrivateData?.city || "",
      state: userPrivateData?.state || "",
      zipCode: userPrivateData?.zipCode || "",
      directions: userPrivateData?.directions || "",
      lat: userPrivateData?.lat || "",
      lng: userPrivateData?.lng || "",
      geohash: userPrivateData?.geohash || "",
    });
  }, [userPrivateData]);

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;

    const actualValue = type === "checkbox" ? checked : value;

    setValues((prevValues) => ({ ...prevValues, [name]: actualValue }));

    // Clear error for the field
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    const auth = getAuth();

    if (!currentUser) {
      try {
        await signInAnonymously(auth);
      } catch (err) {
        console.error("error: ", err);
        // Handle the error according to your application's needs
        setLoading(false);
        return; // Early return to prevent further execution
      }
    }

    // Common logic for after account creation or linking
    const uid = auth.currentUser.uid;

    let newError = validateAddress(values);
    if (Object.keys(newError).length > 0) {
      setErrors(newError);
      setLoading(false); // Ensure to stop the loading process here if there are validation errors
      return; // Exit the function if there are errors
    }
    try {
      await setDoc(
        doc(db, "usersPublic", uid),
        {
          city: values.city,
          state: values.state,
          zipCode: values.zipCode,
          geohash6: values.geohash ? values.geohash.substring(0, 6) : undefined,
        },
        { merge: true }
      );

      await setDoc(
        doc(db, "usersPrivate", uid),
        {
          line1: values.line1,
          line2: values.line2,
          city: values.city,
          state: values.state,
          zipCode: values.zipCode,
          directions: values.directions,
          lat: values.lat,
          lng: values.lng,
          geohash: values.geohash,
        },
        { merge: true }
      );

      handleNext();
    } catch (err) {
      console.log("error: ", err);
    } finally {
      setLoading(false);
    }
  };

  const initAutocomplete = () => {
    if (typeof google === "undefined" || !addressRef.current) {
      console.log("Google Maps or address input not found");
      return;
    }

    console.log("Initializing Autocomplete");
    const autocompleteInstance = new google.maps.places.Autocomplete(
      addressRef.current,
      {
        types: ["address"],
      }
    );
    autocompleteInstance.setFields(["address_components", "geometry"]); // Include geometry to get lat/long
    autocompleteInstance.addListener("place_changed", () =>
      onPlaceSelected(autocompleteInstance)
    );
  };

  const onPlaceSelected = (autocompleteInstance) => {
    console.log("Place selected!"); // Add this line

    setPlaceSelected(true);

    const place = autocompleteInstance.getPlace();

    if (!place.address_components) {
      console.log("Returned place contains no address");
      return;
    }
    if (!place.geometry) {
      console.log("Returned place contains no geometry");
      return;
    }

    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();

    // Generate geohash for the location
    const geohash = geohashForLocation([lat, lng]);

    const addressComponents = {};
    place.address_components.forEach((component) => {
      addressComponents[component.types[0]] = component.long_name;
    });
    const line1 = addressComponents.street_number
      ? `${addressComponents.street_number} ${addressComponents.route}`
      : addressComponents.route;
    const line2 = "";
    const city = addressComponents.locality;
    const state = addressComponents.administrative_area_level_1;
    const zipCode = addressComponents.postal_code;

    setValues((prevValues) => ({
      ...prevValues,
      line1,
      line2,
      city,
      state,
      zipCode,
      lat,
      lng,
      geohash,
    }));

    setErrors({}); // Clear all errors
  };

  const loadScript = (callback) => {
    if (typeof google !== "undefined") {
      console.log("Google Maps API already loaded");
      if (typeof callback === "function") {
        return setTimeout(callback, 0); // Delay the callback execution
      }
      return; // If callback is not a function, just return
    }

    const GOOGLE_MAPS_SCRIPT_ID = "google-maps-script";
    const existingScript = document.getElementById(GOOGLE_MAPS_SCRIPT_ID);
    if (existingScript) {
      console.log("Script already exists in the document");
      return;
    }

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyC6k5ohWTgieFep-vs8Wbe6SKWySlSAx9g&libraries=places&callback=initAutocomplete`;
    script.id = GOOGLE_MAPS_SCRIPT_ID;
    script.onload = () => console.log("Google Maps Script loaded successfully");
    script.onerror = () => console.error("Error loading Google Maps Script");
    document.body.appendChild(script);
  };

  useEffect(() => {
    console.log("AddAddress component mounted");
    loadScript(initAutocomplete);
  }, []);

  useEffect(() => {
    window.initAutocomplete = initAutocomplete;
    loadScript();

    // Add z-index override for .pac-container
    const style = document.createElement("style");
    document.head.appendChild(style);
    style.innerHTML = `.pac-container { z-index: 20000 !important; }`; // Set a value that ensures the dropdown is above the modal

    // Cleanup function
    return () => {
      document.head.removeChild(style);
      window.initAutocomplete = null; // Clear the global reference
    };
  }, []);

  return (
    <>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          p: 2,
          minHeight: "300px",
          alignItems: "flex-start",
        }}
      >
        {showBack && (
          <ButtonBase
            onClick={handleBack}
            disabled={loading}
            sx={{ color: "text.secondary", my: 1 }}
          >
            <KeyboardArrowLeft /> Back
          </ButtonBase>
        )}
        <Typography
          align="left"
          fontWeight={"600"}
          fontSize={"1.15rem"}
          gutterBottom
        >
          Confirm your home address
        </Typography>
        <Typography align="left" sx={{ mb: 2 }}>
          Your address lets you discover local helpers.
        </Typography>
        <TextField
          name="line1"
          fullWidth
          label="Address Line 1"
          value={values.line1}
          onChange={handleChange}
          error={!!errors.line1}
          helperText={
            errors.line1 ||
            "Your location within a half-mile radius and your city, state, and zip code are public for local searches. Your full address is shared with your helper upon booking."
          }
          inputRef={addressRef}
        />
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          width="100%" // This will make the Box take up 100% of the parent's width
          maxWidth={"600px"} // This will ensure the Box does not exceed the specified maxWidth
          sx={{
            pb: { xs: 4, sm: 0 },
            mt: 2,
          }}
        >
          {placeSelected && (
            <>
              <div style={{ display: "flex", gap: "10px" }}>
                <TextField
                  fullWidth
                  name="line2"
                  label="Address Line 2"
                  value={values.line2}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  name="city"
                  label="City"
                  value={values.city}
                  onChange={handleChange}
                  error={!!errors.city}
                  helperText={errors.city}
                />
              </div>
              <div style={{ display: "flex", gap: "10px" }}>
                <TextField
                  fullWidth
                  name="state"
                  label="State"
                  value={values.state}
                  onChange={handleChange}
                  error={!!errors.state}
                  helperText={errors.state}
                />
                <TextField
                  fullWidth
                  name="zipCode"
                  label="Zip Code"
                  value={values.zipCode}
                  onChange={handleChange}
                  error={!!errors.zipCode}
                  helperText={errors.zipCode}
                />
              </div>
              <Accordion
                elevation={0}
                style={{
                  boxShadow: "none",
                  padding: 0,
                  border: "none",
                  margin: 0,
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  style={{ padding: 0, border: "none", margin: 0 }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Additional Directions</Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{ padding: 0, border: "none", margin: 0 }}
                >
                  <TextField
                    fullWidth
                    label="Additional directions to find your address"
                    variant="outlined"
                    name="directions"
                    value={values.directions}
                    onChange={handleChange}
                    multiline
                    rows={4}
                  />
                </AccordionDetails>
              </Accordion>
            </>
          )}
        </Box>

        {showLogin && (
          <>
            <Divider sx={{ width: "100%", mb: 3, color: "text.secondary" }}>
              or
            </Divider>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => {
                handleOpenLoginOrSignUp();
                handleBack();
              }}
              startIcon={<Person />} // Using startIcon to place the Avatar inside the button
              sx={{ height: "56px", mb: 3 }}
            >
              Login or Sign Up
            </Button>
          </>
        )}

        {showDisclaimer && (
          <>
            <div style={{ display: "flex", flexGrow: 1 }} />
            <Typography align="left" variant="caption" color={"text.secondary"}>
              <span>
                By selecting <strong>{buttonText}</strong>, I agree to TaskMom's{" "}
                <a
                  href="/terms-of-service"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "black" }} // Added inline style here
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href="/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "black" }} // Added inline style here
                >
                  Privacy Policy
                </a>
                .
              </span>
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "100%",
          }}
        >
          <Button
            disableElevation
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={loading}
            sx={{
              width: "100%",
              height: "56px",
              textTransform: "none",
            }} // Take up half the available width
          >
            {loading ? ( // Conditional rendering based on loading state
              <CircularProgress size={24} color="inherit" />
            ) : (
              <Typography fontWeight={"500"}>{buttonText}</Typography>
            )}
          </Button>

          {canSkip && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: "56px",
                alignItems: "center",
              }}
            >
              <Typography
                align="center"
                onClick={handleNext}
                fontWeight={"500"}
                sx={{ textDecoration: "underline", my: 1, cursor: "pointer" }}
              >
                Skip for now
              </Typography>
            </Box>
          )}
        </Box>
      </DialogActions>
    </>
  );
};

export default AddressView;
