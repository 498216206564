import { Check, Close, EditNote } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  ListItem,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import React, { useState } from "react";
import { db } from "../../firebase";
import TaskTagsField from "../fields/TaskTagsField";

const TagsDescriptionSection = ({
  tags,
  description,
  isPastEvent,
  visitId,
  helperSkills,
  role,
  status,
}) => {
  const [editableTags, setEditableTags] = useState(tags);
  const [editableDescription, setEditableDescription] = useState(description);
  const [editing, setEditing] = useState(false);

  const theme = useTheme();

  const handleSave = async () => {
    const visitRef = doc(db, "visits", visitId);
    try {
      await updateDoc(visitRef, {
        taskTags: editableTags,
        taskDescription: editableDescription,
      });
      setEditing(false);
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const handleCancel = () => {
    setEditableTags(tags);
    setEditableDescription(description);
    setEditing(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: { xs: "flex-start", sm: "flex-start" },
        gap: 1,
      }}
    >
      <Box sx={{ flex: { sm: role === "requester" ? 0.6 : 1 } }}>
        {editing ? (
          <>
            <TextField
              fullWidth
              minRows={4}
              multiline={true}
              label="Description"
              variant="outlined"
              value={editableDescription}
              onChange={(e) => {
                setEditableDescription(e.target.value);
              }}
              placeholder="Click to add tasks"
              sx={{
                mb: 2,
              }}
            />

            <TaskTagsField
              label="Tasks"
              options={helperSkills} // Assuming this is an array of strings
              selectedTags={editableTags}
              onChange={setEditableTags} // Directly pass the setEditableTags function
              sx={{ minWidth: 120, width: "100%" }} // Pass any additional styling
            />
          </>
        ) : (
          <>
            <ListItem sx={{ paddingX: "0px" }}>
              <Box>
                <Typography variant="body1" fontWeight={"600"}>
                  Description
                </Typography>
                <Typography variant="body1">
                  {description || "No tasks specified"}
                </Typography>
              </Box>
            </ListItem>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {tags?.map((value) => (
                <Chip
                  sx={{
                    backgroundColor: theme.palette.primary.lightest,
                    color: theme.palette.primary.main,
                  }}
                  variant="contained"
                  key={value}
                  label={value}
                />
              ))}
            </Box>
          </>
        )}
      </Box>
      {role === "requester" && (status === "confirmed" || status === "open") && (
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "row", sm: "column" },
            width: "100%",
            gap: 1,
            marginTop: 1,
            flex: { sm: 0.4 },
          }}
        >
          {editing ? (
            <>
              <Button
                variant="outlined"
                color="success"
                startIcon={<Check />}
                sx={{
                  textTransform: "none",
                  flexGrow: 0.5,
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  height: "56px",
                }}
                onClick={handleSave}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                color="inherit"
                startIcon={<Close />}
                sx={{
                  textTransform: "none",
                  flexGrow: 0.5,
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  height: "56px",
                }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </>
          ) : (
            <Button
              variant="outlined"
              color="inherit"
              startIcon={<EditNote />}
              sx={{
                textTransform: "none",
                width: "100%",
                border: "1px solid rgba(0, 0, 0, 0.2)",
                color: "text.secondary",
                maxWidth: { xs: "auto", sm: "276px" },
                height: "56px",
                ml: "auto",
              }}
              onClick={() => setEditing(true)}
            >
              Edit
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

export default TagsDescriptionSection;
