import { Cancel, Search } from "@mui/icons-material";
import {
  Avatar,
  Box,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Skeleton,
  TextField,
} from "@mui/material";
import { collection, getDocs, limit, query, where } from "firebase/firestore";
import _ from "lodash"; // Ensure lodash is installed for debounce functionality
import React, { useEffect, useState } from "react";
import { useDialog } from "../../contexts/DialogProvider";
import { db } from "../../firebase";
import { convertStateNameToAbbreviation } from "../../services/locationServices";
import { formatName } from "../../services/stringServices";

function SearchBar({ onSelect = () => {} }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sessionCache, setSessionCache] = useState({});
  const { handleOpenProfile } = useDialog();

  const [isFocused, setIsFocused] = useState(false); // New state to track focus

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => {
    // Delay handling the blur event to allow list item clicks to register
    setTimeout(() => {
      setIsFocused(false);
    }, 100); // Delay of 100ms. Adjust this value as needed.
  };

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    if (searchTerm === "") {
      // Aggregate all unique users from the cache.
      const allCachedUsers = Object.values(sessionCache).flat();
      const uniqueCachedUsersMap = {};

      allCachedUsers.forEach((user) => {
        uniqueCachedUsersMap[user.id] = user;
      });

      const uniqueCachedUsers = Object.values(uniqueCachedUsersMap);

      setUsers(uniqueCachedUsers);
      return;
    }

    if (sessionCache[searchTerm]) {
      // If results for the current term are cached, use them!
      setUsers(sessionCache[searchTerm]);
      return;
    }

    setLoading(true);
    const debouncedSearch = _.debounce(searchUsers, 500); // Debouncing to minimize the number of requests

    debouncedSearch(searchTerm);

    // Cleanup the debounce
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchTerm, sessionCache]);

  const searchUsers = async (searchText) => {

    if (!searchText.trim()) {
      setUsers([]); // or set it to default users list if you have it.
      return;
    }

    setLoading(true);

    // Queries for each field
    const firstNameQuery = query(
      collection(db, "usersPublic"),
      where("firstName", ">=", searchTerm.trim().toLowerCase()),
      where("firstName", "<=", searchTerm.trim().toLowerCase() + "\uf8ff"),
      limit(5)
    );
    const lastNameQuery = query(
      collection(db, "usersPublic"),
      where("lastName", ">=", searchTerm.trim().toLowerCase()),
      where("lastName", "<=", searchTerm.trim().toLowerCase() + "\uf8ff"),
      limit(5)
    );

    const skill = searchText.trim().toLowerCase();

    try {
      // Fetch data separately
      const [firstNameSnapshot, lastNameSnapshot] = await Promise.all([
        getDocs(firstNameQuery),
        getDocs(lastNameQuery),
      ]);

      const resultSet = {}; // Using an object to store users with unique IDs

      // Process each snapshot and add it to the object
      const processSnapshot = (snapshot) => {
        snapshot.forEach((doc) => {
          const userData = { id: doc.id, ...doc.data() };
          resultSet[userData.id] = userData; // uniqueness is maintained based on user IDs
        });
      };

      processSnapshot(firstNameSnapshot);
      processSnapshot(lastNameSnapshot);

      // Convert the object values to an array
      const uniqueUsers = Object.values(resultSet);

      setUsers(uniqueUsers);
      setLoading(false);

      // Storing data in session cache
      setSessionCache((prevState) => {
        return { ...prevState, [searchTerm]: uniqueUsers };
      });
    } catch (error) {
      console.error("Error fetching users: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleListItemClick = (user) => {
    handleOpenProfile(user.id);
    setSearchTerm("");
    // Call the callback function passed through props
    onSelect(user);
  };

  const renderUserList = () => {
    if (loading) {
      return (
        <div>
          <ListItemButton
            sx={{ borderRadius: "15px", m: 1, boxSizing: "border-box" }}
          >
            <Skeleton
              variant="circular"
              width={40}
              height={40}
              sx={{ marginRight: 2 }}
            />
            <ListItemText
              primary={<Skeleton variant="text" width={100} />}
              secondary={<Skeleton variant="text" width={200} />}
            />
          </ListItemButton>
        </div>
      );
    }
    return users.map((user) => (
      <ListItemButton
        key={user.id}
        onClick={() => handleListItemClick(user)}
        sx={{ borderRadius: "15px", m: 1, boxSizing: "border-box" }}
      >
        <Avatar sx={{ marginRight: 2 }} src={user.avatarUrl}></Avatar>
        <ListItemText
          primary={`${formatName(user.firstName)} ${formatName(user.lastName)}`}
          secondary={
            user?.city &&
            user?.state &&
            `${user?.city}, ${convertStateNameToAbbreviation(user?.state)}`
          }
        />
        {/* <Chip label={user.status} /> */}
      </ListItemButton>
    ));
  };

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        maxWidth: "400px",
        mx: "auto",
      }}
    >
      <Paper
        sx={{
          boxShadow: `0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)`,
          overflow: "auto",
          borderRadius: "19px",
          position: "absolute",
          backgroundColor: "white",
          width: "100%", // Match width of the parent Box
          // boxSizing: "border-box",
          height: "auto",
          top: -26, // Position it right below the TextField
          border: isFocused
            ? "1px solid rgba(0, 0, 0, 0.15)"
            : "1px solid rgba(0, 0, 0, 0)",
          left: 0, // Align it with the left edge of the Box
          right: 0, // Align it with the right edge of the Box
          transition: "box-shadow 0.3s ease-in-out", // Smooth transition for shadow
          boxShadow: isFocused ? "0 5px 10px 0 rgba(0,0,0,0.15)" : "none",
          transition: "box-shadow, border, backgroundColor, 0.3s ease-in-out", // Smooth transition for shadow
          "&:hover, &.Mui-focused": {
            boxShadow: "0 5px 10px 0 rgba(0,0,0,0.15)", // Slightly more pronounced shadow on hover
            border: "1px solid rgba(0, 0, 0, 0.15)",
            backgroundColor: "white",
          },
        }}
      >
        <TextField
          placeholder="Search helpers..."
          value={searchTerm}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disableRipple
          sx={{
            padding: "6px",
            backgroundColor: "rgba(0, 0, 0, 0)",
            width: "100%",
            boxSizing: "border-box",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "0px solid rgba(0, 0, 0, 0)",
            },
            "& .MuiOutlinedInput-root": {
              height: "40px",
              py: 0,
              borderRadius: "50px",
              alignItems: "center",
              border: "none",
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "0px solid rgba(0, 0, 0, 0)",
              },
            },
            "& .MuiIconButton-root": {
              padding: "8px", // Adjust button padding if necessary
            },
          }}
          InputProps={{
            sx: {
              // Target the input element for styling
              "&& .MuiInputBase-input": {
                padding: 0, // Set padding to 0
              },
            },
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {searchTerm && (
                  <IconButton
                    aria-label="clear search"
                    onClick={() => setSearchTerm("")}
                    edge="end"
                    sx={{
                      height: "40px", // Ensuring the IconButton does not exceed the TextField height
                      width: "40px", // Adjust width as needed to keep the IconButton square
                    }}
                  >
                    <Cancel />
                  </IconButton>
                )}
              </InputAdornment>
            ),
            style: {
              height: "40px", // Ensure the input field itself does not exceed the desired height
            },
          }}
        />

        {users.length > 0 &&
          isFocused && <List disablePadding>{renderUserList()}</List>}
      </Paper>
    </Box>
  );
}

export default SearchBar;
