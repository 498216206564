import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { formatTimeRangeCompact } from "../../services/dateServices";
import AvailabilityItem from "./AvailabilityItem";
import VisitItem from "./VisitItem";

const DayColumn = ({
  date,
  visits,
  userId,
  timeZone,
  start,
  end,
  index,
  onSelectVisit,
  height,
  availability,
  onCreateAvailability,
  visitsExist,
  type = "self",
  minBookingMinutes = 30,
  onClickAvailability = () => {},
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [dragStart, setDragStart] = useState(null);
  const [dragEnd, setDragEnd] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [touchStartTimeout, setTouchStartTimeout] = useState(null);

  // Convert height to a number for calculations
  const numericHeight = parseInt(height, 10);

  const intervalHeight =
    numericHeight / (end.diff(start, "minutes").minutes / 15); // 600px height divided by number of intervals

  const delay = isMobile ? 100 : 10;

  // console.log("numericHeight: ", numericHeight);
  // console.log("dragging: ", dragging);
  // console.log("dragEnd: ", dragEnd);
  // console.log("dragStart: ", dragStart);

  // Current time marker
  const now = DateTime.local().setZone(timeZone); // Current time in the specified timeZone

  // const isToday = now.isSame(date, "day"); // Check if the column represents today
  const isToday = now.hasSame(date, "day");

  // Extract only the time portion of the current time
  const currentTimeInMinutes = now.hour * 60 + now.minute;

  // Similarly for the start and end of the timeline
  const timelineStartInMinutes = start.hour * 60 + start.minute;
  const timelineEndInMinutes = end.hour * 60 + end.minute;

  // Calculate position
  const currentTimePosition = isToday
    ? ((currentTimeInMinutes - timelineStartInMinutes) /
        (timelineEndInMinutes - timelineStartInMinutes)) *
      numericHeight
    : null;

  // Define the start and end of the day
  let dayVisits = [];
  if (visitsExist) {
    dayVisits = Object.entries(visits) // Convert visits object to an array of [id, visit] pairs
      .filter(([id, visit]) => {
        // Assuming visit.start is a Firestore Timestamp, adjust if it's stored differently
        return DateTime.fromMillis(visit.start.toMillis())
          .setZone(timeZone)
          .hasSame(DateTime.fromMillis(date.toMillis()), "day");
      })
      .map(([id, visit]) => ({ ...visit, id })); // Add the id back to each visit object
  }

  // console.log("dayVisits: ", dayVisits);

  const snapToInterval = (y) => {
    const intervalIndex = Math.round(y / intervalHeight);
    return intervalIndex * intervalHeight;
  };

  const calculateTime = (y) => {
    const minutesFromStart = (y / intervalHeight) * 15;
    // Assuming `start` is the start of the day as a DateTime object
    const timeOfDay = start.plus({ minutes: minutesFromStart });

    // If `date` is not already a Luxon DateTime object, convert it first (not shown here)
    // Ensure `date` is set to the correct day with the calculated hour and minute
    return date.set({
      hour: timeOfDay.hour,
      minute: timeOfDay.minute,
      second: 0,
      millisecond: 0,
    });
  };

  const getTouchY = (event) => {
    const touch = event.touches[0] || event.changedTouches[0];
    const rect = event.currentTarget.getBoundingClientRect();
    return touch.clientY - rect.top;
  };

  const handleMouseDown = (event) => {
    // Clear any existing timeout
    if (touchStartTimeout) {
      clearTimeout(touchStartTimeout);
    }

    const y = event.clientY - event.currentTarget.getBoundingClientRect().top;
    const timeoutId = setTimeout(() => {}, delay);

    handleStart(y);
    setTouchStartTimeout(timeoutId);
  };

  const handleMouseMove = (event) => {
    const y = event.clientY - event.currentTarget.getBoundingClientRect().top;
    handleMove(y);
  };

  const handleMouseUp = () => {
    // console.log("dragEnd, dragStart: ", [dragEnd, dragStart]);
    if (Math.abs(dragEnd - dragStart) < 15) {
      setDragStart(null);
      setDragEnd(null);
      setDragging(false);
      return;
    }

    // Clear the timeout if touch ends before the delay
    if (touchStartTimeout) {
      clearTimeout(touchStartTimeout);
      setTouchStartTimeout(null);
    }
    handleEnd();
  };

  const findTimeBounds = ({ visit }) => {
    // const user = visit?.users[userId];
    let timeWindows = [];

    // if (user && user.windows) {
    //   Object.values(user.windows).forEach((window) => {
    //     const windowStart = DateTime.fromJSDate(window?.start.toDate()).setZone(
    //       visit.timeZone
    //     );
    //     const windowEnd = DateTime.fromJSDate(window?.end.toDate()).setZone(
    //       visit.timeZone
    //     );

    //     timeWindows.push({ start: windowStart, end: windowEnd });
    //   });
    // } else {
    //   // If no specific windows are set for the user, use the visit's start/end times
    let start = DateTime.fromJSDate(visit.start.toDate()).setZone(
      visit.timeZone
    );

    let end = DateTime.fromJSDate(visit.end.toDate()).setZone(visit.timeZone);

    timeWindows.push({ start, end });

    return timeWindows;
  };

  const handleStart = (y) => {
    setDragStart(snapToInterval(y));
    setDragEnd(snapToInterval(y + 10));
    setDragging(true);
  };

  const handleMove = (y) => {
    if (!dragging) {
      if (touchStartTimeout) {
        clearTimeout(touchStartTimeout);
        setTouchStartTimeout(null);
      }
      return;
    }
    setDragEnd(snapToInterval(y));
  };

  const handleEnd = () => {
    setDragging(false);

    if (type === "self" && !isMobile) {
      const startTime = calculateTime(Math.min(dragStart, dragEnd));
      const endTime = calculateTime(Math.max(dragStart, dragEnd));
      // console.log("Drag Start Time:", startTime.toFormat("MM-dd-yy HH:mm"));
      // console.log("Drag End Time:", endTime.toFormat("MM-dd-yy HH:mm"));

      onCreateAvailability({ start: startTime, end: endTime });
    }

    setDragStart(null);
    setDragEnd(null);
    // Additional logic if needed when drag ends
  };

  const handleTouchStart = (event) => {
    // Clear any existing timeout
    if (touchStartTimeout) {
      clearTimeout(touchStartTimeout);
    }

    const touchY = getTouchY(event);
    // Set a timeout to delay the start handling
    const timeoutId = setTimeout(() => {
      handleStart(touchY);
    }, 100); // 400ms delay, adjust as needed

    setTouchStartTimeout(timeoutId);
  };

  const handleTouchMove = (event) => {
    if (!dragging) {
      if (touchStartTimeout) {
        clearTimeout(touchStartTimeout);
        setTouchStartTimeout(null);
      }
      return;
    }

    handleMove(getTouchY(event));

    // Reset the timer on movement
  };

  const handleTouchEnd = (event) => {
    // Clear the timeout if touch ends before the delay
    if (touchStartTimeout) {
      clearTimeout(touchStartTimeout);
      setTouchStartTimeout(null);
    }

    handleEnd();
  };

  return (
    <Box
      sx={{
        borderLeft: "1px solid hsl(0, 0%, 86%)", //index === 0 ? "1px solid hsl(0, 0%, 86%)" : "none",
        borderRight: "none", //"1px solid hsl(0, 0%, 86%)",
        borderTop: "1px solid hsl(0, 0%, 76%)",
        borderBottom: "none", //"1px solid hsl(0, 0%, 86%)",
        boxSizing: "border-box",
        position: "relative",
        height: height,
        flexGrow: 1, // Added for even splitting of width
        padding: 1,
        userSelect: "none", // Disable text selection across browsers
        WebkitTouchCallout: "none", // Disable touch callouts on iOS
        // touchAction: "none", // Disable browser handling of touch gestures
      }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      // onTouchStart={handleTouchStart}
      // onTouchMove={handleTouchMove}
      // onTouchEnd={handleTouchEnd}
    >
      {/* Render the temporary rectangle */}
      {type === "self" && dragging && Math.abs(dragEnd - dragStart) > 15 && (
        <Box
          sx={{
            position: "absolute",
            top: `${Math.min(dragStart, dragEnd)}px`,
            height: `${Math.abs(dragEnd - dragStart)}px`,
            left: 2,
            right: 2,
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            borderRadius: "5px",
            border: "1px solid rgba(0, 0, 0, 0.4)",
            boxSizing: "border-box",
            zIndex: 10,
            overflow: "hidden",
            userSelect: "none",
            textOverflow: "ellipsis", // Add this to truncate text
            display: "flex",
            flexDirection: "column",
            padding: 1,
          }}
        >
          <Typography variant="caption" lineHeight={"0.8rem"}>
            {formatTimeRangeCompact({
              start: calculateTime(Math.min(dragStart, dragEnd)),
              end: calculateTime(Math.max(dragStart, dragEnd)),
              timeZone,
            })}
          </Typography>
        </Box>
      )}
      {isToday && currentTimePosition !== null && (
        <Box
          sx={{
            position: "absolute",
            top: `${currentTimePosition}px`,
            left: 0,
            right: 0,
            height: "1px",
            zIndex: 20,
            backgroundColor: theme.palette.secondary.light,
            "&:before": {
              // Circle at the left edge
              content: '""',
              position: "absolute",
              left: "-5px",
              top: "-4px",
              width: "8px",
              height: "8px",
              backgroundColor: theme.palette.secondary.light,
              borderRadius: "50%",
            },
          }}
        />
      )}
      {dayVisits.map((visit) => {
        const timeWindows = findTimeBounds({ visit });
        return timeWindows.map((window, index) => (
          <VisitItem
            key={`${visit.id}-${index}`}
            visit={visit}
            userId={userId}
            timeZone={timeZone}
            window={window}
            start={start}
            end={end}
            onSelect={onSelectVisit}
          />
        ));
      })}
      {availability &&
        availability.map((window, index) => (
          <AvailabilityItem
            key={index}
            window={window}
            dayStart={start}
            dayEnd={end}
            height={height}
            timeZone={timeZone}
            userId={userId}
            type={type}
            minBookingMinutes={minBookingMinutes}
            onClickAvailability={onClickAvailability}
          />
        ))}
    </Box>
  );
};

export default DayColumn;
