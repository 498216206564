import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { IconButton, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const DesktopBackButton = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const location = useLocation();

  if (isMobile || location.pathname === "/" || window.history.length <= 2) {
    return null;
  }

  return (
    <IconButton
      onClick={() => navigate(-1)}
      sx={{
        alignSelf: "flex-start",
        position: "relative",
        top: 0,
        left: 0,
        zIndex: 900,
      }}
    >
      <ArrowBackIosNewIcon />
    </IconButton>
  );
};

export default DesktopBackButton;
