import { useTheme } from "@emotion/react";
import { Box, Paper, Typography, useMediaQuery } from "@mui/material";
import React from "react";

const AboutUsSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const renderAbout = () => {
    return (
      <>
        <Typography variant="h4" component="h2" gutterBottom>
          Meet the Founders
        </Typography>
        <Typography variant="body1" paragraph>
          For Alex and I, family has always been our top priority. We love
          giving our time freely to families in our lives and have personally
          experienced how an extra hand can bless a busy mom's day.
        </Typography>
        <Typography variant="body1" paragraph>
          We want to help moms thrive, even when friends are busy and family is
          far away. We believe that now is the best time to have kids and to
          raise them up to be a light in the world. That's why we created
          TaskMom, to free your time with the helping hand you wish you had.
        </Typography>
        <Typography variant="body1" fontStyle={"italic"}>
          Olivia & Alex
        </Typography>
        <Typography variant="body1" fontStyle={"italic"}>
          Co-owners
        </Typography>
      </>
    );
  };

  return (
    <>
      {!isMobile ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
            borderRadius: "50px",
            m: { xs: 0, sm: 5 },
          }}
        >
          <Paper
            sx={{
              position: "relative",
              width: { xs: "100vw", sm: "100%" }, // Use 100vw for full viewport width on xs screens
              height: { xs: "auto", sm: "auto" }, // Make it a square by setting height equal to width on xs screens
              backgroundImage:
                'url("https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/public%2Ffamily-photo.jpg?alt=media&token=032d9cf6-dc4d-40cf-a695-f602c058ade4")',
              backgroundSize: "cover",
              backgroundPosition: { xs: "left center", sm: "center" }, // Align left on xs screens
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Box
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                width: { xs: "100%", sm: "30%" },
                p: { xs: 4, sm: 4 },
                m: { xs: 2, sm: 10 },

                borderRadius: "15px",
                boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.1)", // Added slight drop shadow
                backdropFilter: "blur(20px)", // Added frosty effect
              }}
            >
              {renderAbout()}
            </Box>
          </Paper>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.2)",
            backgroundColor: "hsla( 190, 100%, 95%, 1)",
            borderRadius: "25px",
            p: 3,
            m: 2,
          }}
        >
          <Paper
            elevation={0}
            sx={{
              height: "300px",
              backgroundImage:
                'url("https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/public%2Ffamily-photo.jpg?alt=media&token=032d9cf6-dc4d-40cf-a695-f602c058ade4")',
              backgroundSize: "cover",
              backgroundPosition: "left center", // Centered for all sizes
              mb: 2,
            }}
          />
          {renderAbout()}
        </Box>
      )}
    </>
  );
};

export default AboutUsSection;
